import {
  DefaultSortOptions,
  FieldNames, FilterKind, SortOptions, StudyFilterModel
} from '../models';

export class StudyStore {
  constructor(
    studyService,
    router,
    storeConfig = {}
  ) {
    const {
      extraAggregations = null,
      sortOptions = null,
      viewListingInline = false
    } = storeConfig;
    // Default state generator
    const getDefaultState = () => ({
      subscriptionResult: null,
      error: '',
      studies: [],
      featuredStudies: [],
      recentStudies: [],
      totalHits: 0,
      studySuggestions: [],
      homePageSearchCategories: [],
      sidebarSearchCategories: [],
      languages: [],
      sortOptions: sortOptions || DefaultSortOptions,
      // For instance, if you want to have language aggregations,
      // you need to add it to the extra aggregations as this is not enabled for every gallery
      extraAggregations: extraAggregations || [],
      filter: new StudyFilterModel(),
      // Used for resetting infinite loading, see: https://peachscript.github.io/vue-infinite-loading/guide/use-with-filter-or-tabs.html
      infiniteLoaderReset: 0,
      // Rehydrate must take place when clicking on the browser back button (= manual action), in all other scenarios it shouldn't
      rehydrateOnRouteChange: true,
      // When true, and routing is set up accordingly, the studylisting will be viewable inside the gallery
      viewListingInline
    });

    const updateStudiesAndUrl = (state, replaceUrl = false) => {
      // In this case the url gets updated automatically (so no back button was clicked)
      // That's why the state is already correct and should not be rehydrated from the URL params this time
      state.rehydrateOnRouteChange = false;

      // Reset paging and study list
      state.filter.resetPaging();
      state.studies = [];

      // Update/replace URL after filter change
      const routerChange = {
        // In some cases, we're on the home screen (clicking on browsable item)
        // so a redirect to studies is then necessary
        name: 'studies',
        // Append current filter params
        query: state.filter.toQueryParams()
      };
      if (replaceUrl) {
        router.replace(routerChange);
      } else {
        router.push(routerChange);
      }
      state.infiniteLoaderReset++;
    };

    const removeFilter = (state, filter) => {
      const { searchFilters } = state.filter;
      const filterToRemove = searchFilters.find(x => x.isOfKind(filter.fieldName, filter.filterKind));
      if (!filterToRemove) {
        return;
      }
      searchFilters.splice(searchFilters.indexOf(filterToRemove), 1);
    };

    const addFilter = (state, newFilter) => {
      const { searchFilters } = state.filter;

      if (searchFilters.some(x => x.isEqualToFilter(newFilter))) {
        return;
      }

      // Add filter to list of active filters when not already present
      searchFilters.push(newFilter);
    };

    this.state = getDefaultState(); // Initialize state
    this.getters = {
      foundStudies: state => (state.studies || []).filter(x => !x.isSimilar),
      similarStudies: state => (state.studies || []).filter(x => x.isSimilar),
      featuredStudies: state => (state.featuredStudies || []),
      recentStudies: state => (state.recentStudies || []),
      studySuggestions: state => state.studySuggestions || [],
      homePageSearchCategories: state => state.homePageSearchCategories,
      sidebarSearchCategories: state => state.sidebarSearchCategories,
      sidebarLanguages: state => state.languages,
      currentFilter: state => state.filter,
      infiniteLoaderReset: state => state.infiniteLoaderReset,
      subscriptionResult: state => state.subscriptionResult,
      sorting: state => {
        if (!state.filter.sortBy) {
          const firstSortEl = state.sortOptions[0];
          return {
            sortBy: firstSortEl.key,
            direction: firstSortEl.direction
          };
        }

        return {
          sortBy: state.filter.sortBy,
          direction: state.filter.sortDirection
        };
      },
      activeSearchQuery: state => state.filter.searchQuery,
      allFilters: state => state.filter.searchFilters || [],
      selectedTerms: state => state.filter.searchFilters.filter(x => x.filterKind === FilterKind.term) || [],
      genderFilter: state => state.filter.searchFilters.find(x => x.filterKind === FilterKind.mustNotEqual &&
          x.fieldName === FieldNames.gender),
      locationFilter: state => state.filter.searchFilters.find(x => x.filterKind === FilterKind.geoDistance),
      languageFilter: state => state.filter.searchFilters.find(x => x.filterKind === FilterKind.term &&
          x.fieldName === FieldNames.languages),
      ageFilters: state => state.filter.searchFilters.filter(x => x.filterKind === FilterKind.range &&
          [FieldNames.ageMin, FieldNames.ageMax].includes(x.fieldName)),
      // A getter for retrieving a search filter from the list of active filters, by its kind and field name
      filterForKindAndFieldName: state => (filterKind, fieldName) => state.filter.searchFilters.find(x => x.filterKind === filterKind &&
          x.fieldName === fieldName),
      // A getter for retrieving a search filter from the list of active filters, by its kind and field name
      filterForFieldName: state => fieldName => state.filter.searchFilters.find(x => x.fieldName === fieldName)
    };
    this.mutations = {
      forceRehydrateOnUrlChange(state) {
        state.rehydrateOnRouteChange = true;
      },
      updateTotals(state, total) {
        state.totalHits = total;
      },
      setHomepageSearchCategories(state, searchCategories) {
        state.homePageSearchCategories = searchCategories || [];
      },
      setSidebarSearchCategories(state, searchCategories) {
        state.sidebarSearchCategories = searchCategories || [];
      },
      setError(state, error) {
        state.error = error;
      },
      loadLanguagesSuccess(state, languages) {
        state.languages = languages || [];
        state.error = '';
      },
      appendStudies(state, newStudies) {
        if (newStudies && newStudies.length > 0) {
          // Append studies instead of replacing list, for infinite scrolling
          newStudies.forEach(x => state.studies.push(x));
          state.error = '';
          state.studySuggestions = [];
        }
      },
      resetStudiesSearchResult(state) {
        state.studies = [];
        state.homePageSearchCategories = [];
      },
      changeSortBy(state, { sortBy, direction }) {
        // Sort by hasn't changed, abort
        const currentFilter = state.filter;
        if (currentFilter.sortBy === sortBy && currentFilter.sortDirection === direction) {
          return;
        }
        currentFilter.sortBy = sortBy;
        currentFilter.sortDirection = direction;

        updateStudiesAndUrl(state);
      },
      makeFirstSortExplicit(state) {
        // Initially when the user leaves the default sorting (=relevance) on, we don't send it to the backend
        // because in the backend when no sorting is given it takes the "_score" (aka relevance) by default
        // Use this if the user has no sorting yet selected, but we do want to make it explicit (send it to the backed)
        // You would use this when the gallery has custom default sorting options, and the first option is not
        // "relevance" (_score)
        if (!state.filter.sortBy) {
          const firstSortEl = state.sortOptions[0];
          const currentFilter = state.filter;
          currentFilter.sortBy = firstSortEl.key;
          currentFilter.sortDirection = firstSortEl.direction;
        }
      },
      setSearchCategoryCheckedState(_state, { searchCategory, checkedState }) {
        searchCategory.setCheckedState(checkedState);
      },
      replaceSearchFilter(state, { oldFilter, newFilter }) {
        // Sometimes you want to replace one filter for the other in one go
        // E.g.: you want to search for studies which are "active-recruiting", upon clearing
        // you want to search for studies which are NOT "active-recruiting", by using this mutation
        // you can swap the filters in one go not causing two reloads
        removeFilter(state, oldFilter);
        addFilter(state, newFilter);
        updateStudiesAndUrl(state);
      },
      addSearchFilter(state, newFilter) {
        addFilter(state, newFilter);
        updateStudiesAndUrl(state);
      },
      addOrUpdateSearchFilters(state, payload) {
        // Payload can be:
        // - a list of filters directly
        // OR
        // - an object containing: filters + boolean to adjust the router push/replace behavior
        let filters;
        let replaceInsteadOfPushUrl = false; // Replacing the url is an AVMA edge-case
        if (Array.isArray(payload)) {
          filters = payload;
        } else {
          filters = payload.filters;
          replaceInsteadOfPushUrl = payload.replaceUrl;
        }
        const { searchFilters } = state.filter;
        // eslint-disable-next-line no-restricted-syntax
        for (const filter of filters) {
          const existingFilter = searchFilters.find(x => x.isOfKind(filter.fieldName, filter.filterKind));
          if (existingFilter) {
            existingFilter.filterValue = filter.filterValue;
          } else {
            // Add filter to list of active filters when not already present
            searchFilters.push(filter);
          }
        }
        updateStudiesAndUrl(state, replaceInsteadOfPushUrl);
      },
      removeSearchFilters(state, filtersToRemove) {
        // Remove every filter and then update url in one go
        if (filtersToRemove) {
          filtersToRemove.forEach(x => removeFilter(state, x));
        }

        // Remove sorting by location when the location filter was removed
        if (state.filter.sortBy === SortOptions.location &&
            filtersToRemove.some(x => x.filterKind === FilterKind.geoDistance)) {
          state.filter.resetSorting();
        }
        updateStudiesAndUrl(state);
      },
      clearAllFilters(state, updateUrl = true) {
        // Remove sorting by location when active
        if (state.filter.sortBy === SortOptions.location) {
          state.filter.resetSorting();
        }

        state.filter.resetAllFilters();
        // We sometimes don't want to update the url, for instance when leaving the studies page
        // and in the process clearing searchvalues, at that point the url doesn't need to get updated
        if (updateUrl) {
          updateStudiesAndUrl(state);
        }
      },
      enrichSearchFilters(state, enrichedFilters) {
        state.filter.searchFilters = enrichedFilters;
      },
      nextPage(state) {
        state.filter.nextPage();
      },
      searchStudies(state, searchQuery) {
        const currentFilter = state.filter;
        if (currentFilter.searchQuery === searchQuery) {
          return;
        }

        // Performing a new search resets the paging as well as the sorting(direction)
        currentFilter.resetSorting();
        currentFilter.resetPaging();
        currentFilter.searchQuery = searchQuery;
        state.studySuggestions = [];
        updateStudiesAndUrl(state);
      },
      loadSuggestionsSuccess(state, suggestions) {
        state.studySuggestions = suggestions || [];
        state.error = '';
      },
      loadSuggestionsError(state, error) {
        state.studySuggestions = [];
        state.error = error;
      },
      updateStateAfterRouteChange(state, { url, resetLoader }) {
        // When rehydrate is needed (user clicked back button or initial visit), rebuild the state from the URL params
        if (state.rehydrateOnRouteChange) {
          state.filter.resetAllFilters();
          state.filter.resetPaging();
          state.filter.resetSorting();
          state.studies = [];
          state.filter.initializeFromUrl(url);

          // When infinite loader component is already active, it needs to get a resetAllFilters
          if (resetLoader) {
            state.infiniteLoaderReset++;
          }
        } else {
          // Reset the rehydrate flag, so when the user navigates back this method gets executed
          // The flag is only false when we manually updated the state already (see 'updateStudiesAndUrl' method)
          state.rehydrateOnRouteChange = true;
        }
      },
      userSubscribed(state, result) {
        state.subscriptionResult = result;
      },
      loadFeaturedStudiesSuccess(state, studies) {
        state.featuredStudies = studies;
        state.error = '';
      },
      loadFeaturedStudiesError(state, error) {
        state.featuredStudies = [];
        state.error = error;
      },
      loadRecentStudiesSuccess(state, studies) {
        state.recentStudies = studies;
        state.error = '';
      },
      loadRecentStudiesError(state, error) {
        state.recentStudies = [];
        state.error = error;
      }
    };

    this.actions = {
      /**
       *  Load a list of featured studies
       */
      async loadFeaturedStudies({ commit }) {
        try {
          const filter = new StudyFilterModel();
          filter.take = 9;
          const featuredStudies = await studyService.getFeaturedStudies(filter);
          commit('loadFeaturedStudiesSuccess', featuredStudies);
        } catch (error) {
          commit('loadFeaturedStudiesError', error);
        }
      },
      /**
       *  Load a list of recent studies
       */
      async loadRecentStudies({ commit }) {
        try {
          const filter = new StudyFilterModel();
          filter.take = 9;
          const recentStudies = await studyService.getRecentStudies(filter);
          commit('loadRecentStudiesSuccess', recentStudies);
        } catch (error) {
          commit('loadRecentStudiesError', error);
        }
      },
      /**
       *  Load a list of aggregated SearchCategories
       */
      async loadHomePageSearchCategories({ commit }) {
        try {
          const searchCategories = await studyService.getHomePageSearchCategories();
          commit('setHomepageSearchCategories', searchCategories);
        } catch (error) {
          commit('setHomepageSearchCategories', []);
          commit('setError', error);
        }
      },
      /**
       *  Load next page of studies with a pagination or sidebar filter applied
       */
      async loadStudies({ state, commit, getters }, loadingState) {
        try {
          // Don't increase page number on very first load, otherwise first result set gets skipped
          if (getters.foundStudies.length > 0) {
            commit('nextPage');
          }

          const studiesAndAggregations = await studyService.getStudiesAndAggregations(getters.currentFilter, state.extraAggregations);

          // Add search categories to sidebar
          commit('setSidebarSearchCategories', studiesAndAggregations.searchCategories);

          // Keep track of available study languages (+ their counts)
          commit('loadLanguagesSuccess', studiesAndAggregations.languages);

          // Update search summary
          commit('updateTotals', studiesAndAggregations.total);

          // Append studies to study list
          const { studies } = studiesAndAggregations;
          commit('appendStudies', studies);

          // Replace current 'dumb' search filters with enriched ones
          const { enrichedFilters } = studiesAndAggregations;
          commit('enrichSearchFilters', enrichedFilters);

          // Stop the infinite loading(animation) when needed
          if (!studies) {
            loadingState.complete();
          } else if ((studies.some(x => x.isSimilar) || studies.length === 0)) {
            if (getters.foundStudies.length > 0) {
              loadingState.loaded(); // When not setting loaded() and then setting complete(), the user gets a 'No studies found' message
            }
            loadingState.complete(); // Studies contain suggested studies as well, stop further loading
          } else {
            loadingState.loaded();
            if (studies.length < getters.currentFilter.take) {
              loadingState.complete();
            }
          }
        } catch (error) {
          commit('setError', error);
          commit('setSidebarSearchCategories', []);
        }
      },
      /**
       * Load a list of suggestions automatically when typing
       */
      async loadStudySuggestions({ commit }, suggestionQuery) {
        try {
          const suggestions = await studyService.getStudySuggestions(
            suggestionQuery
          );
          // Add suggestions to suggestion container
          commit('loadSuggestionsSuccess', suggestions);
        } catch (error) {
          commit('loadSuggestionsError', error);
        }
      },
      /**
       * Subscribe a user for new studies (with current search filters)
       */
      async subscribeUser({ commit }, subscriptionModel) {
        const result = await studyService.subscribeToStudies(subscriptionModel);
        commit('userSubscribed', result);
      },
      /**
       * When clicking on a suggestion that is a redirect, check in the backend if user is still allowed to view this
       * and redirect
       */
      async redirectToSuggestedStudy({ state }, suggestion) {
        if (state.viewListingInline && suggestion.inlineListingQueryParams) {
          // TODO: get rid of avma specific stuff here
          await window.avmaRouter.push({ name: 'study', params: suggestion.inlineListingQueryParams });
        } else {
          const redirectUrl = await studyService.getRedirectStudyUrl({ studyId: suggestion.id });
          if (redirectUrl) {
            window.location.href = redirectUrl;
          }
        }
      },
      async navigateToStudy({ state }, studyCard) {
        if (state.viewListingInline && studyCard.inlineListingQueryParams) {
          // TODO: get rid of avma specific stuff here
          await window.avmaRouter.push({ name: 'study', params: studyCard.inlineListingQueryParams });
        } else {
          window.location.href = studyCard.studyUrl;
        }
      },
      /**
       * When viewing a studylisting
       */
      async getStudyUrl(_, { studyId, vctCode }) {
        return studyService.getRedirectStudyUrl({ studyId, vctCode });
      },
      /**
       * Checks or unchecks a search category item and in doing so, adds or removes corresponding term filters
       * and eventually updating the study list
       */
      updateFiltersAfterSearchCategoryClicked({ commit }, { searchCategory, checkedState }) {
        // Put the search category checkbox as checked before even calling the backend api
        // This gives the impression of more speed :-)
        commit('setSearchCategoryCheckedState', { searchCategory, checkedState });
        const searchFilter = searchCategory.mapToSearchFilter();

        // When search category checked, add a new term filter
        if (searchCategory.isChecked) {
          commit('addSearchFilter', searchFilter);
        } else {
          // When search category item unchecked, remove from term filters if present
          commit('removeSearchFilters', [searchFilter]);
        }
      }
    };
  }
}
