<template>
  <div class="section-wrapper p-3">
    <div>
      <span class="title-20-bold grey-700">
        {{ heading }}
      </span>
      <slot name="heading-extra"></slot>
    </div>
    <div class="body-14-medium grey-700">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true
    }
  }
};
</script>
