<template>
  <SpBanner banner-action="studies">
    <template #title>
      Browse and connect with actively recruiting studies at Oregon Health & Science University
    </template>
    <template #content>
      <div>
        Clinical trials are research studies in which people help doctors find ways to improve health and treat diseases.
        The results of these research studies, called outcomes, give healthcare providers the data necessary to make advances and improvements in the way doctors treat or prevent disease.
      </div>
      <div class="mt-3">
        OHSU participates in hundreds of trials in a variety of scientific areas and diseases. To find out more about OHSU clinical trials, please see the resources on the ‘About’ tab or browse our study listings.
      </div>
    </template>
  </SpBanner>
  <H2Title margin="my-3">
    Featured Studies
  </H2Title>
  <!-- Featured section -->
  <div class="row my-3">
    <Featured :studies="featuredStudies" />
  </div>

  <BrowseStudiesButton class="mt-2 mb-5" />

  <H2Title margin="my-3">
    Browse studies by
  </H2Title>
  <!-- Browsable sections -->
  <div v-for="(searchCategory, index) in searchCategories" :key="index" class="row my-3">
    <div class="col">
      <BrowsableSection :searchCategory="searchCategory" orderBy="column" @itemClicked="addFilter" />
    </div>
  </div>
</template>

<script>


import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(
      'studies',
      {
        searchCategories: 'homePageSearchCategories',
        featuredStudies: 'featuredStudies'
      }
    )
  },
  created() {
    this.$store.dispatch('studies/loadHomePageSearchCategories');
    this.$store.dispatch('studies/loadFeaturedStudies');
  },
  methods: {
    addFilter(newFilter) {
      this.$store.commit('studies/addSearchFilter', newFilter);
    }
  }
};
</script>
