<script setup>
/**
 * Responsive variant of h3 titles
 */
import { DeviceSize, useResizeListener } from '@yuzulabs/lib-general';

const deviceSize = useResizeListener().currentDeviceSize;
defineProps({
  margin: {
    // Overrule the default h3/mb-2 margin
    type: String,
    required: false
  }
});
</script>

<template>
  <h4 v-if="deviceSize >= DeviceSize.md" :class="[margin ?? '']" class="grey-700">
    <slot></slot>
  </h4>
  <div v-if="deviceSize < DeviceSize.md" :class="[margin ?? 'mb-2']" class="grey-700 h4-mob">
    <slot></slot>
  </div>
</template>
