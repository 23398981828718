<script setup>
import SpTag from './SpTag.vue';

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  statusColor: {
    type: String,
    required: true
  }
});
const tagStyle = { '--sp-tag-label-border': props.statusColor };
const dotStyle = { 'background-color': props.statusColor };
</script>

<template>
  <SpTag :style="tagStyle" :clickable="false" :label="label">
    <div class="dot rounded-circle" :style="dotStyle" />
  </SpTag>
</template>

<style scoped lang="scss">
.dot {
  margin-right: 0.35rem;
  height: 0.625rem;
  width: 0.625rem;
}
</style>
