<script setup>
import SpCloseButton from '../general/SpCloseButton.vue';

const emits = defineEmits(['close']);
</script>

<template>
  <div class="sidebar-modal w-100 h-100 overflow-auto position-fixed d-flex flex-column p-4">
    <div class="pb-3 text-end">
      <SpCloseButton @click="emits('close')" />
    </div>
    <div class="flex-grow-1">
      <slot></slot>
    </div>
  </div>
</template>
<style lang="scss">
body:has(.sidebar-modal) {
  overflow: hidden;
  position: fixed;
}
</style>
<style scoped lang="scss">
.sidebar-modal {
  background-color: var(--sp-general-bg);
  z-index: 1020;
  left: 0;
  top: 0;
}
</style>
