import { createI18n } from 'vue-i18n';
import { mergeDeep } from '@yuzulabs/lib-general';
import { languages } from '../locales';

/**
 * Set up internationalization
 * Add extra gallery specific translations if needed, must have the same structure as index.js in locales
 * Extra translations are then "deep-merged" with the default ones
 * @param storageKey
 * @param extraTranslations
 * @returns {I18n<{en: {}, es: {}} extends Record<string, unknown> ? {en: {}, es: {}} : {}, {messages: {en: {}, es: {}}, fallbackLocale: string, locale: (string|string)}["datetimeFormats"] extends Record<string, unknown> ? {messages: {en: {}, es: {}}, fallbackLocale: string, locale: (string|string)}["datetimeFormats"] : {}, {messages: {en: {}, es: {}}, fallbackLocale: string, locale: (string|string)}["numberFormats"] extends Record<string, unknown> ? {messages: {en: {}, es: {}}, fallbackLocale: string, locale: (string|string)}["numberFormats"] : {}, string|string extends string ? (string|string) : Locale, true> | I18n<{en: {}, es: {}} extends Record<string, unknown> ? {en: {}, es: {}} : {}, {messages: {en: {}, es: {}}, fallbackLocale: string, locale: (string|string)}["datetimeFormats"] extends Record<string, unknown> ? {messages: {en: {}, es: {}}, fallbackLocale: string, locale: (string|string)}["datetimeFormats"] : {}, {messages: {en: {}, es: {}}, fallbackLocale: string, locale: (string|string)}["numberFormats"] extends Record<string, unknown> ? {messages: {en: {}, es: {}}, fallbackLocale: string, locale: (string|string)}["numberFormats"] : {}, string|string extends string ? (string|string) : Locale, false>}
 */
export default function setupTranslations(storageKey, extraTranslations = null) {
  const allMessages = mergeDeep(languages, extraTranslations || {});
  const localLanguage = localStorage.getItem(storageKey);
  return createI18n({
    legacy: false,
    locale: localLanguage || 'en',
    fallbackLocale: 'en',
    messages: allMessages
  });
}
