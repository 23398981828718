<template>
  <div class="row pt-md-4 min-vh-100 general-bg">
    <div class="col col-xl-8 offset-xl-2 d-flex flex-column side-shadow rounded">
      <slot name="content"></slot>
    </div>
    <!-- On small screens, show the side-info on the bottom -->
    <div v-if="$slots['side-info']" class="d-xl-none col-12">
      <div class="w-100">
        <slot name="side-info"></slot>
      </div>
    </div>
    <!-- On large screens, show the side-info on the side and make it sticky -->
    <div v-if="$slots['side-info']" class="col-2 d-none d-xl-block">
      <div class="w-100 sticky-top">
        <slot name="side-info"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
