/**
 * Settings object for the useResizeListener composable
 * Pass queryMappings when listening to define what media queries we're interested in and to what device size they
 * should correspond to
 * Example mapping:
 *
 * const queryDeviceSizequeryMappings = [
 *   {
 *     query: BootstrapQueries.xs,
 *     correspondingSize: DeviceSize.sm
 *   },
 *   {
 *     query: BootstrapQueries.md,
 *     correspondingSize: DeviceSize.md
 *   },
 *   {
 *     query: BootstrapQueries.lgUp,
 *     correspondingSize: DeviceSize.lg
 *   }
 * ];
 */
export class ResizeListenerSettings {
  init(deviceSizeRef, removeEventListenerFunctions) {
    if (!deviceSizeRef) {
      throw new Error('Cannot init ResizeListenerSettings, no device size reference passed');
    }
    this.deviceSizeRef = deviceSizeRef;
    this.removeEventListenerFunctions = removeEventListenerFunctions;
  }

  startListening(queryMappings) {
    if (!queryMappings || !queryMappings.length) {
      throw new Error('Cannot use resizeListener composable, invalid queryMappings provided to ResizeListenerSettings');
    }

    if (queryMappings.length < 2) {
      // You need two queryMappings because the listener only matches when you're in a media query,
      // meaning that with only one query, when you don't match anymore, the deviceSizeRef remains untouched
      // Example: you want to know if you're in mobile view, in that case you should listen to two queries,
      // 1. xs 2. sm, (by default the deviceSizeRef is set to md), then in your component you can check for
      // 1. size < sm 2. size >= sm
      throw new Error('Cannot use resizeListener composable, provide at least two queryMappings');
    }

    // For every query, start a matchMedia change event listener
    queryMappings.forEach(x => {
      const mediaQueryList = window.matchMedia(x.query);

      const handleChangeInMediaQuery = mql => {
        if (mql.matches) {
          // This media query matches, this means we can inform the listening component through
          // the use of the currentDeviceSize ref
          this.deviceSizeRef.value = x.correspondingSize;
        }
      };
      mediaQueryList.addEventListener('change', handleChangeInMediaQuery);
      const removeEventListener = () => {
        mediaQueryList.removeEventListener('change', handleChangeInMediaQuery);
      };
      this.removeEventListenerFunctions.push(removeEventListener);

      // Calculate the initial device size before it is resized
      // Then, the eventlistener takes over when resizing
      if (mediaQueryList.matches) {
        this.deviceSizeRef.value = x.correspondingSize;
      }
    });
  }
}

export default new ResizeListenerSettings();
