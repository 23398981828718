export function useSharedRouterSettings(router, store) {
  // Record every route change that is different from the previous one
  router.afterEach((to, from) => {
    // We don't await the recording because we don't want to slow the route switching
    store.dispatch('general/recordPageView', { toPathName: to && to.name, fromPathName: from && from.name });
  });

  router.beforeEach(async to => {
    if (!to) {
      return false;
    }

    // Make sure gallery settings are loaded before accessing any route
    await store.state.general.gallerySettingsLoaded;

    // Automatically append title part to main title (if present)
    if (to.meta.title) {
      document.title = `${store.state.general.galleryWindowTitle || 'Studypages'} - ${to.meta.title}`;
    }

    return true;
  });
}
