import { constants } from '../constants';
import { StaticPage } from '../components';

// Install function executed by Vue.use()
function getDefaultRouterSetup({
  homeComponent,
  studiesComponent,
  aboutComponent = null,
  contactComponent = null
}) {
  // Unfortunately, there's no other way, because the router is initialized before the vuex store can be used
  const config = window.galleryConfigData;

  const pageTypes = constants.staticPageTypes;
  const routeNames = constants.routeNames;
  const thirdPartyGalleryEnabled = config.thirdPartyGalleryEnabled || false;
  const staticPages = config.staticPages || [];
  const staticAboutPage = staticPages.find(x => x.type === pageTypes.about);
  const staticContactPage = staticPages.find(x => x.type === pageTypes.contact);
  const staticPrivacyPolicyPage = staticPages.find(x => x.type === pageTypes.privacyPolicy);
  const staticTermsPage = staticPages.find(x => x.type === pageTypes.terms);
  const baseUrl = thirdPartyGalleryEnabled ? '/' : process.env.VITE_APP_BASE_URL;
  const homeRoute = thirdPartyGalleryEnabled ? '/' : '/home/';

  // "Always present" routes
  const routes = [
    { path: '/', redirect: { name: routeNames.home } },
    { path: homeRoute, component: homeComponent, name: routeNames.home },
    {
      path: '/studies/',
      component: studiesComponent,
      name: routeNames.studies,
      meta: { hideNavbarSearch: true, title: 'Studies' }
    }
  ];

  // "Optional" routes, which can be its own component or a "static page" (from the backend)
  if (aboutComponent || staticAboutPage) {
    routes.push(
      {
        path: '/about/',
        component: staticAboutPage ? StaticPage : aboutComponent,
        props: staticAboutPage ? { pageHtml: staticAboutPage.html || '' } : null,
        name: routeNames.about,
        meta: { title: 'About' }
      }
    );
  }
  if (contactComponent || staticContactPage) {
    routes.push(
      {
        path: '/contact/',
        component: staticContactPage ? StaticPage : contactComponent,
        props: staticContactPage ? { pageHtml: staticContactPage.html || '' } : null,
        name: routeNames.contact,
        meta: { title: 'Contact' }
      }
    );
  }
  if (staticPrivacyPolicyPage) {
    routes.push(
      {
        path: '/privacy-policy/',
        component: StaticPage,
        props: { pageHtml: staticPrivacyPolicyPage.html || '' },
        name: routeNames.privacyPolicy,
        meta: { title: 'Privacy Policy' }
      }
    );
  }
  if (staticTermsPage) {
    routes.push(
      {
        path: '/terms/',
        component: StaticPage,
        props: { pageHtml: staticTermsPage.html || '' },
        name: routeNames.terms,
        meta: { title: 'Terms' }
      }
    );
  }
  return { routes, baseUrl };
}

export default getDefaultRouterSetup;
