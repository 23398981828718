const vueHideLast = {};

vueHideLast.install = function(app) {
  app.directive('hide-last', {
    created(el, binding) {
      const mustHide = binding.value.index === Object.keys(binding.value.items).length - 1;
      if (mustHide) {
        el.style.display = 'none';
      }
    }
  });
};
export default vueHideLast;
