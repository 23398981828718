import { FieldNames, FilterKind, SearchFilterModel } from '../shared';

/**
 * Used for describing "study tags" in the StudyCard component
 */
export class SimpleGallerySearchCategory {
  constructor(id, tagPath, label, parentLabel, showAsAttributeOnCard, icon) {
    this.id = id;
    this.tagPath = tagPath;
    this.label = label;
    this.parentLabel = parentLabel;
    this.showAsAttributeOnCard = showAsAttributeOnCard;
    this.icon = (icon || '').split(' ');
  }

  mapToSearchFilter() {
    return new SearchFilterModel(
      FieldNames.searchCategoryPaths,
      FilterKind.term,
      this.tagPath
    );
  }

  static createFromBackendData(data) {
    return new SimpleGallerySearchCategory(
      data.id,
      data.tag_path,
      data.label,
      data.parent_label,
      data.show_as_attribute_on_card,
      data.icon
    );
  }
}
