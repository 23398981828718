<script setup>
import { useStore } from 'vuex';
import StudyCardAttributes from './StudyCardAttributes.vue';
import StudyTags from './StudyTags.vue';
import SpStudyStatus from '../../general/SpStudyStatus.vue';
import { StudyCardModel } from '../../../models';
import { useStudyCard } from '../../../composables';

const props = defineProps({
  study: {
    type: StudyCardModel,
    required: true
  }
});
const store = useStore();
const emits = defineEmits(['searchFilterAdded']);
const { addToFilters, navigateToStudy } = useStudyCard(props, emits, store);
</script>

<template>
  <!-- Desktop view -->
  <div class="desktop p-4" @click="navigateToStudy">
    <div class="pb-3 d-flex align-items-center">
      <SpStudyStatus :status-color="study.statusColor" :label="study.status" />
      <div v-if="study.vctCode" class="text-muted ms-auto small-12">
        {{ study.vctCode }}
      </div>
    </div>
    <div class="row">
      <div class="col-7">
        <div class="grey-900 title-20-bold">
          {{ study.title }}
        </div>
        <div class="mt-2 grey-700 body-16 line-clamp">
          <span v-sanitize-html="study.description"></span>
        </div>
      </div>
      <div class="col-5 study-image">
        <img :src="study.thumbnail" alt="study thumbnail image" />
      </div>
    </div>
    <div class="row">
      <div class="col-7">
        <div class="d-flex flex-column justify-content-end h-100">
          <StudyCardAttributes class="my-2" :study="study" />
          <StudyTags :study="study" @clicked="addToFilters($event)" />
        </div>
      </div>
      <div class="col-5 study-image">
        <div v-if="study.topInvestigator" class="d-flex flex-column">
          <div class="small-12 grey-400 my-3">
            {{ $t('studies.main.card.ifInvestigatorTitle') }}
          </div>
          <div class="grey-900 body-14-medium">
            {{ study.topInvestigator }}
          </div>
          <div class="pt-3 my-auto">
            <button class="btn w-100 sp-btn-secondary-icon text-center" @click="navigateToStudy">
              {{ $t('studies.main.learnMore') }}
              <font-awesome-icon class="blue-deep mx-2" icon="angle-right" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "shared/study-card-shared";

.desktop {
  $card-radius: 20px;
  border-radius: $card-radius;
  .study-image {
    img {
      border-radius: 0.625rem;
      height: 225px;
      width: 100%;
      // Automatically scale image and instead of centering, put top first
      object-fit: cover;
      object-position: top;
    }
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.mobile, .desktop {
  background-color: white;
  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 14px 0 rgba(212, 213, 223, 0.76);
  }
}
</style>
