<script setup>
import { useStore } from 'vuex';
import StudyCardAttributes from './StudyCardAttributes.vue';
import StudyTags from './StudyTags.vue';
import SpStudyStatus from '../../general/SpStudyStatus.vue';
import { StudyCardModel } from '../../../models';
import { useStudyCard } from '../../../composables';

const props = defineProps({
  study: {
    type: StudyCardModel,
    required: true
  },
  // Use the "mobile" view, no matter the resolution and set height to 100 because it's contained
  featuredMode: {
    type: Boolean,
    required: false,
    default: false
  }
});
const store = useStore();
const emits = defineEmits(['searchFilterAdded']);
const { addToFilters, navigateToStudy } = useStudyCard(props, emits, store);
</script>

<template>
  <div :class="{ 'h-100': featuredMode }" class="mobile position-relative pb-3 d-flex flex-column" @click="navigateToStudy">
    <SpStudyStatus class="study-status-mobile position-absolute"
                   :status-color="study.statusColor"
                   :label="study.status" />

    <div class="study-image  ">
      <img :src="study.thumbnail" alt="study thumbnail image" />
    </div>
    <div class="study-content flex-grow-1 d-flex flex-column p-3">
      <div v-if="study.vctCode" class="text-muted small-12">
        {{ study.vctCode }}
      </div>
      <div class="title-18-bold grey-900">
        {{ study.title }}
      </div>
      <div class="my-2 body-16 grey-700">
        {{ study.tagline }}
      </div>
      <div v-if="study.topInvestigator" class="my-2 p-2 investigator body-14-medium grey-900">
        <span class="body-14 ps-2">{{ $t('studies.main.card.ifInvestigatorTitle') }}</span>
        {{ study.topInvestigator }}
      </div>
      <StudyCardAttributes class="my-2" :study="study" />
      <StudyTags :study="study" @clicked="addToFilters($event)" />
      <div class="mt-auto pt-3">
        <button class="btn sp-btn-secondary-icon text-center w-100" @click="navigateToStudy">
          {{ $t('studies.main.learnMore') }}
          <font-awesome-icon class="blue-deep mx-2" icon="angle-right" />
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "shared/study-card-shared";
.mobile {
  $card-radius: 16px;
  border-radius: $card-radius;

  .study-status-mobile {
    left: calc($card-radius / 2);
    top: calc($card-radius / 2);
  }

  .study-image {
    overflow: hidden;
    border-top-left-radius: $card-radius;
    border-top-right-radius: $card-radius;
    background-color: var(--mid-grey);
    img {
      height: 175px;
      width: 100%;
      // Automatically scale image and instead of centering, put top first
      object-fit: cover;
      object-position: top;
    }
  }

  .study-content {
    .investigator {
      border-left: 1px solid var(--blue-deep);
    }
  }
}
</style>
