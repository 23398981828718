<template>
  <div>
    <!-- Slot (e.g. for showing subscription box)-->
    <slot></slot>

    <!-- List of studies -->
    <div v-for="item in foundStudies" :key="item.id" class="mb-3">
      <StudyCard :study="item" @searchFilterAdded="onSearchFilterAdded($event)" />
    </div>

    <!-- Show similar studies when no more studies left -->
    <div v-if="similarStudies.length > 0" class="bg-special-blue p-4 mt-2 text-center">
      <div class="title-20-medium-plus grey-600 pb-4">
        {{ $t('studies.main.interestedInTheFollowingTitle') }}
      </div>
      <div v-for="item in similarStudies"
           :key="`similar${item.id}`"
           class="mb-3">
        <StudyCard :study="item"
                   @searchFilterAdded="onSearchFilterAdded($event)">
        </StudyCard>
      </div>
    </div>

    <!-- Infinite loader -->
    <infinite-loading :identifier="resetCounter" @infinite="infiniteHandler">
      <!-- Empty 'no more results' label is intentional-->
      <div>
        <slot name="no-more"></slot>
      </div>
      <template #no-results>
        <slot name="custom-no-results-message">
          <!-- By default it displays: No studies found -->
          <div>{{ $t('studies.main.ifNoResultText') }}</div>
        </slot>
      </template>
    </infinite-loading>
  </div>
</template>

<script>
// import InfiniteLoading from "vue-infinite-loading";

import { InfiniteLoading } from '../../general';
import { StudyCard } from '../card';

export default {
  components: {
    StudyCard,
    InfiniteLoading
  },
  props: {
    foundStudies: {
      type: Array,
      required: true
    },
    similarStudies: {
      type: Array,
      required: true
    },
    // https://peachscript.github.io/vue-infinite-loading/guide/use-with-filter-or-tabs.html
    // This way we know when a filter was updated and the infinite loader must be reset
    resetCounter: {
      type: Number,
      required: false
    }
  },
  emits: ['loadMore', 'searchFilterAdded'],
  methods: {
    infiniteHandler($state) {
      this.$emit('loadMore', $state);
    },
    onSearchFilterAdded(newFilter) {
      this.$emit('searchFilterAdded', newFilter);
    }
  }
};
</script>
<style scoped lang="scss">
.bg-special-blue {
  border-radius: var(--sp-banner-border-radius);
  background-color: #f3f5ff;
}
</style>
