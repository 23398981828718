<template>
  <div>
    <div v-for="(item, index) in items" :key="index" class="form-check form-check-inline">
      <!-- For some reason v-model doesn't work in this scenario :( -->
      <input :id="`radio-${dynamicId}${fieldName}${item.value}`"
             v-model="picked"
             :name="`inlineRadioOptions${dynamicId}`"
             :value="item.value"
             class="form-check-input"
             type="radio">
      <label :for="`radio-${dynamicId}${fieldName}${item.value}`" class="form-check-label">{{ item.label }}</label>
    </div>
  </div>
</template>

<script>

import { FilterKind, SearchFilterModel } from '../../../models';

export default {
  props: {
    filter: {
      type: Object,
      required: false,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    defaultValue: {
      type: String,
      required: false,
      default: 'all'
    }
  },
  emits: ['update'],
  data() {
    return {
      dynamicId: Date.now()
    };
  },
  computed: {
    picked: {
      get() {
        return this.filter ? this.filter.filterValue : this.defaultValue;
      },
      set(newValue) {
        const newFilter = newValue === this.defaultValue ?
          null : new SearchFilterModel(this.fieldName, FilterKind.term, newValue);
        this.$emit('update', {
          oldFilter: this.filter,
          newFilter
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
