<template>
  <!-- Active filter chips -->
  <ActiveFilters :active-filters="allFilters" @clearAll="onClearAllFilters" @clearFilter="onSearchFiltersCleared([$event])" />

  <GenericSection heading="Sex">
    <GenderFilter :genderSearchFilter="genderFilter" @update="onGenderUpdated($event)">
    </GenderFilter>
  </GenericSection>

  <!-- Age section -->
  <GenericSection heading="Age">
    <AgeFilter :ageFilters="ageFilters"
               :question="questionLabel"
               @update="onAgeUpdated($event)">
    </AgeFilter>
  </GenericSection>

  <!-- Options section -->
  <GenericSection heading="Options">
    <BooleanFilter :filter="healthyVolunteerFilter"
                   :field-name="healthyVolunteerFieldName"
                   label="Accepts healthy volunteers"
                   @update="onFilterUpdated($event.oldFilter, $event.newFilter)" />
    <BooleanFilter :filter="hasCompensationFilter"
                   :field-name="compensationFieldName"
                   label="Has compensation"
                   @update="onFilterUpdated($event.oldFilter, $event.newFilter)" />
  </GenericSection>

  <!-- Topic/department/center/... search categories with children -->
  <AggregatedSection v-for="(searchCategory, index) in searchCategories"
                     :key="index"
                     :searchCategory="searchCategory"
                     :selectedTerms="selectedTerms"
                     @itemClicked="onSearchCategoryClicked($event)"
                     @clearSearchFilters="onSearchFiltersCleared($event)">
  </AggregatedSection>
</template>

<script>
import { mapGetters } from 'vuex';
import { FieldNames, FilterKind } from '@yuzulabs/lib-gallery';

export default {
  emits: ['sidebarItemClicked'],
  data() {
    return {
      // questionLabel: 'What\'s your pet\'s age?'
    };
  },
  data() {
    return {
      compensationFieldName: FieldNames.hasCompensation,
      healthyVolunteerFieldName: FieldNames.healthyVolunteer
    };
  },
  computed: {
    ...mapGetters(
      'studies',
      {
        searchCategories: 'sidebarSearchCategories',
        ageFilters: 'ageFilters',
        selectedTerms: 'selectedTerms',
        genderFilter: 'genderFilter',
        filterForKindAndFieldName: 'filterForKindAndFieldName',
        allFilters: 'allFilters'
      }
    ),
    healthyVolunteerFilter() {
      return this.$store.getters['studies/filterForFieldName'](FieldNames.healthyVolunteer);
    }
  },
  methods: {
    onSearchCategoryClicked(itemFilter) {
      this.$store.dispatch('studies/updateFiltersAfterSearchCategoryClicked', itemFilter);
      this.hideSidebarModal();
    },
    hideSidebarModal() {
      // In mobile view the sidebar is shown as a modal overlay
      // When the user taps an item, the modal needs to close automatically
      this.$emit('sidebarItemClicked');
    },
    onSearchFiltersCleared(filters) {
      this.$store.commit('studies/removeSearchFilters', filters);
    },
    onAgeUpdated(updatedAgeFilters) {
      if (updatedAgeFilters.needsRemoval) {
        // When no age was given, just remove the filters when present
        this.$store.commit('studies/removeSearchFilters', updatedAgeFilters.filters);
      } else {
        this.$store.commit('studies/addOrUpdateSearchFilters', updatedAgeFilters.filters);
      }
    },
    onFilterUpdated(currentFilter, newFilter) {
      if (newFilter) {
        this.$store.commit('studies/addOrUpdateSearchFilters', [newFilter]);
      } else if (currentFilter) {
        // 'Any' language selected, remove from active search filters if present
        this.$store.commit('studies/removeSearchFilters', [currentFilter]);
      }
    },
    onGenderUpdated(newGenderFilter) {
      if (newGenderFilter) {
        this.$store.commit('studies/addOrUpdateSearchFilters', [newGenderFilter]);
      } else {
        // When no gender was selected, just remove the filter if it was present
        const genderFilter = this.filterForKindAndFieldName(FilterKind.mustNotEqual, FieldNames.gender);
        if (genderFilter) {
          this.$store.commit('studies/removeSearchFilters', [genderFilter]);
        }
      }
      this.hideSidebarModal();
    },
    onClearAllFilters() {
      this.$store.commit('studies/clearAllFilters', true);
    }
  }
};
</script>
