<script setup>
import {
  computed, nextTick, onMounted, onUnmounted, ref
} from 'vue';
import { DeviceSize, useResizeListener } from '../composables';

const emits = defineEmits(['closed']);
const props = defineProps({
  height: {
    type: [Number, String],
    required: false,
    default: 'fit-content'
  },
  maxHeight: {
    type: String,
    required: false,
    default: '100%'
  },
  width: {
    type: [Number, String],
    required: false,
    default: 'fit-content'
  },
  maxWidth: {
    type: String,
    required: false,
    default: '100%'
  },
  hasCloseButton: {
    type: Boolean,
    required: false,
    default: true
  },
  title: {
    type: String,
    required: false,
    default: null
  },
  centerTitle: {
    type: Boolean,
    required: false,
    default: true
  },
  escOrClickOutsideClosesModal: {
    // Pressing esc or clicking outside will close the modal
    type: Boolean,
    required: false,
    default: true
  },
  // In 99% of the cases, you want to leave this at the default (='hidden')
  // If you want scrolling, it's better to wrap your child component inside a div with overflow-auto
  // Only very rarely you need the overflow to be visible
  // For instance when you have a custom dropdown, and it needs to overflow Modal itself (e.g. the <TypeAhead> component)
  contentOverflow: {
    type: String,
    required: false,
    default: 'hidden'
  }
});
const modal = ref(null);
const scrollYPosition = ref(0);
const deviceSize = useResizeListener().currentDeviceSize;
const model = defineModel();
const modalStyle = computed(() => {
  // In 'mobile view' ignore all given heights and width, just force the modal to be "fullscreen"
  if (deviceSize.value < DeviceSize.md) {
    return {
      height: '100%',
      width: '100%'
    };
  }
  return {
    height: props.height,
    width: props.width,
    'max-height': props.maxHeight,
    'max-width': props.maxWidth,
    'border-radius': '0.4rem' // The modal gets rounded corners when not in fullscreen (aka mobile) view
  };
});

const closeModal = (event, { triggeredByEsc = false, triggeredByCloseOutside = false }) => {
  // The click happened inside the modal itself
  if (triggeredByCloseOutside && modal.value?.contains(event?.target)) {
    return;
  }
  // Clicking the close button always closes the modal, if you don't want this behaviour just don't show the
  // close button :-)
  // When clicking outside, or pressing esc, the prop must allow it
  if ((triggeredByEsc || triggeredByCloseOutside) && !props.escOrClickOutsideClosesModal) {
    return;
  }
  emits('closed');
  model.value = false; // The parent component doesn't need to manually set the 'modalIsVisible' to false anymore
};
onMounted(() => {
  // Next tick needed here because otherwise a previous modal still closing might set overflow to '' right after we
  // set it here to 'hidden'
  nextTick(() => {
    scrollYPosition.value = window.scrollY;
    // We need overflow to be hidden, otherwise the background scrolls instead of the modal
    document.body.style.overflow = 'hidden';
  });
});

onUnmounted(() => {
  document.body.style.overflow = '';
  // Temporary solution while we wait for a modal successor
  // The problem is that upon opening the modal, the scroll position gets lost because the backdrop is fixed
  window.scrollTo(0, scrollYPosition.value);
});

</script>

<template>
  <teleport to="body">
    <div class="custom-modal-backdrop d-flex align-items-center justify-content-center"
         :class="{ 'backdrop-padding': deviceSize >= DeviceSize.md }"
         @mousedown.exact="closeModal($event, { triggeredByCloseOutside: true })">
      <!-- The click outside will pass a truthy var to the closeModal function -->
      <div ref="modal"
           v-focus
           data-testid="modal-popup"
           tabindex="0"
           :style="modalStyle"
           class="custom-modal-wrapper d-flex flex-column"
           @keyup.esc="closeModal($event, { triggeredByEsc: true })">
        <!-- (Optional) title header -->
        <div v-if="hasCloseButton || title || $slots.titleExtra"
             class="d-flex custom-modal-header mb-2 w-100 align-items-center">
          <div class="w-100 h-100 d-flex align-items-center">
            <div v-if="title"
                 data-testid="headlineOfModal"
                 class="modal-title flex-grow-1"
                 :class="{ 'text-center': centerTitle }">
              {{ title }}
            </div>
            <div>
              <slot name="titleExtra" />
            </div>
          </div>
          <!-- Close modal button -->
          <div v-if="hasCloseButton"
               data-testid="closeModelBtn"
               class="close-btn"
               @click.stop="closeModal($event, {})">
            &times;
          </div>
        </div>
        <!-- Modal content -->
        <div :style="{ overflow: contentOverflow }" class="w-100 flex-grow-1 d-flex">
          <slot />
        </div>
      </div>
    </div>
  </teleport>
</template>

<style scoped lang="scss">
$header-height: 31px;
$title-font-size: 19px;

.custom-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  position: fixed;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 9998;
  overflow: hidden auto;
  outline: none;

  &.backdrop-padding {
    padding: 2%;
  }

  .custom-modal-wrapper {
    -webkit-box-shadow: -1px 1px 37px 0px rgba(0, 0, 0, 0.39);
    -moz-box-shadow: -1px 1px 37px 0px rgba(0, 0, 0, 0.39);
    box-shadow: -1px 1px 37px 0px rgba(0, 0, 0, 0.39);
    background-color: white;
    position: relative;
    padding: 0.7rem;

    &:focus-visible {
      outline: none;
    }

    .custom-modal-header {
      height: 31px;

      .modal-title {
        cursor: default;
        font-size: 18px;
        font-weight: 500;
        margin-right: .5rem; // Can also be achieved with `me-2` but we don't want to break BS4 components for now
      }
    }

    .close-btn {
      border-radius: 0.25rem;
      font-weight: 700;
      line-height: 1;
      cursor: pointer;
      background-color: rgba(231, 138, 138, 0.93);
      color: white;
      font-size: 15px;
      padding: 8px 11px;
      text-decoration: none;
      z-index: 9999;

      &:hover {
        background-color: rgba(201, 119, 119, 0.93);
      }
    }
  }
}
</style>
