import {
  GeneralService, GeneralStore, StudyService, StudyStore
} from '@yuzulabs/lib-gallery';
import { createStore } from 'vuex';
import router from '../router';

// eslint-disable-next-line no-undef
const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  strict: debug, // Vuex strict mode: https://vuex.vuejs.org/guide/strict.html
  modules: {
    studies: { ...new StudyStore(new StudyService(process.env.VITE_APP_BASE_URL), router), namespaced: true },
    general: { ...new GeneralStore(new GeneralService(process.env.VITE_APP_BASE_URL)), namespaced: true }
  }
});
