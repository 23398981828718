<script setup>

import { DeviceSize, useResizeListener } from '@yuzulabs/lib-general';
import { computed } from 'vue';

defineProps({
  content: {
    type: String,
    required: false
  },
  imgSource: {
    type: String,
    required: true
  }
});
const deviceSize = useResizeListener().currentDeviceSize;
const titleClass = computed(() => {
  if (deviceSize.value >= DeviceSize.md) {
    return 'h3-mob';
  }
  return deviceSize.value < DeviceSize.sm ? 'h5-mob text-center' : 'h4-mob text-center';
});
const xsMode = computed(() => deviceSize.value <= DeviceSize.xs);
</script>
<template>
  <div v-bind="$attrs" class="position-relative">
    <div class="overflow-hidden rounded">
      <img :src="imgSource" alt="image banner" class="img-fluid">
    </div>
    <div class="position-absolute content-wrapper w-100" :class="{ 'h-100': xsMode }">
      <div class="d-flex h-100 w-100" :class="{ 'align-items-center': xsMode }">
        <div class="row flex-grow-1">
          <div v-if="content" :class="titleClass" class="col-12 col-md-7">
            <div class="px-3" :class="{ 'py-3': deviceSize > DeviceSize.sm }">
              {{ content }}
            </div>
          </div>
          <div v-if="!xsMode"
               :class="[content ? 'col-12 col-md-5' : 'col']"
               class="mt-auto">
            <div class="px-3" :class="{ 'py-3': deviceSize > DeviceSize.sm }">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="xsMode">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.content-wrapper {
  bottom: 0;
  left: 0;
  color: var(--gallery-img-banner-content-color);
  text-shadow: 1px 1px 3px rgba(0,0,0, 0.5);
}
</style>
