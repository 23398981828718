<template>
  <div>
    <select :value="languageFilter?.filterValue || 'any'" class="form-select" @change="languageSwitched($event.target.value)">
      <option value="any">
        {{ langFilterAny }}
      </option>
      <option v-for="item in languages" :key="item.languageCode" :value="item.languageCode">
        {{ item.languageLabel }} ({{ item.count }})
      </option>
    </select>
  </div>
</template>

<script>

import { SearchFilterModel } from '../../models';

export default {
  props: {
    languages: {
      type: Array, // Of type AggregatedLanguageModel
      required: true
    },
    languageFilter: {
      type: SearchFilterModel,
      required: false
    },
    langFilterAny: {
      type: String,
      required: false,
      default: 'Any'
    }
  },
  emits: ['languageSwitched'],
  methods: {
    languageSwitched(languageCode) {
      if (languageCode === 'any') {
        // Reset language selection by emitting null
        this.$emit('languageSwitched', null);
      } else {
        const item = this.languages.find(x => x.languageCode === languageCode);
        this.$emit('languageSwitched', item.mapToSearchFilter());
      }
    }
  }
};
</script>
<style scoped lang="scss">
div {
  max-width: 200px;
}
</style>
