import {
  sanitizedHtml, BootstrapQueries, DeviceSize, ResizeListenerSettings
} from '@yuzulabs/lib-general';
import * as directives from '../directives';
import * as components from '../components';
import { setupFontAwesomeIcons } from './icon-setup';
import 'bootstrap';

// Install function executed by Vue.use()
function installGalleryLib(app) {
  // Register components
  Object.entries(components).forEach(([componentName, component]) => {
    app.component(componentName, component);
  });

  // Register custom directives
  Object.entries(directives).forEach(([, directive]) => {
    directive.install(app);
  });

  // Register external directives
  sanitizedHtml.install(app); // Needed for StaticPage to work

  // Also register the font awesome icons and component
  setupFontAwesomeIcons(app);

  // Start listening for media query changes
  const queryDeviceSizeMappings = [
    { query: BootstrapQueries.xs, correspondingSize: DeviceSize.xs },
    { query: BootstrapQueries.sm, correspondingSize: DeviceSize.sm },
    { query: BootstrapQueries.mdUp, correspondingSize: DeviceSize.md }
  ];
  ResizeListenerSettings.startListening(queryDeviceSizeMappings);
}

export default installGalleryLib;
