<script setup>
import { watch } from 'vue';
import SearchSuggestions from './shared/SearchSuggestions.vue';
import SortStudies from './shared/SortStudies.vue';
import { SpCloseButton } from '../../general';
import { defaultEmits, defaultProps, useStudySearch } from '../../../composables';

const props = defineProps({ ...defaultProps });
const emits = defineEmits([...defaultEmits, 'showModal']);
const {
  searchValue,
  showSuggestions,
  hideSuggestions,
  getSuggestions,
  searchStudies,
  clearSearch,
  onSuggestionClicked,
  displaySuggestions
} = useStudySearch(props, emits);

watch(() => props.activeSearchValue, newValue => {
  searchValue.value = newValue;
  hideSuggestions();
});
</script>
<template>
  <div v-if="!navBarMode" class="d-flex my-3 pt-3">
    <div class="grey-700 title-20-medium-plus">
      {{ totalHits }} {{ $t('studies.main.counterStudiesTitle') }}
    </div>
    <slot name="summary-extra"></slot>
  </div>
  <!-- On outside click, the study suggestions list gets hidden/cleared: https://stackoverflow.com/a/53956904/1100255 -->
  <div v-click-outside="hideSuggestions" class="mb-3 mt-2">
    <!-- Search field -->
    <div class="search-container w-100 d-flex align-items-center">
      <input v-model="searchValue"
             aria-label="Search studies"
             type="text"
             class="search-input flex-grow-1"
             :placeholder="$t('header.searchPlaceholder')"
             @focus="showSuggestions"
             @input="getSuggestions"
             @keyup.enter="searchStudies">
      <div class="input-icons">
        <SpCloseButton v-if="searchValue" @click="clearSearch" />
        <font-awesome-icon class="search-icon" :icon="['fal', 'search']" @click="searchStudies" />
      </div>
    </div>
    <SearchSuggestions v-if="displaySuggestions"
                       :study-suggestions="studySuggestions"
                       :search-value="searchValue"
                       :nav-bar-mode="navBarMode"
                       @suggestion-clicked="onSuggestionClicked($event)"
                       @search-studies="searchStudies()" />
  </div>
  <div v-if="!navBarMode" class="d-flex mt-3 filter-sort-wrapper">
    <div class="w-50">
      <button class="btn w-100 btn-sm sp-btn-primary"
              type="button"
              @click="emits('showModal')">
        <font-awesome-icon :icon="['fal', 'sliders-h']" />
        <span class="ps-2">{{ $t('studies.main.studySearchSmallFiltersTitle') }}</span>
      </button>
    </div>
    <div class="w-50">
      <SortStudies :location-filter-active="locationFilterActive"
                   :sorting="sorting"
                   @sort-by-updated="emits('sortByUpdated', $event)" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './shared/study-search-shared';

.filter-sort-wrapper {
  column-gap: 1rem;
}
</style>
