<script setup>
import { DeviceSize, useResizeListener } from '@yuzulabs/lib-general';
import StudySearchSmall from './StudySearchSmall.vue';
import StudySearchMedium from './StudySearchMedium.vue';
import { defaultEmits, defaultProps } from '../../../composables';

const props = defineProps({ ...defaultProps });
const emits = defineEmits([...defaultEmits, 'showModal']);
const deviceSize = useResizeListener().currentDeviceSize;
</script>

<template>
  <StudySearchMedium v-if="deviceSize >= DeviceSize.md"
                     v-bind="props"
                     @redirect="emits('redirect', $event)"
                     @search="emits('search', $event)"
                     @sort-by-updated="emits('sortByUpdated', $event)"
                     @load-suggestions="emits('loadSuggestions', $event)">
    <template #summary-extra>
      <slot></slot>
    </template>
  </StudySearchMedium>
  <StudySearchSmall v-if="deviceSize < DeviceSize.md"
                    v-bind="props"
                    @redirect="emits('redirect', $event)"
                    @show-modal="emits('showModal')"
                    @search="emits('search', $event)"
                    @sort-by-updated="emits('sortByUpdated', $event)"
                    @load-suggestions="emits('loadSuggestions', $event)">
    <template #summary-extra>
      <slot></slot>
    </template>
  </StudySearchSmall>
</template>

