export class SearchFilterModel {
  constructor(fieldName, filterKind, filterValue, formattedFieldName, formattedFilterValue, gscId, rootGscId) {
    this.filterValue = filterValue;

    // Corresponds to the ElasticSearchFilterKind enum in the backend
    this.filterKind = filterKind;

    // Corresponds to an elasticsearch field that's being searched on, e.g.: sc_paths
    this.fieldName = fieldName;

    // Enriched field name
    this.formattedFieldName = formattedFieldName;

    // Enriched filter value
    this.formattedFilterValue = formattedFilterValue;

    // We need this so we can clear complete root categories and their children
    // E.g. clear 'Topic' should clear all topic children
    this.gallerySearchCategoryId = gscId;
    this.rootGallerySearchCategoryId = rootGscId;
  }

  // Hacky way of checking value equality
  // Used for determining if a filter already is in the filters list
  isEqualTo(fieldName, filterKind, filterValue) {
    return this.isOfKind(fieldName, filterKind) && this.filterValue === filterValue;
  }

  isEqualToFilter(otherFilter) {
    return this.isEqualTo(otherFilter.fieldName, otherFilter.filterKind, otherFilter.filterValue);
  }

  isOfKind(fieldName, filterKind) {
    return this.fieldName === fieldName && this.filterKind === filterKind;
  }

  static mapFromBackend(backendFilter) {
    return new SearchFilterModel(
      backendFilter.field_name,
      backendFilter.filter_kind,
      backendFilter.filter_value,
      backendFilter.formatted_field_name,
      backendFilter.formatted_filter_value,
      backendFilter.gsc_id,
      backendFilter.root_gsc_id
    );
  }

  mapForBackend() {
    return {
      filter_kind: this.filterKind,
      filter_value: this.filterValue,
      field_name: this.fieldName
    };
  }
}

export const FilterKind = {
  term: 'term',
  range: 'range',
  mustNotEqual: 'mustNotEqual',
  match: 'match',
  geoDistance: 'geoDistance'
};
