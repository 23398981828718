<template>
  <div class="grey-600 body-16">
    <H3Title>
      What is a Clinical Research Study?
    </H3Title>
    <p>
      A research study is an organized activity to learn more about a
      problem or answer questions. Many different kinds of studies
      are conducted. For example studies may be done to:
    </p>
    <ul>
      <li>
        Test if a product, such as a drug or equipment, is safe and
        effective.
      </li>
      <li>
        Find out what education practices work best.
      </li>
      <li>
        Determine the best way to treat or prevent an illness.
      </li>
      <li>
        Survey or an interview to understand needs, problems, or
        feelings people have about an important topic.
      </li>
    </ul>
    <H3Title>
      Why should I participate?
    </H3Title>
    <p>
      There are many reasons to take part in research. You may want to:
    </p>
    <ul>
      <li>
        Help other people.
      </li>
      <li>
        Help find a cure for an illness.
      </li>
      <li>
        Help scientists find out more about how the human body and mind work.
      </li>
    </ul>
    <p>
      If you decide to take part in a research study, you do so as a VOLUNTEER.
      That means YOU decide whether or not you will take part. You can say “no”.
      If you choose to do so, you have many important rights, including the right to decide that you want to quit the study at any time.
    </p>
    <H3Title>
      Be Informed
    </H3Title>
    <p>
      Informed consent is the process of learning the key facts about a research study before you decide whether or not to volunteer.
      You should only agree to volunteer in a study if you have a clear understanding of what will take place in
      the study and how it might affect you. Informed consent begins when the research staff explains the facts to you about the research study.
    </p>
    <p>
      The research staff will assist you with the “informed consent document” that goes
      over these facts so you can decide whether or not you want to take part in the study.
      These facts include details about the study, procedures you may receive, the benefits
      and risks that could result, and your rights as a research volunteer.
    </p>
    <H3Title>
      Additional Resources
    </H3Title>

    <div class="my-3">
      <a href="https://www.ohsu.edu/octri/find-and-recruit-participants-you-need" target="_blank">
        OCTRI Recruitment
      </a>
    </div>

    <div class="mt-3">
      <a href="https://www.ohsu.edu/health/clinical-trials" target="_blank">
        OHSU Clinical Trials
      </a>
    </div>

    <div class="mt-3">
      <a href="https://www.ohsu.edu/health/clinical-trials-frequently-asked-questions" target="_blank">
        OHSU Clinical Trials FAQ
      </a>
    </div>

    <div class="mt-3">
      <a href="https://www.ohsu.edu/research-integrity" target="_blank">
        OHSU Research Integrity Office
      </a>
    </div>

    <div class="my-3">
      <a href="https://www.nih.gov/health-information/nih-clinical-research-trials-you" target="_blank">
        National Institutes of Health (NIH) - Clinical Research Trials and You
      </a>
    </div>
  </div>
</template>
<script>

export default {
};
</script>

<style lang="scss" scoped>
</style>
