<template>
  <div class="wrapper rounded d-inline-flex border rounded-pill align-items-center">
    <div class="bool-item m-2 text-center d-flex align-items-center" @click="itemClicked(null)">
      <font-awesome-icon :class="{ selected: currentSelected === undefined }"
                         :icon="['fad', 'question-circle']"
                         class="icon" />
    </div>
    <div class="bool-item m-2 text-center d-flex align-items-center" @click="itemClicked(true)">
      <font-awesome-icon :class="{ selected: currentSelected === true }" :icon="['fad', 'check-circle']" class="icon" />
    </div>
    <div class="bool-item m-2 text-center d-flex align-items-center" @click="itemClicked(false)">
      <font-awesome-icon :class="{ selected: currentSelected === false }" :icon="['fad', 'times-circle']" class="icon" />
    </div>
  </div>
</template>

<script>

import { FilterKind, SearchFilterModel } from '../../../models';

export default {
  props: {
    filter: {
      type: Object,
      required: false,
      default: null
    },
    // This component will be in the 'checked' state when this value matches the filter value from the
    // incoming filter
    checkedFilterValue: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    }
  },
  emits: ['update'],
  computed: {
    currentSelected() {
      if (!this.filter) {
        return undefined;
      }

      // Only selected when a term filter for the exact value is active
      return this.filter.filterKind === FilterKind.term &&
          this.filter.filterValue === this.checkedFilterValue;
    }
  },
  methods: {
    itemClicked(newValue) {
      if (newValue) {
        // User clicked on 'yes/confirm' button
        this.emitUpdate(new SearchFilterModel(this.fieldName, FilterKind.term, this.checkedFilterValue));
      } else if (newValue === null) {
        // User clicked on undecided button
        this.emitUpdate(null);
      } else {
        // User clicked on 'no/deny' button
        this.emitUpdate(new SearchFilterModel(this.fieldName, FilterKind.mustNotEqual, this.checkedFilterValue));
      }
    },
    emitUpdate(newFilter) {
      // User probably clicked the same button again, skip
      if ((!this.filter && !newFilter) ||
          (this.filter && newFilter && this.filter.isEqualToFilter(newFilter))) {
        return;
      }

      this.$emit('update', {
        oldFilter: this.filter,
        newFilter
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: var(--grey-100);
}

.bool-item {
  .icon {
    width: 20px;
    height: 20px;

    --fa-primary-color: white;
    --fa-secondary-color: var(--grey-200);
    --fa-secondary-opacity: 1.0;

    &:hover {
      --fa-secondary-color: var(--grey-300);
      --fa-secondary-opacity: 1.0;
    }

    &.selected, &.selected:hover {
      --fa-secondary-color: var(--grey-400);
      --fa-secondary-opacity: 1.0;
    }
  }

  cursor: pointer;
}
</style>
