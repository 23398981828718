import { ref } from 'vue';
import { ResizeListenerSettings } from '../infrastructure';

export const DeviceSize = {
  xs: 0,
  sm: 10,
  md: 20,
  lg: 30,
  xl: 40
};


// Common media queries to be used in combination with useMatchMediaQuery composable
// Update the list of breakpoints when migrating from bootstrap4 to bootstrap5!!
// https://getbootstrap.com/docs/4.0/layout/grid/#grid-options
const breakpoints = {
  small: 576,
  medium: 768,
  large: 992,
  extraLarge: 1200
};

export const BootstrapQueries = {
  xs: `screen and (max-width: ${breakpoints.small - 1}px)`,
  sm: `screen and (min-width: ${breakpoints.small}px) and (max-width: ${breakpoints.medium - 1}px)`,
  smUp: `screen and (min-width: ${breakpoints.small}px)`,
  md: `screen and (min-width: ${breakpoints.medium}px) and (max-width: ${breakpoints.large - 1}px)`,
  mdUp: `screen and (min-width: ${breakpoints.medium}px)`,
  lg: `screen and (min-width: ${breakpoints.large}px) and (max-width: ${breakpoints.extraLarge - 1}px)`,
  lgUp: `screen and (min-width: ${breakpoints.large}px)`,
  xl: `screen and (min-width: ${breakpoints.extraLarge}px)`
};

const removeEventListenerFunctions = [];
const currentDeviceSize = ref(DeviceSize.md); // Defaults to sensible screen width

const removeAllEventListeners = () => {
  removeEventListenerFunctions.forEach(remove => remove());
};

ResizeListenerSettings.init(currentDeviceSize, removeEventListenerFunctions);

export const useResizeListener = () => ({
  currentDeviceSize,
  removeAllEventListeners
});

