// Organization specific service overrides
export class OrgServiceConfigModel {
  constructor({
    getStudiesAndAggregationsFilterExtraData = null,
    customStudyCardMapper,
    customSuggestionMapper
  }) {
    this.getStudiesAndAggregationsFilterExtraData = getStudiesAndAggregationsFilterExtraData;
    this.customStudyCardMapper = customStudyCardMapper;
    this.customSuggestionMapper = customSuggestionMapper;
  }
}
