<template>
  <div class="sticky-top pb-3 general-bg">
    <StudySearch :activeSearchValue="activeSearchQuery"
                 :sorting="sorting"
                 :studySuggestions="studySuggestions"
                 :locationFilterActive="!!locationFilter"
                 :total-hits="totalHits"
                 @loadSuggestions="onLoadSuggestions($event)"
                 @redirect="onRedirect($event)"
                 @showModal="onShowModal"
                 @search="onSearch($event)"
                 @sortByUpdated="onSortByUpdated($event)" />
  </div>
  <StudyList :foundStudies="foundStudies"
             :resetCounter="infiniteLoaderReset"
             :similarStudies="similarStudies"
             @loadMore="onLoadMore($event)"
             @searchFilterAdded="onSearchFilterAdded($event)">
    <Subscription v-if="subscriptionsEnabled"
                  :currentFilter="currentFilter"
                  :subscriptionResult="subscriptionResult"
                  @subscribed="onSubscribed($event)">
    </Subscription>
  </StudyList>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  emits: ['showModal'],
  computed: {
    ...mapState('studies', ['totalHits']),
    ...mapGetters(
      'studies',
      [
        'foundStudies',
        'similarStudies',
        'infiniteLoaderReset',
        'studySuggestions',
        'sorting',
        'activeSearchQuery',
        'currentFilter',
        'subscriptionResult',
        'locationFilter',
        'recruitmentFilter'
      ]
    ),
    ...mapGetters(
      'general',
      [
        'subscriptionsEnabled'
      ]
    )
  },
  unmounted() {
    this.clearAllFilters(false);
  },
  methods: {
    async onLoadMore($state) {
      await this.$store.dispatch('studies/loadStudies', $state);
    },
    async onRedirect(suggestion) {
      await this.$store.dispatch('studies/redirectToSuggestedStudy', suggestion);
    },
    async onSearch(searchValue) {
      this.$store.commit('studies/searchStudies', searchValue);
    },
    async onSortByUpdated(newOption) {
      this.$store.commit('studies/changeSortBy', newOption);
    },
    async onLoadSuggestions(searchValue) {
      await this.$store.dispatch('studies/loadStudySuggestions', searchValue);
    },
    onShowModal() {
      this.$emit('showModal');
    },
    clearAllFilters(updateUrl = true) {
      this.$store.commit('studies/clearAllFilters', updateUrl);
    },
    onSearchFilterAdded(newFilter) {
      this.$store.commit('studies/addSearchFilter', newFilter);
    },
    async onSubscribed(subscriptionModel) {
      await this.$store.dispatch('studies/subscribeUser', subscriptionModel);
    }
  }
};
</script>
