import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faCheck,
  faExclamation,
  faFemale,
  faList,
  faMale,
  faSortAmountDown,
  faAngleRight,
  faAngleLeft,
  faUser,
  faPhone,
  faPhoneAlt,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';

import { faChevronSquareUp, faLongArrowLeft, faSearch as faSearchSolid } from '@fortawesome/pro-solid-svg-icons';
import {
  faBirthdayCake, faUsers, faPaw, faCodeBranch
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faClipboardList,
  faPlusCircle,
  faShieldVirus,
  faSyringe,
  faVirus,
  faVirusSlash,
  faWatchFitness,
  faSearch,
  faTimes,
  faBallot,
  faVenusMars,
  faMapMarkerAlt,
  faSlidersH,
  faChevronDown,
  faBell,
  faQuestionCircle as faQuestionCircleLight
} from '@fortawesome/pro-light-svg-icons';

import {
  faCheckCircle,
  faQuestionCircle,
  faTimesCircle
} from '@fortawesome/pro-duotone-svg-icons';

/**
 * Initialize font awesome icons and component
 */
export function setupFontAwesomeIcons(app) {
  library.add(faCheck);
  library.add(faExclamation);
  library.add(faSearch);
  library.add(faSearchSolid);
  library.add(faMale);
  library.add(faFemale);
  library.add(faList);
  library.add(faTimes);
  library.add(faUser);
  library.add(faSortAmountDown);
  library.add(faAngleDoubleUp);
  library.add(faAngleDoubleDown);
  library.add(faChevronSquareUp);
  library.add(faClipboardList);
  library.add(faSyringe);
  library.add(faWatchFitness);
  library.add(faShieldVirus);
  library.add(faPlusCircle);
  library.add(faVirus);
  library.add(faVirusSlash);
  library.add(faCheckCircle);
  library.add(faTimesCircle);
  library.add(faQuestionCircle);
  library.add(faAngleRight);
  library.add(faAngleLeft);
  library.add(faPhone);
  library.add(faEnvelope);
  library.add(faBirthdayCake);
  library.add(faUsers);
  library.add(faBallot);
  library.add(faVenusMars);
  library.add(faMapMarkerAlt);
  library.add(faSlidersH);
  library.add(faChevronDown);
  library.add(faPhoneAlt);
  library.add(faBell);
  library.add(faLongArrowLeft);
  library.add(faQuestionCircleLight);
  library.add(faCodeBranch);
  library.add(faPaw);
  app.component('FontAwesomeIcon', FontAwesomeIcon);
}
