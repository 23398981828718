<template>
  <GenericSection :heading="searchCategory.label">
    <template #heading-extra>
      <!-- Uncheck all selected filters for this search category -->
      <a v-if="hasSearchCategoryFilter"
         class="d-inline-block text-info clear-search-cat ms-3"
         href="#"
         @click.prevent="clearSearchCategoryFilters()">
        {{ $t('studies.filter.aggregated.clearSelectionTitle') }}
      </a>
    </template>
    <template #default>
      <!-- All checkbox items -->
      <AggregatedItem v-for="item in visibleItems"
                      :key="item.term + item.label"
                      :item="item"
                      @itemClicked="bubbleItemClickedEvent($event)"></AggregatedItem>

      <!-- Show all/less link with collapse icon -->
      <a v-if="collapseVisible"
         class="text-info"
         href="#"
         @click.prevent="toggleCollapse()">
        <font-awesome-icon v-if="isCollapsed" icon="angle-double-down" />
        <font-awesome-icon v-if="!isCollapsed" icon="angle-double-up" />
        {{ isCollapsed ? 'Show all' : 'Show less' }}
      </a>
    </template>
  </GenericSection>
</template>

<script>
/* eslint-disable no-nested-ternary */
import { AggregatedGscModel, FieldNames } from '../../models';
import AggregatedItem from './AggregatedItem.vue';
import GenericSection from './generic/GenericSection.vue';

export default {
  components: {
    GenericSection,
    AggregatedItem
  },
  props: {
    searchCategory: {
      type: AggregatedGscModel,
      required: true
    },
    selectedTerms: {
      type: Array,
      required: true
    }
  },
  emits: ['itemClicked', 'clearSearchFilters'],
  data() {
    return {
      defaultNumberVisible: 5,
      isCollapsed: true,
      collapseVisible: true
    };
  },
  computed: {
    visibleItems() {
      // When expanded, show everything
      if (!this.isCollapsed) {
        return this.searchCategory.children;
      }

      // When collapsed, show checked first, then unchecked, then disabled
      const sorted = [...this.searchCategory.children].sort((a, b) => {
        const stateScoreA = a.isChecked ? 1 : a.isDisabled ? -1 : 0;
        const stateScoreB = b.isChecked ? 1 : b.isDisabled ? -1 : 0;

        // Different states (e.g. a is checked while b is unchecked)
        if (stateScoreA !== stateScoreB) {
          return stateScoreA > stateScoreB ? -1 : 1;
        }

        // State score and item order is the same
        if (a.order === b.order) {
          return 0;
        }

        // When state score is the same, but text is not, order by 'order' field
        return a.order < b.order ? -1 : 1;
      });

      const visibleItems = [];
      sorted.forEach(x => {
        // Checked items are always visible, even if default number visible is exceeded
        if (x.isChecked) {
          visibleItems.push(x);
        }

        // Max number of items in collapsed state exceeded
        else if (visibleItems.length < this.defaultNumberVisible) {
          visibleItems.push(x);
        }
      });

      return visibleItems;
    },
    hasSearchCategoryFilter() {
      return this.selectedTerms.some(x => x.fieldName === FieldNames.searchCategoryPaths &&
          x.rootGallerySearchCategoryId === this.searchCategory.id);
    }
  },
  created() {
    // Only show collapse button when there's at least 2 more items coming after the default number visible
    const isCollapsible = this.searchCategory.children.length >= this.defaultNumberVisible + 2;
    this.collapseVisible = isCollapsible;
    this.isCollapsed = isCollapsible;
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;

      // When collapsed, scroll back to top of search category
      if (this.isCollapsed) {
        this.$nextTick(() => {
          this.$refs[this.searchCategory.label].scrollIntoView();
        });
      }
    },
    // Bubble up event coming from child menu item
    bubbleItemClickedEvent(eventData) {
      this.$emit('itemClicked', eventData);
    },
    clearSearchCategoryFilters() {
      // When clicking "Clear selection" next to 'Topic' (which is a root GSC)
      // All checked children underneath should be unchecked (= the corresponding search filters must be removed)
      const checkedChildrenGscIds = this.searchCategory.children.filter(x => x.isChecked).map(x => x.id);
      const correspondingFilters = this.selectedTerms.filter(x => checkedChildrenGscIds.some(c => c === x.gallerySearchCategoryId));
      if (correspondingFilters && correspondingFilters.length > 0) {
        this.$emit('clearSearchFilters', correspondingFilters);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.clear-search-cat {
  font-size: 14px;
}
</style>
