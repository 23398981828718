<script setup>
import { computed } from 'vue';

const props = defineProps({
  searchValue: {
    type: String,
    required: false
  },
  studySuggestions: {
    type: Array,
    required: true
  },
  // When the search input is displayed in the navbar, this will change the suggestion container's layout
  navBarMode: {
    type: Boolean,
    required: false,
    default: false
  }
});
const emits = defineEmits(['searchStudies', 'suggestionClicked']);
const showSearchHelpEntry = computed(() => {
  // Only show the first auto-added entry when there are no other topics/keywords/...with the same suggestion label
  if (props.studySuggestions.length === 0) {
    return true;
  }
  return (props.searchValue && !props.studySuggestions.some(x => x.suggestionLabel === props.searchValue));
});
const searchStudies = () => emits('searchStudies');
const onSuggestionClicked = item => emits('suggestionClicked', item);
</script>

<template>
  <div v-if="searchValue" class="position-relative">
    <div :class="{ 'grow-left': navBarMode }" class="suggestion-container border border-top-0">
      <!-- First entry always gives the option to perform a search (same as pressing enter or clicking search icon) -->
      <div v-if="showSearchHelpEntry"
           class="suggestion-item body-14 text-truncate"
           @click.stop.prevent="searchStudies()">
        <font-awesome-icon class="me-2 text-secondary" icon="search" />
        {{ searchValue }}
      </div>
      <!-- List of suggestions -->
      <div v-for="(item, index) in studySuggestions"
           :key="index"
           class="suggestion-item d-flex body-14 align-items-center"
           @click.stop.prevent="onSuggestionClicked(item)">
        <img v-if="item.thumbnail"
             :src="item.thumbnail"
             alt="study thumbnail image"
             class="img-fluid rounded limited me-2" />
        <div v-else-if="item.isInvestigator" class="avenir-dem">
          <font-awesome-icon class="me-2 text-secondary" icon="user" />
        </div>
        <div v-else class="avenir-dem">
          <font-awesome-icon class="me-2 text-secondary" icon="search" />
        </div>
        <div class="line-clamp">
          {{ item.suggestionLabel }}
        </div>
      </div>
      <!-- Suggestions extra search button-->
      <div v-if="studySuggestions && studySuggestions.length" class="text-center">
        <button class="btn btn-sm btn-secondary"
                type="button"
                @click="searchStudies()">
          {{ $t('studies.main.searchSuggestions') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.suggestion-container {
  margin-top: 10px;
  -webkit-box-shadow: 0 4px 10px 0 rgba(205, 205, 205, 0.46);
  -moz-box-shadow: 0 4px 10px 0 rgba(205, 205, 205, 0.46);
  box-shadow: 0 4px 10px 0 rgba(205, 205, 205, 0.46);
  position: absolute;
  width: 100%;
  z-index: 666;
  background-color: white;
  border-radius: 6px;
  border-top: 1px solid var(--mid-grey);
  color: var(--grey-700);

  img.limited {
    max-width: 40px;
  }

  // Activated when search in displayed in navbar, then the suggestion container would be too small without this fix
  &.grow-left {
    right: 0;
  }

  & > div {
    padding: 20px;
    cursor: default;

    &.suggestion-item {
      cursor: pointer;

      &:hover {
        background-color: #edf4f8;
      }

      div {
        cursor: pointer;
      }

      .line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
</style>
