import { FieldNames, FilterKind, SearchFilterModel } from '../shared';

export class AggregatedGscModel {
  constructor({
    id,
    label,
    tagPath,
    studyCount,
    icon,
    isChecked,
    isDisabled,
    children,
    collapseChildrenCount
  }) {
    this.id = id;
    this.label = label || '';
    this.tagPath = tagPath;
    this.studyCount = studyCount || 0;
    this.icon = icon;
    this.isChecked = isChecked;
    this.isDisabled = isDisabled;
    this.children = children || [];
    this.isCollapsible = collapseChildrenCount === 0 ||
        (collapseChildrenCount > 0 && this.children.length >= collapseChildrenCount);
  }

  static createFromBackendData(data) {
    return new AggregatedGscModel({
      id: data.id,
      label: data.label,
      tagPath: data.tag_path,
      studyCount: data.count,
      icon: data.icon,
      isChecked: data.is_checked,
      isDisabled: data.disabled,
      children: (data.children || []).map(x => AggregatedGscModel.createFromBackendData(x)),
      collapseChildrenCount: data.collapse_children_count
    });
  }

  setCheckedState(checkedState) {
    this.isChecked = checkedState;
  }

  mapToSearchFilter() {
    return new SearchFilterModel(
      FieldNames.searchCategoryPaths,
      FilterKind.term,
      this.tagPath
    );
  }
}
