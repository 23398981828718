<template>
  <div>
    <!-- Subscription banner (hidden when in compact mode) -->
    <div v-if="!compactMode" class="d-flex flex-column mb-3 justify-content-center align-items-center subscribe-banner p-3">
      <h5Title>
        {{ $t('studies.main.banner.title') }}
      </h5Title>
      <div class="body-16-medium grey-500 mb-1">
        {{ $t('studies.main.banner.description') }}
      </div>
      <div class="my-2">
        <button id="loc-btn-subscription"
                class="btn btn-sm sp-btn-primary"
                type="button"
                @click="showModal()">
          {{ $t('studies.main.banner.btnNotifyMeByEmail') }}
        </button>
      </div>
    </div>
    <!-- Subscription button (compact mode only) -->
    <div v-if="compactMode" class="d-flex flex-column align-items-center">
      <button id="loc-btn-subscription"
              class="btn sp-btn-secondary small-notify-btn"
              type="button"
              @click="showModal()">
        <font-awesome-icon class="me-1" :icon="['fal', 'bell']" />
        {{ $t('studies.main.subButton.btnGetNotifiedTitle') }}
      </button>
    </div>
    <!-- Modal window -->
    <Modal v-if="subscription && showSubscriptionModal"
           v-model="showSubscriptionModal"
           :title="doneSubscribing ? '' : $t('studies.main.modalWindow.btnNotifyAboutNewStudies')">
      <div v-if="doneSubscribing && subscriptionResult === true"
           class="text-center text-success sub-status my-5">
        <font-awesome-icon class="me-2" icon="check" />
        {{ $t('studies.main.modalWindow.subscribedTitle') }}
      </div>
      <div v-if="doneSubscribing && subscriptionResult === false"
           class="text-center text-danger sub-status my-5">
        <font-awesome-icon class="me-2" icon="exclamation" />
        {{ $t('studies.main.modalWindow.couldNotSubscribe') }}
      </div>
      <form v-if="!doneSubscribing" @submit.prevent="completeSubscription">
        <div v-if="errors.length" class="text-danger">
          <span class="avenir-dem">
            {{ $t('studies.main.modalWindow.form.errorTitle') }}
          </span>
          <ul>
            <li v-for="(error, index) in errors" :key="index">
              {{ error }}
            </li>
          </ul>
        </div>

        <div class="mb-3">
          <label class="body-16-medium" for="inputEmail">{{ $t('studies.main.modalWindow.form.email.label') }}</label>
          <input id="inputEmail"
                 v-model="subscription.email"
                 aria-describedby="emailHelp"
                 class="form-control"
                 :placeholder="$t('studies.main.modalWindow.form.email.placeholder')"
                 type="email" />
          <small id="emailHelp"
                 class="form-text text-muted">{{ $t('studies.main.modalWindow.form.email.text') }} </small>
        </div>
        <div class="mb-3">
          <label class="body-16-medium" for="inputName">{{ $t('studies.main.modalWindow.form.name.label') }}</label>
          <input id="inputName"
                 v-model="subscription.fullName"
                 aria-describedby="emailHelp"
                 class="form-control"
                 :placeholder="$t('studies.main.modalWindow.form.name.placeholder')"
                 type="text" />
        </div>
        <div class="mb-2">
          <label class="body-16-medium" for="dailyRadio">{{ $t('studies.main.modalWindow.form.radio.title') }} </label>
          <div class="form-check">
            <input id="dailyRadio"
                   v-model="subscription.periodicity"
                   class="form-check-input"
                   type="radio"
                   value="0" />
            <label class="form-check-label body-14-light" for="dailyRadio">{{ $t('studies.main.modalWindow.form.radio.label1') }}</label>
          </div>
          <div class="form-check">
            <input id="weeklyRadio"
                   v-model="subscription.periodicity"
                   class="form-check-input"
                   type="radio"
                   value="1" />
            <label class="form-check-label body-14-light" for="weeklyRadio">{{ $t('studies.main.modalWindow.form.radio.label2') }}</label>
          </div>
          <div class="form-check">
            <input id="monthlyRadio"
                   v-model="subscription.periodicity"
                   class="form-check-input"
                   type="radio"
                   value="2" />
            <label class="form-check-label body-14-light" for="monthlyRadio">{{ $t('studies.main.modalWindow.form.radio.label3') }}</label>
          </div>
        </div>
        <div v-if="subscription.hasExtraCriteria" class="mb-2">
          {{ $t('studies.main.modalWindow.form.hasExtraCriteriaTitle') }}
        </div>
        <div v-if="subscription.hasExtraCriteria" class="mb-2">
          <!-- Search chip -->
          <transition name="bounce">
            <Chip v-if="subscription.searchQuery"
                  :label="subscription.searchQuery"
                  class="me-2"
                  color="red"
                  prefix="Searched for"
                  @closed="clearSearch()">
            </Chip>
          </transition>

          <!-- Search filter chips -->
          <transition-group name="bounce">
            <Chip v-for="item in subscription.searchFilters"
                  :key="item.filterValue + item.fieldName"
                  :label="item.formattedFilterValue"
                  :prefix="item.formattedFieldName"
                  class="me-2"
                  color="yellow"
                  @closed="removeFromFilters(item)">
            </Chip>
          </transition-group>
        </div>
        <div class="text-end mb-0">
          <button class="btn sp-btn-primary" type="submit">
            {{ $t('studies.main.modalWindow.form.subscribeBtnTitle') }}
          </button>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script setup>
import { Modal } from '@yuzulabs/lib-general';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Chip, H5Title } from '../../general';
import { SubscriptionModel } from '../../../models';

const props = defineProps({
  currentFilter: {
    type: Object,
    required: false
  },
  subscriptionResult: {
    type: Boolean,
    required: false,
    default: null
  },
  compactMode: {
    type: Boolean,
    required: false,
    default: false
  }
});
const emits = defineEmits(['subscribed']);
const errors = ref([]);
const subscription = ref(null);
const showSubscriptionModal = ref(false);
const doneSubscribing = ref(false);
const { t } = useI18n();
const validEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
const completeSubscription = () => {
  errors.value = [];
  if (!subscription.value.email) {
    const emailRequiredMessage = t('studies.main.modalWindow.form.errors.emailRequired');
    errors.value.push(emailRequiredMessage);
  } else if (!validEmail(subscription.value.email)) {
    const validEmailRequired = t('studies.main.modalWindow.form.errors.validEmailRequired');
    errors.value.push(validEmailRequired);
  }
  if (!subscription.value.fullName) {
    const nameRequired = t('studies.main.modalWindow.form.errors.nameRequired');
    errors.value.push(nameRequired);
  }

  if (errors.value.length > 0) {
    return;
  }
  doneSubscribing.value = true;
  emits('subscribed', subscription.value);
};

const showModal = () => {
  errors.value = [];
  subscription.value = new SubscriptionModel({
    ...props.currentFilter
  });
  doneSubscribing.value = false;
  showSubscriptionModal.value = true;
};

const removeFromFilters = filter => {
  subscription.value.removeFilterFromSubscription(filter);
};

const clearSearch = () => {
  subscription.value.removeSearchQuery();
};
</script>
<style lang="scss" scoped>
.sp-btn-secondary.small-notify-btn {
  padding: 0.1rem 0.7rem;
}

.subscribe-banner {
  background-color: var(--mid-grey);
  border-radius: var(--sp-banner-border-radius);
}

.sub-status {
  font-size: 30px;
}
</style>
