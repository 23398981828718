export const studyAppUserUserType = {
  RE: 'Researcher',
  PA: 'Participant',
  RP: 'Researcher and Participant',
  UN: 'Unknown'
};

export const studyAppUserGender = {
  M: 'Male',
  F: 'Female',
  U: 'Not specified'
};

export const studyAppUserSignupSource = {
  WEB: 'Website',
  IOS: 'iOS',
  AND: 'Android',
  UNK: 'Unknown'
};

export const studyAppUserLanguage = {
  null: '-Use Browser Default-',
  en: 'English',
  nl: 'Dutch',
  de: 'German',
  ja: 'Japanese',
  ko: 'Korean'
};

export const studyAppUserSource = {
  SP: 'StudyPages',
  TS: 'Teamscope'
};

export const studyAppUserSignupWebOpt = 'WEB';

export const studyAppUserSignupIosOpt = 'IOS';

export const studyAppUserSignupAndroidOpt = 'AND';

export const studyAppUserSignupUnknownOpt = 'UNK';

export const studyAppUserSignupSourceChoices = {
  WEB: 'Website',
  IOS: 'iOS',
  AND: 'Android',
  UNK: 'Unknown'
};

export const studyAppUserSignupSourceChoicesDict = {
  WEB: 'Website',
  IOS: 'iOS',
  AND: 'Android',
  UNK: 'Unknown'
};

export const studyAppUserLanguageBrowserDefault = null;

export const studyAppUserLanguageEnglish = 'en';

export const studyAppUserLanguageDutch = 'nl';

export const studyAppUserLanguageGerman = 'de';

export const studyAppUserLanguageJapanese = 'ja';

export const studyAppUserLanguageKorean = 'ko';

export const studyAppUserLanguageChoices = {
  null: '-Use Browser Default-',
  en: 'English',
  nl: 'Dutch',
  de: 'German',
  ja: 'Japanese',
  ko: 'Korean'
};

export const studyAppStudyShareLogShareType = {
  SM: 'SMS',
  EM: 'Email',
  FB: 'Facebook',
  TW: 'Twitter'
};

export const studyAppHistoricalStudyNoteHistoryType = {
  '+': 'Created',
  '~': 'Changed',
  '-': 'Deleted'
};

export const studyAppStudyContentHealthyVolunteer = {
  Y: 'Yes',
  N: 'No'
};

export const studyAppStudyContentTargetGender = {
  M: 'Male',
  F: 'Female',
  B: 'All'
};

export const studyAppStudyContentContentLang = {
  ar: 'Arabic',
  en: 'English',
  fr: 'French',
  de: 'German',
  el: 'Greek',
  he: 'Hebrew',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  ru: 'Russian',
  es: 'Spanish',
  tr: 'Turkish',
  vi: 'Vietnamese'
};

export const studyAppStudyContentStatus = {
  DRAFT: 'Draft',
  ORG_ADMIN_REVIEW: 'Org Admin Review',
  PI_REVIEW: 'PI Review',
  STUDYPAGES_REVIEW: 'StudyPages Review',
  PUBLISHED: 'Published',
  ARCHIVED: 'Archived'
};

export const studyAppStudyContentStudyLangArabic = 'ar';

export const studyAppStudyContentStudyLangEnglish = 'en';

export const studyAppStudyContentStudyLangFrench = 'fr';

export const studyAppStudyContentStudyLangGerman = 'de';

export const studyAppStudyContentStudyLangGreek = 'el';

export const studyAppStudyContentStudyLangHebrew = 'he';

export const studyAppStudyContentStudyLangItalian = 'it';

export const studyAppStudyContentStudyLangJapanese = 'ja';

export const studyAppStudyContentStudyLangKorean = 'ko';

export const studyAppStudyContentStudyLangRussian = 'ru';

export const studyAppStudyContentStudyLangSpanish = 'es';

export const studyAppStudyContentStudyLangTurkish = 'tr';

export const studyAppStudyContentStudyLangVietnamese = 'vi';

export const studyAppStudyContentStudyLangChoices = {
  ar: 'Arabic',
  en: 'English',
  fr: 'French',
  de: 'German',
  el: 'Greek',
  he: 'Hebrew',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  ru: 'Russian',
  es: 'Spanish',
  tr: 'Turkish',
  vi: 'Vietnamese'
};

export const studyAppStudyContentReviewStatuses = [
  'ORG_ADMIN_REVIEW',
  'PI_REVIEW',
  'STUDYPAGES_REVIEW'
];

export const studyAppStudyContentPublishedStatuses = [
  'PUBLISHED',
  'ARCHIVED'
];

export const studyAppStudyStudyKind = {
  study: 'Study',
  regist: 'Registry'
};

export const studyAppStudyStudypageType = {
  BAS: 'Basic StudyPage',
  ACT: 'Active StudyPage',
  PRO: 'Pro StudyPage'
};

export const studyAppStudyStudypageStatus = {
  D: 'Draft',
  X: 'Canceled',
  A: 'Active - Not Yet Recruiting',
  R: 'Active - Recruiting',
  F: 'Finished Recruiting',
  I: 'Inactive',
  PIR: 'PI Review',
  OAR: 'Org Admin Review',
  YR: 'StudyPages Review',
  CR: 'Curator Review'
};

export const studyAppStudyTargetGender = {
  M: 'Male',
  F: 'Female',
  B: 'All'
};

export const studyAppStudyHealthyVolunteer = {
  Y: 'Yes',
  N: 'No'
};

export const studyAppStudySiteRecStatus = {
  I: 'Inactive',
  NY: 'Active - Not Yet Recruiting',
  R: 'Recruiting',
  C: 'Completed',
  NR: 'Not Recruiting',
  T: 'Terminated'
};

export const studyAppStudyJoinButtonAction = {
  SPForm: 'Signup Form',
  SelfContact: 'Self Contact',
  ExtLink: 'External Link'
};

export const studyAppStudySiteconnectUiLang = {
  en: 'English',
  'zh-hans': 'Chinese - Simplified'
};

export const studyAppStudyMasterschedulerType = {
  SINGLE_SLOT: 'Single Slot Scheduler',
  MANUF_SLOT: 'Manufacturing Slot Scheduler'
};

export const studyAppStudySurveyType = {
  SURVEY_SP: 'StudyPages Survey',
  SURVEY_EXT: 'External Survey'
};

export const studyAppStudyStudySource = {
  UNK: 'Unknown',
  IND: 'Industry',
  INV: 'Investigator'
};

export const studyAppStudyCompensationType = {
  C: 'Cash',
  GC: 'Gift Card'
};

export const studyAppStudyCompensationStage = {
  UC: 'Upon Completion',
  BC: 'Before Completion'
};

export const studyAppStudyStudyKindStandard = 'study';

export const studyAppStudyStudyKindRegistry = 'regist';

export const studyAppStudyStudyKindChoices = {
  study: 'Study',
  regist: 'Registry'
};

export const studyAppStudyStudypageTypeBasic = 'BAS';

export const studyAppStudyStudypageTypeActive = 'ACT';

export const studyAppStudyStudypageTypePro = 'PRO';

export const studyAppStudyStudypageTypeChoices = {
  BAS: 'Basic StudyPage',
  ACT: 'Active StudyPage',
  PRO: 'Pro StudyPage'
};

export const studyAppStudyOncScrubDurationChoices = {
  '90': '3 Months',
  '180': '6 Months',
  '270': '9 Months',
  '365': '1 Year'
};

export const studyAppStudyOncScrubDurationChoicesDict = {
  '90': '3 Months',
  '180': '6 Months',
  '270': '9 Months',
  '365': '1 Year'
};

export const studyAppStudySiteconnectStudyLangEnglish = 'en';

export const studyAppStudySiteconnectStudyLangChineseSimp = 'zh-hans';

export const studyAppStudySiteconnectStudyLangChoices = {
  en: 'English',
  'zh-hans': 'Chinese - Simplified'
};

export const studyAppStudyMsTypeSingleSlotOpt = 'SINGLE_SLOT';

export const studyAppStudyMsTypeManufSlotOpt = 'MANUF_SLOT';

export const studyAppStudyMsTypeChoices = {
  SINGLE_SLOT: 'Single Slot Scheduler',
  MANUF_SLOT: 'Manufacturing Slot Scheduler'
};

export const studyAppStudySurveyTypeStudypages = 'SURVEY_SP';

export const studyAppStudySurveyTypeExternal = 'SURVEY_EXT';

export const studyAppStudySurveyTypeChoices = {
  SURVEY_SP: 'StudyPages Survey',
  SURVEY_EXT: 'External Survey'
};

export const studyAppStudyStudySourceUnknownOpt = 'UNK';

export const studyAppStudyStudySourceIndustryOpt = 'IND';

export const studyAppStudyStudySourceInvestigatorOpt = 'INV';

export const studyAppStudyStudySourceChoices = {
  UNK: 'Unknown',
  IND: 'Industry',
  INV: 'Investigator'
};

export const studyAppStudyRemovalRequestPiOpt = 'PI';

export const studyAppStudyRemovalFinishedRecOpt = 'RF';

export const studyAppStudyRemovalRequestOtherOpt = 'OTHER';

export const studyAppStudyRemovalRequestChoices = {
  PI: 'Requested by PI',
  RF: 'Recruitment finished',
  OTHER: 'Other'
};

export const studyAppStudyRemovalRequestChoicesDict = {
  PI: 'Requested by PI',
  RF: 'Recruitment finished',
  OTHER: 'Other'
};

export const studyAppStudyStudyFeatStudypage = 'SPG';

export const studyAppStudyStudyFeatPrequalSurvey = 'SRV';

export const studyAppStudyStudyFeatMultiContent = 'MCON';

export const studyAppStudyStudyFeatCampaignTemplate = 'CMP';

export const studyAppStudyStudyFeatCallForwarding = 'PHN';

export const studyAppStudyStudyFeatOutboundCalling = 'OCA';

export const studyAppStudyStudyFeatOutboundCallingDialer = 'OCD';

export const studyAppStudyStudyFeatSmsMessaging = 'SMS';

export const studyAppStudyStudyFeatSmsScheduling = 'SMC';

export const studyAppStudyStudyFeatSmsLeads = 'SML';

export const studyAppStudyStudyFeatInternalMessages = 'IMSG';

export const studyAppStudyStudyFeatVoicemail = 'VML';

export const studyAppStudyStudyFeatTasks = 'TSK';

export const studyAppStudyStudyFeatVisitScheduling = 'VSC';

export const studyAppStudyStudyFeatVisitSchedulingTeam = 'VSCTE';

export const studyAppStudyStudyFeatVisitSchedulingVideo = 'VSCVI';

export const studyAppStudyStudyFeatVisitSchedulingExtras = 'VSCEX';

export const studyAppStudyStudyFeatVisitSchedulingPayments = 'VSCPA';

export const studyAppStudyStudyFeatSignupTags = 'STA';

export const studyAppStudyStudyFeatIneligibleTags = 'ITA';

export const studyAppStudyStudyFeatBookmarklets = 'BKM';

export const studyAppStudyStudyFeatCustomFields = 'CFD';

export const studyAppStudyStudyFeatEconsent = 'ECN';

export const studyAppStudyStudyFeatForms = 'FRM';

export const studyAppStudyStudyFeatFiles = 'FLS';

export const studyAppStudyStudyFeatSnoozeSignups = 'SNSU';

export const studyAppStudyStudyFeatIntegrationSpEdc = 'IEDC';

export const studyAppStudyStudyFeatures = {
  SPG: 'StudyPage',
  SRV: 'StudyPage Pre-qual Survey',
  MCON: 'StudyPage Translations',
  CMP: 'Campaign Template',
  PHN: 'Call Forwarding',
  OCA: 'Outbound Calling',
  OCD: 'Outbound Call Dialer',
  SMS: 'Messaging (Secure/SMS)',
  SMC: 'Message Scheduling',
  SML: 'SMS Leads',
  IMSG: 'Secure Messages Only (SMS disabled)',
  VML: 'Voicemail',
  SS2: 'Advanced Withdrawn form',
  TSK: 'Tasks',
  VSC: 'Visit Scheduling',
  VSCTE: 'Visit Team',
  VSCVI: 'Visit Video',
  VSCEX: 'Visit Extras',
  VSCPA: 'Visit Payments (experiment)',
  STA: 'Signup Tags',
  ITA: 'Ineligible Tracking',
  BKM: 'Bookmarklets',
  CFD: 'Custom Fields',
  ECN: 'eConsent (e-signature)',
  FRM: 'Data Forms',
  FLS: 'Files',
  SNSU: 'Snooze / Pause Signups',
  SIM2: 'Import Signups V2',
  IEDC: 'Integration - SP Data (EDC)'
};

export const studyAppStudyFeatureData = {
  SMS: '[object Object]',
  SMC: '[object Object]',
  SML: '[object Object]',
  IMSG: '[object Object]',
  OCA: '[object Object]',
  PHN: '[object Object]',
  OCD: '[object Object]',
  VML: '[object Object]',
  VSC: '[object Object]',
  VSCTE: '[object Object]',
  VSCVI: '[object Object]',
  VSCEX: '[object Object]',
  VSCPA: '[object Object]',
  SPG: '[object Object]',
  SRV: '[object Object]',
  MCON: '[object Object]',
  SNSU: '[object Object]',
  SS2: '[object Object]',
  STA: '[object Object]',
  ITA: '[object Object]',
  BKM: '[object Object]',
  CFD: '[object Object]',
  ECN: '[object Object]',
  FRM: '[object Object]',
  TSK: '[object Object]',
  FLS: '[object Object]',
  IEDC: '[object Object]'
};

export const studyAppStudyAdminMasterschedulerApp = 'MasterScheduler';

export const studyAppStudyAdminMasterschedulerManufApp = 'MasterScheduler [Manuf]';

export const studyAppStudyAdminSiteconnectApp = 'SiteConnect';

export const studyAppStudyAdminStudypageMegaChildApp = 'StudyPage [MegaStudy Child]';

export const studyAppStudyAdminStudypageBasicApp = 'StudyPage [Basic]';

export const studyAppStudyAdminStudypageApp = 'StudyPage';

export const studyAppStudyAdminStudypageRegistryApp = 'StudyPage [Registry]';

export const studyAppStudyAdminManageOnlyApp = 'Manage-only';

export const studyAppStudyDefaultSignupConfSmsAutoreplyText = 'StudyPages: Thank you for your interest in the study. The study team will contact you to discuss more. Reply here anytime to send them a message.';

export const studyAppVisitMethod = {
  INP: 'In-person Visit',
  REMV: 'Remote Video Visit'
};

export const studyAppVisitType = {
  SCRV: 'Screening Visit',
  STV: 'Study Visit',
  UNP: 'Unplanned Visit'
};

export const studyAppVisitStatus = {
  VPE: 'Visit Pending Schedule',
  VSC: 'Visit Scheduled',
  VRR: 'Visit Reschedule Requested',
  VCA: 'Visit Cancelled',
  VCO: 'Visit Completed'
};

export const studyAppVisitVisitConfResponse = {
  YES: 'Yes',
  NO: 'No'
};

export const studyAppVisitActionCreate = 'create';

export const studyAppVisitActionUpdate = 'update';

export const studyAppVisitActionReminder = 'reminder';

export const studyAppVisitTypeScreeningVisit = 'screening-visit';

export const studyAppVisitTypeStudyVisit = 'study-visit';

export const studyAppVisitTypeUnplannedVisit = 'unplanned-visit';

export const studyAppVisitEngReminderSmsBody = 'Reminder: upcoming appointment for %s';

export const studyAppVisitEsReminderSmsBody = 'Recordatorio: próxima cita para %s';

export const studyAppVisitEngReminderSmsBodyConfirm = 'Please follow this link to confirm your visit and review important information to prepare for your visit: ';

export const studyAppVisitEsReminderSmsBodyConfirm = 'Por favor siga este enlace para confirmar su visita y revisar información importante para prepararse para su visita: ';

export const studyAppParticipantInelReasonStatus = {
  ACT: 'Active',
  INA: 'Inactive'
};

export const studyAppParticipantStudyTagStatus = {
  ACT: 'Active',
  INA: 'Inactive'
};

export const studyAppParticipantGender = {
  M: 'Male',
  F: 'Female',
  U: 'Not specified'
};

export const studyAppParticipantLegacyRemovedRecruitmentStatus = {
  NEW: 'New',
  CON: 'Contacting',
  UNC: 'Unable To Contact',
  NOI: 'Not Interested',
  PNE: 'Pre-Screened - Not Eligible',
  PNI: 'Pre-Screened - Not Interested',
  PPE: 'Pre-Screened - Pending',
  PQA: 'Pre-Screened - Qualified',
  SSC: 'Screening Scheduled',
  SPE: 'Screened - Pending',
  SNE: 'Screened - Not Eligible',
  SNI: 'Screened - Not Interested',
  SQA: 'Screened - Qualified'
};

export const studyAppParticipantContactTimeframe = {
  AT: 'Anytime',
  MO: 'Morning',
  AF: 'Afternoon',
  EV: 'Evening'
};

export const studyAppParticipantSignupSource = {
  SP: 'StudyPage',
  DI: 'Direct',
  LD: 'Lead',
  WI: 'SiteConnect',
  MS_SI_SL: 'MasterScheduler Single Slot',
  MS_MF_SL: 'MasterScheduler Manufacturing Slot',
  IMP: 'Import',
  IN: 'Integration',
  FRG: 'Registry'
};

export const studyAppParticipantRegistryGender = {
  M: 'Male',
  F: 'Female',
  U: 'Not specified'
};

export const studyAppParticipantScheduledTimezone = {
  USP: 'US/Pacific',
  USM: 'US/Mountain',
  USC: 'US/Central',
  USE: 'US/Eastern',
  USH: 'US/Hawaii',
  CAN: 'Canada/Atlantic',
  LAP: 'America/La_Paz',
  WET: 'Europe/Luxembourg',
  LON: 'Europe/London',
  ACB: 'Africa/Casablanca',
  BRU: 'Europe/Brussels',
  MAD: 'Europe/Madrid'
};

export const studyAppParticipantScheduledType = {
  C: 'Call',
  V: 'Visit'
};

export const studyAppParticipantLegacyRemovedEnrollmentStatus = {
  AOT: 'Active on Treatment',
  IOT: 'Off Treatment',
  FUP: 'Follow Up',
  WDR: 'Withdrawn',
  CMP: 'Completed'
};

export const studyAppParticipantEnrollmentTypeEnrOnlyOpt = 'ENO';

export const studyAppParticipantEnrollmentTypeEnrRandOpt = 'ERA';

export const studyAppParticipantEnrollmentTypeEnrIpOpt = 'EIP';

export const studyAppParticipantEnrollmentTypeChoices = {
  ENO: 'Enrolled Only',
  ERA: 'Enrolled & Randomized',
  EIP: 'Enrolled & Assigned Investigational Product (No randomization)'
};

export const studyAppParticipantEnrollmentTypeChoicesDict = {
  ENO: 'Enrolled Only',
  ERA: 'Enrolled & Randomized',
  EIP: 'Enrolled & Assigned Investigational Product (No randomization)'
};

export const studyAppParticipantEnrWdReasonLostOpt = 'LFU';

export const studyAppParticipantEnrWdReasonNonCompOpt = 'NCO';

export const studyAppParticipantEnrWdReasonSafetyConcOpt = 'SCO';

export const studyAppParticipantEnrWdReasonPersonalOpt = 'PRS';

export const studyAppParticipantEnrWdReasonRandErr = 'RER';

export const studyAppParticipantEnrWdReasonChoices = {
  LFU: 'Lost to Follow Up',
  NCO: 'Non-compliance',
  SCO: 'Safety Concern',
  PRS: 'Personal Reasons',
  RER: 'Randomization Error'
};

export const studyAppParticipantEnrWdReasonChoicesDict = {
  LFU: 'Lost to Follow Up',
  NCO: 'Non-compliance',
  SCO: 'Safety Concern',
  PRS: 'Personal Reasons',
  RER: 'Randomization Error'
};

export const studyAppParticipantEnrWdReasonChoicesList = [
  'LFU',
  'NCO',
  'SCO',
  'PRS',
  'RER'
];

export const studyAppParticipantLeadCodeSignup = null;

export const studyAppParticipantLeadCodeParticipant = null;

export const studyAppParticipantLeadCodeFinished = null;

export const studyAppParticipantLeadCodeDeleted = null;

export const studyAppParticipantStudyLeadCodes = {
  signup: '0',
  participant: '1',
  finished: '2',
  deleted: '3'
};

export const studyAppGalleryGallerySearchFeedFeaturedOpt = 'featured';

export const studyAppGalleryGallerySearchFeedRecentOpt = 'most_recent';

export const studyAppIntOrgUserDataRole = {
  AD: 'Admin',
  ED: 'Editor',
  VI: 'Viewer'
};

export const studyAppIntDeptUserDataRole = {
  AD: 'Admin',
  ED: 'Editor'
};

export const studyAppOrganizationOrgtype = {
  ACD: 'Academic',
  SSI: 'Single Site'
};

export const studyAppOrganizationOrgTypeAcademicOpt = 'ACD';

export const studyAppOrganizationOrgTypeSingleSiteOpt = 'SSI';

export const studyAppOrganizationOrgTypeChoices = {
  ACD: 'Academic',
  SSI: 'Single Site'
};

export const studyAppIntMegaStudyUsersRole = {
  SP: 'Sponsor',
  MG: 'Manager',
  RA: 'CRA',
  SM: 'RSM',
  DI: 'Director',
  PI: 'Principal Investigator',
  SI: 'Sub-Investigator',
  IN: 'Investigator',
  CR: 'Coordinator',
  ME: 'Member',
  CO: 'Contact',
  MA: 'Marketer'
};

export const studyAppIntStudyCoordinatorsRole = {
  SP: 'Sponsor',
  MG: 'Manager',
  RA: 'CRA',
  SM: 'RSM',
  DI: 'Director',
  PI: 'Principal Investigator',
  SI: 'Sub-Investigator',
  IN: 'Investigator',
  CR: 'Coordinator',
  ME: 'Member',
  CO: 'Contact',
  MA: 'Marketer'
};

export const studyAppStudyJoinRequestRole = {
  SP: 'Sponsor',
  MG: 'Manager',
  RA: 'CRA',
  SM: 'RSM',
  DI: 'Director',
  PI: 'Principal Investigator',
  SI: 'Sub-Investigator',
  IN: 'Investigator',
  CR: 'Coordinator',
  ME: 'Member',
  CO: 'Contact',
  MA: 'Marketer'
};

export const studyAppStudyJoinRequestStatus = {
  PENDING: 'Pending',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected'
};

export const studyAppIntUserSiteMembershipMembershipType = {
  SP: 'Sponsor',
  PH: 'Pharma',
  IN: 'Investigator',
  DI: 'Director',
  PI: 'Principal Investigator',
  SI: 'Sub-Investigator',
  CO: 'Contact',
  UN: 'Unlisted'
};

export const studyAppIntStudySiteLocationsRecruitingStatus = {
  R: 'Recruiting',
  NY: 'Not Yet Recruiting',
  C: 'Completed',
  NR: 'Not Recruiting'
};

export const studyAppActivityRecordActivityType = {
  ADM_GEN: 'Admin General',
  USR_GEN: 'User General',
  PGV: 'Pageview',
  LIN: 'Login',
  LOU: 'Logout',
  GOLIN: 'Google Oauth Login',
  INT1: 'Interested Click Top',
  INT2: 'Interested Click Bottom',
  INT3: 'Interested Click Mobile',
  TWC_1: 'Twitter Click',
  TWC_2: 'Twitter Click Conf Page',
  FBC_1: 'Facebook Click',
  FBC_2: 'Facebook Click Conf Page',
  EMC_1: 'Email Click',
  EMC_2: 'Email Click Conf Page',
  EMS_1: 'Email Share',
  EMS_2: 'Email Share Conf Page',
  SMC_1: 'SMS Click',
  SMC_2: 'SMS Click Conf Page',
  SMS_1: 'SMS Share',
  SMS_2: 'SMS Share Conf Page',
  SINT: 'Survey Intro',
  SQAT: 'Survey Question Attempt',
  SVAT: 'Survey Attempt',
  SVCM: 'Survey Complete',
  SSU: 'Study Signup',
  SSUI: 'Study Signup Invalid',
  SFL: 'Study Finished Lead',
  ERST: 'Study Signup Recruitment Status Change',
  ERSTV2: 'Study Signup Enrollment Status Change',
  MENR: 'Study Signup Marked Enrolled',
  SSUN: 'Study Signup Note',
  SSUR: 'Study Signup Remove',
  SSACT: 'Study Signup Active',
  SSFN: 'Study Signup Finished',
  INVT: 'Team Invite Send',
  INVU: 'Team Invite Used',
  TMRL: 'Team Role Change',
  TMTY: 'Team Type Change',
  TMAD: 'Add Team Member',
  TMRM: 'Remove Team Member',
  SETT: 'Study Settings Change',
  CTCH: 'Call Tracking Change',
  SPCR: 'Study Create',
  SPCP: 'Study Copy',
  SPDE: 'Study Delete',
  SSDE: 'Study Signups Data Export',
  SSRE: 'Study Signup Record Export',
  SCDV: 'Coordinator Dashboard View',
  SDE: 'Study Details Edit',
  STCR: 'Site Create',
  UNRS: 'Unable To Contact Response - Submit',
  UNRY: 'Unable To Contact Response - Yes',
  UNRN: 'Unable To Contact Response - No',
  SFCHNG: 'Study Feature Change',
  SPUPDT: 'Study Participant Update',
  VISUPD: 'Visit Update',
  VISRMD: 'Visit Reminder',
  VISTMU: 'Visit Template Update',
  VISCFR: 'Visit Confirm Response',
  SRMR: 'Study Removal Request',
  SPUB: 'StudyPage Publish',
  STAT: 'Study Status Change',
  SPSC: 'StudyPage Status Change',
  SPCE: 'StudyPage Content Edit',
  SPCA: 'StudyPage Content Add',
  SPCD: 'StudyPage Content Delete',
  SPTYPE: 'StudyPage Type Change',
  SPBTN: 'StudyPage Button Action Change',
  SPRQ: 'StudyPage Review Request',
  SPUBRQ: 'StudyPage Publish Request',
  GPVH: 'Gallery Home Pageview',
  GPVS: 'Gallery Studies Pageview',
  GPVJF: 'Gallery Studies JSON Feed Pageview',
  GPVA: 'Gallery About Pageview',
  GSCH: 'Gallery Search',
  GTYP: 'Gallery Filter Type',
  GTOP: 'Gallery Filter Topic',
  GSSCH: 'Gallery Study Settings Change',
  WPV: 'Widget Pageview',
  SCWPR: 'SiteConnect WI Progress',
  WCR: 'Widget Call Reschedule',
  WCC: 'Widget Call Cancellation',
  SCWICC: 'SiteConnect WI Call Completed',
  SCWICU: 'SiteConnect WI Call Upcoming',
  SCWCS: 'SiteConnect WI Call Scheduled',
  MSSCH: 'MasterScheduler Site Schedule',
  MSRSCH: 'MasterScheduler Site Reschedule',
  MSCNCL: 'MasterScheduler Site Cancel',
  MSASCH: 'MasterScheduler Admin Site Schedule',
  MSARSCH: 'MasterScheduler Admin Site Reschedule',
  MSACNCL: 'MasterScheduler Admin Site Cancel',
  MSABD: 'MasterScheduler Admin Block Date',
  MSAUBD: 'MasterScheduler Admin Unblock Date',
  MSMFGEN: 'MasterScheduler MF General Activity',
  GSAATM: 'Global Study Admin Add Team Member',
  GSARTM: 'Global Study Admin Remove Team Member',
  GSADSE: 'Global Study Admin Site Data Export',
  GSPGV: 'Global Pageview Activity',
  GSINT1: 'Global Interested Click Top',
  GSINT2: 'Global Interested Click Bottom',
  GSINT3: 'Global Interested Click Mobile',
  GSINT4: 'Global Interested Click View All',
  GSTWC: 'Global Twitter Click',
  GSFBC: 'Global Facebook Click',
  GSEMC: 'Global Email Click',
  GSEMS: 'Global Email Share',
  GSSMC: 'Global SMS Click',
  GSSMS: 'Global SMS Share',
  GSSITC: 'Global Show Info Tab Click',
  GSSLTC: 'Global Show Locations Tab Click',
  GSSLCNYR: 'Global Site Link Click Not Yet Rec',
  GSSLCIPE: 'Global Site Link Click IRB Pending',
  GSSLCIAP: 'Global Site Link Click IRB Approved',
  GSFMLC: 'Global Find My Location Click',
  GSLSC: 'Global Location Search Click',
  CSSEC: 'Edit Coordinator',
  CSSACI: 'Add Custom Investigator',
  CSSECI: 'Edit Custom Investigator',
  CSSSAS: 'See All Sites Click',
  ALIN: 'API Login',
  ALOU: 'API Logout',
  APPU: 'API Profile Picture Update',
  ASNU: 'API Signup Note Update',
  SPINV: 'StudyPages Invite Send',
  SPFLA: 'StudyPages Failed Login Attempt',
  SPILA: 'StudyPages Inactive Login Attempt',
  ORGADD: 'Organization User Add',
  ORGRMV: 'Organization User Remove',
  ORGSRQ: 'Organization Study Team Request',
  ORGRLC: 'Organization Role Change',
  ORGCTCH: 'Organization Call Tracking Change',
  STPGV: 'SitePage Home Pageview',
  STPGSI: 'SitePage Sponsor Inquiry',
  SCHR_UPD: 'Update',
  SCHR_PGV: 'Pageview',
  SCHR_EMA: 'Email',
  SCHR_CAL: 'Call Activity',
  SPSLS: 'StudyPages Standard Login',
  SPPRR: 'StudyPages Password Reset Request',
  SPSLR: 'StudyPages SSO Login Required',
  SPPCR: 'StudyPages Password Change Required',
  SPLMA: 'StudyPages Login for Mobile App',
  OALIN: 'Provider StudyPages Login',
  SARCH: 'Study Archived',
  SUNARCH: 'Study Unarchived',
  SNOOZ: 'Study Snoozed',
  UNSNOOZ: 'Study Unsnoozed',
  SPNTU: 'Study Participant Note Updated',
  SPNTD: 'Study Participant Note Deleted',
  SMSLM: 'SMS Lead Moved',
  UC_RRAF: 'User Created Request Researcher Access Form',
  UC_SAM: 'User Created SAML',
  UC_JDRF: 'User Created JDRF',
  UC_INV: 'User Created Invite',
  UC_DFT: 'User Created Default',
  UC_PR: 'User Created Portal Registration',
  OASTCR: 'Org Admin Study Tag Created',
  OASTCH: 'Org Admin Study Tag Changed',
  OASTDE: 'Org Admin Study Tag Deleted',
  OASTAS: 'Org Admin Study Tag Assigned To Study',
  OASTUS: 'Org Admin Study Tag Unnasigned To Study'
};

export const studyAppSMSActivityType = {
  sms: 'SMS',
  internal: 'Internal'
};

export const studyAppMessageActionType = {
  VR: 'Visit Request',
  VC: 'Visit Choice',
  DT: 'Decision Tree'
};

export const studyAppMessageActionInitiatedBy = {
  ST: 'Study Team',
  participant: 'Participant'
};

export const studyAppStudyCampaignType = {
  CFB: 'Facebook Campaign'
};

export const studyAppScheduleRequestType = {
  SRCA: 'Call',
  SRVI: 'Visit'
};

export const studyAppOrgReportStatus = {
  PENDING: 'PENDING',
  COLLECTING: 'COLLECTING',
  FINISHED: 'FINISHED'
};

export const studyAppStudyTaskPriority = {
  NORMAL: 'NORMAL',
  IMPORTANT: 'IMPORTANT'
};

export const studyAppStudyTaskStatus = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED'
};

export const studyAppStudyTaskActiveStatuses = [
  'NEW',
  'IN_PROGRESS'
];

export const studyAppTokenActionAction = {
  pi_study_review: 'Review study by PI outside of studypages'
};

export const studyAppStudyInvestigatorsRole = {
  PI: 'Principal Investigator',
  IN: 'Investigator',
  SI: 'Sub-Investigator'
};

export const studyAppWorkspaceNotificationType = {
  published_study_edited: 'Published study edited',
  not_published_study_edited: 'Not published study edited'
};

export const studyAppWorkspaceNotificationWhatChangedBlacklist = [
  'multi_locations',
  'restricted_fields',
  'is_draft',
  'multi_content_enabled',
  'google_maps_key',
  'sp_ai_assist',
  'user_edit_allowed',
  'investigators',
  'use_content_v2',
  'is_avma_study',
  'schema_version',
  'feature_can_snooze',
  'is_snoozed',
  '[object Object]'
];

export const studyAppGalleryStaticPageLanguageCode = {
  ar: 'Arabic',
  zh: 'Chinese',
  nl: 'Dutch',
  en: 'English',
  fr: 'French',
  de: 'German',
  el: 'Greek',
  he: 'Hebrew',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  ru: 'Russian',
  es: 'Spanish',
  tr: 'Turkish',
  vi: 'Vietnamese'
};

export const studyAppGalleryStaticPageType = {
  privacy_policy: 'Privacy Policy',
  terms: 'Terms of Use',
  about: 'About'
};

export const studyAppExpiringTokenExpiryTimeMinutes = null;

export const studyAppSnoozedConfigReason = {
  sponsor_hold: 'Sponsor/Study Recruitment Hold',
  slow_down: 'Slow down! I have to catch up',
  out_of_office: 'Out of Office/Team Unavailable',
  other: 'Other'
};

export const studyAppStudyPublishValidationValidationType = {
  is_required: 'Is Required',
  min_length: 'Min Length',
  max_length: 'Max Length'
};

export const studyAppImportPeopleStatus = {
  pending: 'Pending',
  in_progress: 'In Progress',
  completed: 'Completed',
  failed: 'Failed'
};

export const studyAppParticipantStatusGroupLabel = {
  pending: 'Pending',
  lost: 'Lost',
  ineligible: 'Ineligible',
  active: 'Active',
  inactive: 'Inactive'
};

export const studyAppParticipantStatusGroupCategory = {
  signup: 'Signup',
  enrolled: 'Enrolled'
};

export const studyAppCachedOrgReportReportType = {
  studies: 'Studies',
  signup: 'Signup',
  signup_activity: 'Signup Activity',
  participant: 'Participant',
  participant_activity: 'Participant Activity',
  team_activity: 'Team Activity',
  gallery_public_activity: 'Gallery Public Activity'
};

export const studyAppCachedOrgReportRelatedDate = {
  this_week: 'This Week',
  this_month: 'This Month',
  this_year: 'This Year',
  all: 'All'
};

export const studyAppCoordinatorStudyNotificationsSettingsNotificationReason = {
  new_participant: 'New Participant Enrolled Notifications',
  task_assigned: 'Task Assigned Notifications',
  task_overdue: 'Task Overdue Notifications',
  task_completed: 'Task Completed Notifications',
  form_completed: 'Form Completed Notifications'
};

export const studyAppFeaturesRolloutModel = {
  study: 'Study',
  organization: 'Organization',
  user: 'User'
};

export const studyAppTeamStudyNotificationsSettingsNotificationReason = {
  new_participant: 'New Participant Enrolled Notifications'
};

export const studyAppCachedReportsStatsCheckerActivity = 'stats_checker_activity';

export const studyAppCachedReportsStatsCheckerFriendly = 'stats_checker_friendly';

export const siteAppGallerySubscriptionMaxLengthFailed = null;

export const siteAppOrgInviteCampaignStatus = {
  DRA: 'Draft',
  ACT: 'Active',
  PAU: 'Paused',
  FIN: 'Finished',
  DEL: 'Deleted'
};

export const siteAppOrgInviteSurveyResultType = {
  SP: 'StudyPages',
  TYPEFORM: 'Typeform'
};

export const siteAppOrgInviteLeadLeadSource = {
  DSH: 'Dashboard',
  API: 'API',
  REG: 'Registry'
};

export const siteAppOrgInviteLeadSendMethod = {
  NO: 'NO',
  EML: 'Email',
  SMS: 'SMS',
  BOTH: 'BOTH'
};

export const siteAppOrgInviteLeadSendStatus = {
  NEW: 'New',
  SCSS: 'Success',
  FAIL: 'Fail'
};

export const siteAppOrgInviteLeadLeadResponse = {
  UNK: 'Unknown',
  INT: 'Interested',
  NI: 'Not Interested'
};

export const siteAppOrgInviteActivityActivityType = {
  INV_SND: 'Invitation Send',
  RMD_SND: 'Invitation Reminder Send',
  RTR_SND: 'Invitation Retry Send',
  SP_PGV: 'StudyPage View'
};

export const siteAppInviteMessageActivityActivityType = {
  SMS: 'SMS SENT',
  EMAIL: 'EMAIL SENT'
};

export const siteAppImportOrgInviteLeadsStatus = {
  pending: 'Pending',
  in_progress: 'In Progress',
  completed: 'Completed',
  failed: 'Failed'
};

export const surveyAppSurveyStatus = {
  D: 'Draft',
  P: 'Published',
  C: 'Completed'
};

export const surveyAppSurveySurveyStatusDraftOpt = 'D';

export const surveyAppSurveySurveyStatusPublishedOpt = 'P';

export const surveyAppSurveySurveyStatusCompleteOpt = 'C';

export const surveyAppSurveySurveyStatusChoices = {
  D: 'Draft',
  P: 'Published',
  C: 'Completed'
};

export const surveyAppQuestionDataType = {
  B: 'Yes/No',
  S: 'Single Choice',
  M: 'Multiple Choice',
  T: 'Text',
  I: 'Number',
  D: 'Date',
  BMI: 'BMI'
};

export const surveyAppQuestionSurveyQuestionBooleanOpt = 'B';

export const surveyAppQuestionSurveyQuestionSingleSelectOpt = 'S';

export const surveyAppQuestionSurveyQuestionMultipleSelectOpt = 'M';

export const surveyAppQuestionSurveyQuestionFreetextOpt = 'T';

export const surveyAppQuestionSurveyQuestionIntegerOpt = 'I';

export const surveyAppQuestionSurveyQuestionDateOpt = 'D';

export const surveyAppQuestionSurveyQuestionBmiOpt = 'BMI';

export const surveyAppQuestionSurveyQuestionChoices = {
  B: 'Yes/No',
  S: 'Single Choice',
  M: 'Multiple Choice',
  T: 'Text',
  I: 'Number',
  D: 'Date',
  BMI: 'BMI'
};

export const surveyAppQuestionSurveyQuestionChoicesDict = {
  B: 'Yes/No',
  S: 'Single Choice',
  M: 'Multiple Choice',
  T: 'Text',
  I: 'Number',
  D: 'Date',
  BMI: 'BMI'
};

export const surveyAppQuestionSurveyQuestionSingleSelectDefault = 'Default';

export const surveyAppQuestionSurveyChoicesDelimiter = '***';

export const surveyAppQuestionSurveyCriteriaDelimiter = '***';

export const surveyAppResultEntryType = {
  SPG: 'StudyPage',
  DIR: 'Direct',
  EML: 'Email'
};

export const surveyAppAnswerSurveyAnswerDelimiter = '***';

export const callTrackingAppVoiceMessageSettingsGreetingType = {
  GRDF: 'Greeting Default',
  GRTP: 'Greeting Text',
  GRAU: 'Greeting Audio'
};

export const callTrackingAppVoiceMessageSettingsCustomVmTextMaxChars = null;

export const callTrackingAppPhoneCallDirection = {
  INB: 'Inbound',
  OUT: 'Outbound'
};

export const visitAppSPVideoRoomType = {
  ADH: 'Ad-Hoc',
  SCH: 'Scheduled'
};

export const visitAppSPVideoRoomStatus = {
  NEW: 'New',
  INP: 'In progress',
  CMP: 'Completed'
};

export const visitAppSPVideoRoomScheduledTimezone = {
  'US/Pacific': 'PST',
  'US/Mountain': 'MST',
  'US/Central': 'CST',
  'US/Eastern': 'EST',
  'US/Hawaii': 'HST'
};

export const visitAppSPVideoAttendeeType = {
  HST: 'Host',
  PRT: 'Participant'
};

export const visitAppSPVideoActivityActivityType = {
  RSCB: 'Room Remote Status Callback',
  PGVA: 'Room Pageview - Attendee',
  RCRS: 'Room Remote Create - System',
  RRCRS: 'Room Remote Recreate - System',
  RCLS: 'Room Remote Close - System',
  LSCS: 'Room Local Status Change - System',
  RCRH: 'Room Remote Create - Host',
  RRCRH: 'Room Remote Recreate - Host',
  RCLH: 'Room Remote Close - Host',
  LSCH: 'Room Local Status Change - Host',
  SINC: 'Settings Incompatible Device'
};

export const djangoCeleryResultsTaskResultStatus = {
  FAILURE: 'FAILURE',
  PENDING: 'PENDING',
  RECEIVED: 'RECEIVED',
  RETRY: 'RETRY',
  REVOKED: 'REVOKED',
  STARTED: 'STARTED',
  SUCCESS: 'SUCCESS'
};

export const oauth2ProviderApplicationClientType = {
  confidential: 'Confidential',
  public: 'Public'
};

export const oauth2ProviderApplicationAuthorizationGrantType = {
  'authorization-code': 'Authorization code',
  implicit: 'Implicit',
  password: 'Resource owner password-based',
  'client-credentials': 'Client credentials',
  'openid-hybrid': 'OpenID connect hybrid'
};

export const oauth2ProviderApplicationAlgorithm = {
  '': 'No OIDC support',
  RS256: 'RSA with SHA-2 256',
  HS256: 'HMAC with SHA-2 256'
};

export const oauth2ProviderGrantCodeChallengeMethod = {
  plain: 'plain',
  S256: 'S256'
};

export const eavAttributeDatatype = {
  text: 'Text',
  date: 'Date',
  float: 'Float',
  int: 'Integer',
  bool: 'True / False',
  object: 'Django Object',
  enum: 'Multiple Choice',
  json: 'JSON Object',
  csv: 'Comma-Separated-Value'
};

export const eavAttributeTypeText = 'text';

export const eavAttributeTypeFloat = 'float';

export const eavAttributeTypeInt = 'int';

export const eavAttributeTypeDate = 'date';

export const eavAttributeTypeBoolean = 'bool';

export const eavAttributeTypeObject = 'object';

export const eavAttributeTypeEnum = 'enum';

export const eavAttributeTypeJson = 'json';

export const eavAttributeTypeCsv = 'csv';

export const eavAttributeDatatypeChoices = {
  text: 'Text',
  date: 'Date',
  float: 'Float',
  int: 'Integer',
  bool: 'True / False',
  object: 'Django Object',
  enum: 'Multiple Choice',
  json: 'JSON Object',
  csv: 'Comma-Separated-Value'
};

