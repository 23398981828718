/* eslint-disable no-restricted-syntax */
import { FieldNames, FilterKind, SearchFilterModel } from '../shared';

const filterValueDelimiter = '__';
const filterIdentifier = '~';

export class StudyFilterModel {
  constructor() {
    this.resetPaging();
    this.resetSorting();
    this.resetAllFilters();
  }

  resetPaging() {
    this.skip = 0;
    this.take = 10;
  }

  resetSorting() {
    this.sortBy = undefined;
    this.sortDirection = undefined;
  }

  resetAllFilters() {
    this.searchQuery = '';
    this.searchFilters = [];
  }


  hasActiveFilter() {
    return this.searchFilters.length > 0 || this.searchQuery;
  }

  nextPage() {
    // First time 10 pages are taken (defined initially in study filter model), after that take only 5
    this.skip += this.take;
    this.take = 5;
  }

  // Parse filter values from the current url
  initializeFromUrl(url) {
    this.updateWhenHasValue(url.search, 'searchQuery');
    this.updateWhenHasValue(url.sort_by, 'sortBy');
    this.updateWhenHasValue(url.sort_direction, 'sortDirection');

    // New style url query params: range~age_min=lte_20&range~age_max=gte_20&term~sc_paths=types_drug
    const urlFilterParts = Object.keys(url).filter(x => x.includes(filterIdentifier));
    if (urlFilterParts.length > 0) {
      // Convert the following url query param:
      // term~sc_paths=topic_autism-adhd-and-other__topic_behavior-and-sleep
      // term~topic_tags=autism-adhd-and-other__behavior-and-sleep
      // Into:
      // SearchFilterModel: filterValue=some_term  | fieldName = topic_tags | filterKind = term
      // SearchFilterModel: filterValue=other_term | fieldName = topic_tags | filterKind = term
      for (const filterPart of urlFilterParts) {
        // Example: split term~some_term into fieldName and filterValue
        const splitFilter = filterPart.split(filterIdentifier);
        if (splitFilter.length !== 2) {
          continue;
        }
        const filterKind = splitFilter[0];
        let fieldName = splitFilter[1];
        const filterValues = url[filterPart].split(filterValueDelimiter);

        if (StudyFilterModel.convertLegacyFieldNeeded(fieldName)) {
          // Old-style url format
          // term~topic=skin => term~sc_paths=topics_skin
          // term~topic=topics_skin => term~sc_paths=topics_skin
          fieldName = FieldNames.searchCategoryPaths;
          filterValues.forEach((filterValue, index) => {
            if (!filterValue.includes('_')) {
              // Old-style value format
              // skin => topics_skin
              filterValues[index] = `${splitFilter[1]}s_${filterValue}`;
            }
          });
        }

        filterValues.forEach(filterValue => this.searchFilters.push(new SearchFilterModel(fieldName, filterKind, filterValue)));
      }
    } else {
      // User linking in using an old-style external link
      const oldTopic = url.topic || url.topics;
      if (oldTopic) {
        this.searchFilters.push(new SearchFilterModel(FieldNames.searchCategoryPaths, FilterKind.term, `topics_${oldTopic}`));
      }
      const oldType = url.type || url.types;
      if (oldType) {
        this.searchFilters.push(new SearchFilterModel(FieldNames.searchCategoryPaths, FilterKind.term, `types_${oldType}`));
      }
      const oldCenter = url.center || url.centers;
      if (oldCenter) {
        this.searchFilters.push(new SearchFilterModel(FieldNames.searchCategoryPaths, FilterKind.term, `centers_${oldCenter}`));
      }
      const oldAnimal = url.animal || url.animals;
      if (oldAnimal) {
        this.searchFilters.push(new SearchFilterModel(FieldNames.searchCategoryPaths, FilterKind.term, `animals_${oldAnimal}`));
      }
    }
  }

  static convertLegacyFieldNeeded(name) {
    switch (name) {
      case 'topic':
      case 'type':
      case 'center':
      case 'animal':
        return true;
      default:
        return false;
    }
  }

  // Updates the current filter model when the specified payload property is not undefined
  updateWhenHasValue(payloadProp, objKey) {
    if (payloadProp !== undefined && payloadProp !== null) {
      this[objKey] = payloadProp;
    }
  }

  // Used for rebuilding the url when one of the filters has been updated
  toQueryParams() {
    const queryParams = {};
    StudyFilterModel.addToQueryParam(queryParams, 'sort_by', this.sortBy);
    StudyFilterModel.addToQueryParam(queryParams, 'sort_direction', this.sortDirection);
    StudyFilterModel.addToQueryParam(queryParams, 'search', this.searchQuery);
    // Convert SearchFilter models back to kind~field_name=value concatenated string
    const mappedSearchFilters = {};

    this.searchFilters.forEach(x => {
      const objKey = x.filterKind + filterIdentifier + x.fieldName;
      if (mappedSearchFilters[objKey]) {
        mappedSearchFilters[objKey] += `${filterValueDelimiter}${x.filterValue}`;
      } else {
        mappedSearchFilters[objKey] = x.filterValue;
      }
    });

    for (const prop in mappedSearchFilters) {
      StudyFilterModel.addToQueryParam(queryParams, prop, mappedSearchFilters[prop]);
    }

    return queryParams;
  }

  // Prepare payload for contacting backend
  toApiPayload() {
    return {
      search: this.searchQuery,
      skip: this.skip,
      take: this.take,
      sort_by: this.sortBy,
      sort_direction: this.sortDirection,
      search_filters: this.searchFilters.map(x => x.mapForBackend())
    };
  }

  static addToQueryParam(queryParams, newParamKey, newParamValue) {
    // Don't add undefined/unnecessary items to url
    if (newParamValue === undefined || newParamValue === null || newParamValue.length === 0) {
      return;
    }

    queryParams[newParamKey] = newParamValue;
  }
}
