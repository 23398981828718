import { constants } from '../../constants';

const pageTypes = constants.staticPageTypes;
const footerContentTypes = constants.footerContentTypes;
export class FooterModel {
  constructor(footerData = {}, staticPages = []) {
    this.privacyPolicyLabel = footerData.privacyPolicyLabel || 'Privacy Policy';
    this.privacyPolicyUrl = footerData.privacyPolicyUrl;
    this.usePrivacyPolicyStaticPage = footerData.privacyPolicyType !== footerContentTypes.externalRef &&
        staticPages.some(x => x.type === pageTypes.privacyPolicy);
    this.termsLabel = footerData.termsLabel || 'Terms of Use';
    this.termsUrl = footerData.termsUrl;
    this.useTermsStaticPage = footerData.termsType !== footerContentTypes.externalRef &&
        staticPages.some(x => x.type === pageTypes.terms);
    this.yearLabel = footerData.yearLabel;
    this.studyPagesLabel = footerData.studyPagesLabel || 'Studypages by Yuzu Labs PBC';
    this.studyPagesUrl = footerData.studyPagesUrl;
  }
}
