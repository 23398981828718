export const es = {
  header: {
    researcherLogin: 'Investigador de inicio de sesión',
    searchPlaceholder: 'Buscar estudios'
  },
  menu: {
    home: 'Hogar',
    studies: 'Estudios',
    about: 'Acerca de',
    contact: 'Contacto'
  },
  top: {
    btnTitle: 'Buscar estudios'
  },
  featuredstudies: {
    title: 'Estudios destacados'
  },
  browse: {
    btnTitle: 'Explorar todo'
  },
  browsestudiesby: {
    title: 'Explorar estudios por'
  },
  studies: {
    filter: {
      mainTitle: 'Filtros',
      clearAllTitle: 'Limpiar todo',
      sex: {
        title: 'Género',
        all: 'Todos',
        male: 'Hombre',
        female: 'Mujer'
      },
      age: {
        title: 'Edad',
        question: '¿Cual es tu edad?'
      },
      options: {
        title: 'Opciones',
        label1: 'Acepta voluntarios sanos',
        label2: 'Tiene compensación'
      },
      language: {
        title: 'Idioma',
        langFilterAny: 'Cualquier'
      },
      aggregated: {
        clearSelectionTitle: 'Selección clara'
      }
    },
    main: {
      learnMore: 'Aprende más',
      ifNoResultText: 'No se encontraron estudios',
      counterStudiesTitle: 'Estudios encontrados',
      studySearchSmallFiltersTitle: 'Filtros',
      sort: {
        title: 'Ordenar por:',
        relevance: 'Relevancia',
        newestFirst: 'El más nuevo primero',
        oldestFirst: 'Los más viejos primero',
        distance: 'Distancia'
      },
      interestedInTheFollowingTitle: 'También te podrían interesar los siguientes estudios',
      card: {
        ifInvestigatorTitle: 'Investigador',
        btnTitle: 'Aprende más'
      },
      banner: {
        title: '¿Interesado en estos estudios?',
        description: 'Descubra cuándo están disponibles nuevos estudios que le interesan.',
        btnNotifyMeByEmail: 'Notificarme por correo electrónico'
      },
      subButton: {
        btnGetNotifiedTitle: 'Recibe notificaciones sobre nuevos estudios'
      },
      modalWindow: {
        btnNotifyAboutNewStudies: 'Recibe notificaciones sobre nuevos estudios',
        subscribedTitle: '¡Suscrito!',
        couldNotSubscribe: 'No se pudo suscribir',
        form: {
          errorTitle: 'Por favor corrige los siguientes errores:',
          errors: {
            emailRequired: 'Correo electrónico requerido.',
            validEmailRequired: 'Se requiere un correo electrónico válido.',
            nameRequired: 'Nombre requerido.'
          },
          email: {
            label: 'Dirección de correo electrónico',
            placeholder: 'Ingrese correo electrónico',
            text: 'Nunca compartiremos su correo electrónico con nadie más.'
          },
          name: {
            label: 'Nombre',
            placeholder: 'Introduzca su nombre'
          },
          radio: {
            title: '¿Cuándo le gustaría recibir una notificación?',
            label1: 'Cuando haya nuevos estudios disponibles',
            label2: 'Resumen semanal',
            label3: 'Resumen mensual'
          },
          hasExtraCriteriaTitle: 'Sólo se le notificará sobre los estudios que coincidan con estos criterios de búsqueda:',
          subscribeBtnTitle: 'Suscribir'
        }
      },
      geoFilter: {
        showNearMe: 'Mostrar estudios cerca de mí',
        couldNotRetrieve: 'No se pudo recuperar su ubicación, por favor dé acceso en su navegador y vuelva a cargar la página',
        retrievingLocation: 'Recuperando ubicación...'
      },
      searchSuggestions: 'Studypages Buscar'
    }
  },
  footer: {
    poweredBy: 'Energizado por'
  }
};

