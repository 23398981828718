export const en = {
  header: {
    researcherLogin: 'Researcher Login',
    searchPlaceholder: 'Search studies'
  },
  menu: {
    home: 'Home',
    studies: 'Studies',
    about: 'About',
    contact: 'Contact'
  },
  top: {
    btnTitle: 'Find Studies'
  },
  featuredstudies: {
    title: 'Featured studies'
  },
  browse: {
    btnTitle: 'Browse all'
  },
  browsestudiesby: {
    title: 'Browse studies by'
  },
  studies: {
    filter: {
      mainTitle: 'Filters',
      clearAllTitle: 'Clear all',
      sex: {
        title: 'Sex',
        all: 'All',
        male: 'Male',
        female: 'Female'
      },
      age: {
        title: 'Age',
        question: "What's your age?"
      },
      options: {
        title: 'Options',
        label1: 'Accepts healthy volunteers',
        label2: 'Has compensation'
      },
      language: {
        title: 'Language',
        langFilterAny: 'Any'
      },
      aggregated: {
        clearSelectionTitle: 'Clear selection'
      }
    },
    main: {
      learnMore: 'Learn more',
      ifNoResultText: 'No studies found',
      counterStudiesTitle: 'Studies found',
      studySearchSmallFiltersTitle: 'Filters',
      sort: {
        title: 'Sort By:',
        relevance: 'Relevance',
        newestFirst: 'Newest First',
        oldestFirst: 'Oldest First',
        distance: 'Distance'
      },
      interestedInTheFollowingTitle: 'You might also be interested in the following studies',
      card: {
        ifInvestigatorTitle: 'Investigator',
        btnTitle: 'Learn more'
      },
      banner: {
        title: 'Interested in these studies?',
        description: "Find out when new studies you're interested in are available.",
        btnNotifyMeByEmail: 'Notify me by email'
      },
      subButton: {
        btnGetNotifiedTitle: 'Get notified about new studies'
      },
      modalWindow: {
        btnNotifyAboutNewStudies: 'Get notified about new studies',
        subscribedTitle: 'Subscribed!',
        couldNotSubscribe: 'Could not subscribe',
        form: {
          errorTitle: 'Please correct the following error(s):',
          errors: {
            emailRequired: 'Email required.',
            validEmailRequired: 'Valid email required.',
            nameRequired: 'Name required.'
          },
          email: {
            label: 'Email address',
            placeholder: 'Enter email',
            text: "We'll never share your email with anyone else."
          },
          name: {
            label: 'Name',
            placeholder: 'Enter your name'
          },
          radio: {
            title: 'When would you like to get notified?',
            label1: 'When new studies are available',
            label2: 'Weekly digest',
            label3: 'Monthly digest'
          },
          hasExtraCriteriaTitle: 'You will only be notified about studies matching these search criteria:',
          subscribeBtnTitle: 'Subscribe'
        }
      },
      geoFilter: {
        showNearMe: 'Show studies near me',
        couldNotRetrieve: 'Could not retrieve your location, please give access in your browser and reload the page',
        retrievingLocation: 'Retrieving location...'
      },
      searchSuggestions: 'Studypages Search'
    }
  },
  footer: {
    poweredBy: 'Powered by'
  }
};
