<script setup>
import { ref } from 'vue';
import { StudiesContentWrapper, SidebarModal, useRehydrate } from '@yuzulabs/lib-gallery';
import { useStore } from 'vuex';
import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import { StudiesMain } from '../components/studies';
import Sidebar from '../components/sidebar/Sidebar.vue';

const sidebarModalVisible = ref(false);
const setSidebarModalVisibility = isVisible => {
  sidebarModalVisible.value = isVisible;
};
// When visiting the studies app, rehydrate the studies store with the URL query params
const { rehydrateBeforeRouteUpdate } = useRehydrate(useStore(), useRoute());

// Activated on each route update within the /studies path
// Will rehydrate the state with the query params (ONLY WHEN NEEDED)
onBeforeRouteUpdate(rehydrateBeforeRouteUpdate);
</script>

<template>
  <StudiesContentWrapper>
    <template #side-small>
      <SidebarModal v-if="sidebarModalVisible" @close="setSidebarModalVisibility(false)">
        <Sidebar @sidebar-item-clicked="setSidebarModalVisibility(false)" />
      </SidebarModal>
    </template>
    <template #side-medium>
      <Sidebar />
    </template>
    <template #main>
      <StudiesMain @show-modal="setSidebarModalVisibility(true)" />
    </template>
  </StudiesContentWrapper>
</template>
