export const PlatformUrls = {
  favicon: {
    path: '/favicon.ico',
    djangoRouteName: 'favicon',
    djangoPath: '/favicon.ico'
  },
  participantDetail: {
    path: '/api/v1/participant',
    djangoRouteName: 'participant-detail',
    djangoPath: '/api/v1/participant/<pk>'
  },
  participantRollbackToSignup: {
    path: '/api/v1/participant',
    djangoRouteName: 'participant-rollback-to-signup',
    djangoPath: '/api/v1/participant/<pk>/rollback_to_signup'
  },
  visitList: {
    path: '/api/v1/visit',
    djangoRouteName: 'visit-list',
    djangoPath: '/api/v1/visit'
  },
  visitDetail: {
    path: '/api/v1/visit',
    djangoRouteName: 'visit-detail',
    djangoPath: '/api/v1/visit/<pk>'
  },
  calendarActivityList: {
    path: '/api/v1/calendar-activity',
    djangoRouteName: 'calendar_activity-list',
    djangoPath: '/api/v1/calendar-activity'
  },
  calendarActivityDetail: {
    path: '/api/v1/calendar-activity',
    djangoRouteName: 'calendar_activity-detail',
    djangoPath: '/api/v1/calendar-activity/<pk>'
  },
  studyTaskList: {
    path: '/api/v1/study-task',
    djangoRouteName: 'study_task-list',
    djangoPath: '/api/v1/study-task'
  },
  studyTaskDetail: {
    path: '/api/v1/study-task',
    djangoRouteName: 'study_task-detail',
    djangoPath: '/api/v1/study-task/<pk>'
  },
  snoozedConfigList: {
    path: '/api/v1/snoozed-config',
    djangoRouteName: 'snoozed_config-list',
    djangoPath: '/api/v1/snoozed-config'
  },
  snoozedConfigDetail: {
    path: '/api/v1/snoozed-config',
    djangoRouteName: 'snoozed_config-detail',
    djangoPath: '/api/v1/snoozed-config/<study_id>'
  },
  participantForStudyTaskList: {
    path: '/api/v1/participant-for-study-task',
    djangoRouteName: 'participant_for_study_task-list',
    djangoPath: '/api/v1/participant-for-study-task'
  },
  participantForStudyTaskDetail: {
    path: '/api/v1/participant-for-study-task',
    djangoRouteName: 'participant_for_study_task-detail',
    djangoPath: '/api/v1/participant-for-study-task/<pk>'
  },
  callFeedbackList: {
    path: '/api/v1/call-feedback',
    djangoRouteName: 'call_feedback-list',
    djangoPath: '/api/v1/call-feedback'
  },
  callFeedbackDetail: {
    path: '/api/v1/call-feedback',
    djangoRouteName: 'call_feedback-detail',
    djangoPath: '/api/v1/call-feedback/<pk>'
  },
  filesList: {
    path: '/api/v1/files',
    djangoRouteName: 'files-list',
    djangoPath: '/api/v1/files'
  },
  filesDetail: {
    path: '/api/v1/files',
    djangoRouteName: 'files-detail',
    djangoPath: '/api/v1/files/<pk>'
  },
  megastudySiteList: {
    path: '/api/v1/megastudy-site',
    djangoRouteName: 'megastudy_site-list',
    djangoPath: '/api/v1/megastudy-site'
  },
  megastudySiteSpadmin: {
    path: '/api/v1/megastudy-site/spadmin',
    djangoRouteName: 'megastudy_site-spadmin',
    djangoPath: '/api/v1/megastudy-site/spadmin'
  },
  megastudySiteDetail: {
    path: '/api/v1/megastudy-site',
    djangoRouteName: 'megastudy_site-detail',
    djangoPath: '/api/v1/megastudy-site/<uid>'
  },
  registryConditionsList: {
    path: '/api/v1/registry-conditions',
    djangoRouteName: 'registry-conditions-list',
    djangoPath: '/api/v1/registry-conditions'
  },
  registryConditionsDetail: {
    path: '/api/v1/registry-conditions',
    djangoRouteName: 'registry-conditions-detail',
    djangoPath: '/api/v1/registry-conditions/<id>'
  },
  registryConditionsDeleteCondition: {
    path: '/api/v1/registry-conditions',
    djangoRouteName: 'registry-conditions-delete-condition',
    djangoPath: '/api/v1/registry-conditions/<id>/delete-condition'
  },
  registryConditionsEditCondition: {
    path: '/api/v1/registry-conditions',
    djangoRouteName: 'registry-conditions-edit-condition',
    djangoPath: '/api/v1/registry-conditions/<id>/edit-condition'
  },
  studypageDetail: {
    path: '/api/v1/studypage',
    djangoRouteName: 'studypage-detail',
    djangoPath: '/api/v1/studypage/<pk>'
  },
  studypageStudyContent: {
    path: '/api/v1/studypage',
    djangoRouteName: 'studypage-study_content',
    djangoPath: '/api/v1/studypage/<pk>/<content_lang>'
  },
  studypageV3Detail: {
    path: '/api/v1/studypage-v3',
    djangoRouteName: 'studypage_v3-detail',
    djangoPath: '/api/v1/studypage-v3/<id>'
  },
  studypageV3Content: {
    path: '/api/v1/studypage-v3',
    djangoRouteName: 'studypage_v3-content',
    djangoPath: '/api/v1/studypage-v3/<id>/<content_lang>'
  },
  studypageV3RochesterDetail: {
    path: '/api/v1/studypage-v3-rochester',
    djangoRouteName: 'studypage_v3_rochester-detail',
    djangoPath: '/api/v1/studypage-v3-rochester/<id>'
  },
  studypageV3RochesterContent: {
    path: '/api/v1/studypage-v3-rochester',
    djangoRouteName: 'studypage_v3_rochester-content',
    djangoPath: '/api/v1/studypage-v3-rochester/<id>/<content_lang>'
  },
  studypageV3VetmedDetail: {
    path: '/api/v1/studypage-v3-vetmed',
    djangoRouteName: 'studypage_v3_vetmed-detail',
    djangoPath: '/api/v1/studypage-v3-vetmed/<id>'
  },
  studypageV3VetmedContent: {
    path: '/api/v1/studypage-v3-vetmed',
    djangoRouteName: 'studypage_v3_vetmed-content',
    djangoPath: '/api/v1/studypage-v3-vetmed/<id>/<content_lang>'
  },
  orgLocationsDirectoryList: {
    path: '/api/v1/org-locations-directory',
    djangoRouteName: 'org_locations_directory-list',
    djangoPath: '/api/v1/org-locations-directory'
  },
  orgLocationsDirectoryDetail: {
    path: '/api/v1/org-locations-directory',
    djangoRouteName: 'org_locations_directory-detail',
    djangoPath: '/api/v1/org-locations-directory/<pk>'
  },
  studypageImagesDetail: {
    path: '/api/v1/studypage-images',
    djangoRouteName: 'studypage_images-detail',
    djangoPath: '/api/v1/studypage-images/<pk>'
  },
  investigatorList: {
    path: '/api/v1/investigator',
    djangoRouteName: 'investigator-list',
    djangoPath: '/api/v1/investigator'
  },
  investigatorDetail: {
    path: '/api/v1/investigator',
    djangoRouteName: 'investigator-detail',
    djangoPath: '/api/v1/investigator/<pk>'
  },
  studyPdfDocsList: {
    path: '/api/v1/study-pdf-docs',
    djangoRouteName: 'study_pdf-docs-list',
    djangoPath: '/api/v1/study-pdf-docs'
  },
  studyPdfDocsDetail: {
    path: '/api/v1/study-pdf-docs',
    djangoRouteName: 'study_pdf-docs-detail',
    djangoPath: '/api/v1/study-pdf-docs/<pk>'
  },
  studyPdfDocsDownload: {
    path: '/api/v1/study-pdf-docs',
    djangoRouteName: 'study_pdf-docs-download',
    djangoPath: '/api/v1/study-pdf-docs/<pk>/download'
  },
  workspaceNotificationList: {
    path: '/api/v1/workspace-notification',
    djangoRouteName: 'workspace_notification-list',
    djangoPath: '/api/v1/workspace-notification'
  },
  workspaceNotificationDetail: {
    path: '/api/v1/workspace-notification',
    djangoRouteName: 'workspace_notification-detail',
    djangoPath: '/api/v1/workspace-notification/<pk>'
  },
  studyInvestigatorList: {
    path: '/api/v1/study-investigator',
    djangoRouteName: 'study_investigator-list',
    djangoPath: '/api/v1/study-investigator'
  },
  studyInvestigatorDetail: {
    path: '/api/v1/study-investigator',
    djangoRouteName: 'study_investigator-detail',
    djangoPath: '/api/v1/study-investigator/<pk>'
  },
  studyInvestigatorSetTopPriority: {
    path: '/api/v1/study-investigator',
    djangoRouteName: 'study_investigator-set-top-priority',
    djangoPath: '/api/v1/study-investigator/<pk>/set-top-priority'
  },
  studyContentList: {
    path: '/api/v1/study-content',
    djangoRouteName: 'study_content-list',
    djangoPath: '/api/v1/study-content'
  },
  studyContentDetail: {
    path: '/api/v1/study-content',
    djangoRouteName: 'study_content-detail',
    djangoPath: '/api/v1/study-content/<pk>'
  },
  studyContentMakeDefault: {
    path: '/api/v1/study-content',
    djangoRouteName: 'study_content-make-default',
    djangoPath: '/api/v1/study-content/<pk>/make-default'
  },
  studySiteSettingsDetail: {
    path: '/api/v1/study-settings',
    djangoRouteName: 'study_site_settings-detail',
    djangoPath: '/api/v1/study-settings/<study_id>'
  },
  organizationSettingsPageDetail: {
    path: '/api/v1/org-settings-page',
    djangoRouteName: 'organization_settings_page-detail',
    djangoPath: '/api/v1/org-settings-page/<orgcode>'
  },
  teamscopeStudyList: {
    path: '/api/v1/teamscope-study',
    djangoRouteName: 'teamscope_study-list',
    djangoPath: '/api/v1/teamscope-study'
  },
  teamscopeStudyDetail: {
    path: '/api/v1/teamscope-study',
    djangoRouteName: 'teamscope_study-detail',
    djangoPath: '/api/v1/teamscope-study/<studypages_study_id>'
  },
  teamscopeStudyRefreshStudy: {
    path: '/api/v1/teamscope-study',
    djangoRouteName: 'teamscope_study-refresh-study',
    djangoPath: '/api/v1/teamscope-study/<studypages_study_id>/refresh'
  },
  orgInviteCampaignList: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign-list',
    djangoPath: '/api/v1/org-invite-campaign'
  },
  orgInviteCampaignDetail: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign-detail',
    djangoPath: '/api/v1/org-invite-campaign/<internal_id>'
  },
  orgInviteCampaignCampaignSummary: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign-campaign-summary',
    djangoPath: '/api/v1/org-invite-campaign/<internal_id>/summary'
  },
  orgInviteCampaignDeliverySettings: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign-delivery-settings',
    djangoPath: '/api/v1/org-invite-campaign/<internal_id>/delivery-settings'
  },
  orgInviteCampaignEmailTemplateSettings: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign-email-template-settings',
    djangoPath: '/api/v1/org-invite-campaign/<internal_id>/email-template-settings'
  },
  orgInviteCampaignHeaderData: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign-header-data',
    djangoPath: '/api/v1/org-invite-campaign/<internal_id>/header-data'
  },
  orgInviteCampaignReportEmails: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign-report-emails',
    djangoPath: '/api/v1/org-invite-campaign/<internal_id>/report_emails'
  },
  orgInviteCampaignSendTestEmail: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign-send-test-email',
    djangoPath: '/api/v1/org-invite-campaign/<internal_id>/send-test-email'
  },
  orgInviteCampaignSendTestSms: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign-send-test-sms',
    djangoPath: '/api/v1/org-invite-campaign/<internal_id>/send-test-sms'
  },
  orgInviteCampaignSmsTemplateSettings: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign-sms-template-settings',
    djangoPath: '/api/v1/org-invite-campaign/<internal_id>/sms-template-settings'
  },
  orgInviteCampaignVerifyCode: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign-verify-code',
    djangoPath: '/api/v1/org-invite-campaign/<internal_id>/verify-code'
  },
  verificationLeadDetail: {
    path: '/api/v1/get-verification-lead',
    djangoRouteName: 'verification-lead-detail',
    djangoPath: '/api/v1/get-verification-lead/<verify_token>'
  },
  verificationLeadCheckVerification: {
    path: '/api/v1/get-verification-lead',
    djangoRouteName: 'verification-lead-check-verification',
    djangoPath: '/api/v1/get-verification-lead/<verify_token>/check_verification'
  },
  verificationLeadExampleLead: {
    path: '/api/v1/get-verification-lead',
    djangoRouteName: 'verification-lead-example-lead',
    djangoPath: '/api/v1/get-verification-lead/<verify_token>/example_lead'
  },
  verificationLeadSendVerification: {
    path: '/api/v1/get-verification-lead',
    djangoRouteName: 'verification-lead-send-verification',
    djangoPath: '/api/v1/get-verification-lead/<verify_token>/send_verification'
  },
  verificationLeadUnsubscribe: {
    path: '/api/v1/get-verification-lead',
    djangoRouteName: 'verification-lead-unsubscribe',
    djangoPath: '/api/v1/get-verification-lead/<verify_token>/unsubscribe'
  },
  studyFavoriteList: {
    path: '/api/v1/study-favorite',
    djangoRouteName: 'study_favorite-list',
    djangoPath: '/api/v1/study-favorite'
  },
  studyFavoriteDetail: {
    path: '/api/v1/study-favorite',
    djangoRouteName: 'study_favorite-detail',
    djangoPath: '/api/v1/study-favorite/<study_id>'
  },
  studySignupTagDetail: {
    path: '/api/v1/study-signup-tag',
    djangoRouteName: 'study_signup_tag-detail',
    djangoPath: '/api/v1/study-signup-tag/<pk>'
  },
  studyIneligibleTagList: {
    path: '/api/v1/study-ineligible-tag',
    djangoRouteName: 'study_ineligible_tag-list',
    djangoPath: '/api/v1/study-ineligible-tag'
  },
  studyIneligibleTagDetail: {
    path: '/api/v1/study-ineligible-tag',
    djangoRouteName: 'study_ineligible_tag-detail',
    djangoPath: '/api/v1/study-ineligible-tag/<pk>'
  },
  curatorGallerySearchCategoryList: {
    path: '/api/v1/curator-gallery-search-category',
    djangoRouteName: 'curator_gallery_search_category-list',
    djangoPath: '/api/v1/curator-gallery-search-category'
  },
  curatorGallerySearchCategoryBulkCreate: {
    path: '/api/v1/curator-gallery-search-category/bulk-create',
    djangoRouteName: 'curator_gallery_search_category-bulk-create',
    djangoPath: '/api/v1/curator-gallery-search-category/bulk-create'
  },
  curatorGallerySearchCategoryDetail: {
    path: '/api/v1/curator-gallery-search-category',
    djangoRouteName: 'curator_gallery_search_category-detail',
    djangoPath: '/api/v1/curator-gallery-search-category/<pk>'
  },
  studyCommentList: {
    path: '/api/v1/study-comment',
    djangoRouteName: 'study_comment-list',
    djangoPath: '/api/v1/study-comment'
  },
  studyCommentDetail: {
    path: '/api/v1/study-comment',
    djangoRouteName: 'study_comment-detail',
    djangoPath: '/api/v1/study-comment/<pk>'
  },
  studyCuratorList: {
    path: '/api/v1/study-curator',
    djangoRouteName: 'study_curator-list',
    djangoPath: '/api/v1/study-curator'
  },
  studyCuratorDetail: {
    path: '/api/v1/study-curator',
    djangoRouteName: 'study_curator-detail',
    djangoPath: '/api/v1/study-curator/<study_id>'
  },
  orgAdminStudyTagList: {
    path: '/api/v1/org-admin-study-tag',
    djangoRouteName: 'org-admin-study-tag-list',
    djangoPath: '/api/v1/org-admin-study-tag'
  },
  orgAdminStudyTagDetail: {
    path: '/api/v1/org-admin-study-tag',
    djangoRouteName: 'org-admin-study-tag-detail',
    djangoPath: '/api/v1/org-admin-study-tag/<pk>'
  },
  orgAdminStudyTagIsUsed: {
    path: '/api/v1/org-admin-study-tag',
    djangoRouteName: 'org-admin-study-tag-is-used',
    djangoPath: '/api/v1/org-admin-study-tag/<pk>/is-used'
  },
  studyNoteList: {
    path: '/api/v1/study-note',
    djangoRouteName: 'study_note-list',
    djangoPath: '/api/v1/study-note'
  },
  studyNoteDetail: {
    path: '/api/v1/study-note',
    djangoRouteName: 'study_note-detail',
    djangoPath: '/api/v1/study-note/<pk>'
  },
  participantStatusList: {
    path: '/api/v1/participant-status',
    djangoRouteName: 'participant_status-list',
    djangoPath: '/api/v1/participant-status'
  },
  participantStatusDetail: {
    path: '/api/v1/participant-status',
    djangoRouteName: 'participant_status-detail',
    djangoPath: '/api/v1/participant-status/<pk>'
  },
  participantStatusMoveParticipantsToNewStatus: {
    path: '/api/v1/participant-status',
    djangoRouteName: 'participant_status-move-participants-to-new-status',
    djangoPath: '/api/v1/participant-status/<pk>/move-participants-to-new-status'
  },
  participantStatusGroupList: {
    path: '/api/v1/participant-status-group',
    djangoRouteName: 'participant_status_group-list',
    djangoPath: '/api/v1/participant-status-group'
  },
  studyContentReviewDetail: {
    path: '/api/v1/study-content-review',
    djangoRouteName: 'study-content-review-detail',
    djangoPath: '/api/v1/study-content-review/<study_content_id>'
  },
  studyContentReviewApprove: {
    path: '/api/v1/study-content-review',
    djangoRouteName: 'study-content-review-approve',
    djangoPath: '/api/v1/study-content-review/<study_content_id>/approve'
  },
  studyContentReviewCancelSubmit: {
    path: '/api/v1/study-content-review',
    djangoRouteName: 'study-content-review-cancel-submit',
    djangoPath: '/api/v1/study-content-review/<study_content_id>/cancel_submit'
  },
  studyContentReviewReject: {
    path: '/api/v1/study-content-review',
    djangoRouteName: 'study-content-review-reject',
    djangoPath: '/api/v1/study-content-review/<study_content_id>/reject'
  },
  studyContentReviewSubmitToReview: {
    path: '/api/v1/study-content-review',
    djangoRouteName: 'study-content-review-submit-to-review',
    djangoPath: '/api/v1/study-content-review/<study_content_id>/submit_to_review'
  },
  orgUserRoleList: {
    path: '/api/v1/org-user-role',
    djangoRouteName: 'org_user_role-list',
    djangoPath: '/api/v1/org-user-role'
  },
  orgImagesDirectoryList: {
    path: '/api/v1/org-images-directory',
    djangoRouteName: 'org_images_directory-list',
    djangoPath: '/api/v1/org-images-directory'
  },
  orgImagesDirectoryDetail: {
    path: '/api/v1/org-images-directory',
    djangoRouteName: 'org_images_directory-detail',
    djangoPath: '/api/v1/org-images-directory/<pk>'
  },
  orgadminIntstudycoordinatorsList: {
    path: '/api/v1/orgadmin-int-study-coordinators',
    djangoRouteName: 'orgadmin_intstudycoordinators-list',
    djangoPath: '/api/v1/orgadmin-int-study-coordinators'
  },
  orgadminIntstudycoordinatorsDetail: {
    path: '/api/v1/orgadmin-int-study-coordinators',
    djangoRouteName: 'orgadmin_intstudycoordinators-detail',
    djangoPath: '/api/v1/orgadmin-int-study-coordinators/<pk>'
  },
  researchConditionDirectoryList: {
    path: '/api/v1/research-condition-directory',
    djangoRouteName: 'research_condition_directory-list',
    djangoPath: '/api/v1/research-condition-directory'
  },
  userStudyWorkspaceConfigList: {
    path: '/api/v1/user-study-workspace-config',
    djangoRouteName: 'user_study_workspace_config-list',
    djangoPath: '/api/v1/user-study-workspace-config'
  },
  userStudyWorkspaceConfigDetail: {
    path: '/api/v1/user-study-workspace-config',
    djangoRouteName: 'user_study_workspace_config-detail',
    djangoPath: '/api/v1/user-study-workspace-config/<pk>'
  },
  userStudyWorkspaceConfigCopy: {
    path: '/api/v1/user-study-workspace-config',
    djangoRouteName: 'user_study_workspace_config-copy',
    djangoPath: '/api/v1/user-study-workspace-config/<pk>/copy'
  },
  consentFilesList: {
    path: '/api/v1/consent-files',
    djangoRouteName: 'consent-files-list',
    djangoPath: '/api/v1/consent-files'
  },
  consentFilesDetail: {
    path: '/api/v1/consent-files',
    djangoRouteName: 'consent-files-detail',
    djangoPath: '/api/v1/consent-files/<id>'
  },
  coordinatorNotificationsSettingsList: {
    path: '/api/v1/coordinator-notifications-settings',
    djangoRouteName: 'coordinator_notifications_settings-list',
    djangoPath: '/api/v1/coordinator-notifications-settings'
  },
  coordinatorNotificationsSettingsDetail: {
    path: '/api/v1/coordinator-notifications-settings',
    djangoRouteName: 'coordinator_notifications_settings-detail',
    djangoPath: '/api/v1/coordinator-notifications-settings/<study_id>'
  },
  allStudyCoordinatorsNotificationsSettingsList: {
    path: '/api/v1/all-study-coordinators-notifications-settings',
    djangoRouteName: 'all_study_coordinators_notifications_settings-list',
    djangoPath: '/api/v1/all-study-coordinators-notifications-settings'
  },
  allStudyCoordinatorsNotificationsSettingsDetail: {
    path: '/api/v1/all-study-coordinators-notifications-settings',
    djangoRouteName: 'all_study_coordinators_notifications_settings-detail',
    djangoPath: '/api/v1/all-study-coordinators-notifications-settings/<study_id>'
  },
  studyTeamNotificationsSettingsList: {
    path: '/api/v1/study-team-notifications-settings',
    djangoRouteName: 'study_team_notifications_settings-list',
    djangoPath: '/api/v1/study-team-notifications-settings'
  },
  studyTeamNotificationsSettingsDetail: {
    path: '/api/v1/study-team-notifications-settings',
    djangoRouteName: 'study_team_notifications_settings-detail',
    djangoPath: '/api/v1/study-team-notifications-settings/<pk>'
  },
  participantConsentDetail: {
    path: '/api/v1/participant-consent',
    djangoRouteName: 'participant_consent-detail',
    djangoPath: '/api/v1/participant-consent/<participant_id>'
  },
  participantConsentCancelEsignRequest: {
    path: '/api/v1/participant-consent',
    djangoRouteName: 'participant_consent-cancel-esign-request',
    djangoPath: '/api/v1/participant-consent/<participant_id>/cancel-esign-request'
  },
  studyBookmarkList: {
    path: '/api/v1/study-bookmark',
    djangoRouteName: 'study-bookmark-list',
    djangoPath: '/api/v1/study-bookmark'
  },
  studyBookmarkDetail: {
    path: '/api/v1/study-bookmark',
    djangoRouteName: 'study-bookmark-detail',
    djangoPath: '/api/v1/study-bookmark/<id>'
  },
  studyBookmarkArchive: {
    path: '/api/v1/study-bookmark',
    djangoRouteName: 'study-bookmark-archive',
    djangoPath: '/api/v1/study-bookmark/<id>/archive'
  },
  apiRoot: {
    path: '/api/v1/spadmin',
    djangoRouteName: 'api-root',
    djangoPath: '/api/v1/spadmin/<drf_format_suffix:format>'
  },
  orgInviteCampaignLeads: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign_leads',
    djangoPath: '/api/v1/org-invite-campaign/<int:internal_id>/leads'
  },
  orgInviteCampaignLeadsBatch: {
    path: '/api/v1/org-invite-campaign',
    djangoRouteName: 'org_invite_campaign_leads_batch',
    djangoPath: '/api/v1/org-invite-campaign/<int:internal_id>/leads/batch-create'
  },
  publicHomePage: {
    path: '/',
    djangoRouteName: 'public-home-page',
    djangoPath: '/'
  },
  RobotsTxt: {
    path: '/robots.txt',
    djangoRouteName: '',
    djangoPath: '/robots.txt'
  },
  signupConf: {
    path: '/signup_conf/',
    djangoRouteName: 'signup-conf',
    djangoPath: '/signup_conf/'
  },
  aboutPage: {
    path: '/about/',
    djangoRouteName: 'about-page',
    djangoPath: '/about/'
  },
  staticPages: {
    path: '/en-us',
    djangoRouteName: 'static-pages',
    djangoPath: '/en-us/<page_name>/'
  },
  publicNewsPage: {
    path: '/news/',
    djangoRouteName: 'public-news-page',
    djangoPath: '/news/'
  },
  privacyTerms: {
    path: '/privacy_terms/',
    djangoRouteName: 'privacy-terms',
    djangoPath: '/privacy_terms/'
  },
  publicLoginPage: {
    path: '/login/',
    djangoRouteName: 'public-login-page',
    djangoPath: '/login/'
  },
  orgLoginRedirectPage: {
    path: '/org_login_redirect/',
    djangoRouteName: 'org-login-redirect-page',
    djangoPath: '/org_login_redirect/'
  },
  logoutPage: {
    path: '/logout/',
    djangoRouteName: 'logout-page',
    djangoPath: '/logout/'
  },
  signupResearcher: {
    path: '/signup_researcher/',
    djangoRouteName: 'signup-researcher',
    djangoPath: '/signup_researcher/'
  },
  publicActivityEndpoint: {
    path: '/pub_activity/',
    djangoRouteName: 'public-activity-endpoint',
    djangoPath: '/pub_activity/'
  },
  demoRequestConf: {
    path: '/demo_conf/',
    djangoRouteName: 'demo-request-conf',
    djangoPath: '/demo_conf/'
  },
  socialBegin: {
    path: '/login',
    djangoRouteName: 'social:begin',
    djangoPath: '/login/<str:backend>/'
  },
  socialComplete: {
    path: '/complete',
    djangoRouteName: 'social:complete',
    djangoPath: '/complete/<str:backend>/'
  },
  socialDisconnect: {
    path: '/disconnect',
    djangoRouteName: 'social:disconnect',
    djangoPath: '/disconnect/<str:backend>/'
  },
  socialDisconnectIndividual: {
    path: '/disconnect',
    djangoRouteName: 'social:disconnect_individual',
    djangoPath: '/disconnect/<str:backend>/<int:association_id>/'
  },
  invitationRegister: {
    path: '/accept',
    djangoRouteName: 'invitation_register',
    djangoPath: '/accept/<invitation_key>/'
  },
  siteconnectApiTestGuardant: {
    path: '/siteconnect_api_test/guardant/v0.1',
    djangoRouteName: 'siteconnect-api-test-guardant',
    djangoPath: '/siteconnect_api_test/guardant/v0.1/<api_resource_name>/'
  },
  siteconnectApiGuardant: {
    path: '/siteconnect_api/guardant/v0.1',
    djangoRouteName: 'siteconnect-api-guardant',
    djangoPath: '/siteconnect_api/guardant/v0.1/<api_resource_name>/'
  },
  WellKnownAcmeChallengeP0Bi9Ftfgpuaqytabdenuocgwiovknmrnhti5Wzgrer4: {
    path: '/.well-known/acme-challenge/p0BI9ftfGpUAqYTabDeNUOCgWIovKnmrNHTi5WzGrER4',
    djangoRouteName: '',
    djangoPath: '/.well-known/acme-challenge/p0BI9ftfGpUAqYTabDeNUOCgWIovKnmrNHTi5WzGrER4'
  },
  searchEngineSitemapSp: {
    path: '/sitemap/main\.txt',
    djangoRouteName: 'search-engine-sitemap-sp',
    djangoPath: '/sitemap/main\.txt'
  },
  searchEngineSitemapAvmavct: {
    path: '/sitemap/avmavct\.txt',
    djangoRouteName: 'search-engine-sitemap-avmavct',
    djangoPath: '/sitemap/avmavct\.txt'
  },
  drchronoOauthManage: {
    path: '/drchrono_redirect/',
    djangoRouteName: 'drchrono-oauth-manage',
    djangoPath: '/drchrono_redirect/'
  },
  drchronoPatientIframe: {
    path: '/drchrono/',
    djangoRouteName: 'drchrono-patient-iframe',
    djangoPath: '/drchrono/'
  },
  drchronoTestConsole: {
    path: '/drchrono_test/',
    djangoRouteName: 'drchrono-test-console',
    djangoPath: '/drchrono_test/'
  },
  memberDashboardPage: {
    path: '/dashboard/.',
    djangoRouteName: 'member-dashboard-page',
    djangoPath: '/dashboard/.'
  },
  studyDirectoryPage: {
    path: '/study_directory/',
    djangoRouteName: 'study-directory-page',
    djangoPath: '/study_directory/'
  },
  settingsTopPage: {
    path: '/settings/',
    djangoRouteName: 'settings-top-page',
    djangoPath: '/settings/'
  },
  settingsProfilePage: {
    path: '/settings/profile/',
    djangoRouteName: 'settings-profile-page',
    djangoPath: '/settings/profile/'
  },
  settingsCalendarPage: {
    path: '/settings/calendar/',
    djangoRouteName: 'settings-calendar-page',
    djangoPath: '/settings/calendar/'
  },
  settingsSecurityPage: {
    path: '/settings/security/',
    djangoRouteName: 'settings-security-page',
    djangoPath: '/settings/security/'
  },
  settingsAudioPage: {
    path: '/settings/audio/',
    djangoRouteName: 'settings-audio-page',
    djangoPath: '/settings/audio/'
  },
  settingsNotificationsPage: {
    path: '/settings/notifications/',
    djangoRouteName: 'settings-notifications-page',
    djangoPath: '/settings/notifications/'
  },
  settingsNewslettersPage: {
    path: '/settings/newsletters/',
    djangoRouteName: 'settings-newsletters-page',
    djangoPath: '/settings/newsletters/'
  },
  userNotificationsPage: {
    path: '/user/study_invites/',
    djangoRouteName: 'user-notifications-page',
    djangoPath: '/user/study_invites/'
  },
  passwordChange: {
    path: '/password_change/',
    djangoRouteName: 'password-change',
    djangoPath: '/password_change/'
  },
  passwordChangeFirst: {
    path: '/password_change_first/',
    djangoRouteName: 'password-change-first',
    djangoPath: '/password_change_first/'
  },
  validationEmailSentPage: {
    path: '/email-sent/',
    djangoRouteName: 'validation-email-sent-page',
    djangoPath: '/email-sent/'
  },
  profileViewMyselfPage: {
    path: '/profile/',
    djangoRouteName: 'profile-view-myself-page',
    djangoPath: '/profile/'
  },
  profileViewPage: {
    path: '/profile',
    djangoRouteName: 'profile-view-page',
    djangoPath: '/profile/<profile_id>/'
  },
  invProfileViewPage: {
    path: '/investigator',
    djangoRouteName: 'inv-profile-view-page',
    djangoPath: '/investigator/<public_id>/'
  },
  helpPage: {
    path: '/help/',
    djangoRouteName: 'help-page',
    djangoPath: '/help/'
  },
  helpSupportMarkdownPage: {
    path: '/help',
    djangoRouteName: 'help-support-markdown-page',
    djangoPath: '/help/<support_slug>/'
  },
  knowledgebasePage: {
    path: '/support/knowledge_base/',
    djangoRouteName: 'knowledgebase-page',
    djangoPath: '/support/knowledge_base/'
  },
  covidResourcesUcdPage: {
    path: '/covid_resources/ucd/',
    djangoRouteName: 'covid-resources-ucd-page',
    djangoPath: '/covid_resources/ucd/'
  },
  showUserguideUcdPage: {
    path: '/userguide/ucd/',
    djangoRouteName: 'show-userguide-ucd-page',
    djangoPath: '/userguide/ucd/'
  },
  showUserguidePage: {
    path: '/userguide/',
    djangoRouteName: 'show-userguide-page',
    djangoPath: '/userguide/'
  },
  faqPage: {
    path: '/faq/',
    djangoRouteName: 'faq-page',
    djangoPath: '/faq/'
  },
  studyPageRedirect: {
    path: '/s',
    djangoRouteName: 'study-page-redirect',
    djangoPath: '/s/<study_slug>/redirect/'
  },
  studyPageRedirectPreview: {
    path: '/s',
    djangoRouteName: 'study-page-redirect-preview',
    djangoPath: '/s/<study_slug>/redirect/preview'
  },
  studyInviteExpiredPage: {
    path: '/study_invite_expired/',
    djangoRouteName: 'study-invite-expired-page',
    djangoPath: '/study_invite_expired/'
  },
  oldStudyPageCallback: {
    path: '/s',
    djangoRouteName: 'old-study-page-callback',
    djangoPath: '/s/<study_slug>/survey_complete'
  },
  studyIntegrationSurveyCallback: {
    path: '/integration',
    djangoRouteName: 'study-integration-survey-callback',
    djangoPath: '/integration/<study_slug>/survey_callback'
  },
  viewStudyPageTranslation: {
    path: '',
    djangoRouteName: 'view-study-page-translation',
    djangoPath: '/<language_code>/s/<study_slug>/'
  },
  viewStudyPage: {
    path: '/s',
    djangoRouteName: 'view-study-page',
    djangoPath: '/s/<study_slug>/'
  },
  viewStudyPageOld: {
    path: '/en/s',
    djangoRouteName: 'view-study-page-old',
    djangoPath: '/en/s/<study_slug>/'
  },
  sendStudyEmailEndpoint: {
    path: '/study',
    djangoRouteName: 'send-study-email-endpoint',
    djangoPath: '/study/<public_id>/<megastudy_id>/<language_code>/send_email/'
  },
  sendStudySmsEndpoint: {
    path: '/study',
    djangoRouteName: 'send-study-sms-endpoint',
    djangoPath: '/study/<public_id>/<megastudy_id>/<language_code>/send_sms/'
  },
  sendEmailEndpoint: {
    path: '/send_email/',
    djangoRouteName: 'send-email-endpoint',
    djangoPath: '/send_email/'
  },
  sendSmsEndpoint: {
    path: '/send_sms/',
    djangoRouteName: 'send-sms-endpoint',
    djangoPath: '/send_sms/'
  },
  redcapIntegrationRedirect: {
    path: '/redcap_redirect/p',
    djangoRouteName: 'redcap-integration-redirect',
    djangoPath: '/redcap_redirect/p/<participant_id>/'
  },
  vmacsIntegrationRedirect: {
    path: '/vmacs_redirect/p',
    djangoRouteName: 'vmacs-integration-redirect',
    djangoPath: '/vmacs_redirect/p/<participant_id>/'
  },
  studyDashboardPage: {
    path: '/study',
    djangoRouteName: 'study-dashboard-page',
    djangoPath: '/study/<study_id>/dashboard/.'
  },
  registryWorkspaceEndpoint: {
    path: '/registry',
    djangoRouteName: 'registry-workspace-endpoint',
    djangoPath: '/registry/<study_id>/workspace/'
  },
  createNewStudy: {
    path: '/study/create/',
    djangoRouteName: 'create-new-study',
    djangoPath: '/study/create/'
  },
  createNewAvmaStudy: {
    path: '/study/create-avma-study/',
    djangoRouteName: 'create-new-avma-study',
    djangoPath: '/study/create-avma-study/'
  },
  manageNewStudyOnly: {
    path: '/study/simple/manage/',
    djangoRouteName: 'manage-new-study-only',
    djangoPath: '/study/simple/manage/'
  },
  manageStudyPage: {
    path: '/study',
    djangoRouteName: 'manage-study-page',
    djangoPath: '/study/<study_id>/manage/'
  },
  studyTeamPage: {
    path: '/study',
    djangoRouteName: 'study-team-page',
    djangoPath: '/study/<study_id>/team/'
  },
  studySettingsPage: {
    path: '/study',
    djangoRouteName: 'study-settings-page',
    djangoPath: '/study/<study_id>/settings/'
  },
  previewStudyPage: {
    path: '/study',
    djangoRouteName: 'preview-study-page',
    djangoPath: '/study/<study_id>/preview/'
  },
  studypagePreviewRender: {
    path: '/study',
    djangoRouteName: 'studypage-preview-render',
    djangoPath: '/study/<study_id>/<megastudy_id>/<language_code>/<return_type>/render/'
  },
  studyDraftPage: {
    path: '/draft',
    djangoRouteName: 'study-draft-page',
    djangoPath: '/draft/<draft_key>/'
  },
  studySiteDashboardPage: {
    path: '/study/site/dashboard/',
    djangoRouteName: 'study-site-dashboard-page',
    djangoPath: '/study/site/dashboard/'
  },
  studySiteSelectPage: {
    path: '/study',
    djangoRouteName: 'study-site-select-page',
    djangoPath: '/study/<study_id>/sites/'
  },
  visitStudyCalFeed: {
    path: '/visits/feed',
    djangoRouteName: 'visit-study-cal-feed',
    djangoPath: '/visits/feed/<cal_feed_key>/'
  },
  visitsPrintPage: {
    path: '/visits_print',
    djangoRouteName: 'visits-print-page',
    djangoPath: '/visits_print/<s_pub_id>/<p_pub_id>/'
  },
  myCalFeed: {
    path: '/my_cal/feed',
    djangoRouteName: 'my-cal-feed',
    djangoPath: '/my_cal/feed/<cal_feed_key>/'
  },
  visitModifyPage: {
    path: '/visit_update',
    djangoRouteName: 'visit-modify-page',
    djangoPath: '/visit_update/<visit_conf_id>/'
  },
  visitActivityEndpoint: {
    path: '/study',
    djangoRouteName: 'visit-activity-endpoint',
    djangoPath: '/study/<study_id>/visit_activity/'
  },
  mycalendarActivityEndpoint: {
    path: '/mycalendar_activity/',
    djangoRouteName: 'mycalendar-activity-endpoint',
    djangoPath: '/mycalendar_activity/'
  },
  studyEsignEndpoint: {
    path: '/study',
    djangoRouteName: 'study-esign-endpoint',
    djangoPath: '/study/<study_id>/esign_activity/'
  },
  studyParticipantEsignEndpoint: {
    path: '/study',
    djangoRouteName: 'study-participant-esign-endpoint',
    djangoPath: '/study/<study_id>/participant_esign_activity/'
  },
  studyEsignListTemplates: {
    path: '/study',
    djangoRouteName: 'study-esign-list-templates',
    djangoPath: '/study/<study_id>/esign_templates/'
  },
  manageEmbeddedTemplate: {
    path: '/manage_esign_template/',
    djangoRouteName: 'manage-embedded-template',
    djangoPath: '/manage_esign_template/'
  },
  esignDebug: {
    path: '/sign_debug/',
    djangoRouteName: 'esign-debug',
    djangoPath: '/sign_debug/'
  },
  studyManageFiles: {
    path: '/study',
    djangoRouteName: 'study-manage-files',
    djangoPath: '/study/<study_id>/files/'
  },
  publicEsignDocument: {
    path: '/sign_document',
    djangoRouteName: 'public-esign-document',
    djangoPath: '/sign_document/<signature_id>/'
  },
  studyActivityEndpoint: {
    path: '/study',
    djangoRouteName: 'study-activity-endpoint',
    djangoPath: '/study/<study_id>/activity/'
  },
  studyPublicActivityEndpoint: {
    path: '/study',
    djangoRouteName: 'study-public-activity-endpoint',
    djangoPath: '/study/<public_id>/activity_public/'
  },
  studyReadonlyActivityEndpoint: {
    path: '/study',
    djangoRouteName: 'study-readonly-activity-endpoint',
    djangoPath: '/study/<pub_study_id>/<language_code>/readonly_activity/'
  },
  customUrlLinkPage: {
    path: '',
    djangoRouteName: 'custom-url-link-page',
    djangoPath: '/<custom_slug>/'
  },
  allOfUsCalifornia: {
    path: '/allofus/',
    djangoRouteName: 'all-of-us-california',
    djangoPath: '/allofus/'
  },
  megastudyApp: {
    path: '/mstudy_admin',
    djangoRouteName: 'megastudy-app',
    djangoPath: '/mstudy_admin/<megastudy_id>/.'
  },
  megastudyActivityEndpoint: {
    path: '/mstudy',
    djangoRouteName: 'megastudy-activity-endpoint',
    djangoPath: '/mstudy/<megastudy_id>/study/<study_id>/activity/'
  },
  megastudyDashboardPage: {
    path: '/mstudy',
    djangoRouteName: 'megastudy-dashboard-page',
    djangoPath: '/mstudy/<megastudy_id>/dashboard/'
  },
  previewMegastudyPage: {
    path: '/mstudy',
    djangoRouteName: 'preview-megastudy-page',
    djangoPath: '/mstudy/<megastudy_id>/preview/'
  },
  manageChildstudyPage: {
    path: '/cstudy',
    djangoRouteName: 'manage-childstudy-page',
    djangoPath: '/cstudy/<study_id>/manage/'
  },
  adminMegastudyPreviewPage: {
    path: '/admin/mstudy',
    djangoRouteName: 'admin-megastudy-preview-page',
    djangoPath: '/admin/mstudy/<megastudy_id>/preview/'
  },
  adminMegastudyExportEndpoint: {
    path: '/admin/mstudy',
    djangoRouteName: 'admin-megastudy-export-endpoint',
    djangoPath: '/admin/mstudy/<megastudy_id>/export_endpoint/'
  },
  adminMegastudyActivityEndpoint: {
    path: '/admin/mstudy',
    djangoRouteName: 'admin-megastudy-activity-endpoint',
    djangoPath: '/admin/mstudy/<megastudy_id>/activity_endpoint/'
  },
  siteconnectCallModify: {
    path: '/call_update',
    djangoRouteName: 'siteconnect-call-modify',
    djangoPath: '/call_update/<call_conf_id>/'
  },
  siteconnectCalFeed: {
    path: '/siteconnect/feed',
    djangoRouteName: 'siteconnect-cal-feed',
    djangoPath: '/siteconnect/feed/<cal_feed_key>/'
  },
  siteconnectWidget: {
    path: '/study/joinwidget',
    djangoRouteName: 'siteconnect-widget',
    djangoPath: '/study/joinwidget/<pub_study_id>/<site_id>/'
  },
  siteconnectWidgetCheckslots: {
    path: '/study/joinwidget',
    djangoRouteName: 'siteconnect-widget-checkslots',
    djangoPath: '/study/joinwidget/<pub_study_id>/<site_id>/<date>/'
  },
  siteconnectDashboardPage: {
    path: '/siteconnect',
    djangoRouteName: 'siteconnect-dashboard-page',
    djangoPath: '/siteconnect/<study_id>/dashboard/'
  },
  siteconnectSiteDashboardPage: {
    path: '/siteconnect',
    djangoRouteName: 'siteconnect-site-dashboard-page',
    djangoPath: '/siteconnect/<study_id>/dashboard/<site_id>/'
  },
  manageSiteconnectPage: {
    path: '/siteconnect',
    djangoRouteName: 'manage-siteconnect-page',
    djangoPath: '/siteconnect/<study_id>/manage/'
  },
  siteconnectTestEmbedPage: {
    path: '/siteconnect',
    djangoRouteName: 'siteconnect-test-embed-page',
    djangoPath: '/siteconnect/<study_id>/test_embed/'
  },
  siteconnectSettingsPage: {
    path: '/siteconnect',
    djangoRouteName: 'siteconnect-settings-page',
    djangoPath: '/siteconnect/<study_id>/settings/'
  },
  masterschedulerDashboard: {
    path: '/scheduler',
    djangoRouteName: 'masterscheduler-dashboard',
    djangoPath: '/scheduler/<study_id>/dashboard/'
  },
  masterschedulerTeamPage: {
    path: '/scheduler',
    djangoRouteName: 'masterscheduler-team-page',
    djangoPath: '/scheduler/<study_id>/team/'
  },
  masterschedulerTeamManagePage: {
    path: '/scheduler',
    djangoRouteName: 'masterscheduler-team-manage-page',
    djangoPath: '/scheduler/<study_id>/team/manage/'
  },
  masterschedulerAuditLogPage: {
    path: '/scheduler',
    djangoRouteName: 'masterscheduler-audit-log-page',
    djangoPath: '/scheduler/<study_id>/audit_log/'
  },
  masterschedulerSettingsPage: {
    path: '/scheduler',
    djangoRouteName: 'masterscheduler-settings-page',
    djangoPath: '/scheduler/<study_id>/settings/'
  },
  msMfPatientDetailPage: {
    path: '/scheduler',
    djangoRouteName: 'ms-mf-patient-detail-page',
    djangoPath: '/scheduler/<study_id>/patient/<participant_id>/'
  },
  mfClinicalSiteDashboardPage: {
    path: '/scheduler',
    djangoRouteName: 'mf-clinical-site-dashboard-page',
    djangoPath: '/scheduler/<study_id>/clinical_site/dashboard/'
  },
  mfManufSiteDashboardPage: {
    path: '/scheduler',
    djangoRouteName: 'mf-manuf-site-dashboard-page',
    djangoPath: '/scheduler/<study_id>/manufacturing/dashboard/'
  },
  mfManufAuditLogPage: {
    path: '/scheduler',
    djangoRouteName: 'mf-manuf-audit-log-page',
    djangoPath: '/scheduler/<study_id>/audit/'
  },
  mfManufSiteManagePage: {
    path: '/scheduler',
    djangoRouteName: 'mf-manuf-site-manage-page',
    djangoPath: '/scheduler/<study_id>/manufacturing/<manuf_site_id>/manage/'
  },
  mfClinicalSiteDetailPage: {
    path: '/scheduler',
    djangoRouteName: 'mf-clinical-site-detail-page',
    djangoPath: '/scheduler/<study_id>/clinical_site/<clinical_site_id>/detail/'
  },
  exportScheduleCsvEndpoint: {
    path: '/scheduler',
    djangoRouteName: 'export-schedule-csv-endpoint',
    djangoPath: '/scheduler/<study_id>/export_csv/'
  },
  schedulerActivityEndpoint: {
    path: '/scheduler',
    djangoRouteName: 'scheduler-activity-endpoint',
    djangoPath: '/scheduler/<study_id>/activity/'
  },
  inboundSms: {
    path: '/inbound_sms/',
    djangoRouteName: 'inbound-sms',
    djangoPath: '/inbound_sms/'
  },
  outboundSms: {
    path: '/outbound_sms',
    djangoRouteName: 'outbound-sms',
    djangoPath: '/outbound_sms/<study_id>/'
  },
  callbackSmsInbound: {
    path: '/inbound_callback_sms/',
    djangoRouteName: 'callback-sms-inbound',
    djangoPath: '/inbound_callback_sms/'
  },
  callbackSmsOutbound: {
    path: '/outbound_callback_sms/',
    djangoRouteName: 'callback-sms-outbound',
    djangoPath: '/outbound_callback_sms/'
  },
  inboundFailSms: {
    path: '/inbound_fail_sms/',
    djangoRouteName: 'inbound-fail-sms',
    djangoPath: '/inbound_fail_sms/'
  },
  getSmsStream: {
    path: '/get_sms_stream',
    djangoRouteName: 'get-sms-stream',
    djangoPath: '/get_sms_stream/<study_id>/'
  },
  smsActivityEndpoint: {
    path: '/sms_activity_endpoint',
    djangoRouteName: 'sms-activity-endpoint',
    djangoPath: '/sms_activity_endpoint/<study_id>/'
  },
  getUnreadSmsStudies: {
    path: '/get_unread_sms_study_data/',
    djangoRouteName: 'get-unread-sms-studies',
    djangoPath: '/get_unread_sms_study_data/'
  },
  studyCampaignsPage: {
    path: '/study',
    djangoRouteName: 'study-campaigns-page',
    djangoPath: '/study/<study_id>/campaigns/'
  },
  manageNewFbCampaignPage: {
    path: '/study',
    djangoRouteName: 'manage-new-fb-campaign-page',
    djangoPath: '/study/<study_id>/campaigns/facebook/'
  },
  manageFbCampaignPage: {
    path: '/study',
    djangoRouteName: 'manage-fb-campaign-page',
    djangoPath: '/study/<study_id>/campaigns/facebook/<campaign_id>/'
  },
  ajaximage: {
    path: '/ajaximage/upload',
    djangoRouteName: 'ajaximage',
    djangoPath: '/ajaximage/upload/<upload_to>/<max_width>/<max_height>/<crop>'
  },
  followToggleUserPage: {
    path: '/ajax/follow_toggle/user',
    djangoRouteName: 'follow-toggle-user-page',
    djangoPath: '/ajax/follow_toggle/user/<var>/'
  },
  shutterstockSearchEndpoint: {
    path: '/image_search/',
    djangoRouteName: 'shutterstock-search-endpoint',
    djangoPath: '/image_search/'
  },
  shutterstockApiQueryEndpoint: {
    path: '/ss_api_query/',
    djangoRouteName: 'shutterstock-api-query-endpoint',
    djangoPath: '/ss_api_query/'
  },
  jumpToResource: {
    path: '/jump_to',
    djangoRouteName: 'jump-to-resource',
    djangoPath: '/jump_to/<r_type>/<r_id>/'
  },
  pdfStudyPage: {
    path: '/study',
    djangoRouteName: 'pdf-study-page',
    djangoPath: '/study/<study_id>/studypage_pdf/studypage.pdf'
  },
  pdfStudyPageContent: {
    path: '/study',
    djangoRouteName: 'pdf-study-page-content',
    djangoPath: '/study/<study_id>/<content_id>/studypage_pdf/studypage.pdf'
  },
  pdf: {
    path: '/pdf/',
    djangoRouteName: 'pdf',
    djangoPath: '/pdf/'
  },
  pdfPDetailPage: {
    path: '/pdf/pdetail',
    djangoRouteName: 'pdf-p-detail-page',
    djangoPath: '/pdf/pdetail/<study_id>/'
  },
  crispyFormsTest: {
    path: '/test_crispy/',
    djangoRouteName: 'crispy-forms-test',
    djangoPath: '/test_crispy/'
  },
  testFbConvTrackPixel: {
    path: '/test_fb_conv_track_pixel/',
    djangoRouteName: 'test-fb-conv-track-pixel',
    djangoPath: '/test_fb_conv_track_pixel/'
  },
  testFbPixel: {
    path: '/test_fb_pixel/',
    djangoRouteName: 'test-fb-pixel',
    djangoPath: '/test_fb_pixel/'
  },
  testSurveyView: {
    path: '/test_survey_view/',
    djangoRouteName: 'test-survey-view',
    djangoPath: '/test_survey_view/'
  },
  testSurveyEmbed: {
    path: '/test_survey_embed/',
    djangoRouteName: 'test-survey-embed',
    djangoPath: '/test_survey_embed/'
  },
  trackEmailOpen: {
    path: '/email_pixel/',
    djangoRouteName: 'track-email-open',
    djangoPath: '/email_pixel/'
  },
  emailTest: {
    path: '/email_test/',
    djangoRouteName: 'email-test',
    djangoPath: '/email_test/'
  },
  jdrfEmailTest: {
    path: '/jdrf_email_test/',
    djangoRouteName: 'jdrf-email-test',
    djangoPath: '/jdrf_email_test/'
  },
  samlMetadata: {
    path: '/saml_metadata/',
    djangoRouteName: 'saml-metadata',
    djangoPath: '/saml_metadata/'
  },
  samlLoginTest: {
    path: '/test_saml_login/',
    djangoRouteName: 'saml-login-test',
    djangoPath: '/test_saml_login/'
  },
  listNumbers: {
    path: '/study',
    djangoRouteName: 'list-numbers',
    djangoPath: '/study/<study_id>/study_phone/list_numbers/'
  },
  purchaseStudyNumber: {
    path: '/study',
    djangoRouteName: 'purchase-study-number',
    djangoPath: '/study/<study_id>/study_phone/purchase_number/'
  },
  releaseStudyNumber: {
    path: '/study',
    djangoRouteName: 'release-study-number',
    djangoPath: '/study/<study_id>/study_phone/release_number/'
  },
  studymeManage: {
    path: '/studyme/manage',
    djangoRouteName: 'studyme-manage',
    djangoPath: '/studyme/manage/<participant_pub_id>/'
  },
  participantMessenger: {
    path: '/m/.',
    djangoRouteName: 'participant-messenger',
    djangoPath: '/m/.'
  },
  portalActivityEndpoint: {
    path: '/portal_activity',
    djangoRouteName: 'portal-activity-endpoint',
    djangoPath: '/portal_activity/<org_code>/'
  },
  t1dPortalPublicHomePage: {
    path: '/portal/t1d/',
    djangoRouteName: 't1d-portal-public-home-page',
    djangoPath: '/portal/t1d/'
  },
  t1dPortalPublicRegisterPage: {
    path: '/portal/t1d/register/',
    djangoRouteName: 't1d-portal-public-register-page',
    djangoPath: '/portal/t1d/register/'
  },
  portalTrialViewPage: {
    path: '/portal/t1d/trial',
    djangoRouteName: 'portal-trial-view-page',
    djangoPath: '/portal/t1d/trial/<study_id>/'
  },
  t1dPortalPhysiciansPage: {
    path: '/portal/t1d/physicians/',
    djangoRouteName: 't1d-portal-physicians-page',
    djangoPath: '/portal/t1d/physicians/'
  },
  portalStudiesPage: {
    path: '/portal/studies/',
    djangoRouteName: 'portal-studies-page',
    djangoPath: '/portal/studies/'
  },
  portalSearchPage: {
    path: '/portal/search/',
    djangoRouteName: 'portal-search-page',
    djangoPath: '/portal/search/'
  },
  portalArchivesPage: {
    path: '/portal/studies/archived/',
    djangoRouteName: 'portal-archives-page',
    djangoPath: '/portal/studies/archived/'
  },
  portalBookmarksPage: {
    path: '/portal/studies/bookmarked/',
    djangoRouteName: 'portal-bookmarks-page',
    djangoPath: '/portal/studies/bookmarked/'
  },
  portalPrintTrialList: {
    path: '/portal/studies/print',
    djangoRouteName: 'portal-print-trial-list',
    djangoPath: '/portal/studies/print/<list_type>/'
  },
  portalProfilePage: {
    path: '/portal/profile/',
    djangoRouteName: 'portal-profile-page',
    djangoPath: '/portal/profile/'
  },
  portalAdminDashboardPage: {
    path: '/portal/admin/dashboard/',
    djangoRouteName: 'portal-admin-dashboard-page',
    djangoPath: '/portal/admin/dashboard/'
  },
  portalAdminSponsorsPage: {
    path: '/portal/admin/sponsors/',
    djangoRouteName: 'portal-admin-sponsors-page',
    djangoPath: '/portal/admin/sponsors/'
  },
  galleryUnsubscribe: {
    path: '/unsubscribe',
    djangoRouteName: 'gallery-unsubscribe',
    djangoPath: '/unsubscribe/<subscription_pub_id>/'
  },
  sitepageHome: {
    path: '/site',
    djangoRouteName: 'sitepage-home',
    djangoPath: '/site/<sitepage_code>/'
  },
  sitepageTeam: {
    path: '/site',
    djangoRouteName: 'sitepage-team',
    djangoPath: '/site/<sitepage_code>/team/'
  },
  sitepageDirections: {
    path: '/site',
    djangoRouteName: 'sitepage-directions',
    djangoPath: '/site/<sitepage_code>/directions/'
  },
  sitepageFaq: {
    path: '/site',
    djangoRouteName: 'sitepage-faq',
    djangoPath: '/site/<sitepage_code>/faq/'
  },
  sitepageSponsors: {
    path: '/site',
    djangoRouteName: 'sitepage-sponsors',
    djangoPath: '/site/<sitepage_code>/sponsors/'
  },
  sitepageHomeCustom: {
    path: '',
    djangoRouteName: 'sitepage-home-custom',
    djangoPath: '/<custom_url>/'
  },
  siteHomeCustom: {
    path: '',
    djangoRouteName: 'site-home-custom',
    djangoPath: '/<custom_url>/<resource_url>/'
  },
  sitepageCustomC4D: {
    path: '/site/centerforderm',
    djangoRouteName: 'sitepage-custom-c4d',
    djangoPath: '/site/centerforderm/<page_name>/'
  },
  galleryWidgetPage: {
    path: '/gallery_widget',
    djangoRouteName: 'gallery-widget-page',
    djangoPath: '/gallery_widget/<widget_name>/'
  },
  galleryActivityEndpoint: {
    path: '',
    djangoRouteName: 'gallery-activity-endpoint',
    djangoPath: '/<gallery_url_name>/activity/'
  },
  galleryStudyFeedJson: {
    path: '',
    djangoRouteName: 'gallery-study-feed-json',
    djangoPath: '/<gallery_url_name>/studies/feed/json/'
  },
  galleryElasticSuggest: {
    path: '',
    djangoRouteName: 'gallery-elastic-suggest',
    djangoPath: '/<gallery_url_name>/studies/suggest/json/'
  },
  galleryStudySearch: {
    path: '',
    djangoRouteName: 'gallery-study-search',
    djangoPath: '/<gallery_url_name>/studies/search/json/'
  },
  gallerySuggestRedirect: {
    path: '',
    djangoRouteName: 'gallery-suggest-redirect',
    djangoPath: '/<gallery_url_name>/studies/suggest-redirect/json/'
  },
  gallerySubscribe: {
    path: '',
    djangoRouteName: 'gallery-subscribe',
    djangoPath: '/<gallery_url_name>/studies/subscribe/json/'
  },
  gallerySectionList: {
    path: '',
    djangoRouteName: 'gallery-section-list',
    djangoPath: '/<gallery_url_name>/studies/searchcategories/json/'
  },
  galleryRecordActivity: {
    path: '',
    djangoRouteName: 'gallery-record-activity',
    djangoPath: '/<gallery_url_name>/record-activity/'
  },
  GalleryUrlName: {
    path: '',
    djangoRouteName: '',
    djangoPath: '/<gallery_url_name>'
  },
  galleryHomePage: {
    path: '',
    djangoRouteName: 'gallery-home-page',
    djangoPath: '/<gallery_url_name>/.'
  },
  orgadminDeeplinkTopPage: {
    path: '/orgadmin',
    djangoRouteName: 'orgadmin-deeplink-top-page',
    djangoPath: '/orgadmin/<admin_code>/'
  },
  orgadminDeeplinkNestedRoute: {
    path: '/orgadmin',
    djangoRouteName: 'orgadmin-deeplink-nested-route',
    djangoPath: '/orgadmin/<admin_code>/<var>'
  },
  orgadminDeeplinkStudyDetailPage: {
    path: '/orgadmin',
    djangoRouteName: 'orgadmin-deeplink-study-detail-page',
    djangoPath: '/orgadmin/<admin_code>/studies/details/<study_id>/'
  },
  verificationProcess: {
    path: '/verification-process/',
    djangoRouteName: 'verification_process',
    djangoPath: '/verification-process/'
  },
  orgadminDeeplinkInvestigatorsPage: {
    path: '/orgadmin',
    djangoRouteName: 'orgadmin-deeplink-investigators-page',
    djangoPath: '/orgadmin/<admin_code>/investigators/'
  },
  orgadminDeeplinkUsersPage: {
    path: '/orgadmin',
    djangoRouteName: 'orgadmin-deeplink-users-page',
    djangoPath: '/orgadmin/<admin_code>/users/'
  },
  orgadminDeeplinkInvitecampaignsPage: {
    path: '/admin',
    djangoRouteName: 'orgadmin-deeplink-invitecampaigns-page',
    djangoPath: '/admin/<admin_code>/campaigns/'
  },
  orgadminDeeplinkMessagesPage: {
    path: '/admin',
    djangoRouteName: 'orgadmin-deeplink-messages-page',
    djangoPath: '/admin/<admin_code>/messages/'
  },
  orgadminDeeplinkActivityPage: {
    path: '/admin',
    djangoRouteName: 'orgadmin-deeplink-activity-page',
    djangoPath: '/admin/<admin_code>/activity/'
  },
  orgadminDeeplinkOverviewPage: {
    path: '/admin',
    djangoRouteName: 'orgadmin-deeplink-overview-page',
    djangoPath: '/admin/<admin_code>/'
  },
  orgadminDeeplinkStudiesPage: {
    path: '/admin',
    djangoRouteName: 'orgadmin-deeplink-studies-page',
    djangoPath: '/admin/<admin_code>/studies/'
  },
  orgadminDeeplinkReportsPage: {
    path: '/admin',
    djangoRouteName: 'orgadmin-deeplink-reports-page',
    djangoPath: '/admin/<admin_code>/reports/'
  },
  orgadminDeeplinkGalleriesPage: {
    path: '/admin',
    djangoRouteName: 'orgadmin-deeplink-galleries-page',
    djangoPath: '/admin/<admin_code>/galleries/'
  },
  orgAdminPreviewStudyPage: {
    path: '/admin',
    djangoRouteName: 'org-admin-preview-study-page',
    djangoPath: '/admin/<admin_code>/study/<study_id>/preview/'
  },
  orgAdminPlatformInviteEndpoint: {
    path: '/api/v1/platform_invite',
    djangoRouteName: 'org-admin-platform-invite-endpoint',
    djangoPath: '/api/v1/platform_invite/<str:orgcode>'
  },
  orgAdminStudyEndpointsLegacy: {
    path: '/admin',
    djangoRouteName: 'org-admin-study-endpoints-legacy',
    djangoPath: '/admin/<admin_code>/study/<study_id>/'
  },
  orgMemberActivityEndpoint: {
    path: '/organization',
    djangoRouteName: 'org-member-activity-endpoint',
    djangoPath: '/organization/<admin_code>/activity/'
  },
  spadminOrgActivityEndpoint: {
    path: '/organization',
    djangoRouteName: 'spadmin-org-activity-endpoint',
    djangoPath: '/organization/<admin_code>/admin_activity/'
  },
  spadminOrgExportCsv: {
    path: '/organization',
    djangoRouteName: 'spadmin-org-export-csv',
    djangoPath: '/organization/<admin_code>/exports/csv/'
  },
  pubOaicUnsubscribePage: {
    path: '/unsubscribe/oaic',
    djangoRouteName: 'pub-oaic-unsubscribe-page',
    djangoPath: '/unsubscribe/oaic/<lead_token>/'
  },
  apiOaic: {
    path: '/api_oaic/v0.1',
    djangoRouteName: 'api-oaic',
    djangoPath: '/api_oaic/v0.1/<api_resource_name>/'
  },
  siteViewPmg: {
    path: '/demo/site/pmg/winston/',
    djangoRouteName: 'site-view-pmg',
    djangoPath: '/demo/site/pmg/winston/'
  },
  siteViewRcr: {
    path: '/demo/site/rochesterclinicalresearch/',
    djangoRouteName: 'site-view-rcr',
    djangoPath: '/demo/site/rochesterclinicalresearch/'
  },
  siteViewC4D: {
    path: '/demo/site/centerfordermatology/',
    djangoRouteName: 'site-view-c4d',
    djangoPath: '/demo/site/centerfordermatology/'
  },
  liverspaceMainPage: {
    path: '/liverspace/',
    djangoRouteName: 'liverspace-main-page',
    djangoPath: '/liverspace/'
  },
  liverspaceInfoScg: {
    path: '/liverspace/info/scg/',
    djangoRouteName: 'liverspace-info-scg',
    djangoPath: '/liverspace/info/scg/'
  },
  appLiverspacePage: {
    path: '/app/liverspace/',
    djangoRouteName: 'app-liverspace-page',
    djangoPath: '/app/liverspace/'
  },
  appPoopmdPage: {
    path: '/app/poopmd/',
    djangoRouteName: 'app-poopmd-page',
    djangoPath: '/app/poopmd/'
  },
  appEncephalappPage: {
    path: '/app/encephalapp/',
    djangoRouteName: 'app-encephalapp-page',
    djangoPath: '/app/encephalapp/'
  },
  siteDashboardPage: {
    path: '/demo/site/dashboard/',
    djangoRouteName: 'site-dashboard-page',
    djangoPath: '/demo/site/dashboard/'
  },
  siteCreatePage: {
    path: '/demo/site/create/',
    djangoRouteName: 'site-create-page',
    djangoPath: '/demo/site/create/'
  },
  siteManagePage: {
    path: '/demo/site/manage',
    djangoRouteName: 'site-manage-page',
    djangoPath: '/demo/site/manage/<site_id>/'
  },
  siteMembersPage: {
    path: '/demo/site/members',
    djangoRouteName: 'site-members-page',
    djangoPath: '/demo/site/members/<site_id>/'
  },
  siteViewPage: {
    path: '/demo/site/view',
    djangoRouteName: 'site-view-page',
    djangoPath: '/demo/site/view/<site_id>/'
  },
  forwardCall: {
    path: '/call_tracking/forward_call/',
    djangoRouteName: 'forward-call',
    djangoPath: '/call_tracking/forward_call/'
  },
  callRecording: {
    path: '/call_tracking/call_recording/',
    djangoRouteName: 'call-recording',
    djangoPath: '/call_tracking/call_recording/'
  },
  callRecordingCallback: {
    path: '/call_tracking/call_recording_callback/',
    djangoRouteName: 'call-recording-callback',
    djangoPath: '/call_tracking/call_recording_callback/'
  },
  statusCallbackInbound: {
    path: '/call_tracking/status_callback_inbound/',
    djangoRouteName: 'status-callback-inbound',
    djangoPath: '/call_tracking/status_callback_inbound/'
  },
  statusCallbackOutbound: {
    path: '/call_tracking/status_callback_outbound/',
    djangoRouteName: 'status-callback-outbound',
    djangoPath: '/call_tracking/status_callback_outbound/'
  },
  voicemailCapture: {
    path: '/call_tracking/voicemail/',
    djangoRouteName: 'voicemail-capture',
    djangoPath: '/call_tracking/voicemail/'
  },
  voicemailTranscribe: {
    path: '/call_tracking/voicemail_trans/',
    djangoRouteName: 'voicemail-transcribe',
    djangoPath: '/call_tracking/voicemail_trans/'
  },
  vmActivityGalleryEndpoint: {
    path: '/call_tracking/vm_activity_org',
    djangoRouteName: 'vm-activity-gallery-endpoint',
    djangoPath: '/call_tracking/vm_activity_org/<gallery_id>/'
  },
  vmActivityStudyEndpoint: {
    path: '/call_tracking/vm_activity_study',
    djangoRouteName: 'vm-activity-study-endpoint',
    djangoPath: '/call_tracking/vm_activity_study/<study_id>/'
  },
  faxActivityStudyEndpoint: {
    path: '/call_tracking/fax_activity_study',
    djangoRouteName: 'fax-activity-study-endpoint',
    djangoPath: '/call_tracking/fax_activity_study/<study_id>/'
  },
  capabilityTokenEndpoint: {
    path: '/call_tracking/get_token/',
    djangoRouteName: 'capability-token-endpoint',
    djangoPath: '/call_tracking/get_token/'
  },
  ttsTestPage: {
    path: '/call_tracking/tts_test/',
    djangoRouteName: 'tts-test-page',
    djangoPath: '/call_tracking/tts_test/'
  },
  callOutboundPage: {
    path: '/call_tracking/call_outbound/',
    djangoRouteName: 'call-outbound-page',
    djangoPath: '/call_tracking/call_outbound/'
  },
  inboundCallerId: {
    path: '/call_tracking/inbound_caller_id/',
    djangoRouteName: 'inbound-caller-id',
    djangoPath: '/call_tracking/inbound_caller_id/'
  },
  spGoCallForward: {
    path: '/call_tracking/sp_go_call_forward/',
    djangoRouteName: 'sp_go_call_forward',
    djangoPath: '/call_tracking/sp_go_call_forward/'
  },
  processStudyFax: {
    path: '/fax_tracking/process_study_fax/',
    djangoRouteName: 'process-study-fax',
    djangoPath: '/fax_tracking/process_study_fax/'
  },
  receiveStudyFaxAction: {
    path: '/fax_tracking/receive_study_fax/',
    djangoRouteName: 'receive-study-fax-action',
    djangoPath: '/fax_tracking/receive_study_fax/'
  },
  faxStatusCallbackInbound: {
    path: '/fax_tracking/status_callback_inbound/',
    djangoRouteName: 'fax-status-callback-inbound',
    djangoPath: '/fax_tracking/status_callback_inbound/'
  },
  ghAvailabilityEmailTest: {
    path: '/gh_availability_email/',
    djangoRouteName: 'gh-availability-email-test',
    djangoPath: '/gh_availability_email/'
  },
  ghAvailability: {
    path: '/gh_availability',
    djangoRouteName: 'gh-availability',
    djangoPath: '/gh_availability/<availability_id>/'
  },
  ghAvailabilityConfEmailTest: {
    path: '/gh_availability_conf_email/',
    djangoRouteName: 'gh-availability-conf-email-test',
    djangoPath: '/gh_availability_conf_email/'
  },
  ghSchedulingEmailTest: {
    path: '/gh_scheduling_email/',
    djangoRouteName: 'gh-scheduling-email-test',
    djangoPath: '/gh_scheduling_email/'
  },
  ghScheduling: {
    path: '/gh_scheduling',
    djangoRouteName: 'gh-scheduling',
    djangoPath: '/gh_scheduling/<scheduling_id>/'
  },
  ghPiReminderEmailTest: {
    path: '/gh_reminder_email',
    djangoRouteName: 'gh-pi-reminder-email-test',
    djangoPath: '/gh_reminder_email/<type>/'
  },
  ghConferenceCallTest: {
    path: '/gh_conference_call_test',
    djangoRouteName: 'gh-conference-call-test',
    djangoPath: '/gh_conference_call_test/<ghcallrequest_id>/'
  },
  ghConference: {
    path: '/gh_conference/',
    djangoRouteName: 'gh-conference',
    djangoPath: '/gh_conference/'
  },
  ghConferenceWait: {
    path: '/gh_conference_wait/',
    djangoRouteName: 'gh-conference-wait',
    djangoPath: '/gh_conference_wait/'
  },
  ghConferenceCallback: {
    path: '/gh_conference_callback/',
    djangoRouteName: 'gh-conference-callback',
    djangoPath: '/gh_conference_callback/'
  },
  ghConferenceAnnounce: {
    path: '/gh_conference_announce/',
    djangoRouteName: 'gh-conference-announce',
    djangoPath: '/gh_conference_announce/'
  },
  ghAdminChecker: {
    path: '/gh_admin_checker/',
    djangoRouteName: 'gh-admin-checker',
    djangoPath: '/gh_admin_checker/'
  },
  ghAdminDetails: {
    path: '/gh_admin_details',
    djangoRouteName: 'gh-admin-details',
    djangoPath: '/gh_admin_details/<ghcallrequest_id>/'
  },
  ghConnectPubStudyMatchInfo: {
    path: '/guardant/trials',
    djangoRouteName: 'gh-connect-pub-study-match-info',
    djangoPath: '/guardant/trials/<match_key>/'
  },
  ghConnectPubStudyMatchPagenum: {
    path: '/guardant/trials',
    djangoRouteName: 'gh-connect-pub-study-match-pagenum',
    djangoPath: '/guardant/trials/<match_key>/<page_num>/'
  },
  ghConnectPubStudyInfo: {
    path: '/gh_study_info',
    djangoRouteName: 'gh-connect-pub-study-info',
    djangoPath: '/gh_study_info/<connect_pub_id>/'
  },
  ghConnectPubStudyPagenum: {
    path: '/gh_study_info',
    djangoRouteName: 'gh-connect-pub-study-pagenum',
    djangoPath: '/gh_study_info/<connect_pub_id>/<page_num>/'
  },
  ghConnectPubStudyPrint: {
    path: '/gh_study_info/print',
    djangoRouteName: 'gh-connect-pub-study-print',
    djangoPath: '/gh_study_info/print/<connect_pub_id>/'
  },
  ghAdminConnectCreate: {
    path: '/gh_admin_connect_create/',
    djangoRouteName: 'gh-admin-connect-create',
    djangoPath: '/gh_admin_connect_create/'
  },
  ghAdminConnectChecker: {
    path: '/gh_admin_connect_checker/',
    djangoRouteName: 'gh-admin-connect-checker',
    djangoPath: '/gh_admin_connect_checker/'
  },
  ghAdminConnectDetails: {
    path: '/gh_admin_connect_details',
    djangoRouteName: 'gh-admin-connect-details',
    djangoPath: '/gh_admin_connect_details/<ghconnectrequest_id>/'
  },
  phrpCoursePublicHomePage: {
    path: '/phrp/',
    djangoRouteName: 'phrp-course-public-home-page',
    djangoPath: '/phrp/'
  },
  courseSummaryPage: {
    path: '/phrp/summary/',
    djangoRouteName: 'course-summary-page',
    djangoPath: '/phrp/summary/'
  },
  courseOverviewPage: {
    path: '/phrp/overview/',
    djangoRouteName: 'course-overview-page',
    djangoPath: '/phrp/overview/'
  },
  courseGlossaryPage: {
    path: '/phrp/glossary/',
    djangoRouteName: 'course-glossary-page',
    djangoPath: '/phrp/glossary/'
  },
  courseCitationsPage: {
    path: '/phrp/citations/',
    djangoRouteName: 'course-citations-page',
    djangoPath: '/phrp/citations/'
  },
  courseFaqPage: {
    path: '/phrp/faq/',
    djangoRouteName: 'course-faq-page',
    djangoPath: '/phrp/faq/'
  },
  courseHelpPage: {
    path: '/phrp/help/',
    djangoRouteName: 'course-help-page',
    djangoPath: '/phrp/help/'
  },
  courseQuizPage: {
    path: '/phrp/quiz',
    djangoRouteName: 'course-quiz-page',
    djangoPath: '/phrp/quiz/<quiz_id>/'
  },
  courseSectionPage: {
    path: '/phrp/course',
    djangoRouteName: 'course-section-page',
    djangoPath: '/phrp/course/<section_name>/'
  },
  courseUserProfilePage: {
    path: '/phrp/profile/',
    djangoRouteName: 'course-user-profile-page',
    djangoPath: '/phrp/profile/'
  },
  changeLanguageEndpoint: {
    path: '/phrp/change_language/',
    djangoRouteName: 'change-language-endpoint',
    djangoPath: '/phrp/change_language/'
  },
  videoVisitRoomPage: {
    path: '/video_visit/room',
    djangoRouteName: 'video-visit-room-page',
    djangoPath: '/video_visit/room/<room_id>/'
  },
  videoVisitRoomStatePage: {
    path: '/video_visit/room',
    djangoRouteName: 'video-visit-room-state-page',
    djangoPath: '/video_visit/room/<room_id>/<user_state>/'
  },
  videoVisitSettingsPage: {
    path: '/video_visit/settings/',
    djangoRouteName: 'video-visit-settings-page',
    djangoPath: '/video_visit/settings/'
  },
  videoVisitStatusCallbackPage: {
    path: '/video_visit/status_callback/',
    djangoRouteName: 'video-visit-status-callback-page',
    djangoPath: '/video_visit/status_callback/'
  },
  surveyActivityEndpoint: {
    path: '/survey_activity',
    djangoRouteName: 'survey-activity-endpoint',
    djangoPath: '/survey_activity/<pub_study_id>/<is_readonly>/'
  },
  surveyResultDetail: {
    path: '/study',
    djangoRouteName: 'survey-result-detail',
    djangoPath: '/study/<study_id>/study_survey_result/'
  },
  surveyExternalResultDetail: {
    path: '/study',
    djangoRouteName: 'survey-external-result-detail',
    djangoPath: '/study/<study_id>/<participant_pub_uuid>/ext_survey_result/<external_result_id>/'
  },
  workspaceFormsMgmtEndpoint: {
    path: '/study',
    djangoRouteName: 'workspace-forms-mgmt-endpoint',
    djangoPath: '/study/<study_id>/sp_survey_mgmt/'
  },
  formInviteResponsePage: {
    path: '/form_response',
    djangoRouteName: 'form-invite-response-page',
    djangoPath: '/form_response/<invite_key>/'
  },
  setLanguage: {
    path: '/i18n/setlang/',
    djangoRouteName: 'set_language',
    djangoPath: '/i18n/setlang/'
  },
  SpadminOgAdminAdminTagT1DTrials: {
    path: '/spadmin/og_admin/admin_tag_t1d_trials',
    djangoRouteName: '',
    djangoPath: '/spadmin/og_admin/admin_tag_t1d_trials'
  },
  SpadminAdminTagT1DTrials: {
    path: '/spadmin/admin_tag_t1d_trials',
    djangoRouteName: '',
    djangoPath: '/spadmin/admin_tag_t1d_trials'
  },
  adminPortalRequestAdminEndpoint: {
    path: '/spadmin/portal_request_admin_endpoint/',
    djangoRouteName: 'admin:portal-request-admin-endpoint',
    djangoPath: '/spadmin/portal_request_admin_endpoint/'
  },
  SpadminOgAdminPortalRequestAdmin: {
    path: '/spadmin/og_admin/portal_request_admin',
    djangoRouteName: '',
    djangoPath: '/spadmin/og_admin/portal_request_admin'
  },
  SpadminPortalRequestAdmin: {
    path: '/spadmin/portal_request_admin',
    djangoRouteName: '',
    djangoPath: '/spadmin/portal_request_admin'
  },
  SpadminOgAdminStatsChecker: {
    path: '/spadmin/og_admin/stats_checker',
    djangoRouteName: '',
    djangoPath: '/spadmin/og_admin/stats_checker'
  },
  SpadminStatsChecker: {
    path: '/spadmin/stats_checker',
    djangoRouteName: '',
    djangoPath: '/spadmin/stats_checker'
  },
  SpadminOgAdminThriveChecker: {
    path: '/spadmin/og_admin/thrive_checker',
    djangoRouteName: '',
    djangoPath: '/spadmin/og_admin/thrive_checker'
  },
  SpadminThriveChecker: {
    path: '/spadmin/thrive_checker',
    djangoRouteName: '',
    djangoPath: '/spadmin/thrive_checker'
  },
  SpadminOgAdminMigrationCheckerReport: {
    path: '/spadmin/og_admin/migration_checker_report',
    djangoRouteName: '',
    djangoPath: '/spadmin/og_admin/migration_checker_report'
  },
  SpadminMigrationCheckerReport: {
    path: '/spadmin/migration_checker_report',
    djangoRouteName: '',
    djangoPath: '/spadmin/migration_checker_report'
  },
  SpadminOgAdminSecureMessagesReport: {
    path: '/spadmin/og_admin/secure_messages_report',
    djangoRouteName: '',
    djangoPath: '/spadmin/og_admin/secure_messages_report'
  },
  SpadminSecureMessagesReport: {
    path: '/spadmin/secure_messages_report',
    djangoRouteName: '',
    djangoPath: '/spadmin/secure_messages_report'
  },
  adminOneTimeToken: {
    path: '/spadmin/one-time-token/',
    djangoRouteName: 'admin:one-time-token',
    djangoPath: '/spadmin/one-time-token/'
  },
  adminViewsUsage: {
    path: '/spadmin/views-usage/',
    djangoRouteName: 'admin:views-usage',
    djangoPath: '/spadmin/views-usage/'
  },
  adminLinksShortener: {
    path: '/spadmin/links_shortener/',
    djangoRouteName: 'admin:links_shortener',
    djangoPath: '/spadmin/links_shortener/'
  },
  adminNewSpadminVueDashboard: {
    path: '/spadmin/new_spadmin/.',
    djangoRouteName: 'admin:new_spadmin_vue_dashboard',
    djangoPath: '/spadmin/new_spadmin/.'
  },
  adminWebsockets: {
    path: '/spadmin/websockets/',
    djangoRouteName: 'admin:websockets',
    djangoPath: '/spadmin/websockets/'
  },
  adminSendEmail: {
    path: '/spadmin/send_email/',
    djangoRouteName: 'admin:send_email',
    djangoPath: '/spadmin/send_email/'
  },
  adminGalleryGallerySearchCategories: {
    path: '/spadmin/gsc/gallery',
    djangoRouteName: 'admin:gallery_gallery_search_categories',
    djangoPath: '/spadmin/gsc/gallery/<gallery_id>/'
  },
  adminGallerySearchCategoriesStudies: {
    path: '/spadmin/gsc/studies/',
    djangoRouteName: 'admin:gallery_search_categories_studies',
    djangoPath: '/spadmin/gsc/studies/'
  },
  adminGallerySearchCategoriesStudiesGet: {
    path: '/spadmin/gsc/studies',
    djangoRouteName: 'admin:gallery_search_categories_studies_get',
    djangoPath: '/spadmin/gsc/studies/<gallerystudy_id>/'
  },
  adminGallerySearchCategory: {
    path: '/spadmin/gsc/',
    djangoRouteName: 'admin:gallery_search_category',
    djangoPath: '/spadmin/gsc/'
  },
  adminSearchCategories: {
    path: '/spadmin/sc/',
    djangoRouteName: 'admin:search_categories',
    djangoPath: '/spadmin/sc/'
  },
  adminSearchCategoriesUpdate: {
    path: '/spadmin/sc',
    djangoRouteName: 'admin:search_categories_update',
    djangoPath: '/spadmin/sc/<searchcategory_id>/'
  },
  adminManageScMenu: {
    path: '/spadmin/sc_manage/',
    djangoRouteName: 'admin:manage_sc_menu',
    djangoPath: '/spadmin/sc_manage/'
  },
  adminPushNotification: {
    path: '/spadmin/push_notification/',
    djangoRouteName: 'admin:push_notification',
    djangoPath: '/spadmin/push_notification/'
  },
  adminAdminOrgStudyImport: {
    path: '/spadmin/org_study_import/',
    djangoRouteName: 'admin:admin-org-study-import',
    djangoPath: '/spadmin/org_study_import/'
  },
  adminSystemInfoDebug: {
    path: '/spadmin/system_info_debug/',
    djangoRouteName: 'admin:system_info_debug',
    djangoPath: '/spadmin/system_info_debug/'
  },
  adminElasticAdmin: {
    path: '/spadmin/elastic_admin/',
    djangoRouteName: 'admin:elastic_admin',
    djangoPath: '/spadmin/elastic_admin/'
  },
  adminSpadminGhcrDetailPage: {
    path: '/spadmin/ghcr_checker',
    djangoRouteName: 'admin:spadmin-ghcr-detail-page',
    djangoPath: '/spadmin/ghcr_checker/<ghcallrequest_id>/'
  },
  adminGhcrChecker: {
    path: '/spadmin/ghcr_checker/',
    djangoRouteName: 'admin:ghcr-checker',
    djangoPath: '/spadmin/ghcr_checker/'
  },
  adminCampaignChecker: {
    path: '/spadmin/campaign_checker/',
    djangoRouteName: 'admin:campaign-checker',
    djangoPath: '/spadmin/campaign_checker/'
  },
  adminActivityChecker: {
    path: '/spadmin/activity_checker/',
    djangoRouteName: 'admin:activity-checker',
    djangoPath: '/spadmin/activity_checker/'
  },
  adminSpadminStudyThumbsDebug: {
    path: '/spadmin/org_admin',
    djangoRouteName: 'admin:spadmin-study-thumbs-debug',
    djangoPath: '/spadmin/org_admin/<org_id>/thumbs/'
  },
  adminSignupChecker: {
    path: '/spadmin/signup_checker/',
    djangoRouteName: 'admin:signup_checker',
    djangoPath: '/spadmin/signup_checker/'
  },
  adminManageUnmatchedIncomingSms: {
    path: '/spadmin/manage_unmatched_incoming_sms/',
    djangoRouteName: 'admin:manage_unmatched_incoming_sms',
    djangoPath: '/spadmin/manage_unmatched_incoming_sms/'
  },
  adminManageUnmatchedIncomingSmsNew: {
    path: '/spadmin/manage_unmatched_incoming_sms/',
    djangoRouteName: 'admin:manage_unmatched_incoming_sms-new',
    djangoPath: '/spadmin/manage_unmatched_incoming_sms/'
  },
  adminStudyFinder: {
    path: '/spadmin/study_finder/',
    djangoRouteName: 'admin:study_finder',
    djangoPath: '/spadmin/study_finder/'
  },
  adminStudyFinderNew: {
    path: '/spadmin/study_finder/',
    djangoRouteName: 'admin:study_finder-new',
    djangoPath: '/spadmin/study_finder/'
  },
  adminHelperLocationData: {
    path: '/spadmin/helper_location_data/',
    djangoRouteName: 'admin:helper_location_data',
    djangoPath: '/spadmin/helper_location_data/'
  },
  adminStudypagesReferralStats: {
    path: '/spadmin/studypages_referral_stats/',
    djangoRouteName: 'admin:studypages_referral_stats',
    djangoPath: '/spadmin/studypages_referral_stats/'
  },
  adminStudypagesRealtime: {
    path: '/spadmin/studypages_realtime/',
    djangoRouteName: 'admin:studypages_realtime',
    djangoPath: '/spadmin/studypages_realtime/'
  },
  adminStudypagesAnalytics: {
    path: '/spadmin/studypages_analytics/',
    djangoRouteName: 'admin:studypages_analytics',
    djangoPath: '/spadmin/studypages_analytics/'
  },
  adminStudypagesAnalyticsEmbedded: {
    path: '/spadmin/studypages_analytics_embedded/',
    djangoRouteName: 'admin:studypages_analytics_embedded',
    djangoPath: '/spadmin/studypages_analytics_embedded/'
  },
  adminOrgViewAdminPage: {
    path: '/spadmin/org_admin',
    djangoRouteName: 'admin:org-view-admin-page',
    djangoPath: '/spadmin/org_admin/<org_id>/'
  },
  adminUserAdminViewPage: {
    path: '/spadmin/user_admin',
    djangoRouteName: 'admin:user-admin-view-page',
    djangoPath: '/spadmin/user_admin/<user_id>/'
  },
  adminStudyAdminViewPage: {
    path: '/spadmin/study_admin',
    djangoRouteName: 'admin:study-admin-view-page',
    djangoPath: '/spadmin/study_admin/<study_id>/'
  },
  adminStudyAdminMigrateContent: {
    path: '/spadmin/study_admin/migrate-content',
    djangoRouteName: 'admin:study-admin-migrate-content',
    djangoPath: '/spadmin/study_admin/migrate-content/<study_id>/'
  },
  adminStudyAdminFormsMgmtEndpoint: {
    path: '/spadmin/study_admin/forms_mgmt',
    djangoRouteName: 'admin:study-admin-forms-mgmt-endpoint',
    djangoPath: '/spadmin/study_admin/forms_mgmt/<study_id>/'
  },
  adminStudyActivityPage: {
    path: '/spadmin/study_activity/',
    djangoRouteName: 'admin:study-activity-page',
    djangoPath: '/spadmin/study_activity/'
  },
  adminStudyAdminSessionBrowserView: {
    path: '/spadmin/study_admin_session_browser',
    djangoRouteName: 'admin:study-admin-session-browser-view',
    djangoPath: '/spadmin/study_admin_session_browser/<mstudy_id>/<campaign_id>/'
  },
  adminStudyAdminSessionBrowserPage: {
    path: '/spadmin/study_admin_session_browser/',
    djangoRouteName: 'admin:study-admin-session-browser-page',
    djangoPath: '/spadmin/study_admin_session_browser/'
  },
  adminMegastudyAdminStudyCreate: {
    path: '/spadmin/study_admin/megastudy-create',
    djangoRouteName: 'admin:megastudy-admin-study-create',
    djangoPath: '/spadmin/study_admin/megastudy-create'
  },
  adminStudyAdminStudyCreate: {
    path: '/spadmin/study_admin/create_study_workspace/',
    djangoRouteName: 'admin:study-admin-study-create',
    djangoPath: '/spadmin/study_admin/create_study_workspace/'
  },
  adminSpadminOrgReports: {
    path: '/spadmin/org/reports/',
    djangoRouteName: 'admin:spadmin-org-reports',
    djangoPath: '/spadmin/org/reports/'
  },
  adminSpadminOrgChecker: {
    path: '/spadmin/org_checker/',
    djangoRouteName: 'admin:spadmin-org-checker',
    djangoPath: '/spadmin/org_checker/'
  },
  adminSpadminCacheChecker: {
    path: '/spadmin/cache_checker/',
    djangoRouteName: 'admin:spadmin-cache-checker',
    djangoPath: '/spadmin/cache_checker/'
  },
  adminSpadminVideoRoomManage: {
    path: '/spadmin/video_room_manage/',
    djangoRouteName: 'admin:spadmin-video-room-manage',
    djangoPath: '/spadmin/video_room_manage/'
  },
  adminAdminVideoRoomDetails: {
    path: '/spadmin/video_room_details/',
    djangoRouteName: 'admin:admin-video-room-details',
    djangoPath: '/spadmin/video_room_details/'
  },
  adminSpadminVideoRoomChecker: {
    path: '/spadmin/video_room_checker/',
    djangoRouteName: 'admin:spadmin-video-room-checker',
    djangoPath: '/spadmin/video_room_checker/'
  },
  adminSpadminVisitChecker: {
    path: '/spadmin/visit_checker/',
    djangoRouteName: 'admin:spadmin-visit-checker',
    djangoPath: '/spadmin/visit_checker/'
  },
  adminSpadminVisitCheckerLegacy: {
    path: '/spadmin/visit_checker_legacy/',
    djangoRouteName: 'admin:spadmin-visit-checker-legacy',
    djangoPath: '/spadmin/visit_checker_legacy/'
  },
  adminSpadminInviteGenerator: {
    path: '/spadmin/invite_generator/',
    djangoRouteName: 'admin:spadmin-invite-generator',
    djangoPath: '/spadmin/invite_generator/'
  },
  adminInviteChecker: {
    path: '/spadmin/invite_checker/',
    djangoRouteName: 'admin:invite_checker',
    djangoPath: '/spadmin/invite_checker/'
  },
  adminSpadminTeamRequestChecker: {
    path: '/spadmin/team_request_checker/',
    djangoRouteName: 'admin:spadmin-team-request-checker',
    djangoPath: '/spadmin/team_request_checker/'
  },
  adminSpadminIntegrationChecker: {
    path: '/spadmin/integration_checker/',
    djangoRouteName: 'admin:spadmin-integration-checker',
    djangoPath: '/spadmin/integration_checker/'
  },
  adminAdminUserChecker: {
    path: '/spadmin/user_checker/',
    djangoRouteName: 'admin:admin-user-checker',
    djangoPath: '/spadmin/user_checker/'
  },
  adminAdminRegistryChecker: {
    path: '/spadmin/registry_checker/',
    djangoRouteName: 'admin:admin-registry-checker',
    djangoPath: '/spadmin/registry_checker/'
  },
  adminAdminStudyChecker: {
    path: '/spadmin/study_checker/',
    djangoRouteName: 'admin:admin-study-checker',
    djangoPath: '/spadmin/study_checker/'
  },
  adminAdminStudyAnalytics: {
    path: '/spadmin/study_analytics/',
    djangoRouteName: 'admin:admin-study-analytics',
    djangoPath: '/spadmin/study_analytics/'
  },
  adminCtgovStudyAdmin: {
    path: '/spadmin/ctgov_study_admin/',
    djangoRouteName: 'admin:ctgov_study_admin',
    djangoPath: '/spadmin/ctgov_study_admin/'
  },
  adminAdminOriginalDashboard: {
    path: '/spadmin/og_admin/',
    djangoRouteName: 'admin:admin-original-dashboard',
    djangoPath: '/spadmin/og_admin/'
  },
  adminSearch: {
    path: '/spadmin/search/',
    djangoRouteName: 'admin:search',
    djangoPath: '/spadmin/search/'
  },
  adminToggleSidebar: {
    path: '/spadmin/toggle-sidebar/',
    djangoRouteName: 'admin:toggle_sidebar',
    djangoPath: '/spadmin/toggle-sidebar/'
  },
  adminIndex: {
    path: '/spadmin/',
    djangoRouteName: 'admin:index',
    djangoPath: '/spadmin/'
  },
  adminLogin: {
    path: '/spadmin/login/',
    djangoRouteName: 'admin:login',
    djangoPath: '/spadmin/login/'
  },
  adminLogout: {
    path: '/spadmin/logout/',
    djangoRouteName: 'admin:logout',
    djangoPath: '/spadmin/logout/'
  },
  adminPasswordChange: {
    path: '/spadmin/password_change/',
    djangoRouteName: 'admin:password_change',
    djangoPath: '/spadmin/password_change/'
  },
  adminPasswordChangeDone: {
    path: '/spadmin/password_change/done/',
    djangoRouteName: 'admin:password_change_done',
    djangoPath: '/spadmin/password_change/done/'
  },
  adminAutocomplete: {
    path: '/spadmin/autocomplete/',
    djangoRouteName: 'admin:autocomplete',
    djangoPath: '/spadmin/autocomplete/'
  },
  adminJsi18N: {
    path: '/spadmin/jsi18n/',
    djangoRouteName: 'admin:jsi18n',
    djangoPath: '/spadmin/jsi18n/'
  },
  adminViewOnSite: {
    path: '/spadmin/r',
    djangoRouteName: 'admin:view_on_site',
    djangoPath: '/spadmin/r/<int:content_type_id>/<path:object_id>/'
  },
  adminStudyAppParticipantChangelist: {
    path: '/spadmin/study_app/participant/',
    djangoRouteName: 'admin:study_app_participant_changelist',
    djangoPath: '/spadmin/study_app/participant/'
  },
  adminStudyAppParticipantAdd: {
    path: '/spadmin/study_app/participant/add/',
    djangoRouteName: 'admin:study_app_participant_add',
    djangoPath: '/spadmin/study_app/participant/add/'
  },
  adminStudyAppParticipantHistory: {
    path: '/spadmin/study_app/participant',
    djangoRouteName: 'admin:study_app_participant_history',
    djangoPath: '/spadmin/study_app/participant/<path:object_id>/history/'
  },
  adminStudyAppParticipantDelete: {
    path: '/spadmin/study_app/participant',
    djangoRouteName: 'admin:study_app_participant_delete',
    djangoPath: '/spadmin/study_app/participant/<path:object_id>/delete/'
  },
  adminStudyAppParticipantChange: {
    path: '/spadmin/study_app/participant',
    djangoRouteName: 'admin:study_app_participant_change',
    djangoPath: '/spadmin/study_app/participant/<path:object_id>/change/'
  },
  SpadminStudyAppParticipantPathObjectId: {
    path: '/spadmin/study_app/participant',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/participant/<path:object_id>/'
  },
  adminStudyAppParticipantconsentChangelist: {
    path: '/spadmin/study_app/participantconsent/',
    djangoRouteName: 'admin:study_app_participantconsent_changelist',
    djangoPath: '/spadmin/study_app/participantconsent/'
  },
  adminStudyAppParticipantconsentAdd: {
    path: '/spadmin/study_app/participantconsent/add/',
    djangoRouteName: 'admin:study_app_participantconsent_add',
    djangoPath: '/spadmin/study_app/participantconsent/add/'
  },
  adminStudyAppParticipantconsentHistory: {
    path: '/spadmin/study_app/participantconsent',
    djangoRouteName: 'admin:study_app_participantconsent_history',
    djangoPath: '/spadmin/study_app/participantconsent/<path:object_id>/history/'
  },
  adminStudyAppParticipantconsentDelete: {
    path: '/spadmin/study_app/participantconsent',
    djangoRouteName: 'admin:study_app_participantconsent_delete',
    djangoPath: '/spadmin/study_app/participantconsent/<path:object_id>/delete/'
  },
  adminStudyAppParticipantconsentChange: {
    path: '/spadmin/study_app/participantconsent',
    djangoRouteName: 'admin:study_app_participantconsent_change',
    djangoPath: '/spadmin/study_app/participantconsent/<path:object_id>/change/'
  },
  SpadminStudyAppParticipantconsentPathObjectId: {
    path: '/spadmin/study_app/participantconsent',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/participantconsent/<path:object_id>/'
  },
  adminStudyAppActivityrecordChangelist: {
    path: '/spadmin/study_app/activityrecord/',
    djangoRouteName: 'admin:study_app_activityrecord_changelist',
    djangoPath: '/spadmin/study_app/activityrecord/'
  },
  adminStudyAppActivityrecordAdd: {
    path: '/spadmin/study_app/activityrecord/add/',
    djangoRouteName: 'admin:study_app_activityrecord_add',
    djangoPath: '/spadmin/study_app/activityrecord/add/'
  },
  adminStudyAppActivityrecordHistory: {
    path: '/spadmin/study_app/activityrecord',
    djangoRouteName: 'admin:study_app_activityrecord_history',
    djangoPath: '/spadmin/study_app/activityrecord/<path:object_id>/history/'
  },
  adminStudyAppActivityrecordDelete: {
    path: '/spadmin/study_app/activityrecord',
    djangoRouteName: 'admin:study_app_activityrecord_delete',
    djangoPath: '/spadmin/study_app/activityrecord/<path:object_id>/delete/'
  },
  adminStudyAppActivityrecordChange: {
    path: '/spadmin/study_app/activityrecord',
    djangoRouteName: 'admin:study_app_activityrecord_change',
    djangoPath: '/spadmin/study_app/activityrecord/<path:object_id>/change/'
  },
  SpadminStudyAppActivityrecordPathObjectId: {
    path: '/spadmin/study_app/activityrecord',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/activityrecord/<path:object_id>/'
  },
  adminStudyAppUserChangelist: {
    path: '/spadmin/study_app/user/',
    djangoRouteName: 'admin:study_app_user_changelist',
    djangoPath: '/spadmin/study_app/user/'
  },
  adminStudyAppUserAdd: {
    path: '/spadmin/study_app/user/add/',
    djangoRouteName: 'admin:study_app_user_add',
    djangoPath: '/spadmin/study_app/user/add/'
  },
  adminStudyAppUserHistory: {
    path: '/spadmin/study_app/user',
    djangoRouteName: 'admin:study_app_user_history',
    djangoPath: '/spadmin/study_app/user/<path:object_id>/history/'
  },
  adminStudyAppUserDelete: {
    path: '/spadmin/study_app/user',
    djangoRouteName: 'admin:study_app_user_delete',
    djangoPath: '/spadmin/study_app/user/<path:object_id>/delete/'
  },
  adminStudyAppUserChange: {
    path: '/spadmin/study_app/user',
    djangoRouteName: 'admin:study_app_user_change',
    djangoPath: '/spadmin/study_app/user/<path:object_id>/change/'
  },
  SpadminStudyAppUserPathObjectId: {
    path: '/spadmin/study_app/user',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/user/<path:object_id>/'
  },
  adminStudyAppInvestigatorChangelist: {
    path: '/spadmin/study_app/investigator/',
    djangoRouteName: 'admin:study_app_investigator_changelist',
    djangoPath: '/spadmin/study_app/investigator/'
  },
  adminStudyAppInvestigatorAdd: {
    path: '/spadmin/study_app/investigator/add/',
    djangoRouteName: 'admin:study_app_investigator_add',
    djangoPath: '/spadmin/study_app/investigator/add/'
  },
  adminStudyAppInvestigatorHistory: {
    path: '/spadmin/study_app/investigator',
    djangoRouteName: 'admin:study_app_investigator_history',
    djangoPath: '/spadmin/study_app/investigator/<path:object_id>/history/'
  },
  adminStudyAppInvestigatorDelete: {
    path: '/spadmin/study_app/investigator',
    djangoRouteName: 'admin:study_app_investigator_delete',
    djangoPath: '/spadmin/study_app/investigator/<path:object_id>/delete/'
  },
  adminStudyAppInvestigatorChange: {
    path: '/spadmin/study_app/investigator',
    djangoRouteName: 'admin:study_app_investigator_change',
    djangoPath: '/spadmin/study_app/investigator/<path:object_id>/change/'
  },
  SpadminStudyAppInvestigatorPathObjectId: {
    path: '/spadmin/study_app/investigator',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/investigator/<path:object_id>/'
  },
  adminStudyAppOrganizationChangelist: {
    path: '/spadmin/study_app/organization/',
    djangoRouteName: 'admin:study_app_organization_changelist',
    djangoPath: '/spadmin/study_app/organization/'
  },
  adminStudyAppOrganizationAdd: {
    path: '/spadmin/study_app/organization/add/',
    djangoRouteName: 'admin:study_app_organization_add',
    djangoPath: '/spadmin/study_app/organization/add/'
  },
  adminStudyAppOrganizationHistory: {
    path: '/spadmin/study_app/organization',
    djangoRouteName: 'admin:study_app_organization_history',
    djangoPath: '/spadmin/study_app/organization/<path:object_id>/history/'
  },
  adminStudyAppOrganizationDelete: {
    path: '/spadmin/study_app/organization',
    djangoRouteName: 'admin:study_app_organization_delete',
    djangoPath: '/spadmin/study_app/organization/<path:object_id>/delete/'
  },
  adminStudyAppOrganizationChange: {
    path: '/spadmin/study_app/organization',
    djangoRouteName: 'admin:study_app_organization_change',
    djangoPath: '/spadmin/study_app/organization/<path:object_id>/change/'
  },
  SpadminStudyAppOrganizationPathObjectId: {
    path: '/spadmin/study_app/organization',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/organization/<path:object_id>/'
  },
  adminStudyAppDepartmentChangelist: {
    path: '/spadmin/study_app/department/',
    djangoRouteName: 'admin:study_app_department_changelist',
    djangoPath: '/spadmin/study_app/department/'
  },
  adminStudyAppDepartmentAdd: {
    path: '/spadmin/study_app/department/add/',
    djangoRouteName: 'admin:study_app_department_add',
    djangoPath: '/spadmin/study_app/department/add/'
  },
  adminStudyAppDepartmentHistory: {
    path: '/spadmin/study_app/department',
    djangoRouteName: 'admin:study_app_department_history',
    djangoPath: '/spadmin/study_app/department/<path:object_id>/history/'
  },
  adminStudyAppDepartmentDelete: {
    path: '/spadmin/study_app/department',
    djangoRouteName: 'admin:study_app_department_delete',
    djangoPath: '/spadmin/study_app/department/<path:object_id>/delete/'
  },
  adminStudyAppDepartmentChange: {
    path: '/spadmin/study_app/department',
    djangoRouteName: 'admin:study_app_department_change',
    djangoPath: '/spadmin/study_app/department/<path:object_id>/change/'
  },
  SpadminStudyAppDepartmentPathObjectId: {
    path: '/spadmin/study_app/department',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/department/<path:object_id>/'
  },
  adminStudyAppGalleryChangelist: {
    path: '/spadmin/study_app/gallery/',
    djangoRouteName: 'admin:study_app_gallery_changelist',
    djangoPath: '/spadmin/study_app/gallery/'
  },
  adminStudyAppGalleryAdd: {
    path: '/spadmin/study_app/gallery/add/',
    djangoRouteName: 'admin:study_app_gallery_add',
    djangoPath: '/spadmin/study_app/gallery/add/'
  },
  adminStudyAppGalleryHistory: {
    path: '/spadmin/study_app/gallery',
    djangoRouteName: 'admin:study_app_gallery_history',
    djangoPath: '/spadmin/study_app/gallery/<path:object_id>/history/'
  },
  adminStudyAppGalleryDelete: {
    path: '/spadmin/study_app/gallery',
    djangoRouteName: 'admin:study_app_gallery_delete',
    djangoPath: '/spadmin/study_app/gallery/<path:object_id>/delete/'
  },
  adminStudyAppGalleryChange: {
    path: '/spadmin/study_app/gallery',
    djangoRouteName: 'admin:study_app_gallery_change',
    djangoPath: '/spadmin/study_app/gallery/<path:object_id>/change/'
  },
  SpadminStudyAppGalleryPathObjectId: {
    path: '/spadmin/study_app/gallery',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/gallery/<path:object_id>/'
  },
  adminStudyAppGallerystaticpageChangelist: {
    path: '/spadmin/study_app/gallerystaticpage/',
    djangoRouteName: 'admin:study_app_gallerystaticpage_changelist',
    djangoPath: '/spadmin/study_app/gallerystaticpage/'
  },
  adminStudyAppGallerystaticpageAdd: {
    path: '/spadmin/study_app/gallerystaticpage/add/',
    djangoRouteName: 'admin:study_app_gallerystaticpage_add',
    djangoPath: '/spadmin/study_app/gallerystaticpage/add/'
  },
  adminStudyAppGallerystaticpageHistory: {
    path: '/spadmin/study_app/gallerystaticpage',
    djangoRouteName: 'admin:study_app_gallerystaticpage_history',
    djangoPath: '/spadmin/study_app/gallerystaticpage/<path:object_id>/history/'
  },
  adminStudyAppGallerystaticpageDelete: {
    path: '/spadmin/study_app/gallerystaticpage',
    djangoRouteName: 'admin:study_app_gallerystaticpage_delete',
    djangoPath: '/spadmin/study_app/gallerystaticpage/<path:object_id>/delete/'
  },
  adminStudyAppGallerystaticpageChange: {
    path: '/spadmin/study_app/gallerystaticpage',
    djangoRouteName: 'admin:study_app_gallerystaticpage_change',
    djangoPath: '/spadmin/study_app/gallerystaticpage/<path:object_id>/change/'
  },
  SpadminStudyAppGallerystaticpagePathObjectId: {
    path: '/spadmin/study_app/gallerystaticpage',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/gallerystaticpage/<path:object_id>/'
  },
  adminStudyAppSitepageChangelist: {
    path: '/spadmin/study_app/sitepage/',
    djangoRouteName: 'admin:study_app_sitepage_changelist',
    djangoPath: '/spadmin/study_app/sitepage/'
  },
  adminStudyAppSitepageAdd: {
    path: '/spadmin/study_app/sitepage/add/',
    djangoRouteName: 'admin:study_app_sitepage_add',
    djangoPath: '/spadmin/study_app/sitepage/add/'
  },
  adminStudyAppSitepageHistory: {
    path: '/spadmin/study_app/sitepage',
    djangoRouteName: 'admin:study_app_sitepage_history',
    djangoPath: '/spadmin/study_app/sitepage/<path:object_id>/history/'
  },
  adminStudyAppSitepageDelete: {
    path: '/spadmin/study_app/sitepage',
    djangoRouteName: 'admin:study_app_sitepage_delete',
    djangoPath: '/spadmin/study_app/sitepage/<path:object_id>/delete/'
  },
  adminStudyAppSitepageChange: {
    path: '/spadmin/study_app/sitepage',
    djangoRouteName: 'admin:study_app_sitepage_change',
    djangoPath: '/spadmin/study_app/sitepage/<path:object_id>/change/'
  },
  SpadminStudyAppSitepagePathObjectId: {
    path: '/spadmin/study_app/sitepage',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/sitepage/<path:object_id>/'
  },
  adminStudyAppStudypdfdocsChangelist: {
    path: '/spadmin/study_app/studypdfdocs/',
    djangoRouteName: 'admin:study_app_studypdfdocs_changelist',
    djangoPath: '/spadmin/study_app/studypdfdocs/'
  },
  adminStudyAppStudypdfdocsAdd: {
    path: '/spadmin/study_app/studypdfdocs/add/',
    djangoRouteName: 'admin:study_app_studypdfdocs_add',
    djangoPath: '/spadmin/study_app/studypdfdocs/add/'
  },
  adminStudyAppStudypdfdocsHistory: {
    path: '/spadmin/study_app/studypdfdocs',
    djangoRouteName: 'admin:study_app_studypdfdocs_history',
    djangoPath: '/spadmin/study_app/studypdfdocs/<path:object_id>/history/'
  },
  adminStudyAppStudypdfdocsDelete: {
    path: '/spadmin/study_app/studypdfdocs',
    djangoRouteName: 'admin:study_app_studypdfdocs_delete',
    djangoPath: '/spadmin/study_app/studypdfdocs/<path:object_id>/delete/'
  },
  adminStudyAppStudypdfdocsChange: {
    path: '/spadmin/study_app/studypdfdocs',
    djangoRouteName: 'admin:study_app_studypdfdocs_change',
    djangoPath: '/spadmin/study_app/studypdfdocs/<path:object_id>/change/'
  },
  SpadminStudyAppStudypdfdocsPathObjectId: {
    path: '/spadmin/study_app/studypdfdocs',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/studypdfdocs/<path:object_id>/'
  },
  adminStudyAppMegastudyChangelist: {
    path: '/spadmin/study_app/megastudy/',
    djangoRouteName: 'admin:study_app_megastudy_changelist',
    djangoPath: '/spadmin/study_app/megastudy/'
  },
  adminStudyAppMegastudyAdd: {
    path: '/spadmin/study_app/megastudy/add/',
    djangoRouteName: 'admin:study_app_megastudy_add',
    djangoPath: '/spadmin/study_app/megastudy/add/'
  },
  adminStudyAppMegastudyHistory: {
    path: '/spadmin/study_app/megastudy',
    djangoRouteName: 'admin:study_app_megastudy_history',
    djangoPath: '/spadmin/study_app/megastudy/<path:object_id>/history/'
  },
  adminStudyAppMegastudyDelete: {
    path: '/spadmin/study_app/megastudy',
    djangoRouteName: 'admin:study_app_megastudy_delete',
    djangoPath: '/spadmin/study_app/megastudy/<path:object_id>/delete/'
  },
  adminStudyAppMegastudyChange: {
    path: '/spadmin/study_app/megastudy',
    djangoRouteName: 'admin:study_app_megastudy_change',
    djangoPath: '/spadmin/study_app/megastudy/<path:object_id>/change/'
  },
  SpadminStudyAppMegastudyPathObjectId: {
    path: '/spadmin/study_app/megastudy',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/megastudy/<path:object_id>/'
  },
  adminStudyAppStudyChangelist: {
    path: '/spadmin/study_app/study/',
    djangoRouteName: 'admin:study_app_study_changelist',
    djangoPath: '/spadmin/study_app/study/'
  },
  adminStudyAppStudyAdd: {
    path: '/spadmin/study_app/study/add/',
    djangoRouteName: 'admin:study_app_study_add',
    djangoPath: '/spadmin/study_app/study/add/'
  },
  adminStudyAppStudyHistory: {
    path: '/spadmin/study_app/study',
    djangoRouteName: 'admin:study_app_study_history',
    djangoPath: '/spadmin/study_app/study/<path:object_id>/history/'
  },
  adminStudyAppStudyDelete: {
    path: '/spadmin/study_app/study',
    djangoRouteName: 'admin:study_app_study_delete',
    djangoPath: '/spadmin/study_app/study/<path:object_id>/delete/'
  },
  adminStudyAppStudyChange: {
    path: '/spadmin/study_app/study',
    djangoRouteName: 'admin:study_app_study_change',
    djangoPath: '/spadmin/study_app/study/<path:object_id>/change/'
  },
  SpadminStudyAppStudyPathObjectId: {
    path: '/spadmin/study_app/study',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/study/<path:object_id>/'
  },
  adminStudyAppStudycampaignChangelist: {
    path: '/spadmin/study_app/studycampaign/',
    djangoRouteName: 'admin:study_app_studycampaign_changelist',
    djangoPath: '/spadmin/study_app/studycampaign/'
  },
  adminStudyAppStudycampaignAdd: {
    path: '/spadmin/study_app/studycampaign/add/',
    djangoRouteName: 'admin:study_app_studycampaign_add',
    djangoPath: '/spadmin/study_app/studycampaign/add/'
  },
  adminStudyAppStudycampaignHistory: {
    path: '/spadmin/study_app/studycampaign',
    djangoRouteName: 'admin:study_app_studycampaign_history',
    djangoPath: '/spadmin/study_app/studycampaign/<path:object_id>/history/'
  },
  adminStudyAppStudycampaignDelete: {
    path: '/spadmin/study_app/studycampaign',
    djangoRouteName: 'admin:study_app_studycampaign_delete',
    djangoPath: '/spadmin/study_app/studycampaign/<path:object_id>/delete/'
  },
  adminStudyAppStudycampaignChange: {
    path: '/spadmin/study_app/studycampaign',
    djangoRouteName: 'admin:study_app_studycampaign_change',
    djangoPath: '/spadmin/study_app/studycampaign/<path:object_id>/change/'
  },
  SpadminStudyAppStudycampaignPathObjectId: {
    path: '/spadmin/study_app/studycampaign',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/studycampaign/<path:object_id>/'
  },
  adminStudyAppSiteChangelist: {
    path: '/spadmin/study_app/site/',
    djangoRouteName: 'admin:study_app_site_changelist',
    djangoPath: '/spadmin/study_app/site/'
  },
  adminStudyAppSiteAdd: {
    path: '/spadmin/study_app/site/add/',
    djangoRouteName: 'admin:study_app_site_add',
    djangoPath: '/spadmin/study_app/site/add/'
  },
  adminStudyAppSiteHistory: {
    path: '/spadmin/study_app/site',
    djangoRouteName: 'admin:study_app_site_history',
    djangoPath: '/spadmin/study_app/site/<path:object_id>/history/'
  },
  adminStudyAppSiteDelete: {
    path: '/spadmin/study_app/site',
    djangoRouteName: 'admin:study_app_site_delete',
    djangoPath: '/spadmin/study_app/site/<path:object_id>/delete/'
  },
  adminStudyAppSiteChange: {
    path: '/spadmin/study_app/site',
    djangoRouteName: 'admin:study_app_site_change',
    djangoPath: '/spadmin/study_app/site/<path:object_id>/change/'
  },
  SpadminStudyAppSitePathObjectId: {
    path: '/spadmin/study_app/site',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/site/<path:object_id>/'
  },
  adminStudyAppMfclinicalsiteChangelist: {
    path: '/spadmin/study_app/mfclinicalsite/',
    djangoRouteName: 'admin:study_app_mfclinicalsite_changelist',
    djangoPath: '/spadmin/study_app/mfclinicalsite/'
  },
  adminStudyAppMfclinicalsiteAdd: {
    path: '/spadmin/study_app/mfclinicalsite/add/',
    djangoRouteName: 'admin:study_app_mfclinicalsite_add',
    djangoPath: '/spadmin/study_app/mfclinicalsite/add/'
  },
  adminStudyAppMfclinicalsiteHistory: {
    path: '/spadmin/study_app/mfclinicalsite',
    djangoRouteName: 'admin:study_app_mfclinicalsite_history',
    djangoPath: '/spadmin/study_app/mfclinicalsite/<path:object_id>/history/'
  },
  adminStudyAppMfclinicalsiteDelete: {
    path: '/spadmin/study_app/mfclinicalsite',
    djangoRouteName: 'admin:study_app_mfclinicalsite_delete',
    djangoPath: '/spadmin/study_app/mfclinicalsite/<path:object_id>/delete/'
  },
  adminStudyAppMfclinicalsiteChange: {
    path: '/spadmin/study_app/mfclinicalsite',
    djangoRouteName: 'admin:study_app_mfclinicalsite_change',
    djangoPath: '/spadmin/study_app/mfclinicalsite/<path:object_id>/change/'
  },
  SpadminStudyAppMfclinicalsitePathObjectId: {
    path: '/spadmin/study_app/mfclinicalsite',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/mfclinicalsite/<path:object_id>/'
  },
  adminStudyAppMfmanufsiteChangelist: {
    path: '/spadmin/study_app/mfmanufsite/',
    djangoRouteName: 'admin:study_app_mfmanufsite_changelist',
    djangoPath: '/spadmin/study_app/mfmanufsite/'
  },
  adminStudyAppMfmanufsiteAdd: {
    path: '/spadmin/study_app/mfmanufsite/add/',
    djangoRouteName: 'admin:study_app_mfmanufsite_add',
    djangoPath: '/spadmin/study_app/mfmanufsite/add/'
  },
  adminStudyAppMfmanufsiteHistory: {
    path: '/spadmin/study_app/mfmanufsite',
    djangoRouteName: 'admin:study_app_mfmanufsite_history',
    djangoPath: '/spadmin/study_app/mfmanufsite/<path:object_id>/history/'
  },
  adminStudyAppMfmanufsiteDelete: {
    path: '/spadmin/study_app/mfmanufsite',
    djangoRouteName: 'admin:study_app_mfmanufsite_delete',
    djangoPath: '/spadmin/study_app/mfmanufsite/<path:object_id>/delete/'
  },
  adminStudyAppMfmanufsiteChange: {
    path: '/spadmin/study_app/mfmanufsite',
    djangoRouteName: 'admin:study_app_mfmanufsite_change',
    djangoPath: '/spadmin/study_app/mfmanufsite/<path:object_id>/change/'
  },
  SpadminStudyAppMfmanufsitePathObjectId: {
    path: '/spadmin/study_app/mfmanufsite',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/mfmanufsite/<path:object_id>/'
  },
  adminStudyAppSchedulerequestChangelist: {
    path: '/spadmin/study_app/schedulerequest/',
    djangoRouteName: 'admin:study_app_schedulerequest_changelist',
    djangoPath: '/spadmin/study_app/schedulerequest/'
  },
  adminStudyAppSchedulerequestAdd: {
    path: '/spadmin/study_app/schedulerequest/add/',
    djangoRouteName: 'admin:study_app_schedulerequest_add',
    djangoPath: '/spadmin/study_app/schedulerequest/add/'
  },
  adminStudyAppSchedulerequestHistory: {
    path: '/spadmin/study_app/schedulerequest',
    djangoRouteName: 'admin:study_app_schedulerequest_history',
    djangoPath: '/spadmin/study_app/schedulerequest/<path:object_id>/history/'
  },
  adminStudyAppSchedulerequestDelete: {
    path: '/spadmin/study_app/schedulerequest',
    djangoRouteName: 'admin:study_app_schedulerequest_delete',
    djangoPath: '/spadmin/study_app/schedulerequest/<path:object_id>/delete/'
  },
  adminStudyAppSchedulerequestChange: {
    path: '/spadmin/study_app/schedulerequest',
    djangoRouteName: 'admin:study_app_schedulerequest_change',
    djangoPath: '/spadmin/study_app/schedulerequest/<path:object_id>/change/'
  },
  SpadminStudyAppSchedulerequestPathObjectId: {
    path: '/spadmin/study_app/schedulerequest',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/schedulerequest/<path:object_id>/'
  },
  adminStudyAppSmsactivityChangelist: {
    path: '/spadmin/study_app/smsactivity/',
    djangoRouteName: 'admin:study_app_smsactivity_changelist',
    djangoPath: '/spadmin/study_app/smsactivity/'
  },
  adminStudyAppSmsactivityAdd: {
    path: '/spadmin/study_app/smsactivity/add/',
    djangoRouteName: 'admin:study_app_smsactivity_add',
    djangoPath: '/spadmin/study_app/smsactivity/add/'
  },
  adminStudyAppSmsactivityHistory: {
    path: '/spadmin/study_app/smsactivity',
    djangoRouteName: 'admin:study_app_smsactivity_history',
    djangoPath: '/spadmin/study_app/smsactivity/<path:object_id>/history/'
  },
  adminStudyAppSmsactivityDelete: {
    path: '/spadmin/study_app/smsactivity',
    djangoRouteName: 'admin:study_app_smsactivity_delete',
    djangoPath: '/spadmin/study_app/smsactivity/<path:object_id>/delete/'
  },
  adminStudyAppSmsactivityChange: {
    path: '/spadmin/study_app/smsactivity',
    djangoRouteName: 'admin:study_app_smsactivity_change',
    djangoPath: '/spadmin/study_app/smsactivity/<path:object_id>/change/'
  },
  SpadminStudyAppSmsactivityPathObjectId: {
    path: '/spadmin/study_app/smsactivity',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/smsactivity/<path:object_id>/'
  },
  adminStudyAppSmsleadChangelist: {
    path: '/spadmin/study_app/smslead/',
    djangoRouteName: 'admin:study_app_smslead_changelist',
    djangoPath: '/spadmin/study_app/smslead/'
  },
  adminStudyAppSmsleadAdd: {
    path: '/spadmin/study_app/smslead/add/',
    djangoRouteName: 'admin:study_app_smslead_add',
    djangoPath: '/spadmin/study_app/smslead/add/'
  },
  adminStudyAppSmsleadHistory: {
    path: '/spadmin/study_app/smslead',
    djangoRouteName: 'admin:study_app_smslead_history',
    djangoPath: '/spadmin/study_app/smslead/<path:object_id>/history/'
  },
  adminStudyAppSmsleadDelete: {
    path: '/spadmin/study_app/smslead',
    djangoRouteName: 'admin:study_app_smslead_delete',
    djangoPath: '/spadmin/study_app/smslead/<path:object_id>/delete/'
  },
  adminStudyAppSmsleadChange: {
    path: '/spadmin/study_app/smslead',
    djangoRouteName: 'admin:study_app_smslead_change',
    djangoPath: '/spadmin/study_app/smslead/<path:object_id>/change/'
  },
  SpadminStudyAppSmsleadPathObjectId: {
    path: '/spadmin/study_app/smslead',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/smslead/<path:object_id>/'
  },
  adminStudyAppStudydocChangelist: {
    path: '/spadmin/study_app/studydoc/',
    djangoRouteName: 'admin:study_app_studydoc_changelist',
    djangoPath: '/spadmin/study_app/studydoc/'
  },
  adminStudyAppStudydocAdd: {
    path: '/spadmin/study_app/studydoc/add/',
    djangoRouteName: 'admin:study_app_studydoc_add',
    djangoPath: '/spadmin/study_app/studydoc/add/'
  },
  adminStudyAppStudydocHistory: {
    path: '/spadmin/study_app/studydoc',
    djangoRouteName: 'admin:study_app_studydoc_history',
    djangoPath: '/spadmin/study_app/studydoc/<path:object_id>/history/'
  },
  adminStudyAppStudydocDelete: {
    path: '/spadmin/study_app/studydoc',
    djangoRouteName: 'admin:study_app_studydoc_delete',
    djangoPath: '/spadmin/study_app/studydoc/<path:object_id>/delete/'
  },
  adminStudyAppStudydocChange: {
    path: '/spadmin/study_app/studydoc',
    djangoRouteName: 'admin:study_app_studydoc_change',
    djangoPath: '/spadmin/study_app/studydoc/<path:object_id>/change/'
  },
  SpadminStudyAppStudydocPathObjectId: {
    path: '/spadmin/study_app/studydoc',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/studydoc/<path:object_id>/'
  },
  adminStudyAppStudycontentChangelist: {
    path: '/spadmin/study_app/studycontent/',
    djangoRouteName: 'admin:study_app_studycontent_changelist',
    djangoPath: '/spadmin/study_app/studycontent/'
  },
  adminStudyAppStudycontentAdd: {
    path: '/spadmin/study_app/studycontent/add/',
    djangoRouteName: 'admin:study_app_studycontent_add',
    djangoPath: '/spadmin/study_app/studycontent/add/'
  },
  adminStudyAppStudycontentHistory: {
    path: '/spadmin/study_app/studycontent',
    djangoRouteName: 'admin:study_app_studycontent_history',
    djangoPath: '/spadmin/study_app/studycontent/<path:object_id>/history/'
  },
  adminStudyAppStudycontentDelete: {
    path: '/spadmin/study_app/studycontent',
    djangoRouteName: 'admin:study_app_studycontent_delete',
    djangoPath: '/spadmin/study_app/studycontent/<path:object_id>/delete/'
  },
  adminStudyAppStudycontentChange: {
    path: '/spadmin/study_app/studycontent',
    djangoRouteName: 'admin:study_app_studycontent_change',
    djangoPath: '/spadmin/study_app/studycontent/<path:object_id>/change/'
  },
  SpadminStudyAppStudycontentPathObjectId: {
    path: '/spadmin/study_app/studycontent',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/studycontent/<path:object_id>/'
  },
  adminStudyAppStudyfileChangelist: {
    path: '/spadmin/study_app/studyfile/',
    djangoRouteName: 'admin:study_app_studyfile_changelist',
    djangoPath: '/spadmin/study_app/studyfile/'
  },
  adminStudyAppStudyfileAdd: {
    path: '/spadmin/study_app/studyfile/add/',
    djangoRouteName: 'admin:study_app_studyfile_add',
    djangoPath: '/spadmin/study_app/studyfile/add/'
  },
  adminStudyAppStudyfileHistory: {
    path: '/spadmin/study_app/studyfile',
    djangoRouteName: 'admin:study_app_studyfile_history',
    djangoPath: '/spadmin/study_app/studyfile/<path:object_id>/history/'
  },
  adminStudyAppStudyfileDelete: {
    path: '/spadmin/study_app/studyfile',
    djangoRouteName: 'admin:study_app_studyfile_delete',
    djangoPath: '/spadmin/study_app/studyfile/<path:object_id>/delete/'
  },
  adminStudyAppStudyfileChange: {
    path: '/spadmin/study_app/studyfile',
    djangoRouteName: 'admin:study_app_studyfile_change',
    djangoPath: '/spadmin/study_app/studyfile/<path:object_id>/change/'
  },
  SpadminStudyAppStudyfilePathObjectId: {
    path: '/spadmin/study_app/studyfile',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/studyfile/<path:object_id>/'
  },
  adminStudyAppStudystockimageChangelist: {
    path: '/spadmin/study_app/studystockimage/',
    djangoRouteName: 'admin:study_app_studystockimage_changelist',
    djangoPath: '/spadmin/study_app/studystockimage/'
  },
  adminStudyAppStudystockimageAdd: {
    path: '/spadmin/study_app/studystockimage/add/',
    djangoRouteName: 'admin:study_app_studystockimage_add',
    djangoPath: '/spadmin/study_app/studystockimage/add/'
  },
  adminStudyAppStudystockimageHistory: {
    path: '/spadmin/study_app/studystockimage',
    djangoRouteName: 'admin:study_app_studystockimage_history',
    djangoPath: '/spadmin/study_app/studystockimage/<path:object_id>/history/'
  },
  adminStudyAppStudystockimageDelete: {
    path: '/spadmin/study_app/studystockimage',
    djangoRouteName: 'admin:study_app_studystockimage_delete',
    djangoPath: '/spadmin/study_app/studystockimage/<path:object_id>/delete/'
  },
  adminStudyAppStudystockimageChange: {
    path: '/spadmin/study_app/studystockimage',
    djangoRouteName: 'admin:study_app_studystockimage_change',
    djangoPath: '/spadmin/study_app/studystockimage/<path:object_id>/change/'
  },
  SpadminStudyAppStudystockimagePathObjectId: {
    path: '/spadmin/study_app/studystockimage',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/studystockimage/<path:object_id>/'
  },
  adminStudyAppParticipantleadChangelist: {
    path: '/spadmin/study_app/participantlead/',
    djangoRouteName: 'admin:study_app_participantlead_changelist',
    djangoPath: '/spadmin/study_app/participantlead/'
  },
  adminStudyAppParticipantleadAdd: {
    path: '/spadmin/study_app/participantlead/add/',
    djangoRouteName: 'admin:study_app_participantlead_add',
    djangoPath: '/spadmin/study_app/participantlead/add/'
  },
  adminStudyAppParticipantleadHistory: {
    path: '/spadmin/study_app/participantlead',
    djangoRouteName: 'admin:study_app_participantlead_history',
    djangoPath: '/spadmin/study_app/participantlead/<path:object_id>/history/'
  },
  adminStudyAppParticipantleadDelete: {
    path: '/spadmin/study_app/participantlead',
    djangoRouteName: 'admin:study_app_participantlead_delete',
    djangoPath: '/spadmin/study_app/participantlead/<path:object_id>/delete/'
  },
  adminStudyAppParticipantleadChange: {
    path: '/spadmin/study_app/participantlead',
    djangoRouteName: 'admin:study_app_participantlead_change',
    djangoPath: '/spadmin/study_app/participantlead/<path:object_id>/change/'
  },
  SpadminStudyAppParticipantleadPathObjectId: {
    path: '/spadmin/study_app/participantlead',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/participantlead/<path:object_id>/'
  },
  adminStudyAppParticipantleaddataChangelist: {
    path: '/spadmin/study_app/participantleaddata/',
    djangoRouteName: 'admin:study_app_participantleaddata_changelist',
    djangoPath: '/spadmin/study_app/participantleaddata/'
  },
  adminStudyAppParticipantleaddataAdd: {
    path: '/spadmin/study_app/participantleaddata/add/',
    djangoRouteName: 'admin:study_app_participantleaddata_add',
    djangoPath: '/spadmin/study_app/participantleaddata/add/'
  },
  adminStudyAppParticipantleaddataHistory: {
    path: '/spadmin/study_app/participantleaddata',
    djangoRouteName: 'admin:study_app_participantleaddata_history',
    djangoPath: '/spadmin/study_app/participantleaddata/<path:object_id>/history/'
  },
  adminStudyAppParticipantleaddataDelete: {
    path: '/spadmin/study_app/participantleaddata',
    djangoRouteName: 'admin:study_app_participantleaddata_delete',
    djangoPath: '/spadmin/study_app/participantleaddata/<path:object_id>/delete/'
  },
  adminStudyAppParticipantleaddataChange: {
    path: '/spadmin/study_app/participantleaddata',
    djangoRouteName: 'admin:study_app_participantleaddata_change',
    djangoPath: '/spadmin/study_app/participantleaddata/<path:object_id>/change/'
  },
  SpadminStudyAppParticipantleaddataPathObjectId: {
    path: '/spadmin/study_app/participantleaddata',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/participantleaddata/<path:object_id>/'
  },
  adminSiteAppOrginvitecampaignChangelist: {
    path: '/spadmin/site_app/orginvitecampaign/',
    djangoRouteName: 'admin:site_app_orginvitecampaign_changelist',
    djangoPath: '/spadmin/site_app/orginvitecampaign/'
  },
  adminSiteAppOrginvitecampaignAdd: {
    path: '/spadmin/site_app/orginvitecampaign/add/',
    djangoRouteName: 'admin:site_app_orginvitecampaign_add',
    djangoPath: '/spadmin/site_app/orginvitecampaign/add/'
  },
  adminSiteAppOrginvitecampaignHistory: {
    path: '/spadmin/site_app/orginvitecampaign',
    djangoRouteName: 'admin:site_app_orginvitecampaign_history',
    djangoPath: '/spadmin/site_app/orginvitecampaign/<path:object_id>/history/'
  },
  adminSiteAppOrginvitecampaignDelete: {
    path: '/spadmin/site_app/orginvitecampaign',
    djangoRouteName: 'admin:site_app_orginvitecampaign_delete',
    djangoPath: '/spadmin/site_app/orginvitecampaign/<path:object_id>/delete/'
  },
  adminSiteAppOrginvitecampaignChange: {
    path: '/spadmin/site_app/orginvitecampaign',
    djangoRouteName: 'admin:site_app_orginvitecampaign_change',
    djangoPath: '/spadmin/site_app/orginvitecampaign/<path:object_id>/change/'
  },
  SpadminSiteAppOrginvitecampaignPathObjectId: {
    path: '/spadmin/site_app/orginvitecampaign',
    djangoRouteName: '',
    djangoPath: '/spadmin/site_app/orginvitecampaign/<path:object_id>/'
  },
  adminStudyAppSamlorganizationChangelist: {
    path: '/spadmin/study_app/samlorganization/',
    djangoRouteName: 'admin:study_app_samlorganization_changelist',
    djangoPath: '/spadmin/study_app/samlorganization/'
  },
  adminStudyAppSamlorganizationAdd: {
    path: '/spadmin/study_app/samlorganization/add/',
    djangoRouteName: 'admin:study_app_samlorganization_add',
    djangoPath: '/spadmin/study_app/samlorganization/add/'
  },
  adminStudyAppSamlorganizationHistory: {
    path: '/spadmin/study_app/samlorganization',
    djangoRouteName: 'admin:study_app_samlorganization_history',
    djangoPath: '/spadmin/study_app/samlorganization/<path:object_id>/history/'
  },
  adminStudyAppSamlorganizationDelete: {
    path: '/spadmin/study_app/samlorganization',
    djangoRouteName: 'admin:study_app_samlorganization_delete',
    djangoPath: '/spadmin/study_app/samlorganization/<path:object_id>/delete/'
  },
  adminStudyAppSamlorganizationChange: {
    path: '/spadmin/study_app/samlorganization',
    djangoRouteName: 'admin:study_app_samlorganization_change',
    djangoPath: '/spadmin/study_app/samlorganization/<path:object_id>/change/'
  },
  SpadminStudyAppSamlorganizationPathObjectId: {
    path: '/spadmin/study_app/samlorganization',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/samlorganization/<path:object_id>/'
  },
  adminStudyAppStudylocationChangelist: {
    path: '/spadmin/study_app/studylocation/',
    djangoRouteName: 'admin:study_app_studylocation_changelist',
    djangoPath: '/spadmin/study_app/studylocation/'
  },
  adminStudyAppStudylocationAdd: {
    path: '/spadmin/study_app/studylocation/add/',
    djangoRouteName: 'admin:study_app_studylocation_add',
    djangoPath: '/spadmin/study_app/studylocation/add/'
  },
  adminStudyAppStudylocationHistory: {
    path: '/spadmin/study_app/studylocation',
    djangoRouteName: 'admin:study_app_studylocation_history',
    djangoPath: '/spadmin/study_app/studylocation/<path:object_id>/history/'
  },
  adminStudyAppStudylocationDelete: {
    path: '/spadmin/study_app/studylocation',
    djangoRouteName: 'admin:study_app_studylocation_delete',
    djangoPath: '/spadmin/study_app/studylocation/<path:object_id>/delete/'
  },
  adminStudyAppStudylocationChange: {
    path: '/spadmin/study_app/studylocation',
    djangoRouteName: 'admin:study_app_studylocation_change',
    djangoPath: '/spadmin/study_app/studylocation/<path:object_id>/change/'
  },
  SpadminStudyAppStudylocationPathObjectId: {
    path: '/spadmin/study_app/studylocation',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/studylocation/<path:object_id>/'
  },
  adminStudyAppStudypublishvalidationChangelist: {
    path: '/spadmin/study_app/studypublishvalidation/',
    djangoRouteName: 'admin:study_app_studypublishvalidation_changelist',
    djangoPath: '/spadmin/study_app/studypublishvalidation/'
  },
  adminStudyAppStudypublishvalidationAdd: {
    path: '/spadmin/study_app/studypublishvalidation/add/',
    djangoRouteName: 'admin:study_app_studypublishvalidation_add',
    djangoPath: '/spadmin/study_app/studypublishvalidation/add/'
  },
  adminStudyAppStudypublishvalidationHistory: {
    path: '/spadmin/study_app/studypublishvalidation',
    djangoRouteName: 'admin:study_app_studypublishvalidation_history',
    djangoPath: '/spadmin/study_app/studypublishvalidation/<path:object_id>/history/'
  },
  adminStudyAppStudypublishvalidationDelete: {
    path: '/spadmin/study_app/studypublishvalidation',
    djangoRouteName: 'admin:study_app_studypublishvalidation_delete',
    djangoPath: '/spadmin/study_app/studypublishvalidation/<path:object_id>/delete/'
  },
  adminStudyAppStudypublishvalidationChange: {
    path: '/spadmin/study_app/studypublishvalidation',
    djangoRouteName: 'admin:study_app_studypublishvalidation_change',
    djangoPath: '/spadmin/study_app/studypublishvalidation/<path:object_id>/change/'
  },
  SpadminStudyAppStudypublishvalidationPathObjectId: {
    path: '/spadmin/study_app/studypublishvalidation',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/studypublishvalidation/<path:object_id>/'
  },
  adminStudyAppFeaturesrolloutChangelist: {
    path: '/spadmin/study_app/featuresrollout/',
    djangoRouteName: 'admin:study_app_featuresrollout_changelist',
    djangoPath: '/spadmin/study_app/featuresrollout/'
  },
  adminStudyAppFeaturesrolloutAdd: {
    path: '/spadmin/study_app/featuresrollout/add/',
    djangoRouteName: 'admin:study_app_featuresrollout_add',
    djangoPath: '/spadmin/study_app/featuresrollout/add/'
  },
  adminStudyAppFeaturesrolloutHistory: {
    path: '/spadmin/study_app/featuresrollout',
    djangoRouteName: 'admin:study_app_featuresrollout_history',
    djangoPath: '/spadmin/study_app/featuresrollout/<path:object_id>/history/'
  },
  adminStudyAppFeaturesrolloutDelete: {
    path: '/spadmin/study_app/featuresrollout',
    djangoRouteName: 'admin:study_app_featuresrollout_delete',
    djangoPath: '/spadmin/study_app/featuresrollout/<path:object_id>/delete/'
  },
  adminStudyAppFeaturesrolloutChange: {
    path: '/spadmin/study_app/featuresrollout',
    djangoRouteName: 'admin:study_app_featuresrollout_change',
    djangoPath: '/spadmin/study_app/featuresrollout/<path:object_id>/change/'
  },
  SpadminStudyAppFeaturesrolloutPathObjectId: {
    path: '/spadmin/study_app/featuresrollout',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/featuresrollout/<path:object_id>/'
  },
  adminStudyAppEavproxyChangelist: {
    path: '/spadmin/study_app/eavproxy/',
    djangoRouteName: 'admin:study_app_eavproxy_changelist',
    djangoPath: '/spadmin/study_app/eavproxy/'
  },
  adminStudyAppEavproxyAdd: {
    path: '/spadmin/study_app/eavproxy/add/',
    djangoRouteName: 'admin:study_app_eavproxy_add',
    djangoPath: '/spadmin/study_app/eavproxy/add/'
  },
  adminStudyAppEavproxyHistory: {
    path: '/spadmin/study_app/eavproxy',
    djangoRouteName: 'admin:study_app_eavproxy_history',
    djangoPath: '/spadmin/study_app/eavproxy/<path:object_id>/history/'
  },
  adminStudyAppEavproxyDelete: {
    path: '/spadmin/study_app/eavproxy',
    djangoRouteName: 'admin:study_app_eavproxy_delete',
    djangoPath: '/spadmin/study_app/eavproxy/<path:object_id>/delete/'
  },
  adminStudyAppEavproxyChange: {
    path: '/spadmin/study_app/eavproxy',
    djangoRouteName: 'admin:study_app_eavproxy_change',
    djangoPath: '/spadmin/study_app/eavproxy/<path:object_id>/change/'
  },
  SpadminStudyAppEavproxyPathObjectId: {
    path: '/spadmin/study_app/eavproxy',
    djangoRouteName: '',
    djangoPath: '/spadmin/study_app/eavproxy/<path:object_id>/'
  },
  adminInviteAppInvitationChangelist: {
    path: '/spadmin/invite_app/invitation/',
    djangoRouteName: 'admin:invite_app_invitation_changelist',
    djangoPath: '/spadmin/invite_app/invitation/'
  },
  adminInviteAppInvitationAdd: {
    path: '/spadmin/invite_app/invitation/add/',
    djangoRouteName: 'admin:invite_app_invitation_add',
    djangoPath: '/spadmin/invite_app/invitation/add/'
  },
  adminInviteAppInvitationHistory: {
    path: '/spadmin/invite_app/invitation',
    djangoRouteName: 'admin:invite_app_invitation_history',
    djangoPath: '/spadmin/invite_app/invitation/<path:object_id>/history/'
  },
  adminInviteAppInvitationDelete: {
    path: '/spadmin/invite_app/invitation',
    djangoRouteName: 'admin:invite_app_invitation_delete',
    djangoPath: '/spadmin/invite_app/invitation/<path:object_id>/delete/'
  },
  adminInviteAppInvitationChange: {
    path: '/spadmin/invite_app/invitation',
    djangoRouteName: 'admin:invite_app_invitation_change',
    djangoPath: '/spadmin/invite_app/invitation/<path:object_id>/change/'
  },
  SpadminInviteAppInvitationPathObjectId: {
    path: '/spadmin/invite_app/invitation',
    djangoRouteName: '',
    djangoPath: '/spadmin/invite_app/invitation/<path:object_id>/'
  },
  adminSurveyAppSurveyChangelist: {
    path: '/spadmin/survey_app/survey/',
    djangoRouteName: 'admin:survey_app_survey_changelist',
    djangoPath: '/spadmin/survey_app/survey/'
  },
  adminSurveyAppSurveyAdd: {
    path: '/spadmin/survey_app/survey/add/',
    djangoRouteName: 'admin:survey_app_survey_add',
    djangoPath: '/spadmin/survey_app/survey/add/'
  },
  adminSurveyAppSurveyHistory: {
    path: '/spadmin/survey_app/survey',
    djangoRouteName: 'admin:survey_app_survey_history',
    djangoPath: '/spadmin/survey_app/survey/<path:object_id>/history/'
  },
  adminSurveyAppSurveyDelete: {
    path: '/spadmin/survey_app/survey',
    djangoRouteName: 'admin:survey_app_survey_delete',
    djangoPath: '/spadmin/survey_app/survey/<path:object_id>/delete/'
  },
  adminSurveyAppSurveyChange: {
    path: '/spadmin/survey_app/survey',
    djangoRouteName: 'admin:survey_app_survey_change',
    djangoPath: '/spadmin/survey_app/survey/<path:object_id>/change/'
  },
  SpadminSurveyAppSurveyPathObjectId: {
    path: '/spadmin/survey_app/survey',
    djangoRouteName: '',
    djangoPath: '/spadmin/survey_app/survey/<path:object_id>/'
  },
  adminSurveyAppFailedsurveyChangelist: {
    path: '/spadmin/survey_app/failedsurvey/',
    djangoRouteName: 'admin:survey_app_failedsurvey_changelist',
    djangoPath: '/spadmin/survey_app/failedsurvey/'
  },
  adminSurveyAppFailedsurveyAdd: {
    path: '/spadmin/survey_app/failedsurvey/add/',
    djangoRouteName: 'admin:survey_app_failedsurvey_add',
    djangoPath: '/spadmin/survey_app/failedsurvey/add/'
  },
  adminSurveyAppFailedsurveyHistory: {
    path: '/spadmin/survey_app/failedsurvey',
    djangoRouteName: 'admin:survey_app_failedsurvey_history',
    djangoPath: '/spadmin/survey_app/failedsurvey/<path:object_id>/history/'
  },
  adminSurveyAppFailedsurveyDelete: {
    path: '/spadmin/survey_app/failedsurvey',
    djangoRouteName: 'admin:survey_app_failedsurvey_delete',
    djangoPath: '/spadmin/survey_app/failedsurvey/<path:object_id>/delete/'
  },
  adminSurveyAppFailedsurveyChange: {
    path: '/spadmin/survey_app/failedsurvey',
    djangoRouteName: 'admin:survey_app_failedsurvey_change',
    djangoPath: '/spadmin/survey_app/failedsurvey/<path:object_id>/change/'
  },
  SpadminSurveyAppFailedsurveyPathObjectId: {
    path: '/spadmin/survey_app/failedsurvey',
    djangoRouteName: '',
    djangoPath: '/spadmin/survey_app/failedsurvey/<path:object_id>/'
  },
  adminPortalAppPublictrialChangelist: {
    path: '/spadmin/portal_app/publictrial/',
    djangoRouteName: 'admin:portal_app_publictrial_changelist',
    djangoPath: '/spadmin/portal_app/publictrial/'
  },
  adminPortalAppPublictrialAdd: {
    path: '/spadmin/portal_app/publictrial/add/',
    djangoRouteName: 'admin:portal_app_publictrial_add',
    djangoPath: '/spadmin/portal_app/publictrial/add/'
  },
  adminPortalAppPublictrialHistory: {
    path: '/spadmin/portal_app/publictrial',
    djangoRouteName: 'admin:portal_app_publictrial_history',
    djangoPath: '/spadmin/portal_app/publictrial/<path:object_id>/history/'
  },
  adminPortalAppPublictrialDelete: {
    path: '/spadmin/portal_app/publictrial',
    djangoRouteName: 'admin:portal_app_publictrial_delete',
    djangoPath: '/spadmin/portal_app/publictrial/<path:object_id>/delete/'
  },
  adminPortalAppPublictrialChange: {
    path: '/spadmin/portal_app/publictrial',
    djangoRouteName: 'admin:portal_app_publictrial_change',
    djangoPath: '/spadmin/portal_app/publictrial/<path:object_id>/change/'
  },
  SpadminPortalAppPublictrialPathObjectId: {
    path: '/spadmin/portal_app/publictrial',
    djangoRouteName: '',
    djangoPath: '/spadmin/portal_app/publictrial/<path:object_id>/'
  },
  adminCallTrackingAppCallactivityChangelist: {
    path: '/spadmin/call_tracking_app/callactivity/',
    djangoRouteName: 'admin:call_tracking_app_callactivity_changelist',
    djangoPath: '/spadmin/call_tracking_app/callactivity/'
  },
  adminCallTrackingAppCallactivityAdd: {
    path: '/spadmin/call_tracking_app/callactivity/add/',
    djangoRouteName: 'admin:call_tracking_app_callactivity_add',
    djangoPath: '/spadmin/call_tracking_app/callactivity/add/'
  },
  adminCallTrackingAppCallactivityHistory: {
    path: '/spadmin/call_tracking_app/callactivity',
    djangoRouteName: 'admin:call_tracking_app_callactivity_history',
    djangoPath: '/spadmin/call_tracking_app/callactivity/<path:object_id>/history/'
  },
  adminCallTrackingAppCallactivityDelete: {
    path: '/spadmin/call_tracking_app/callactivity',
    djangoRouteName: 'admin:call_tracking_app_callactivity_delete',
    djangoPath: '/spadmin/call_tracking_app/callactivity/<path:object_id>/delete/'
  },
  adminCallTrackingAppCallactivityChange: {
    path: '/spadmin/call_tracking_app/callactivity',
    djangoRouteName: 'admin:call_tracking_app_callactivity_change',
    djangoPath: '/spadmin/call_tracking_app/callactivity/<path:object_id>/change/'
  },
  SpadminCallTrackingAppCallactivityPathObjectId: {
    path: '/spadmin/call_tracking_app/callactivity',
    djangoRouteName: '',
    djangoPath: '/spadmin/call_tracking_app/callactivity/<path:object_id>/'
  },
  adminCallTrackingAppCallsummaryChangelist: {
    path: '/spadmin/call_tracking_app/callsummary/',
    djangoRouteName: 'admin:call_tracking_app_callsummary_changelist',
    djangoPath: '/spadmin/call_tracking_app/callsummary/'
  },
  adminCallTrackingAppCallsummaryAdd: {
    path: '/spadmin/call_tracking_app/callsummary/add/',
    djangoRouteName: 'admin:call_tracking_app_callsummary_add',
    djangoPath: '/spadmin/call_tracking_app/callsummary/add/'
  },
  adminCallTrackingAppCallsummaryHistory: {
    path: '/spadmin/call_tracking_app/callsummary',
    djangoRouteName: 'admin:call_tracking_app_callsummary_history',
    djangoPath: '/spadmin/call_tracking_app/callsummary/<path:object_id>/history/'
  },
  adminCallTrackingAppCallsummaryDelete: {
    path: '/spadmin/call_tracking_app/callsummary',
    djangoRouteName: 'admin:call_tracking_app_callsummary_delete',
    djangoPath: '/spadmin/call_tracking_app/callsummary/<path:object_id>/delete/'
  },
  adminCallTrackingAppCallsummaryChange: {
    path: '/spadmin/call_tracking_app/callsummary',
    djangoRouteName: 'admin:call_tracking_app_callsummary_change',
    djangoPath: '/spadmin/call_tracking_app/callsummary/<path:object_id>/change/'
  },
  SpadminCallTrackingAppCallsummaryPathObjectId: {
    path: '/spadmin/call_tracking_app/callsummary',
    djangoRouteName: '',
    djangoPath: '/spadmin/call_tracking_app/callsummary/<path:object_id>/'
  },
  adminCallTrackingAppVoicemessagesettingsChangelist: {
    path: '/spadmin/call_tracking_app/voicemessagesettings/',
    djangoRouteName: 'admin:call_tracking_app_voicemessagesettings_changelist',
    djangoPath: '/spadmin/call_tracking_app/voicemessagesettings/'
  },
  adminCallTrackingAppVoicemessagesettingsAdd: {
    path: '/spadmin/call_tracking_app/voicemessagesettings/add/',
    djangoRouteName: 'admin:call_tracking_app_voicemessagesettings_add',
    djangoPath: '/spadmin/call_tracking_app/voicemessagesettings/add/'
  },
  adminCallTrackingAppVoicemessagesettingsHistory: {
    path: '/spadmin/call_tracking_app/voicemessagesettings',
    djangoRouteName: 'admin:call_tracking_app_voicemessagesettings_history',
    djangoPath: '/spadmin/call_tracking_app/voicemessagesettings/<path:object_id>/history/'
  },
  adminCallTrackingAppVoicemessagesettingsDelete: {
    path: '/spadmin/call_tracking_app/voicemessagesettings',
    djangoRouteName: 'admin:call_tracking_app_voicemessagesettings_delete',
    djangoPath: '/spadmin/call_tracking_app/voicemessagesettings/<path:object_id>/delete/'
  },
  adminCallTrackingAppVoicemessagesettingsChange: {
    path: '/spadmin/call_tracking_app/voicemessagesettings',
    djangoRouteName: 'admin:call_tracking_app_voicemessagesettings_change',
    djangoPath: '/spadmin/call_tracking_app/voicemessagesettings/<path:object_id>/change/'
  },
  SpadminCallTrackingAppVoicemessagesettingsPathObjectId: {
    path: '/spadmin/call_tracking_app/voicemessagesettings',
    djangoRouteName: '',
    djangoPath: '/spadmin/call_tracking_app/voicemessagesettings/<path:object_id>/'
  },
  adminCallTrackingAppReservednumberorgChangelist: {
    path: '/spadmin/call_tracking_app/reservednumberorg/',
    djangoRouteName: 'admin:call_tracking_app_reservednumberorg_changelist',
    djangoPath: '/spadmin/call_tracking_app/reservednumberorg/'
  },
  adminCallTrackingAppReservednumberorgAdd: {
    path: '/spadmin/call_tracking_app/reservednumberorg/add/',
    djangoRouteName: 'admin:call_tracking_app_reservednumberorg_add',
    djangoPath: '/spadmin/call_tracking_app/reservednumberorg/add/'
  },
  adminCallTrackingAppReservednumberorgHistory: {
    path: '/spadmin/call_tracking_app/reservednumberorg',
    djangoRouteName: 'admin:call_tracking_app_reservednumberorg_history',
    djangoPath: '/spadmin/call_tracking_app/reservednumberorg/<path:object_id>/history/'
  },
  adminCallTrackingAppReservednumberorgDelete: {
    path: '/spadmin/call_tracking_app/reservednumberorg',
    djangoRouteName: 'admin:call_tracking_app_reservednumberorg_delete',
    djangoPath: '/spadmin/call_tracking_app/reservednumberorg/<path:object_id>/delete/'
  },
  adminCallTrackingAppReservednumberorgChange: {
    path: '/spadmin/call_tracking_app/reservednumberorg',
    djangoRouteName: 'admin:call_tracking_app_reservednumberorg_change',
    djangoPath: '/spadmin/call_tracking_app/reservednumberorg/<path:object_id>/change/'
  },
  SpadminCallTrackingAppReservednumberorgPathObjectId: {
    path: '/spadmin/call_tracking_app/reservednumberorg',
    djangoRouteName: '',
    djangoPath: '/spadmin/call_tracking_app/reservednumberorg/<path:object_id>/'
  },
  adminGhAppGhcallrequestChangelist: {
    path: '/spadmin/gh_app/ghcallrequest/',
    djangoRouteName: 'admin:gh_app_ghcallrequest_changelist',
    djangoPath: '/spadmin/gh_app/ghcallrequest/'
  },
  adminGhAppGhcallrequestAdd: {
    path: '/spadmin/gh_app/ghcallrequest/add/',
    djangoRouteName: 'admin:gh_app_ghcallrequest_add',
    djangoPath: '/spadmin/gh_app/ghcallrequest/add/'
  },
  adminGhAppGhcallrequestHistory: {
    path: '/spadmin/gh_app/ghcallrequest',
    djangoRouteName: 'admin:gh_app_ghcallrequest_history',
    djangoPath: '/spadmin/gh_app/ghcallrequest/<path:object_id>/history/'
  },
  adminGhAppGhcallrequestDelete: {
    path: '/spadmin/gh_app/ghcallrequest',
    djangoRouteName: 'admin:gh_app_ghcallrequest_delete',
    djangoPath: '/spadmin/gh_app/ghcallrequest/<path:object_id>/delete/'
  },
  adminGhAppGhcallrequestChange: {
    path: '/spadmin/gh_app/ghcallrequest',
    djangoRouteName: 'admin:gh_app_ghcallrequest_change',
    djangoPath: '/spadmin/gh_app/ghcallrequest/<path:object_id>/change/'
  },
  SpadminGhAppGhcallrequestPathObjectId: {
    path: '/spadmin/gh_app/ghcallrequest',
    djangoRouteName: '',
    djangoPath: '/spadmin/gh_app/ghcallrequest/<path:object_id>/'
  },
  adminGhAppGhconnecttrialChangelist: {
    path: '/spadmin/gh_app/ghconnecttrial/',
    djangoRouteName: 'admin:gh_app_ghconnecttrial_changelist',
    djangoPath: '/spadmin/gh_app/ghconnecttrial/'
  },
  adminGhAppGhconnecttrialAdd: {
    path: '/spadmin/gh_app/ghconnecttrial/add/',
    djangoRouteName: 'admin:gh_app_ghconnecttrial_add',
    djangoPath: '/spadmin/gh_app/ghconnecttrial/add/'
  },
  adminGhAppGhconnecttrialHistory: {
    path: '/spadmin/gh_app/ghconnecttrial',
    djangoRouteName: 'admin:gh_app_ghconnecttrial_history',
    djangoPath: '/spadmin/gh_app/ghconnecttrial/<path:object_id>/history/'
  },
  adminGhAppGhconnecttrialDelete: {
    path: '/spadmin/gh_app/ghconnecttrial',
    djangoRouteName: 'admin:gh_app_ghconnecttrial_delete',
    djangoPath: '/spadmin/gh_app/ghconnecttrial/<path:object_id>/delete/'
  },
  adminGhAppGhconnecttrialChange: {
    path: '/spadmin/gh_app/ghconnecttrial',
    djangoRouteName: 'admin:gh_app_ghconnecttrial_change',
    djangoPath: '/spadmin/gh_app/ghconnecttrial/<path:object_id>/change/'
  },
  SpadminGhAppGhconnecttrialPathObjectId: {
    path: '/spadmin/gh_app/ghconnecttrial',
    djangoRouteName: '',
    djangoPath: '/spadmin/gh_app/ghconnecttrial/<path:object_id>/'
  },
  adminGhAppGhconnectrequestChangelist: {
    path: '/spadmin/gh_app/ghconnectrequest/',
    djangoRouteName: 'admin:gh_app_ghconnectrequest_changelist',
    djangoPath: '/spadmin/gh_app/ghconnectrequest/'
  },
  adminGhAppGhconnectrequestAdd: {
    path: '/spadmin/gh_app/ghconnectrequest/add/',
    djangoRouteName: 'admin:gh_app_ghconnectrequest_add',
    djangoPath: '/spadmin/gh_app/ghconnectrequest/add/'
  },
  adminGhAppGhconnectrequestHistory: {
    path: '/spadmin/gh_app/ghconnectrequest',
    djangoRouteName: 'admin:gh_app_ghconnectrequest_history',
    djangoPath: '/spadmin/gh_app/ghconnectrequest/<path:object_id>/history/'
  },
  adminGhAppGhconnectrequestDelete: {
    path: '/spadmin/gh_app/ghconnectrequest',
    djangoRouteName: 'admin:gh_app_ghconnectrequest_delete',
    djangoPath: '/spadmin/gh_app/ghconnectrequest/<path:object_id>/delete/'
  },
  adminGhAppGhconnectrequestChange: {
    path: '/spadmin/gh_app/ghconnectrequest',
    djangoRouteName: 'admin:gh_app_ghconnectrequest_change',
    djangoPath: '/spadmin/gh_app/ghconnectrequest/<path:object_id>/change/'
  },
  SpadminGhAppGhconnectrequestPathObjectId: {
    path: '/spadmin/gh_app/ghconnectrequest',
    djangoRouteName: '',
    djangoPath: '/spadmin/gh_app/ghconnectrequest/<path:object_id>/'
  },
  adminGhAppGhconnectactivityrecordChangelist: {
    path: '/spadmin/gh_app/ghconnectactivityrecord/',
    djangoRouteName: 'admin:gh_app_ghconnectactivityrecord_changelist',
    djangoPath: '/spadmin/gh_app/ghconnectactivityrecord/'
  },
  adminGhAppGhconnectactivityrecordAdd: {
    path: '/spadmin/gh_app/ghconnectactivityrecord/add/',
    djangoRouteName: 'admin:gh_app_ghconnectactivityrecord_add',
    djangoPath: '/spadmin/gh_app/ghconnectactivityrecord/add/'
  },
  adminGhAppGhconnectactivityrecordHistory: {
    path: '/spadmin/gh_app/ghconnectactivityrecord',
    djangoRouteName: 'admin:gh_app_ghconnectactivityrecord_history',
    djangoPath: '/spadmin/gh_app/ghconnectactivityrecord/<path:object_id>/history/'
  },
  adminGhAppGhconnectactivityrecordDelete: {
    path: '/spadmin/gh_app/ghconnectactivityrecord',
    djangoRouteName: 'admin:gh_app_ghconnectactivityrecord_delete',
    djangoPath: '/spadmin/gh_app/ghconnectactivityrecord/<path:object_id>/delete/'
  },
  adminGhAppGhconnectactivityrecordChange: {
    path: '/spadmin/gh_app/ghconnectactivityrecord',
    djangoRouteName: 'admin:gh_app_ghconnectactivityrecord_change',
    djangoPath: '/spadmin/gh_app/ghconnectactivityrecord/<path:object_id>/change/'
  },
  SpadminGhAppGhconnectactivityrecordPathObjectId: {
    path: '/spadmin/gh_app/ghconnectactivityrecord',
    djangoRouteName: '',
    djangoPath: '/spadmin/gh_app/ghconnectactivityrecord/<path:object_id>/'
  },
  adminVisitAppSpvideoroomChangelist: {
    path: '/spadmin/visit_app/spvideoroom/',
    djangoRouteName: 'admin:visit_app_spvideoroom_changelist',
    djangoPath: '/spadmin/visit_app/spvideoroom/'
  },
  adminVisitAppSpvideoroomAdd: {
    path: '/spadmin/visit_app/spvideoroom/add/',
    djangoRouteName: 'admin:visit_app_spvideoroom_add',
    djangoPath: '/spadmin/visit_app/spvideoroom/add/'
  },
  adminVisitAppSpvideoroomHistory: {
    path: '/spadmin/visit_app/spvideoroom',
    djangoRouteName: 'admin:visit_app_spvideoroom_history',
    djangoPath: '/spadmin/visit_app/spvideoroom/<path:object_id>/history/'
  },
  adminVisitAppSpvideoroomDelete: {
    path: '/spadmin/visit_app/spvideoroom',
    djangoRouteName: 'admin:visit_app_spvideoroom_delete',
    djangoPath: '/spadmin/visit_app/spvideoroom/<path:object_id>/delete/'
  },
  adminVisitAppSpvideoroomChange: {
    path: '/spadmin/visit_app/spvideoroom',
    djangoRouteName: 'admin:visit_app_spvideoroom_change',
    djangoPath: '/spadmin/visit_app/spvideoroom/<path:object_id>/change/'
  },
  SpadminVisitAppSpvideoroomPathObjectId: {
    path: '/spadmin/visit_app/spvideoroom',
    djangoRouteName: '',
    djangoPath: '/spadmin/visit_app/spvideoroom/<path:object_id>/'
  },
  adminVisitAppSpvideoattendeeChangelist: {
    path: '/spadmin/visit_app/spvideoattendee/',
    djangoRouteName: 'admin:visit_app_spvideoattendee_changelist',
    djangoPath: '/spadmin/visit_app/spvideoattendee/'
  },
  adminVisitAppSpvideoattendeeAdd: {
    path: '/spadmin/visit_app/spvideoattendee/add/',
    djangoRouteName: 'admin:visit_app_spvideoattendee_add',
    djangoPath: '/spadmin/visit_app/spvideoattendee/add/'
  },
  adminVisitAppSpvideoattendeeHistory: {
    path: '/spadmin/visit_app/spvideoattendee',
    djangoRouteName: 'admin:visit_app_spvideoattendee_history',
    djangoPath: '/spadmin/visit_app/spvideoattendee/<path:object_id>/history/'
  },
  adminVisitAppSpvideoattendeeDelete: {
    path: '/spadmin/visit_app/spvideoattendee',
    djangoRouteName: 'admin:visit_app_spvideoattendee_delete',
    djangoPath: '/spadmin/visit_app/spvideoattendee/<path:object_id>/delete/'
  },
  adminVisitAppSpvideoattendeeChange: {
    path: '/spadmin/visit_app/spvideoattendee',
    djangoRouteName: 'admin:visit_app_spvideoattendee_change',
    djangoPath: '/spadmin/visit_app/spvideoattendee/<path:object_id>/change/'
  },
  SpadminVisitAppSpvideoattendeePathObjectId: {
    path: '/spadmin/visit_app/spvideoattendee',
    djangoRouteName: '',
    djangoPath: '/spadmin/visit_app/spvideoattendee/<path:object_id>/'
  },
  adminOtpTotpTotpdeviceConfig: {
    path: '/spadmin/otp_totp/totpdevice',
    djangoRouteName: 'admin:otp_totp_totpdevice_config',
    djangoPath: '/spadmin/otp_totp/totpdevice/<int:pk>/config/'
  },
  adminOtpTotpTotpdeviceQrcode: {
    path: '/spadmin/otp_totp/totpdevice',
    djangoRouteName: 'admin:otp_totp_totpdevice_qrcode',
    djangoPath: '/spadmin/otp_totp/totpdevice/<int:pk>/qrcode/'
  },
  adminOtpTotpTotpdeviceChangelist: {
    path: '/spadmin/otp_totp/totpdevice/',
    djangoRouteName: 'admin:otp_totp_totpdevice_changelist',
    djangoPath: '/spadmin/otp_totp/totpdevice/'
  },
  adminOtpTotpTotpdeviceAdd: {
    path: '/spadmin/otp_totp/totpdevice/add/',
    djangoRouteName: 'admin:otp_totp_totpdevice_add',
    djangoPath: '/spadmin/otp_totp/totpdevice/add/'
  },
  adminOtpTotpTotpdeviceHistory: {
    path: '/spadmin/otp_totp/totpdevice',
    djangoRouteName: 'admin:otp_totp_totpdevice_history',
    djangoPath: '/spadmin/otp_totp/totpdevice/<path:object_id>/history/'
  },
  adminOtpTotpTotpdeviceDelete: {
    path: '/spadmin/otp_totp/totpdevice',
    djangoRouteName: 'admin:otp_totp_totpdevice_delete',
    djangoPath: '/spadmin/otp_totp/totpdevice/<path:object_id>/delete/'
  },
  adminOtpTotpTotpdeviceChange: {
    path: '/spadmin/otp_totp/totpdevice',
    djangoRouteName: 'admin:otp_totp_totpdevice_change',
    djangoPath: '/spadmin/otp_totp/totpdevice/<path:object_id>/change/'
  },
  SpadminOtpTotpTotpdevicePathObjectId: {
    path: '/spadmin/otp_totp/totpdevice',
    djangoRouteName: '',
    djangoPath: '/spadmin/otp_totp/totpdevice/<path:object_id>/'
  },
  adminDjangoCeleryResultsTaskresultChangelist: {
    path: '/spadmin/django_celery_results/taskresult/',
    djangoRouteName: 'admin:django_celery_results_taskresult_changelist',
    djangoPath: '/spadmin/django_celery_results/taskresult/'
  },
  adminDjangoCeleryResultsTaskresultAdd: {
    path: '/spadmin/django_celery_results/taskresult/add/',
    djangoRouteName: 'admin:django_celery_results_taskresult_add',
    djangoPath: '/spadmin/django_celery_results/taskresult/add/'
  },
  adminDjangoCeleryResultsTaskresultHistory: {
    path: '/spadmin/django_celery_results/taskresult',
    djangoRouteName: 'admin:django_celery_results_taskresult_history',
    djangoPath: '/spadmin/django_celery_results/taskresult/<path:object_id>/history/'
  },
  adminDjangoCeleryResultsTaskresultDelete: {
    path: '/spadmin/django_celery_results/taskresult',
    djangoRouteName: 'admin:django_celery_results_taskresult_delete',
    djangoPath: '/spadmin/django_celery_results/taskresult/<path:object_id>/delete/'
  },
  adminDjangoCeleryResultsTaskresultChange: {
    path: '/spadmin/django_celery_results/taskresult',
    djangoRouteName: 'admin:django_celery_results_taskresult_change',
    djangoPath: '/spadmin/django_celery_results/taskresult/<path:object_id>/change/'
  },
  SpadminDjangoCeleryResultsTaskresultPathObjectId: {
    path: '/spadmin/django_celery_results/taskresult',
    djangoRouteName: '',
    djangoPath: '/spadmin/django_celery_results/taskresult/<path:object_id>/'
  },
  adminDjangoCeleryResultsGroupresultChangelist: {
    path: '/spadmin/django_celery_results/groupresult/',
    djangoRouteName: 'admin:django_celery_results_groupresult_changelist',
    djangoPath: '/spadmin/django_celery_results/groupresult/'
  },
  adminDjangoCeleryResultsGroupresultAdd: {
    path: '/spadmin/django_celery_results/groupresult/add/',
    djangoRouteName: 'admin:django_celery_results_groupresult_add',
    djangoPath: '/spadmin/django_celery_results/groupresult/add/'
  },
  adminDjangoCeleryResultsGroupresultHistory: {
    path: '/spadmin/django_celery_results/groupresult',
    djangoRouteName: 'admin:django_celery_results_groupresult_history',
    djangoPath: '/spadmin/django_celery_results/groupresult/<path:object_id>/history/'
  },
  adminDjangoCeleryResultsGroupresultDelete: {
    path: '/spadmin/django_celery_results/groupresult',
    djangoRouteName: 'admin:django_celery_results_groupresult_delete',
    djangoPath: '/spadmin/django_celery_results/groupresult/<path:object_id>/delete/'
  },
  adminDjangoCeleryResultsGroupresultChange: {
    path: '/spadmin/django_celery_results/groupresult',
    djangoRouteName: 'admin:django_celery_results_groupresult_change',
    djangoPath: '/spadmin/django_celery_results/groupresult/<path:object_id>/change/'
  },
  SpadminDjangoCeleryResultsGroupresultPathObjectId: {
    path: '/spadmin/django_celery_results/groupresult',
    djangoRouteName: '',
    djangoPath: '/spadmin/django_celery_results/groupresult/<path:object_id>/'
  },
  adminOauth2ProviderApplicationChangelist: {
    path: '/spadmin/oauth2_provider/application/',
    djangoRouteName: 'admin:oauth2_provider_application_changelist',
    djangoPath: '/spadmin/oauth2_provider/application/'
  },
  adminOauth2ProviderApplicationAdd: {
    path: '/spadmin/oauth2_provider/application/add/',
    djangoRouteName: 'admin:oauth2_provider_application_add',
    djangoPath: '/spadmin/oauth2_provider/application/add/'
  },
  adminOauth2ProviderApplicationHistory: {
    path: '/spadmin/oauth2_provider/application',
    djangoRouteName: 'admin:oauth2_provider_application_history',
    djangoPath: '/spadmin/oauth2_provider/application/<path:object_id>/history/'
  },
  adminOauth2ProviderApplicationDelete: {
    path: '/spadmin/oauth2_provider/application',
    djangoRouteName: 'admin:oauth2_provider_application_delete',
    djangoPath: '/spadmin/oauth2_provider/application/<path:object_id>/delete/'
  },
  adminOauth2ProviderApplicationChange: {
    path: '/spadmin/oauth2_provider/application',
    djangoRouteName: 'admin:oauth2_provider_application_change',
    djangoPath: '/spadmin/oauth2_provider/application/<path:object_id>/change/'
  },
  SpadminOauth2ProviderApplicationPathObjectId: {
    path: '/spadmin/oauth2_provider/application',
    djangoRouteName: '',
    djangoPath: '/spadmin/oauth2_provider/application/<path:object_id>/'
  },
  adminOauth2ProviderAccesstokenChangelist: {
    path: '/spadmin/oauth2_provider/accesstoken/',
    djangoRouteName: 'admin:oauth2_provider_accesstoken_changelist',
    djangoPath: '/spadmin/oauth2_provider/accesstoken/'
  },
  adminOauth2ProviderAccesstokenAdd: {
    path: '/spadmin/oauth2_provider/accesstoken/add/',
    djangoRouteName: 'admin:oauth2_provider_accesstoken_add',
    djangoPath: '/spadmin/oauth2_provider/accesstoken/add/'
  },
  adminOauth2ProviderAccesstokenHistory: {
    path: '/spadmin/oauth2_provider/accesstoken',
    djangoRouteName: 'admin:oauth2_provider_accesstoken_history',
    djangoPath: '/spadmin/oauth2_provider/accesstoken/<path:object_id>/history/'
  },
  adminOauth2ProviderAccesstokenDelete: {
    path: '/spadmin/oauth2_provider/accesstoken',
    djangoRouteName: 'admin:oauth2_provider_accesstoken_delete',
    djangoPath: '/spadmin/oauth2_provider/accesstoken/<path:object_id>/delete/'
  },
  adminOauth2ProviderAccesstokenChange: {
    path: '/spadmin/oauth2_provider/accesstoken',
    djangoRouteName: 'admin:oauth2_provider_accesstoken_change',
    djangoPath: '/spadmin/oauth2_provider/accesstoken/<path:object_id>/change/'
  },
  SpadminOauth2ProviderAccesstokenPathObjectId: {
    path: '/spadmin/oauth2_provider/accesstoken',
    djangoRouteName: '',
    djangoPath: '/spadmin/oauth2_provider/accesstoken/<path:object_id>/'
  },
  adminOauth2ProviderGrantChangelist: {
    path: '/spadmin/oauth2_provider/grant/',
    djangoRouteName: 'admin:oauth2_provider_grant_changelist',
    djangoPath: '/spadmin/oauth2_provider/grant/'
  },
  adminOauth2ProviderGrantAdd: {
    path: '/spadmin/oauth2_provider/grant/add/',
    djangoRouteName: 'admin:oauth2_provider_grant_add',
    djangoPath: '/spadmin/oauth2_provider/grant/add/'
  },
  adminOauth2ProviderGrantHistory: {
    path: '/spadmin/oauth2_provider/grant',
    djangoRouteName: 'admin:oauth2_provider_grant_history',
    djangoPath: '/spadmin/oauth2_provider/grant/<path:object_id>/history/'
  },
  adminOauth2ProviderGrantDelete: {
    path: '/spadmin/oauth2_provider/grant',
    djangoRouteName: 'admin:oauth2_provider_grant_delete',
    djangoPath: '/spadmin/oauth2_provider/grant/<path:object_id>/delete/'
  },
  adminOauth2ProviderGrantChange: {
    path: '/spadmin/oauth2_provider/grant',
    djangoRouteName: 'admin:oauth2_provider_grant_change',
    djangoPath: '/spadmin/oauth2_provider/grant/<path:object_id>/change/'
  },
  SpadminOauth2ProviderGrantPathObjectId: {
    path: '/spadmin/oauth2_provider/grant',
    djangoRouteName: '',
    djangoPath: '/spadmin/oauth2_provider/grant/<path:object_id>/'
  },
  adminOauth2ProviderIdtokenChangelist: {
    path: '/spadmin/oauth2_provider/idtoken/',
    djangoRouteName: 'admin:oauth2_provider_idtoken_changelist',
    djangoPath: '/spadmin/oauth2_provider/idtoken/'
  },
  adminOauth2ProviderIdtokenAdd: {
    path: '/spadmin/oauth2_provider/idtoken/add/',
    djangoRouteName: 'admin:oauth2_provider_idtoken_add',
    djangoPath: '/spadmin/oauth2_provider/idtoken/add/'
  },
  adminOauth2ProviderIdtokenHistory: {
    path: '/spadmin/oauth2_provider/idtoken',
    djangoRouteName: 'admin:oauth2_provider_idtoken_history',
    djangoPath: '/spadmin/oauth2_provider/idtoken/<path:object_id>/history/'
  },
  adminOauth2ProviderIdtokenDelete: {
    path: '/spadmin/oauth2_provider/idtoken',
    djangoRouteName: 'admin:oauth2_provider_idtoken_delete',
    djangoPath: '/spadmin/oauth2_provider/idtoken/<path:object_id>/delete/'
  },
  adminOauth2ProviderIdtokenChange: {
    path: '/spadmin/oauth2_provider/idtoken',
    djangoRouteName: 'admin:oauth2_provider_idtoken_change',
    djangoPath: '/spadmin/oauth2_provider/idtoken/<path:object_id>/change/'
  },
  SpadminOauth2ProviderIdtokenPathObjectId: {
    path: '/spadmin/oauth2_provider/idtoken',
    djangoRouteName: '',
    djangoPath: '/spadmin/oauth2_provider/idtoken/<path:object_id>/'
  },
  adminOauth2ProviderRefreshtokenChangelist: {
    path: '/spadmin/oauth2_provider/refreshtoken/',
    djangoRouteName: 'admin:oauth2_provider_refreshtoken_changelist',
    djangoPath: '/spadmin/oauth2_provider/refreshtoken/'
  },
  adminOauth2ProviderRefreshtokenAdd: {
    path: '/spadmin/oauth2_provider/refreshtoken/add/',
    djangoRouteName: 'admin:oauth2_provider_refreshtoken_add',
    djangoPath: '/spadmin/oauth2_provider/refreshtoken/add/'
  },
  adminOauth2ProviderRefreshtokenHistory: {
    path: '/spadmin/oauth2_provider/refreshtoken',
    djangoRouteName: 'admin:oauth2_provider_refreshtoken_history',
    djangoPath: '/spadmin/oauth2_provider/refreshtoken/<path:object_id>/history/'
  },
  adminOauth2ProviderRefreshtokenDelete: {
    path: '/spadmin/oauth2_provider/refreshtoken',
    djangoRouteName: 'admin:oauth2_provider_refreshtoken_delete',
    djangoPath: '/spadmin/oauth2_provider/refreshtoken/<path:object_id>/delete/'
  },
  adminOauth2ProviderRefreshtokenChange: {
    path: '/spadmin/oauth2_provider/refreshtoken',
    djangoRouteName: 'admin:oauth2_provider_refreshtoken_change',
    djangoPath: '/spadmin/oauth2_provider/refreshtoken/<path:object_id>/change/'
  },
  SpadminOauth2ProviderRefreshtokenPathObjectId: {
    path: '/spadmin/oauth2_provider/refreshtoken',
    djangoRouteName: '',
    djangoPath: '/spadmin/oauth2_provider/refreshtoken/<path:object_id>/'
  },
  adminEavAttributeChangelist: {
    path: '/spadmin/eav/attribute/',
    djangoRouteName: 'admin:eav_attribute_changelist',
    djangoPath: '/spadmin/eav/attribute/'
  },
  adminEavAttributeAdd: {
    path: '/spadmin/eav/attribute/add/',
    djangoRouteName: 'admin:eav_attribute_add',
    djangoPath: '/spadmin/eav/attribute/add/'
  },
  adminEavAttributeHistory: {
    path: '/spadmin/eav/attribute',
    djangoRouteName: 'admin:eav_attribute_history',
    djangoPath: '/spadmin/eav/attribute/<path:object_id>/history/'
  },
  adminEavAttributeDelete: {
    path: '/spadmin/eav/attribute',
    djangoRouteName: 'admin:eav_attribute_delete',
    djangoPath: '/spadmin/eav/attribute/<path:object_id>/delete/'
  },
  adminEavAttributeChange: {
    path: '/spadmin/eav/attribute',
    djangoRouteName: 'admin:eav_attribute_change',
    djangoPath: '/spadmin/eav/attribute/<path:object_id>/change/'
  },
  SpadminEavAttributePathObjectId: {
    path: '/spadmin/eav/attribute',
    djangoRouteName: '',
    djangoPath: '/spadmin/eav/attribute/<path:object_id>/'
  },
  adminEavEnumvalueChangelist: {
    path: '/spadmin/eav/enumvalue/',
    djangoRouteName: 'admin:eav_enumvalue_changelist',
    djangoPath: '/spadmin/eav/enumvalue/'
  },
  adminEavEnumvalueAdd: {
    path: '/spadmin/eav/enumvalue/add/',
    djangoRouteName: 'admin:eav_enumvalue_add',
    djangoPath: '/spadmin/eav/enumvalue/add/'
  },
  adminEavEnumvalueHistory: {
    path: '/spadmin/eav/enumvalue',
    djangoRouteName: 'admin:eav_enumvalue_history',
    djangoPath: '/spadmin/eav/enumvalue/<path:object_id>/history/'
  },
  adminEavEnumvalueDelete: {
    path: '/spadmin/eav/enumvalue',
    djangoRouteName: 'admin:eav_enumvalue_delete',
    djangoPath: '/spadmin/eav/enumvalue/<path:object_id>/delete/'
  },
  adminEavEnumvalueChange: {
    path: '/spadmin/eav/enumvalue',
    djangoRouteName: 'admin:eav_enumvalue_change',
    djangoPath: '/spadmin/eav/enumvalue/<path:object_id>/change/'
  },
  SpadminEavEnumvaluePathObjectId: {
    path: '/spadmin/eav/enumvalue',
    djangoRouteName: '',
    djangoPath: '/spadmin/eav/enumvalue/<path:object_id>/'
  },
  adminEavEnumgroupChangelist: {
    path: '/spadmin/eav/enumgroup/',
    djangoRouteName: 'admin:eav_enumgroup_changelist',
    djangoPath: '/spadmin/eav/enumgroup/'
  },
  adminEavEnumgroupAdd: {
    path: '/spadmin/eav/enumgroup/add/',
    djangoRouteName: 'admin:eav_enumgroup_add',
    djangoPath: '/spadmin/eav/enumgroup/add/'
  },
  adminEavEnumgroupHistory: {
    path: '/spadmin/eav/enumgroup',
    djangoRouteName: 'admin:eav_enumgroup_history',
    djangoPath: '/spadmin/eav/enumgroup/<path:object_id>/history/'
  },
  adminEavEnumgroupDelete: {
    path: '/spadmin/eav/enumgroup',
    djangoRouteName: 'admin:eav_enumgroup_delete',
    djangoPath: '/spadmin/eav/enumgroup/<path:object_id>/delete/'
  },
  adminEavEnumgroupChange: {
    path: '/spadmin/eav/enumgroup',
    djangoRouteName: 'admin:eav_enumgroup_change',
    djangoPath: '/spadmin/eav/enumgroup/<path:object_id>/change/'
  },
  SpadminEavEnumgroupPathObjectId: {
    path: '/spadmin/eav/enumgroup',
    djangoRouteName: '',
    djangoPath: '/spadmin/eav/enumgroup/<path:object_id>/'
  },
  adminEavValueChangelist: {
    path: '/spadmin/eav/value/',
    djangoRouteName: 'admin:eav_value_changelist',
    djangoPath: '/spadmin/eav/value/'
  },
  adminEavValueAdd: {
    path: '/spadmin/eav/value/add/',
    djangoRouteName: 'admin:eav_value_add',
    djangoPath: '/spadmin/eav/value/add/'
  },
  adminEavValueHistory: {
    path: '/spadmin/eav/value',
    djangoRouteName: 'admin:eav_value_history',
    djangoPath: '/spadmin/eav/value/<path:object_id>/history/'
  },
  adminEavValueDelete: {
    path: '/spadmin/eav/value',
    djangoRouteName: 'admin:eav_value_delete',
    djangoPath: '/spadmin/eav/value/<path:object_id>/delete/'
  },
  adminEavValueChange: {
    path: '/spadmin/eav/value',
    djangoRouteName: 'admin:eav_value_change',
    djangoPath: '/spadmin/eav/value/<path:object_id>/change/'
  },
  SpadminEavValuePathObjectId: {
    path: '/spadmin/eav/value',
    djangoRouteName: '',
    djangoPath: '/spadmin/eav/value/<path:object_id>/'
  },
  adminAppList: {
    path: '/spadmin',
    djangoRouteName: 'admin:app_list',
    djangoPath: '/spadmin/<app_label>/'
  },
  SpadminUrl: {
    path: '/spadmin',
    djangoRouteName: '',
    djangoPath: '/spadmin/<url>'
  },
  Admin21347982X017283098R27381T03Param: {
    path: '/admin_21347982x017283098r27381t03',
    djangoRouteName: '',
    djangoPath: '/admin_21347982x017283098r27381t03/<param>'
  },
  nestingServerData: {
    path: '/nested_admin/server-data\.js',
    djangoRouteName: 'nesting_server_data',
    djangoPath: '/nested_admin/server-data\.js'
  },
  passwordReset: {
    path: '/password_reset/',
    djangoRouteName: 'password-reset',
    djangoPath: '/password_reset/'
  },
  passwordResetDone: {
    path: '/password_reset/done/',
    djangoRouteName: 'password_reset_done',
    djangoPath: '/password_reset/done/'
  },
  passwordResetConfirm: {
    path: '/reset',
    djangoRouteName: 'password_reset_confirm',
    djangoPath: '/reset/<uidb64>/<token>/'
  },
  teamscopePasswordSet: {
    path: '/teamscope_password_set',
    djangoRouteName: 'teamscope_password_set',
    djangoPath: '/teamscope_password_set/<uidb64>/<token>'
  },
  passwordResetComplete: {
    path: '/password_change/done/',
    djangoRouteName: 'password_reset_complete',
    djangoPath: '/password_change/done/'
  },
  oauth2ProviderAuthorize: {
    path: '/oauth/authorize/',
    djangoRouteName: 'oauth2_provider:authorize',
    djangoPath: '/oauth/authorize/'
  },
  samlPipelineAdditionalData: {
    path: '/additional_info',
    djangoRouteName: 'saml-pipeline-additional-data',
    djangoPath: '/additional_info/<partial_token>/'
  },
  surveyJavascriptCatalog: {
    path: '/en-us/jsi18n/survey/',
    djangoRouteName: 'survey-javascript-catalog',
    djangoPath: '/en-us/jsi18n/survey/'
  },
  callTrackingJavascriptCatalog: {
    path: '/en-us/jsi18n/calltracking/',
    djangoRouteName: 'call-tracking-javascript-catalog',
    djangoPath: '/en-us/jsi18n/calltracking/'
  },
  participantCustomField: {
    path: '/api/v1/participant-custom-field/',
    djangoRouteName: 'participant_custom_field',
    djangoPath: '/api/v1/participant-custom-field/'
  },
  participantList: {
    path: '/api/v1/participant-list/',
    djangoRouteName: 'participant_list',
    djangoPath: '/api/v1/participant-list/'
  },
  inviteToEdc: {
    path: '/api/v1/invite-to-edc/',
    djangoRouteName: 'invite_to_edc',
    djangoPath: '/api/v1/invite-to-edc/'
  },
  participantUserStudies: {
    path: '/api/v1/participant-user-studies/',
    djangoRouteName: 'participant_user_studies',
    djangoPath: '/api/v1/participant-user-studies/'
  },
  participantStudyTag: {
    path: '/api/v1/participant-study-tag-bulk/',
    djangoRouteName: 'participant_study_tag',
    djangoPath: '/api/v1/participant-study-tag-bulk/'
  },
  participantCreateBatch: {
    path: '/api/v1/participant-create-batch',
    djangoRouteName: 'participant_create_batch',
    djangoPath: '/api/v1/participant-create-batch/<int:study_id>'
  },
  currentUserInfo: {
    path: '/api/v1/current-user-info/',
    djangoRouteName: 'current_user_info',
    djangoPath: '/api/v1/current-user-info/'
  },
  signupParticipant: {
    path: '/api/v1/signup/participant',
    djangoRouteName: 'signup_participant',
    djangoPath: '/api/v1/signup/participant/<int:pk>'
  },
  signupSurveys: {
    path: '/api/v1/signup/surveys',
    djangoRouteName: 'signup_surveys',
    djangoPath: '/api/v1/signup/surveys/<int:participant_id>'
  },
  signupVisits: {
    path: '/api/v1/signup/visits',
    djangoRouteName: 'signup_visits',
    djangoPath: '/api/v1/signup/visits/<int:participant_id>'
  },
  signupActivity: {
    path: '/api/v1/signup/activity',
    djangoRouteName: 'signup_activity',
    djangoPath: '/api/v1/signup/activity/<int:participant_id>'
  },
  smsActivity: {
    path: '/api/v1/sms-activity',
    djangoRouteName: 'sms_activity',
    djangoPath: '/api/v1/sms-activity'
  },
  smsLead: {
    path: '/api/v1/sms-lead',
    djangoRouteName: 'sms_lead',
    djangoPath: '/api/v1/sms-lead'
  },
  smsActivityById: {
    path: '/api/v1/sms-activity-by-id',
    djangoRouteName: 'sms_activity_by_id',
    djangoPath: '/api/v1/sms-activity-by-id/<int:id>'
  },
  studyPageAnalytics: {
    path: '/api/v1/studypage-analytics',
    djangoRouteName: 'study_page_analytics',
    djangoPath: '/api/v1/studypage-analytics/<int:study_id>'
  },
  studyPageReferrals: {
    path: '/api/v1/studypage-referrals',
    djangoRouteName: 'study_page_referrals',
    djangoPath: '/api/v1/studypage-referrals/<int:study_id>'
  },
  phoneCall: {
    path: '/api/v1/phone-call',
    djangoRouteName: 'phone_call',
    djangoPath: '/api/v1/phone-call'
  },
  workspaceInvites: {
    path: '/api/v1/workspace-invites',
    djangoRouteName: 'workspace_invites',
    djangoPath: '/api/v1/workspace-invites'
  },
  callsByStudy: {
    path: '/api/v1/calls-data-by-study',
    djangoRouteName: 'calls_by_study',
    djangoPath: '/api/v1/calls-data-by-study/<int:study_id>'
  },
  surveyResultsForStudy: {
    path: '/api/v1/survey-results',
    djangoRouteName: 'survey_results_for_study',
    djangoPath: '/api/v1/survey-results/<int:study_id>'
  },
  studyCoordinators: {
    path: '/api/v1/study-coordinators',
    djangoRouteName: 'study_coordinators',
    djangoPath: '/api/v1/study-coordinators'
  },
  studyDashboard: {
    path: '/api/v1/study-dashboard',
    djangoRouteName: 'study_dashboard',
    djangoPath: '/api/v1/study-dashboard'
  },
  studyGeneralData: {
    path: '/api/v1/study-general-data',
    djangoRouteName: 'study_general_data',
    djangoPath: '/api/v1/study-general-data/<int:pk>'
  },
  assignOrgAdminStudyTag: {
    path: '/api/v1/assign-org-admin-study-tag',
    djangoRouteName: 'assign-org-admin-study-tag',
    djangoPath: '/api/v1/assign-org-admin-study-tag/<int:study_id>'
  },
  unassignOrgAdminStudyTag: {
    path: '/api/v1/unassign-org-admin-study-tag',
    djangoRouteName: 'unassign-org-admin-study-tag',
    djangoPath: '/api/v1/unassign-org-admin-study-tag/<int:study_id>'
  },
  studyFeaturesData: {
    path: '/api/v1/study-features-data',
    djangoRouteName: 'study_features_data',
    djangoPath: '/api/v1/study-features-data/<int:pk>'
  },
  orgcodeAndImageList: {
    path: '/api/v1/orgcode-and-image-list',
    djangoRouteName: 'orgcode_and_image_list',
    djangoPath: '/api/v1/orgcode-and-image-list'
  },
  orgStudiesList: {
    path: '/api/v1/org-studies-list',
    djangoRouteName: 'org_studies_list',
    djangoPath: '/api/v1/org-studies-list'
  },
  newOrgStudiesList: {
    path: '/api/v1/organization',
    djangoRouteName: 'new_org_studies_list',
    djangoPath: '/api/v1/organization/<str:orgcode>/studies'
  },
  orgStudiesForCurators: {
    path: '/api/v1/org-studies-for-curators',
    djangoRouteName: 'org_studies_for_curators',
    djangoPath: '/api/v1/org-studies-for-curators'
  },
  orgUsersList: {
    path: '/api/v1/org-users-list',
    djangoRouteName: 'org_users_list',
    djangoPath: '/api/v1/org-users-list'
  },
  orgsUserCanManageList: {
    path: '/api/v1/orgs-user-can-manage',
    djangoRouteName: 'orgs_user_can_manage_list',
    djangoPath: '/api/v1/orgs-user-can-manage'
  },
  createNewStudyV1: {
    path: '/api/v1/study',
    djangoRouteName: 'create_new_study_v1',
    djangoPath: '/api/v1/study'
  },
  getAllowedOrgsAndDepartments: {
    path: '/api/v1/get-allowed-orgs-and-departments',
    djangoRouteName: 'get_allowed_orgs_and_departments',
    djangoPath: '/api/v1/get-allowed-orgs-and-departments'
  },
  studyIdUniqueCheck: {
    path: '/api/v1/study-id-unique-check',
    djangoRouteName: 'study_id_unique_check',
    djangoPath: '/api/v1/study-id-unique-check'
  },
  studyUpdate: {
    path: '/api/v1/study-update',
    djangoRouteName: 'study_update',
    djangoPath: '/api/v1/study-update/<int:pk>'
  },
  orgStudyView: {
    path: '/api/v1/org-study-view',
    djangoRouteName: 'org_study_view',
    djangoPath: '/api/v1/org-study-view'
  },
  orgUserActivityStream: {
    path: '/api/v1/org-user-activity-stream',
    djangoRouteName: 'org_user_activity_stream',
    djangoPath: '/api/v1/org-user-activity-stream'
  },
  orgStudyStats: {
    path: '/api/v1/org-study-stats',
    djangoRouteName: 'org_study_stats',
    djangoPath: '/api/v1/org-study-stats'
  },
  orgGeneralData: {
    path: '/api/v1/org-general-data',
    djangoRouteName: 'org_general_data',
    djangoPath: '/api/v1/org-general-data/<str:orgcode>'
  },
  galleriesForOrgStudy: {
    path: '/api/v1/galleries-for-org-study',
    djangoRouteName: 'galleries_for_org_study',
    djangoPath: '/api/v1/galleries-for-org-study'
  },
  participantsForOrgStudy: {
    path: '/api/v1/participants-for-org-study',
    djangoRouteName: 'participants_for_org_study',
    djangoPath: '/api/v1/participants-for-org-study'
  },
  galleriesForStudy: {
    path: '/api/v1/galleries-for-study',
    djangoRouteName: 'galleries_for_study',
    djangoPath: '/api/v1/galleries-for-study'
  },
  allOrgMembers: {
    path: '/api/v1/all-org-members',
    djangoRouteName: 'all_org_members',
    djangoPath: '/api/v1/all-org-members'
  },
  allUsers: {
    path: '/api/v1/all-users',
    djangoRouteName: 'all_users',
    djangoPath: '/api/v1/all-users'
  },
  createUser: {
    path: '/api/v1/create-user',
    djangoRouteName: 'create_user',
    djangoPath: '/api/v1/create-user'
  },
  manageUsers: {
    path: '/api/v1/manage-user-flags',
    djangoRouteName: 'manage_users',
    djangoPath: '/api/v1/manage-user-flags/<int:user_id>'
  },
  orgTourComplete: {
    path: '/api/v1/user/org-tour-complete',
    djangoRouteName: 'org_tour_complete',
    djangoPath: '/api/v1/user/org-tour-complete'
  },
  orgInvestigator: {
    path: '/api/v1/org-investigator',
    djangoRouteName: 'org_investigator',
    djangoPath: '/api/v1/org-investigator'
  },
  orgInvestigatorDetail: {
    path: '/api/v1/org-investigator',
    djangoRouteName: 'org_investigator_detail',
    djangoPath: '/api/v1/org-investigator/<str:public_id>'
  },
  orgInvestigatorStudies: {
    path: '/api/v1/org-investigator-studies',
    djangoRouteName: 'org_investigator_studies',
    djangoPath: '/api/v1/org-investigator-studies'
  },
  orgActivity: {
    path: '/api/v1/org-activity',
    djangoRouteName: 'org_activity',
    djangoPath: '/api/v1/org-activity'
  },
  orgStatistic: {
    path: '/api/v1/org-statistic',
    djangoRouteName: 'org_statistic',
    djangoPath: '/api/v1/org-statistic/<str:orgcode>'
  },
  orgUserActivity: {
    path: '/api/v1/org-user-activity',
    djangoRouteName: 'org_user_activity',
    djangoPath: '/api/v1/org-user-activity'
  },
  orgVoiceMessage: {
    path: '/api/v1/org-voicemessage',
    djangoRouteName: 'org_voice_message',
    djangoPath: '/api/v1/org-voicemessage'
  },
  orgVoiceMessagesData: {
    path: '/api/v1/org-voicemesseages-data',
    djangoRouteName: 'org_voice_messages_data',
    djangoPath: '/api/v1/org-voicemesseages-data'
  },
  notOrgUsers: {
    path: '/api/v1/org-not-org-users',
    djangoRouteName: 'not_org_users',
    djangoPath: '/api/v1/org-not-org-users'
  },
  participantsForRegistry: {
    path: '/api/v1/participants-for-registry',
    djangoRouteName: 'participants_for_registry',
    djangoPath: '/api/v1/participants-for-registry'
  },
  currentUserInfoExternal: {
    path: '/external-api/v1/current-user-info',
    djangoRouteName: 'current_user_info_external',
    djangoPath: '/external-api/v1/current-user-info'
  },
  studyVoiceMessages: {
    path: '/api/v1/study-voicemessages',
    djangoRouteName: 'study_voice_messages',
    djangoPath: '/api/v1/study-voicemessages'
  },
  studyVoiceMessagesSettings: {
    path: '/api/v1/study-voicemessages-settings',
    djangoRouteName: 'study_voice_messages_settings',
    djangoPath: '/api/v1/study-voicemessages-settings'
  },
  downloadFile: {
    path: '/api/v1/download',
    djangoRouteName: 'download_file',
    djangoPath: '/api/v1/download/<str:uid>'
  },
  orgReportsSignupsNotSeen: {
    path: '/api/v1/org-reports/signups-not-seen',
    djangoRouteName: 'org_reports_signups_not_seen',
    djangoPath: '/api/v1/org-reports/signups-not-seen'
  },
  orgReportsSignupsForFutureResearch: {
    path: '/api/v1/org-reports/signups-for-future-research',
    djangoRouteName: 'org_reports_signups_for_future_research',
    djangoPath: '/api/v1/org-reports/signups-for-future-research'
  },
  orgReportsSignupsWithoutTeam: {
    path: '/api/v1/org-reports/signups-without-team',
    djangoRouteName: 'org_reports_signups_without_team',
    djangoPath: '/api/v1/org-reports/signups-without-team'
  },
  orgStudyJoinRequestList: {
    path: '/api/v1/org-study-join-request-list',
    djangoRouteName: 'org_study_join_request_list',
    djangoPath: '/api/v1/org-study-join-request-list'
  },
  orgSignups: {
    path: '/api/v1/org-signups',
    djangoRouteName: 'org_signups',
    djangoPath: '/api/v1/org-signups'
  },
  orgGallery: {
    path: '/api/v1/org-gallery-stats',
    djangoRouteName: 'org_gallery',
    djangoPath: '/api/v1/org-gallery-stats'
  },
  orgGalleryHelpline: {
    path: '/api/v1/org-gallery-helpline',
    djangoRouteName: 'org_gallery_helpline',
    djangoPath: '/api/v1/org-gallery-helpline'
  },
  orgGallerySearches: {
    path: '/api/v1/org-gallery-searches',
    djangoRouteName: 'org_gallery_searches',
    djangoPath: '/api/v1/org-gallery-searches'
  },
  orgGallerySettings: {
    path: '/api/v1/gallery-settings',
    djangoRouteName: 'org_gallery_settings',
    djangoPath: '/api/v1/gallery-settings/<int:id>'
  },
  orgGalleryInfo: {
    path: '/public-api/v1/gallery-settings',
    djangoRouteName: 'org_gallery_info',
    djangoPath: '/public-api/v1/gallery-settings/<url_name>'
  },
  megastudySignups: {
    path: '/api/v1/megastudy-site-studies-signups',
    djangoRouteName: 'megastudy_signups',
    djangoPath: '/api/v1/megastudy-site-studies-signups'
  },
  megastudyUsers: {
    path: '/api/v1/megastudy-users',
    djangoRouteName: 'megastudy_users',
    djangoPath: '/api/v1/megastudy-users'
  },
  megastudyStudysiteUsers: {
    path: '/api/v1/megastudy-site-studies-users',
    djangoRouteName: 'megastudy_studysite_users',
    djangoPath: '/api/v1/megastudy-site-studies-users'
  },
  megastudyDashboard: {
    path: '/api/v1/megastudy-dashboard',
    djangoRouteName: 'megastudy_dashboard',
    djangoPath: '/api/v1/megastudy-dashboard/<int:id>'
  },
  megastudyUserActivity: {
    path: '/api/v1/megastudy-user-activity',
    djangoRouteName: 'megastudy_user_activity',
    djangoPath: '/api/v1/megastudy-user-activity'
  },
  orgOverview: {
    path: '/api/v1/org-overview',
    djangoRouteName: 'org_overview',
    djangoPath: '/api/v1/org-overview'
  },
  orgReservedNumbers: {
    path: '/api/v1/org-reserved-numbers',
    djangoRouteName: 'org_reserved_numbers',
    djangoPath: '/api/v1/org-reserved-numbers'
  },
  megastudySiteStudies: {
    path: '/api/v1/megastudy-site-studies',
    djangoRouteName: 'megastudy_site_studies',
    djangoPath: '/api/v1/megastudy-site-studies'
  },
  megastudySiteAnalytics: {
    path: '/api/v1/megastudy-site-analytics',
    djangoRouteName: 'megastudy_site_analytics',
    djangoPath: '/api/v1/megastudy-site-analytics/<str:uid>'
  },
  megastudyGeneralData: {
    path: '/api/v1/megastudy-general-data',
    djangoRouteName: 'megastudy_general_data',
    djangoPath: '/api/v1/megastudy-general-data/<int:pk>'
  },
  megastudyAnalytics: {
    path: '/api/v1/megastudy-analytics',
    djangoRouteName: 'megastudy_analytics',
    djangoPath: '/api/v1/megastudy-analytics/<int:id>'
  },
  orgSitepage: {
    path: '/api/v1/org-sitepage',
    djangoRouteName: 'org_sitepage',
    djangoPath: '/api/v1/org-sitepage'
  },
  orgStudyJoinRequestReview: {
    path: '/api/v1/org-study-join-request-review',
    djangoRouteName: 'org_study_join_request_review',
    djangoPath: '/api/v1/org-study-join-request-review/<int:pk>'
  },
  studyReview: {
    path: '/api/v1/study-review',
    djangoRouteName: 'study_review',
    djangoPath: '/api/v1/study-review/<int:study_id>'
  },
  organizationSettings: {
    path: '/api/v1/org-settings',
    djangoRouteName: 'organization_settings',
    djangoPath: '/api/v1/org-settings/<str:orgcode>'
  },
  gptSpKeywordsDesc: {
    path: '/api/v1/gpt/keywords-sp-description',
    djangoRouteName: 'gpt_sp_keywords_desc',
    djangoPath: '/api/v1/gpt/keywords-sp-description'
  },
  gptByContext: {
    path: '/api/v1/gpt/by-context',
    djangoRouteName: 'gpt_by_context',
    djangoPath: '/api/v1/gpt/by-context'
  },
  gptTextTags: {
    path: '/api/v1/gpt/text-tags',
    djangoRouteName: 'gpt_text_tags',
    djangoPath: '/api/v1/gpt/text-tags'
  },
  gptDescGsc: {
    path: '/api/v1/gpt/desc-gsc',
    djangoRouteName: 'gpt_desc_gsc',
    djangoPath: '/api/v1/gpt/desc-gsc'
  },
  gptKeywordImages: {
    path: '/api/v1/gpt/assign-banners',
    djangoRouteName: 'gpt_keyword_images',
    djangoPath: '/api/v1/gpt/assign-banners'
  },
  clinicalTrialsData: {
    path: '/api/v1/clinicaltrialsdata',
    djangoRouteName: 'clinical_trials_data',
    djangoPath: '/api/v1/clinicaltrialsdata'
  },
  clinicalTrialsUpdate: {
    path: '/api/v1/clinicaltrialsupdate',
    djangoRouteName: 'clinical_trials_update',
    djangoPath: '/api/v1/clinicaltrialsupdate'
  },
  shutterstockSearch: {
    path: '/api/v1/shutterstock/search',
    djangoRouteName: 'shutterstock_search',
    djangoPath: '/api/v1/shutterstock/search'
  },
  orgAnalytics: {
    path: '/api/v1/org-analytics',
    djangoRouteName: 'org_analytics',
    djangoPath: '/api/v1/org-analytics'
  },
  orgAnalyticsStudyDetail: {
    path: '/api/v1/org-analytics/study-detail',
    djangoRouteName: 'org_analytics_study_detail',
    djangoPath: '/api/v1/org-analytics/study-detail'
  },
  studySubmitToReview: {
    path: '/api/v1/submit-study-to-review',
    djangoRouteName: 'study-submit-to-review',
    djangoPath: '/api/v1/submit-study-to-review/<int:study_id>'
  },
  studyPublishValidations: {
    path: '/api/v1/study-publish-validations',
    djangoRouteName: 'study-publish-validations',
    djangoPath: '/api/v1/study-publish-validations/<int:study_id>'
  },
  studypagePreview: {
    path: '/api/v1/studypage-preview',
    djangoRouteName: 'studypage_preview',
    djangoPath: '/api/v1/studypage-preview/<int:pk>'
  },
  bulkSmsSending: {
    path: '/api/v1/bulk-sms-sending',
    djangoRouteName: 'bulk_sms_sending',
    djangoPath: '/api/v1/bulk-sms-sending/<int:study_id>'
  },
  investigatorRoles: {
    path: '/api/v1/investigator-roles',
    djangoRouteName: 'investigator_roles',
    djangoPath: '/api/v1/investigator-roles'
  },
  getCsrf: {
    path: '/api/v1/get-csrf',
    djangoRouteName: 'get_csrf',
    djangoPath: '/api/v1/get-csrf'
  },
  messageView: {
    path: '/api/v1/participant-area/messages',
    djangoRouteName: 'message_view',
    djangoPath: '/api/v1/participant-area/messages/<int:pk>'
  },
  getVerifCode: {
    path: '/api/v1/participant-area/get-code',
    djangoRouteName: 'get_verif_code',
    djangoPath: '/api/v1/participant-area/get-code'
  },
  verifyCode: {
    path: '/api/v1/participant-area/verify-code',
    djangoRouteName: 'verify_code',
    djangoPath: '/api/v1/participant-area/verify-code'
  },
  messageSend: {
    path: '/api/v1/participant-area/message-send',
    djangoRouteName: 'message_send',
    djangoPath: '/api/v1/participant-area/message-send'
  },
  messagesByStudy: {
    path: '/api/v1/participant-area/messages-by-study',
    djangoRouteName: 'messages_by_study',
    djangoPath: '/api/v1/participant-area/messages-by-study'
  },
  orgActivityTypes: {
    path: '/api/v1/org-activity-types',
    djangoRouteName: 'org_activity_types',
    djangoPath: '/api/v1/org-activity-types'
  },
  participantLogout: {
    path: '/api/v1/participant-area/logout',
    djangoRouteName: 'participant_logout',
    djangoPath: '/api/v1/participant-area/logout'
  },
  participantForRegistryList: {
    path: '/api/v1/participant-for-registry-list',
    djangoRouteName: 'participant_for_registry_list',
    djangoPath: '/api/v1/participant-for-registry-list'
  },
  participantDataByUser: {
    path: '/api/v1/participant-data-by-user',
    djangoRouteName: 'participant_data_by_user',
    djangoPath: '/api/v1/participant-data-by-user'
  },
  participantPhoneNumbers: {
    path: '/api/v1/participant-phone-numbers',
    djangoRouteName: 'participant_phone_numbers',
    djangoPath: '/api/v1/participant-phone-numbers/<str:p_id>'
  },
  participantDuplicate: {
    path: '/api/v1/duplicated-participant-records',
    djangoRouteName: 'participant-duplicate',
    djangoPath: '/api/v1/duplicated-participant-records/<int:study_id>'
  },
  snoozedSignups: {
    path: '/api/v1/snoozed-signups',
    djangoRouteName: 'snoozed-signups',
    djangoPath: '/api/v1/snoozed-signups/<int:study_id>/'
  },
  orgMemberProfile: {
    path: '/api/v1/org-member',
    djangoRouteName: 'org_member_profile',
    djangoPath: '/api/v1/org-member/<int:pk>'
  },
  studyForOrgMemberList: {
    path: '/api/v1/org-member-studies',
    djangoRouteName: 'study_for_org_member_list',
    djangoPath: '/api/v1/org-member-studies'
  },
  studyCheckerList: {
    path: '/api/v1/study-checker-list',
    djangoRouteName: 'study_checker_list',
    djangoPath: '/api/v1/study-checker-list'
  },
  schemaExternalUi: {
    path: '/external-swagger/',
    djangoRouteName: 'schema-external-ui',
    djangoPath: '/external-swagger/'
  },
  orgInviteCampaignsStudiesListData: {
    path: '/api/v1/org-invite-campaigns',
    djangoRouteName: 'org-invite-campaigns-studies-list-data',
    djangoPath: '/api/v1/org-invite-campaigns/<str:orgcode>/studies-list'
  },
  snoozedConfigIsActive: {
    path: '/api/v1/snoozed-config',
    djangoRouteName: 'snoozed_config-is-active',
    djangoPath: '/api/v1/snoozed-config/<str:public_id>/is_active'
  },
  gallerySearchCategories: {
    path: '/api/v1/gallery-search-categories',
    djangoRouteName: 'gallery_search_categories',
    djangoPath: '/api/v1/gallery-search-categories/<str:orgcode>'
  },
  studyLocationList: {
    path: '/api/v1/study-location',
    djangoRouteName: 'study_location_list',
    djangoPath: '/api/v1/study-location'
  },
  avmaValidate: {
    path: '/api/v1/avma-validate',
    djangoRouteName: 'avma_validate',
    djangoPath: '/api/v1/avma-validate/<int:study_id>'
  },
  avmaHistory: {
    path: '/api/v1/avma-history',
    djangoRouteName: 'avma_history',
    djangoPath: '/api/v1/avma-history/<int:study_id>'
  },
  newAvmaStudyCreate: {
    path: '/api/v1/avma-study',
    djangoRouteName: 'new_avma_study_create',
    djangoPath: '/api/v1/avma-study'
  },
  avmaSelections: {
    path: '/api/v1/avma-selections',
    djangoRouteName: 'avma_selections',
    djangoPath: '/api/v1/avma-selections'
  },
  studyNoteHistory: {
    path: '/api/v1/study-note-history',
    djangoRouteName: 'study_note_history',
    djangoPath: '/api/v1/study-note-history'
  },
  getParticipantAreaLink: {
    path: '/api/v1/get-participant-area-link',
    djangoRouteName: 'get_participant_area_link',
    djangoPath: '/api/v1/get-participant-area-link'
  },
  studyLatestVersionAvma: {
    path: '/api/v1/study-latest-version-avma',
    djangoRouteName: 'study_latest_version_avma',
    djangoPath: '/api/v1/study-latest-version-avma/<str:public_id>'
  },
  studyLatestPdfVersion: {
    path: '/api/v1/study-latest-version-pdf-avma',
    djangoRouteName: 'study_latest_pdf_version',
    djangoPath: '/api/v1/study-latest-version-pdf-avma/<str:public_id>'
  },
  avmaPublishedDiff: {
    path: '/api/v1/avma-published-diff',
    djangoRouteName: 'avma_published_diff',
    djangoPath: '/api/v1/avma-published-diff/<int:study_id>'
  },
  studyFieldsList: {
    path: '/api/v1/study-fields',
    djangoRouteName: 'study_fields_list',
    djangoPath: '/api/v1/study-fields/<int:study_id>'
  },
  participant2FaPrefLang: {
    path: '/api/v1/partipant-2fa-pref-lang',
    djangoRouteName: 'participant_2fa_pref_lang',
    djangoPath: '/api/v1/partipant-2fa-pref-lang/<str:token>'
  },
  avmaAnalytics: {
    path: '/api/v1/avma-analytics',
    djangoRouteName: 'avma_analytics',
    djangoPath: '/api/v1/avma-analytics'
  },
  studyPageContentLang: {
    path: '/api/v1/studypage-v3-content-langs',
    djangoRouteName: 'study-page-content-lang',
    djangoPath: '/api/v1/studypage-v3-content-langs/<int:study_id>'
  },
  studyLatestVersion: {
    path: '/api/v1/studycontent-latest-version',
    djangoRouteName: 'study_latest_version',
    djangoPath: '/api/v1/studycontent-latest-version/<str:public_id>/<str:lang>'
  },
  studyLatestVersionDraft: {
    path: '/api/v1/studycontent-latest-version',
    djangoRouteName: 'study_latest_version_draft',
    djangoPath: '/api/v1/studycontent-latest-version/<str:public_id>/<str:lang>/<str:draft_code>'
  },
  studyLatestDraftVersion: {
    path: '/api/v1/studycontent-draft-version',
    djangoRouteName: 'study_latest_draft_version',
    djangoPath: '/api/v1/studycontent-draft-version/<int:study_id>/<str:lang>'
  },
  studyLatestVersionDraftByKey: {
    path: '/api/v1/studycontent-draft-version-by-key',
    djangoRouteName: 'study_latest_version_draft_by_key',
    djangoPath: '/api/v1/studycontent-draft-version-by-key/<str:draft_key>/<str:lang>'
  },
  studyPageContentImagesV3: {
    path: '/api/v1/studypage-content-images-v3',
    djangoRouteName: 'study-page-content-images-v3',
    djangoPath: '/api/v1/studypage-content-images-v3/<int:study_id>/<str:lang>'
  },
  participantAccountPasswordSet: {
    path: '/edc_password_set',
    djangoRouteName: 'participant_account_password_set',
    djangoPath: '/edc_password_set/<uidb64>/<token>'
  },
  teamscopeParticipantForms: {
    path: '/api/v1/participant-edc',
    djangoRouteName: 'teamscope_participant_forms',
    djangoPath: '/api/v1/participant-edc/<int:participant_id>/form-entries'
  },
  teamscopeParticipantDashboardForms: {
    path: '/api/v1/participant-dashboard',
    djangoRouteName: 'teamscope_participant_dashboard_forms',
    djangoPath: '/api/v1/participant-dashboard/<str:public_id>/form-entries'
  },
  preScreenFormReviewed: {
    path: '/api/v1/participant-edc',
    djangoRouteName: 'pre_screen_form_reviewed',
    djangoPath: '/api/v1/participant-edc/<int:participant_id>/pre-screen-form-reviewed'
  },
  createParticipantCase: {
    path: '/api/v1/participant-edc',
    djangoRouteName: 'create_participant_case',
    djangoPath: '/api/v1/participant-edc/<int:participant_id>/create-participant-case'
  },
  deleteUserFromAllOrgStudies: {
    path: '/api/v1/delete-user-from-all-org-studies',
    djangoRouteName: 'delete_user_from_all_org_studies',
    djangoPath: '/api/v1/delete-user-from-all-org-studies/<int:user_id>/<str:orgcode>'
  },
  orgMemberRemove: {
    path: '/api/v1/org-member-remove',
    djangoRouteName: 'org_member_remove',
    djangoPath: '/api/v1/org-member-remove'
  },
  magicLinkLogin: {
    path: '/magic-link',
    djangoRouteName: 'magic_link_login',
    djangoPath: '/magic-link/<str:magic_link_uid>'
  },
  externalApiMagicLink: {
    path: '/external-api/teamscope/v1/get-magic-link-for-user/',
    djangoRouteName: 'external_api_magic_link',
    djangoPath: '/external-api/teamscope/v1/get-magic-link-for-user/'
  },
  activityRecordsByEmailList: {
    path: '/api/v1/activity-records-by-email',
    djangoRouteName: 'activity_records_by_email-list',
    djangoPath: '/api/v1/activity-records-by-email'
  },
  patchMessageAction: {
    path: '/api/v1/message-action',
    djangoRouteName: 'patch-message-action',
    djangoPath: '/api/v1/message-action/<str:message_action_uid>'
  },
  makeParticipantIneligible: {
    path: '/api/v1/make-participant-ineligible',
    djangoRouteName: 'make_participant_ineligible',
    djangoPath: '/api/v1/make-participant-ineligible/<int:participant_id>'
  },
  orgCustomFieldsExport: {
    path: '/api/v1/organization',
    djangoRouteName: 'org_custom_fields_export',
    djangoPath: '/api/v1/organization/<str:orgcode>/custom-fields/export'
  },
  studyDashboardList: {
    path: '/api/v1/study-dashboard-list',
    djangoRouteName: 'study_dashboard_list',
    djangoPath: '/api/v1/study-dashboard-list'
  },
  megastudyDashboardList: {
    path: '/api/v1/megastudy-dashboard-list',
    djangoRouteName: 'megastudy_dashboard_list',
    djangoPath: '/api/v1/megastudy-dashboard-list'
  },
  participantWithdrawn: {
    path: '/api/v1/participant',
    djangoRouteName: 'participant-withdrawn',
    djangoPath: '/api/v1/participant/<int:id>/enrolled-withdrawn/'
  },
  spPocketGetToken: {
    path: '/api/v1/sp-pocket/authorize/get-token',
    djangoRouteName: 'sp_pocket_get_token',
    djangoPath: '/api/v1/sp-pocket/authorize/get-token'
  },
  spPocketRevokeToken: {
    path: '/api/v1/sp-pocket/authorize/revoke-token',
    djangoRouteName: 'sp_pocket_revoke_token',
    djangoPath: '/api/v1/sp-pocket/authorize/revoke-token'
  },
  spPocketCurrentUserInfo: {
    path: '/api/v1/sp-pocket/current-user-info',
    djangoRouteName: 'sp_pocket_current_user_info',
    djangoPath: '/api/v1/sp-pocket/current-user-info'
  },
  spPocketStudyDashboardList: {
    path: '/api/v1/sp-pocket/study-dashboard-list',
    djangoRouteName: 'sp_pocket_study_dashboard_list',
    djangoPath: '/api/v1/sp-pocket/study-dashboard-list'
  },
  spPocketStudyTaskList: {
    path: '/api/v1/sp-pocket/study-task',
    djangoRouteName: 'sp_pocket_study_task-list',
    djangoPath: '/api/v1/sp-pocket/study-task'
  },
  spPocketStudyTaskDetail: {
    path: '/api/v1/sp-pocket/study-task',
    djangoRouteName: 'sp_pocket_study_task-detail',
    djangoPath: '/api/v1/sp-pocket/study-task/<pk>'
  },
  spPocketStudyBookmarkList: {
    path: '/api/v1/sp-pocket/study-bookmark',
    djangoRouteName: 'sp-pocket-study-bookmark-list',
    djangoPath: '/api/v1/sp-pocket/study-bookmark'
  },
  spPocketStudyBookmarkDetail: {
    path: '/api/v1/sp-pocket/study-bookmark',
    djangoRouteName: 'sp-pocket-study-bookmark-detail',
    djangoPath: '/api/v1/sp-pocket/study-bookmark/<id>'
  },
  spPocketStudyBookmarkArchive: {
    path: '/api/v1/sp-pocket/study-bookmark',
    djangoRouteName: 'sp-pocket-study-bookmark-archive',
    djangoPath: '/api/v1/sp-pocket/study-bookmark/<id>/archive'
  },
  studyConsentFiles: {
    path: '/api/v1/study-consent-files',
    djangoRouteName: 'study_consent_files',
    djangoPath: '/api/v1/study-consent-files'
  },
  debugOrgInviteInvitations: {
    path: '/api/v1/org-invite-invitations',
    djangoRouteName: 'debug_org_invite_invitations',
    djangoPath: '/api/v1/org-invite-invitations/<int:internal_id>'
  },
  studyTeamNotificationsSettings: {
    path: '/api/v1/study-team-notifications-settings',
    djangoRouteName: 'study_team_notifications_settings',
    djangoPath: '/api/v1/study-team-notifications-settings/<int:study_id>/<str:notification_reason>/'
  },
  studyConsentTemplates: {
    path: '/api/v1/study-consent-templates',
    djangoRouteName: 'study-consent-templates',
    djangoPath: '/api/v1/study-consent-templates'
  },
  processSignature: {
    path: '/api/v1/process-signature',
    djangoRouteName: 'process-signature',
    djangoPath: '/api/v1/process-signature'
  },
  teamscopeUserMigration: {
    path: '/external-api/teamscope/v1/user-migration',
    djangoRouteName: 'teamscope_user_migration',
    djangoPath: '/external-api/teamscope/v1/user-migration'
  },
  externalOrgStudies: {
    path: '/external-api/v1/org-studies',
    djangoRouteName: 'external_org_studies',
    djangoPath: '/external-api/v1/org-studies'
  },
  mobileApiOrgadminIntstudycoordinatorsList: {
    path: '/api/v1/mobile-api/orgadmin-int-study-coordinators',
    djangoRouteName: 'mobile_api_orgadmin_intstudycoordinators-list',
    djangoPath: '/api/v1/mobile-api/orgadmin-int-study-coordinators'
  },
  mobileApiOrgadminIntstudycoordinatorsDetail: {
    path: '/api/v1/mobile-api/orgadmin-int-study-coordinators',
    djangoRouteName: 'mobile_api_orgadmin_intstudycoordinators-detail',
    djangoPath: '/api/v1/mobile-api/orgadmin-int-study-coordinators/<pk>'
  },
  mobileApiUser: {
    path: '/api/v1/mobile-api/user/',
    djangoRouteName: 'mobile_api_user',
    djangoPath: '/api/v1/mobile-api/user/'
  },
  mobileApiUserProfilepicUpdate: {
    path: '/api/v1/mobile-api/user/profilepic-update/',
    djangoRouteName: 'mobile_api_user_profilepic_update',
    djangoPath: '/api/v1/mobile-api/user/profilepic-update/'
  },
  mobileApiUserRevokeToken: {
    path: '/api/v1/mobile-api/auth/revoke-token/',
    djangoRouteName: 'mobile_api_user_revoke_token',
    djangoPath: '/api/v1/mobile-api/auth/revoke-token/'
  },
  mobileApiUserGetTokenById: {
    path: '/api/v1/mobile-api/auth/get-token-by-id/',
    djangoRouteName: 'mobile_api_user_get_token_by_id',
    djangoPath: '/api/v1/mobile-api/auth/get-token-by-id/'
  },
  registerMobileDevice: {
    path: '/api/v1/mobile-api/auth/register-mobile-device/',
    djangoRouteName: 'register_mobile_device',
    djangoPath: '/api/v1/mobile-api/auth/register-mobile-device/'
  },
  idpList: {
    path: '/api/v1/mobile-api/auth/idp-list/',
    djangoRouteName: 'idp_list',
    djangoPath: '/api/v1/mobile-api/auth/idp-list/'
  },
  mobileOrgUsersList: {
    path: '/api/v1/mobile-api/org-users-list',
    djangoRouteName: 'mobile_org_users_list',
    djangoPath: '/api/v1/mobile-api/org-users-list'
  },
  mobileApiStudyList: {
    path: '/api/v1/mobile-api/study/',
    djangoRouteName: 'mobile_api_study_list',
    djangoPath: '/api/v1/mobile-api/study/'
  },
  mobileStudyUpdate: {
    path: '/api/v1/mobile-api/study-update',
    djangoRouteName: 'mobile_study_update',
    djangoPath: '/api/v1/mobile-api/study-update/<int:pk>'
  },
  mobileApiStudyFavoriteList: {
    path: '/api/v1/mobile-api/study/favorite',
    djangoRouteName: 'mobile_api_study_favorite_list',
    djangoPath: '/api/v1/mobile-api/study/favorite/<int:study_id>/'
  },
  mobileApiStudyDetail: {
    path: '/api/v1/mobile-api/study',
    djangoRouteName: 'mobile_api_study_detail',
    djangoPath: '/api/v1/mobile-api/study/<int:pk>/'
  },
  mobileOrgsUserCanManageList: {
    path: '/api/v1/mobile-api/orgs-user-can-manage',
    djangoRouteName: 'mobile_orgs_user_can_manage_list',
    djangoPath: '/api/v1/mobile-api/orgs-user-can-manage'
  },
  mobileOrgStudiesList: {
    path: '/api/v1/mobile-api/org-studies-list',
    djangoRouteName: 'mobile_org_studies_list',
    djangoPath: '/api/v1/mobile-api/org-studies-list'
  },
  mobileOrgStudyView: {
    path: '/api/v1/mobile-api/org-study-view',
    djangoRouteName: 'mobile_org_study_view',
    djangoPath: '/api/v1/mobile-api/org-study-view'
  },
  mobileApiStudyCreate: {
    path: '/api/v1/mobile-api/study-create',
    djangoRouteName: 'mobile_api_study_create',
    djangoPath: '/api/v1/mobile-api/study-create'
  },
  mobileApiGetAllowedOrgAndDept: {
    path: '/api/v1/mobile-api/get-allowed-org-and-dept',
    djangoRouteName: 'mobile_api_get_allowed_org_and_dept',
    djangoPath: '/api/v1/mobile-api/get-allowed-org-and-dept'
  },
  mobileStudyIqUniqueCheck: {
    path: '/api/v1/mobile-api/study-iq-unique-check',
    djangoRouteName: 'mobile_study_iq_unique_check',
    djangoPath: '/api/v1/mobile-api/study-iq-unique-check'
  },
  mobileStudyGeneralData: {
    path: '/api/v1/mobile-api/study-general-data',
    djangoRouteName: 'mobile_study_general_data',
    djangoPath: '/api/v1/mobile-api/study-general-data/<int:pk>'
  },
  mobileOrgActivity: {
    path: '/api/v1/mobile-api/org-activity',
    djangoRouteName: 'mobile_org_activity',
    djangoPath: '/api/v1/mobile-api/org-activity'
  },
  mobileOrgActivityTypes: {
    path: '/api/v1/mobile-api/org-activity-types',
    djangoRouteName: 'mobile_org_activity_types',
    djangoPath: '/api/v1/mobile-api/org-activity-types'
  },
  mobileOrgMemberProfile: {
    path: '/api/v1/mobile-api/org-member',
    djangoRouteName: 'mobile_org_member_profile',
    djangoPath: '/api/v1/mobile-api/org-member/<int:pk>'
  },
  mobileOrgAdminPlatformInviteEndpoint: {
    path: '/api/v1/mobile-api/platform_invite',
    djangoRouteName: 'mobile-org-admin-platform-invite-endpoint',
    djangoPath: '/api/v1/mobile-api/platform_invite/<str:orgcode>'
  },
  mobileOrgStatistic: {
    path: '/api/v1/mobile-api/org-statistic',
    djangoRouteName: 'mobile_org_statistic',
    djangoPath: '/api/v1/mobile-api/org-statistic/<str:orgcode>'
  },
  mobileAvmaStudyCreate: {
    path: '/api/v1/mobile-api/avma-study',
    djangoRouteName: 'mobile_avma_study_create',
    djangoPath: '/api/v1/mobile-api/avma-study'
  },
  mobileApiParticipantList: {
    path: '/api/v1/mobile-api/participant/',
    djangoRouteName: 'mobile_api_participant_list',
    djangoPath: '/api/v1/mobile-api/participant/'
  },
  mobileApiParticipantDetail: {
    path: '/api/v1/mobile-api/participant',
    djangoRouteName: 'mobile_api_participant_detail',
    djangoPath: '/api/v1/mobile-api/participant/<int:pk>/'
  },
  mobileApiParticipantStatusGroup: {
    path: '/api/v1/mobile-api/participant-status-group',
    djangoRouteName: 'mobile_api_participant_status_group',
    djangoPath: '/api/v1/mobile-api/participant-status-group'
  },
  mobileApiParticipantObject: {
    path: '/api/v1/mobile-api/participant-object',
    djangoRouteName: 'mobile_api_participant_object',
    djangoPath: '/api/v1/mobile-api/participant-object/<int:pk>/'
  },
  mobileOrgUserRole: {
    path: '/api/v1/mobile-api/org-user-role',
    djangoRouteName: 'mobile_org_user_role',
    djangoPath: '/api/v1/mobile-api/org-user-role'
  },
  mobileApiStudyNote: {
    path: '/api/v1/mobile-api/study-note/',
    djangoRouteName: 'mobile_api_study_note',
    djangoPath: '/api/v1/mobile-api/study-note/'
  },
  mobileApiSmsLead: {
    path: '/api/v1/mobile-api/sms-lead/',
    djangoRouteName: 'mobile_api_sms_lead',
    djangoPath: '/api/v1/mobile-api/sms-lead/'
  },
  mobileApiSendSms: {
    path: '/api/v1/mobile-api/send-sms/',
    djangoRouteName: 'mobile_api_send_sms',
    djangoPath: '/api/v1/mobile-api/send-sms/'
  },
  mobileSmsActivity: {
    path: '/api/v1/mobile-api/sms-activity/',
    djangoRouteName: 'mobile_sms_activity',
    djangoPath: '/api/v1/mobile-api/sms-activity/'
  },
  mobileApiFcmAddToken: {
    path: '/api/v1/mobile-api/fcm/add-messaging-token-to-group/',
    djangoRouteName: 'mobile_api_fcm_add_token',
    djangoPath: '/api/v1/mobile-api/fcm/add-messaging-token-to-group/'
  },
  megastudyList: {
    path: '/api/v1/spadmin/megastudy',
    djangoRouteName: 'megastudy-list',
    djangoPath: '/api/v1/spadmin/megastudy'
  },
  megastudyDetail: {
    path: '/api/v1/spadmin/megastudy',
    djangoRouteName: 'megastudy-detail',
    djangoPath: '/api/v1/spadmin/megastudy/<pk>'
  },
  spaMegastudyUsersList: {
    path: '/api/v1/spadmin/megastudy',
    djangoRouteName: 'spa-megastudy-users-list',
    djangoPath: '/api/v1/spadmin/megastudy/<int:megastudy_id>/users'
  },
  spaMegastudyUsersDetail: {
    path: '/api/v1/spadmin/megastudy-user',
    djangoRouteName: 'spa-megastudy-users-detail',
    djangoPath: '/api/v1/spadmin/megastudy-user/<pk>'
  },
  unmatchedIncomingSmsList: {
    path: '/api/v1/unmatched-incoming-sms-list',
    djangoRouteName: 'unmatched_incoming_sms_list',
    djangoPath: '/api/v1/unmatched-incoming-sms-list'
  },
  realtimeParticipants: {
    path: '/api/v1/realtime-participants',
    djangoRouteName: 'realtime_participants',
    djangoPath: '/api/v1/realtime-participants'
  },
  spaOrganizationList: {
    path: '/api/v1/spadmin/orgs',
    djangoRouteName: 'spa-organization-list',
    djangoPath: '/api/v1/spadmin/orgs'
  },
  spaUserList: {
    path: '/api/v1/spadmin/users',
    djangoRouteName: 'spa-user-list',
    djangoPath: '/api/v1/spadmin/users'
  },
  spaVisitList: {
    path: '/api/v1/spadmin/visits',
    djangoRouteName: 'spa-visit-list',
    djangoPath: '/api/v1/spadmin/visits'
  },
  spaResearchersList: {
    path: '/api/v1/spadmin/researcher-users',
    djangoRouteName: 'spa-researchers-list',
    djangoPath: '/api/v1/spadmin/researcher-users'
  },
  getStudyMedia: {
    path: '/api/v1/get-study-media',
    djangoRouteName: 'get_study_media',
    djangoPath: '/api/v1/get-study-media'
  },
  setStudyBanner: {
    path: '/api/v1/set-study-banner',
    djangoRouteName: 'set_study_banner',
    djangoPath: '/api/v1/set-study-banner'
  },
  spaSmsFailed: {
    path: '/api/v1/spadmin/sms-failed',
    djangoRouteName: 'spa-sms-failed',
    djangoPath: '/api/v1/spadmin/sms-failed'
  },
  spaStudyManage: {
    path: '/api/v1/spadmin/study-manage',
    djangoRouteName: 'spa-study-manage',
    djangoPath: '/api/v1/spadmin/study-manage/<str:study_id>'
  },
  spaActivityList: {
    path: '/api/v1/spadmin/activity-list',
    djangoRouteName: 'spa-activity-list',
    djangoPath: '/api/v1/spadmin/activity-list'
  },
  studyJsonFieldsView: {
    path: '/api/v1/spadmin/study-json-fields',
    djangoRouteName: 'study-json-fields-view',
    djangoPath: '/api/v1/spadmin/study-json-fields/<int:id>'
  },
  spaAllStudiesSearch: {
    path: '/api/v1/spadmin/all-studies',
    djangoRouteName: 'spa-all-studies-search',
    djangoPath: '/api/v1/spadmin/all-studies'
  },
  schemaSwaggerUi: {
    path: '/swagger/',
    djangoRouteName: 'schema-swagger-ui',
    djangoPath: '/swagger/'
  },
  schemaRedoc: {
    path: '/redoc/',
    djangoRouteName: 'schema-redoc',
    djangoPath: '/redoc/'
  },
  StaticPath: {
    path: '/static',
    djangoRouteName: '',
    djangoPath: '/static/<path>'
  },
  MediaPath: {
    path: '/media',
    djangoRouteName: '',
    djangoPath: '/media/<path>'
  },
  debugDebugDecisionTreeInit: {
    path: '/api/v1/debug/decision-tree-init',
    djangoRouteName: 'debug:debug_decision_tree_init',
    djangoPath: '/api/v1/debug/decision-tree-init/<int:participant_id>/'
  },
  debugDebugInitVisitReminderProcess: {
    path: '/api/v1/debug/init-visit-reminder-process/',
    djangoRouteName: 'debug:debug_init_visit_reminder_process',
    djangoPath: '/api/v1/debug/init-visit-reminder-process/'
  },
  debugDebugMarkdownToHtml: {
    path: '/api/v1/debug/markdown-to-html',
    djangoRouteName: 'debug:debug_markdown_to_html',
    djangoPath: '/api/v1/debug/markdown-to-html/<int:study_id>/'
  },
  debugThriveRandomization: {
    path: '/api/v1/debug/thrive-randomization',
    djangoRouteName: 'debug:thrive_randomization',
    djangoPath: '/api/v1/debug/thrive-randomization'
  },
  debugOrgVoiceMessageCreate: {
    path: '/api/v1/debug/org-voicemessage-create',
    djangoRouteName: 'debug:org_voice_message_create',
    djangoPath: '/api/v1/debug/org-voicemessage-create'
  },
  oauth2ProviderToken: {
    path: '/oauth/token/',
    djangoRouteName: 'oauth2_provider:token',
    djangoPath: '/oauth/token/'
  },
  oauth2ProviderRevokeToken: {
    path: '/oauth/revoke_token/',
    djangoRouteName: 'oauth2_provider:revoke-token',
    djangoPath: '/oauth/revoke_token/'
  },
  oauth2ProviderIntrospect: {
    path: '/oauth/introspect/',
    djangoRouteName: 'oauth2_provider:introspect',
    djangoPath: '/oauth/introspect/'
  },
  oauth2ProviderList: {
    path: '/oauth/applications/',
    djangoRouteName: 'oauth2_provider:list',
    djangoPath: '/oauth/applications/'
  },
  oauth2ProviderRegister: {
    path: '/oauth/applications/register/',
    djangoRouteName: 'oauth2_provider:register',
    djangoPath: '/oauth/applications/register/'
  },
  oauth2ProviderDetail: {
    path: '/oauth/applications',
    djangoRouteName: 'oauth2_provider:detail',
    djangoPath: '/oauth/applications/<pk>/'
  },
  oauth2ProviderDelete: {
    path: '/oauth/applications',
    djangoRouteName: 'oauth2_provider:delete',
    djangoPath: '/oauth/applications/<pk>/delete/'
  },
  oauth2ProviderUpdate: {
    path: '/oauth/applications',
    djangoRouteName: 'oauth2_provider:update',
    djangoPath: '/oauth/applications/<pk>/update/'
  },
  oauth2ProviderAuthorizedTokenList: {
    path: '/oauth/authorized_tokens/',
    djangoRouteName: 'oauth2_provider:authorized-token-list',
    djangoPath: '/oauth/authorized_tokens/'
  },
  oauth2ProviderAuthorizedTokenDelete: {
    path: '/oauth/authorized_tokens',
    djangoRouteName: 'oauth2_provider:authorized-token-delete',
    djangoPath: '/oauth/authorized_tokens/<pk>/delete/'
  },
  oauth2ProviderOidcConnectDiscoveryInfo: {
    path: '/oauth/\.well-known/openid-configuration/',
    djangoRouteName: 'oauth2_provider:oidc-connect-discovery-info',
    djangoPath: '/oauth/\.well-known/openid-configuration/'
  },
  oauth2ProviderJwksInfo: {
    path: '/oauth/\.well-known/jwks.json',
    djangoRouteName: 'oauth2_provider:jwks-info',
    djangoPath: '/oauth/\.well-known/jwks.json'
  },
  oauth2ProviderUserInfo: {
    path: '/oauth/userinfo/',
    djangoRouteName: 'oauth2_provider:user-info',
    djangoPath: '/oauth/userinfo/'
  },
  CommunitySample: {
    path: '/community-sample/',
    djangoRouteName: '',
    djangoPath: '/community-sample/'
  }
};
