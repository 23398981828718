<template>
  <div class="d-flex flex-wrap align-items-center">
    <label class="me-2" for="ageInput">{{ question }}</label>
    <input id="ageInput"
           v-model.number="ageInput"
           class="form-control d-inline-block w-auto"
           :max="max"
           :min="min"
           name="ageInput"
           type="number" />
  </div>
</template>

<script>
import {
  FieldNames, FilterKind, FilterValues, General, SearchFilterModel
} from '../../models';

export default {
  props: {
    ageFilters: {
      type: Array,
      required: true
    },
    question: {
      type: String,
      required: false,
      default: 'What\'s your age?'
    }
  },
  emits: ['update'],
  data() {
    return {
      min: 0,
      max: 150
    };
  },
  computed: {
    ageInput: {
      get() {
        // Extract value from already present age_min or age_max filter
        const alreadyPresentFilter = this.ageFilters.find(x => x.fieldName === FieldNames.ageMin || x.fieldName === FieldNames.ageMax);
        if (alreadyPresentFilter) {
          const regexResult = General.lteGteRegex.exec(alreadyPresentFilter.filterValue);
          return regexResult && regexResult[2] && parseInt(regexResult[2], 10);
        }

        return undefined;
      },
      set(newValue) {
        if (!newValue) {
          this.onValueChanged(newValue);
          return;
        }
        const parsed = parseInt(newValue, 10);
        if (Number.isNaN(parsed)) {
          newValue = null;
        } else if (newValue < this.min) {
          newValue = this.min;
        } else if (newValue > this.max) {
          newValue = this.max;
        }
        this.onValueChanged(newValue);
      }
    }
  },
  methods: {
    onValueChanged(newValue) {
      // Let's not overload the backend by doing a search every keypress ;-)
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.$emit('update', {
          filters: [
            new SearchFilterModel(FieldNames.ageMin, FilterKind.range, FilterValues.ltePrefix + newValue),
            new SearchFilterModel(FieldNames.ageMax, FilterKind.range, FilterValues.gtePrefix + newValue)
          ],
          needsRemoval: !newValue // Only when user clears/invalidates input, filters get removed
        });
      }, 300);
    }
  }
};
</script>
