<script setup>
import { DeviceSize, useResizeListener } from '@yuzulabs/lib-general';

const deviceSize = useResizeListener().currentDeviceSize;
</script>
<template>
  <div class="wrapper d-flex flex-column h-100">
    <h4 v-if="deviceSize >= DeviceSize.md" class="info-title">
      <slot name="title"></slot>
    </h4>
    <div v-if="deviceSize < DeviceSize.md" class="mb-2 h5-mob info-title">
      <slot name="title"></slot>
    </div>
    <div class="flex-grow-1 info-content" :class="[deviceSize >= DeviceSize.md ? 'body-16' : 'body-14']">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.wrapper {
  padding: 24px;
  border: 1px solid var(--sp-info-box-border);
  border-radius: 16px;
  background-color: var(--sp-info-box-bg);
  .info-title {
    color: var(--sp-info-box-title-color);
  }
  .info-content {
    color: var(--sp-info-box-content-color);

    :slotted(a) {
      color: var(--sp-info-box-content-color);
    }
    :slotted(.btn) {
      min-width: 204px;
    }
    :slotted(.tel-link) {
      text-decoration: none;
    }
    :slotted(img) {
      width: 112px;
      height: 112px;
    }
  }
}
</style>
