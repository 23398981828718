<script setup>

import { DeviceSize, useResizeListener } from '@yuzulabs/lib-general';
import { computed } from 'vue';

defineProps({
  bannerAction: {
    // Defines the name of the route the banner button should point to
    // When empty, no button is shown
    type: String,
    required: false
  }
});
const deviceSize = useResizeListener().currentDeviceSize;
const titleClass = computed(() => {
  if (deviceSize >= DeviceSize.md) {
    return 'banner-font-desktop';
  }
  return deviceSize < DeviceSize.sm ? 'h4-mob' : 'h2-mob';
});
</script>
<template>
  <div class="banner-wrapper" v-bind="$attrs">
    <div class="row">
      <div class="col">
        <div class="grey-800 mb-2 align-self-stretch" :class="titleClass">
          <slot name="title"></slot>
        </div>
      </div>
    </div>
    <div class="row">
      <div :class="{ 'col-md-7': $slots.side }" class="col-12">
        <div class="grey-800 align-self-stretch" :class="[deviceSize >= DeviceSize.md ? 'body-16' : 'body-14']">
          <slot name="content"></slot>
        </div>
        <router-link :class="[deviceSize >= DeviceSize.md ? 'limited-btn-width' : 'w-100']"
                     class="btn sp-btn-primary mt-3"
                     :to="{ name: bannerAction }">
          {{ $t('top.btnTitle') }}
        </router-link>
      </div>
      <!-- Side content, works good with InfoBox components -->
      <div v-if="$slots.side && deviceSize >= DeviceSize.md" class="col-md-5">
        <slot name="side"></slot>
      </div>
    </div>
    <div v-if="$slots.side && deviceSize < DeviceSize.md" class="row pt-3">
      <div class="col">
        <slot name="side"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.banner-wrapper {
  border-radius: var(--sp-banner-border-radius);
  background-color: var(--sp-banner-bg);
  padding: var(--sp-banner-padding);
  .limited-btn-width {
    width: 230px;
  }
}
</style>
