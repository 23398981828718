<template>
  <!-- Load only when the gallery config data was supplied -->
  <div v-if="galleryCode" class="container-fluid">
    <CustomNavbar :image="galleryLogo"
                  :imageLinkUrl="galleryLogoLink"
                  :menuItems="menuItems"
                  :researcherLogin="researcherLogin"
                  :title="galleryTitle"
                  :compactTitle="true">
      <!-- Wrapper for search: this is also a placeholder when navbar cannot be visible -->
      <div class="input-study-search-wrapper">
        <!-- Change the visibility of the navbar search by adding hideNavbarSearch to the route metadata -->
        <StudySearch v-if="!$route.meta.hideNavbarSearch"
                     :activeSearchValue="activeSearchQuery"
                     :navBarMode="true"
                     :studySuggestions="studySuggestions"
                     @loadSuggestions="onLoadSuggestions($event)"
                     @redirect="onRedirect($event)"
                     @search="onSearch($event)">
        </StudySearch>
      </div>
    </CustomNavbar>

    <ContentWithSideInfo>
      <template #content>
        <div class="flex-grow-1">
          <router-view></router-view>
        </div>
        <!-- Infobox-->
        <div class="row" style="margin-bottom:12px;">
          <div v-if="(galleryPhone || ~galleryPhone) && !viewingContactPage" class="col-12 col-md-4 my-2">
            <InfoBox>
              <template #title>
                Have questions about clinical trials?
              </template>
              <template #content>
                <div>
                  For general questions about participating in research studies at OHSU, contact OCTRI Recruitment at
                </div>
                <div class="mt-3 mb-2">
                  <strong>
                    <a href="mailto:octrirecruitment@ohsu.edu">octrirecruitment@ohsu.edu</a>
                  </strong>
                </div>
                <div>
                  or
                </div>
                <div class="mt-2 mb-3">
                  <font-awesome-icon :icon="['fas', 'phone-alt']" class="me-2" />
                  <strong>
                    <a :href="`tel:${galleryPhoneOrFallback}`" class="text-nowrap tel-link">
                      {{ galleryPhoneOrFallback }}
                    </a>
                  </strong>
                </div>
              </template>
            </InfoBox>
          </div>
          <div class="col-12 col-md-4 my-2">
            <InfoBox>
              <template #title>
                Have questions about a specific study?
              </template>
              <template #content>
                For more information about a specific study, please call the contact person listed for that study.
              </template>
            </InfoBox>
          </div>
          <div class="col-12 col-md-4 my-2">
            <InfoBox>
              <template #title>
                Have questions about your rights as a participant?
              </template>
              <template #content>
                If you have any questions regarding your rights as a research participant,
                you may contact the OHSU Research Integrity Office at <a class="tel-link text-nowrap" href="tel:(503) 494-7887">(503) 494-7887</a>.
              </template>
            </InfoBox>
          </div>
        </div>
      </template>
    </ContentWithSideInfo>
    <CustomFooter />
  </div>
</template>

<script>
import { MenuItem, CustomNavbar } from '@yuzulabs/lib-gallery';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    CustomNavbar
  },
  data() {
    return {
      menuItems: [
        new MenuItem('Home', 'home'),
        new MenuItem('Studies', 'studies'),
        new MenuItem('About', 'about')
        // new MenuItem('Contact', 'contact')
      ]
    };
  },
  computed: {
    ...mapGetters(
      'studies',
      [
        'studySuggestions',
        'activeSearchQuery'
      ]
    ),
    ...mapGetters(
      'general',
      [
        'galleryPhone',
        'galleryCode',
        'galleryLogo',
        'galleryLogoLink',
        'galleryTitle',
        'researcherLogin',
        'customData',
        'galleryWindowTitle'
      ]
    ),
    galleryPhoneOrFallback() {
      return this.galleryPhone || ' (503) 346-0811';
    },
    viewingContactPage() {
      return this.$route.name === 'contact';
    }
  },
  async mounted() {
    await this.$store.dispatch('general/initGallery', window.galleryConfigData);
    document.title = this.galleryWindowTitle || 'Studypages';
  },
  methods: {
    async onRedirect(suggestion) {
      await this.$store.dispatch('studies/redirectToSuggestedStudy', suggestion);
    },
    async onSearch(searchValue) {
      this.$store.commit('studies/searchStudies', searchValue);
    },
    async onLoadSuggestions(searchValue) {
      await this.$store.dispatch('studies/loadStudySuggestions', searchValue);
    }
  }
};
</script>

<style lang="scss">
h2 {
  font-size: 1.75rem !important;
}
h4 {
  font-size: 1.50rem !important;
}
h5 {
  font-size: 1.25rem !important;
}

.search-container {
  border: 2px solid var(--grey-200) !important;
  .search-input {
    font-size: 14px !important;
  }
}

#navbarSupportedContent {
  padding: 0 10px 0 10px;
}

.custom-footer {
  background-color: white !important;
}

.title-20-medium {
  font-size: 24px !important;
}

.navbar-wrapper {
  .yuzu-gallery {
    &.navbar-nav {
      .nav-link {
        margin-right: 4rem; // AVMA has a lot of tabs, so we reduce the margins
      }
    }
  }
}
</style>
