<template>
  <div :class="`chip-${color}`" class="chip py-0 px-3 d-inline-block">
    <div class="chip-inner d-inline-block">
      <span class="avenir-dem">{{ prefix }}:</span>
      {{ label }}
    </div>
    <span class="close-btn ps-2 float-end" @click="close()">&times;</span>
  </div>
</template>

<script>
export default {
  props: {
    prefix: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    color: {
      type: String, // Possible values: 'yellow' or 'red'
      required: true
    }
  },
  emits: ['closed'],
  methods: {
    close() {
      this.$emit('closed');
    }
  }
};
</script>
<style lang="scss" scoped>
.chip-yellow {
  background-color: #fff18e;

  .close-btn {
    color: #888;

    &:hover {
      color: #000;
    }
  }
}

.chip-red {
  background-color: #ffa385;
  color: black;

  .close-btn {
    color: black;

    &:hover {
      color: gray;
    }
  }
}

// General chip styling and animations
.chip {
  max-height: 40px;
  line-height: 40px;
  border-radius: 25px;

  .chip-inner {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
  }

  .close-btn {
    font-weight: bold;
    float: right;
    font-size: 20px;
    cursor: pointer;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.4s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
