import {
  createRouter, createWebHistory
} from 'vue-router';
import { getDefaultRouterSetup } from '@yuzulabs/lib-gallery';
import {
  About, Home, Studies
} from '@/pages';

const { routes, baseUrl } = getDefaultRouterSetup({
  homeComponent: Home,
  studiesComponent: Studies,
  aboutComponent: About
});
const router = createRouter({
  history: createWebHistory(baseUrl),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 };
  }
});

export default router;

