<template>
  <div class="search-cat-item my-2" :class="{ 'item-disabled': item.isDisabled }">
    <div class="d-flex">
      <div class="form-check">
        <label>
          <input v-bind.prop="checkboxState"
                 type="checkbox"
                 class="form-check-input sp-checkbox"
                 @change="toggleItemState()">
          <span :class="[item.children.length > 0 ? 'grey-700' : 'grey-600']"
                class="form-check-label body-14-medium">
            {{ item.label }} ({{ item.studyCount }})
          </span>
        </label>
      </div>
      <div v-if="item.isCollapsible && hasChildren">
        <a class="text-info ms-2"
           href="#"
           @click.prevent="toggleCollapse()">
          <font-awesome-icon v-if="isCollapsed" icon="angle-double-down" />
          <font-awesome-icon v-if="!isCollapsed" icon="angle-double-up" />
        </a>
      </div>
    </div>
    <div v-if="hasChildren && !isCollapsed" class="ps-3">
      <AggregatedItem v-for="child in item.children"
                      :key="child.term + child.label"
                      :force-checked="item.isChecked || forceChecked"
                      :item="child"
                      @itemClicked="bubbleItemClickedEvent($event)"></AggregatedItem>
    </div>
  </div>
</template>

<script>


import { AggregatedGscModel } from '../../models';

export default {
  name: 'AggregatedItem',
  props: {
    item: {
      type: AggregatedGscModel,
      required: true
    },
    // When the parent is checked, all children should be forcefully (visually) checked as well
    // Note that they aren't really 'active' (in the url or query sent to the backend they're not present)
    // It's just a visual indication which improves the UX experience
    forceChecked: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['itemClicked'],
  data() {
    return {
      isCollapsed: true
    };
  },
  computed: {
    checkboxState() {
      let isIndeterminate = false;
      const isChecked = this.item.isChecked || this.forceChecked;
      const { isDisabled } = this.item;
      if (this.hasChildren && !isChecked && !isDisabled) {
        const childrenCheckedCount = this.item.children.filter(x => x.isChecked).length;
        isIndeterminate = childrenCheckedCount > 0 && childrenCheckedCount < this.item.children.length;
      }

      return {
        checked: isChecked,
        disabled: isDisabled,
        indeterminate: isIndeterminate
      };
    },
    hasChildren() {
      return this.item.children && this.item.children.length > 0;
    }
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    toggleItemState() {
      if (this.item.isDisabled) {
        return;
      }


      // Normal scenario: the current item gets checked or unchecked
      // BUT: when the current item is indeterminate (meaning that one of the children is currently checked),
      // we want it to be visually unchecked, to accomplish that, we have to set the child as unchecked
      const itemToGetUpdated = this.checkboxState.indeterminate ? this.item.children.find(x => x.isChecked) : this.item;

      // Update the checkbox state and reload the study list in the background
      this.bubbleItemClickedEvent({
        searchCategory: itemToGetUpdated,
        checkedState: !itemToGetUpdated.isChecked
      });
    },
    // Bubble up event to the root section
    bubbleItemClickedEvent(eventData) {
      this.$emit('itemClicked', eventData);
    }
  }
};
</script>
<style lang="scss" scoped>

</style>
