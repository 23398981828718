export function useStudyCard(props, emits, store) {
  const addToFilters = newFilter => {
    emits('searchFilterAdded', newFilter);
  };

  const navigateToStudy = async () => {
    await store.dispatch('studies/navigateToStudy', props.study);
  };

  return {
    addToFilters,
    navigateToStudy
  };
}
