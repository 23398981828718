<script setup>
import { watch } from 'vue';
import SearchSuggestions from './shared/SearchSuggestions.vue';
import SortStudies from './shared/SortStudies.vue';
import { SpCloseButton } from '../../general';
import { defaultEmits, defaultProps, useStudySearch } from '../../../composables';

const props = defineProps({ ...defaultProps });
const emits = defineEmits([...defaultEmits]);
const {
  searchValue,
  showSuggestions,
  hideSuggestions,
  getSuggestions,
  searchStudies,
  clearSearch,
  onSuggestionClicked,
  displaySuggestions
} = useStudySearch(props, emits);

watch(() => props.activeSearchValue, newValue => {
  searchValue.value = newValue;
  hideSuggestions();
});
</script>
<template>
  <div v-if="!navBarMode" class="d-flex mb-3 pt-2">
    <div class="grey-700 title-20-medium-plus">
      {{ totalHits }} {{ $t('studies.main.counterStudiesTitle') }}
    </div>
    <slot name="summary-extra"></slot>
  </div>
  <div class="d-flex">
    <div class="flex-grow-1 d-flex justify-content-end">
      <!-- On outside click, the study suggestions list gets hidden/cleared: https://stackoverflow.com/a/53956904/1100255 -->
      <div v-click-outside="hideSuggestions" :class="[navBarMode ? 'limited-width' : 'w-100']">
        <!-- Search field -->
        <div class="search-container d-flex align-items-center">
          <input v-model="searchValue"
                 aria-label="Search studies"
                 type="text"
                 class="search-input flex-grow-1"
                 :placeholder="$t('header.searchPlaceholder')"
                 @focus="showSuggestions"
                 @input="getSuggestions"
                 @keyup.enter="searchStudies">
          <div class="input-icons">
            <SpCloseButton v-if="searchValue" @click="clearSearch" />
            <font-awesome-icon class="search-icon" :icon="['fal', 'search']" @click="searchStudies" />
          </div>
        </div>
        <SearchSuggestions v-if="displaySuggestions"
                           :study-suggestions="studySuggestions"
                           :search-value="searchValue"
                           :nav-bar-mode="navBarMode"
                           @suggestion-clicked="onSuggestionClicked($event)"
                           @search-studies="searchStudies()" />
      </div>
    </div>
    <div v-if="!navBarMode" class="ps-3">
      <SortStudies :location-filter-active="locationFilterActive"
                   :sorting="sorting"
                   @sort-by-updated="emits('sortByUpdated', $event)" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './shared/study-search-shared';
</style>
