import { SimpleGallerySearchCategory } from './simple-gsc-model';

export class StudyCardModel {
  constructor(
    {
      id,
      title,
      tagline,
      description,
      studyTags,
      studyAttributes,
      thumbnail,
      status,
      studyType,
      targetGender,
      targetAge,
      topInvestigator,
      studyUrl,
      statusColor,
      isSimilar,
      locationDistance = null
    }
  ) {
    this.id = id;
    this.title = title;
    this.tagline = tagline;
    this.description = description;
    this.studyTags = studyTags || [];
    this.studyAttributes = studyAttributes || [];
    this.thumbnail = thumbnail;
    this.status = status;
    this.studyType = studyType;
    this.targetGender = targetGender;
    this.targetAge = targetAge;
    this.topInvestigator = topInvestigator;
    this.studyUrl = studyUrl;
    this.statusColor = statusColor;
    this.isSimilar = isSimilar;
    this.locationDistance = locationDistance; // Currently in miles, maybe in the future in km

    // Only use when viewListingInline = true
    // Example: { id: this.id } or { vct_code: this.vctCode }
    this.inlineListingQueryParams = null;
  }

  static createFromBackendData(data) {
    return new StudyCardModel(this.mapBackendData(data));
  }

  // Separate mapper to allow easy inheritance of this model in specific galleries
  static mapBackendData(data) {
    const allTags = (data.visible_sc_items || []).map(x => SimpleGallerySearchCategory.createFromBackendData(x));
    return {
      id: data.id,
      title: data.name,
      tagline: data.tagline,
      description: data.description,
      studyTags: allTags.filter(x => !x.showAsAttributeOnCard),
      studyAttributes: allTags.filter(x => x.showAsAttributeOnCard),
      thumbnail: data.thumbnail,
      status: data.status,
      studyType: data.study_type,
      targetGender: data.target_gender,
      targetAge: data.age_range,
      topInvestigator: data.top_investigator,
      studyUrl: data.absolute_url,
      statusColor: data.status_color,
      isSimilar: data.is_similar,
      locationDistance: data.distance_to_location
    };
  }
}
