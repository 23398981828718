export class StudySuggestionModel {
  constructor(
    {
      id,
      mustRedirect,
      isInvestigator,
      suggestionLabel,
      thumbnail
    }
  ) {
    this.id = id;
    this.mustRedirect = mustRedirect;
    this.suggestionLabel = suggestionLabel;
    this.thumbnail = thumbnail;
    this.isInvestigator = isInvestigator;
  }

  static createFromBackendData(data) {
    return new StudySuggestionModel(this.mapBackendData(data));
  }

  // Separate mapper to allow easy inheritance of this model in specific galleries
  static mapBackendData(data) {
    return {
      id: data.id,
      mustRedirect: data.must_redirect,
      isInvestigator: data.is_investigator,
      suggestionLabel: data.suggestion_label,
      thumbnail: data.thumbnail
    };
  }
}
