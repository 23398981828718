<template>
  <div class="sp-switch form-check form-switch">
    <input :id="fieldName"
           v-model="checked"
           class="form-check-input"
           type="checkbox" />
    <label :for="fieldName" class="form-check-label pe-3">{{ label }}</label>
  </div>
</template>

<script>

import { FilterKind, SearchFilterModel } from '../../../models';

export default {
  props: {
    filter: {
      type: SearchFilterModel,
      required: false,
      default: null
    },
    fieldName: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    defaultValue: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['update'],
  computed: {
    checked: {
      get() {
        return this.filter ? this.filter.filterValue === 'true' : this.defaultValue;
      },
      set() {
        // When currently a filter is present, set it to null, so it gets removed
        // If not, the filter gets added
        const newFilter = this.filter ? null : new SearchFilterModel(this.fieldName, FilterKind.term, 'true');
        this.$emit('update', {
          oldFilter: this.filter,
          newFilter
        });
      }
    }
  }
};
</script>

