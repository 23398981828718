import { FieldNames, FilterKind, SearchFilterModel } from '../shared';

export class AggregatedLanguageModel {
  constructor(data) {
    this.languageCode = data.language_code;
    this.languageLabel = data.language_label;
    this.count = data.count;
  }

  mapToSearchFilter() {
    return new SearchFilterModel(
      FieldNames.languages,
      FilterKind.term,
      this.languageCode
    );
  }
}
