<script setup>

defineProps({
  activeFilters: {
    type: Array,
    required: true
  }
});
const emits = defineEmits(['clearFilter', 'clearAll']);
function clearFilter(item) {
  emits('clearFilter', item);
}

function clearAll() {
  emits('clearAll');
}
</script>

<template>
  <div v-if="activeFilters.length" class="mb-3">
    <div class="d-flex mb-3">
      <div class="title-20-bold grey-700">
        {{ $t('studies.filter.mainTitle') }}
      </div>
      <div class="ms-auto grey-500 body-14">
        <a href="#" @click.prevent="clearAll">{{ $t('studies.filter.clearAllTitle') }}</a>
      </div>
    </div>
    <div class="filters-wrapper pt-3 pb-2 d-flex flex-wrap">
      <div v-for="item in activeFilters" :key="item" class="d-flex align-items-center mb-2 filter-item text-center bg-white me-2">
        <div class="grey-700 body-14-medium">
          <template v-if="item.formattedFieldName">
            {{ item.formattedFieldName }}
          </template>
          <span v-if="!item.formattedFieldName" class="mx-2 spinner-border spinner-border-sm text-secondary" role="status" />
          <template v-if="item.formattedFilterValue">
            : <span :class="item.formattedFilterValue.length > 20 ? 'text-wrap' : 'text-nowrap'">
              {{ item.formattedFilterValue }}
            </span>
          </template>
        </div>
        <div class="clear-icon" @click="clearFilter(item)">
          <font-awesome-icon class="ms-2" :icon="['fal', 'times']" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.filters-wrapper {
  border-radius: var(--sp-banner-border-radius);
  background-color: var(--grey-100);
  padding: 1.25rem;
  .filter-item {
    padding: 0.5rem 0.75rem;
    border-radius: 1.875rem;
    .clear-icon {
      color: var(--grey-400);
      cursor: pointer;
      &:hover {
        color: var(--grey-800);
      }

    }
  }

}
</style>
