export function useRehydrate(store, route) {
  const rehydrateStudiesStore = (url, resetLoader) => {
    store.commit('studies/updateStateAfterRouteChange', {
      url,
      resetLoader
    });
  };
    // When visiting the studies app, rehydrate the studies store with the URL query params
  rehydrateStudiesStore(route.query, false);
  return {
    rehydrateBeforeRouteUpdate: async (to, from, next) => {
      // Activated on each route update within the /studies path
      // Will rehydrate the state with the query params (ONLY WHEN NEEDED)
      rehydrateStudiesStore(to.query, true);
      await next();
    }
  };
}
