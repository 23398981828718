import * as Sentry from '@sentry/vue';

function initializeSentry(app, router = null) {
  const sentryConfig = window.sentryConfig;
  if (sentryConfig) {
    const integrations = [Sentry.replayIntegration()];

    if (router) {
      integrations.push(Sentry.browserTracingIntegration({ router }));
    }

    Sentry.init({
      app,
      dsn: sentryConfig.api_url,
      integrations,
      tracesSampleRate: sentryConfig.debugMode ? 1.0 : 0.5,
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
    });
  }
}

export default initializeSentry;

