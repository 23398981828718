<script setup>
import { useI18n } from 'vue-i18n';
import {
  FieldNames, FilterKind, FilterValues, SearchFilterModel
} from '../../models';

const { t } = useI18n();

const props = defineProps({
  genderSearchFilter: {
    type: SearchFilterModel,
    required: false,
    default: null
  }
});
const emits = defineEmits(['update']);
const genderOptions = [
  {
    label: t('studies.filter.sex.all'),
    exclude: undefined,
    disabledWhenSelected: true
  },
  {
    label: t('studies.filter.sex.male'),
    exclude: FilterValues.genderFemale,
    disabledWhenSelected: false
  },
  {
    label: t('studies.filter.sex.female'),
    exclude: FilterValues.genderMale,
    disabledWhenSelected: false
  }
];
const excludeGender = genderToExclude => {
  // When already selected, select the 'All' gender by emitting a null SearchFilterModel
  if (props.genderSearchFilter?.filterValue === genderToExclude) {
    genderToExclude = null;
  }
  emits('update', genderToExclude ?
    new SearchFilterModel(FieldNames.gender, FilterKind.mustNotEqual, genderToExclude) : null);
};
</script>
<template>
  <div v-for="gender in genderOptions" :key="gender.label" class="my-1 form-check">
    <label>
      <input :checked="genderSearchFilter?.filterValue === gender.exclude"
             :disabled="gender.disabledWhenSelected && genderSearchFilter?.filterValue === gender.exclude"
             type="checkbox"
             class="form-check-input sp-checkbox"
             @change="excludeGender(gender.exclude)">
      <span class="form-check-label-label">
        {{ gender.label }}
      </span>
    </label>
  </div>
</template>
<style lang="scss" scoped>
</style>
