<script setup>
import { DeviceSize, useResizeListener } from '@yuzulabs/lib-general';
import StudyCardMedium from './StudyCardMedium.vue';
import { StudyCardModel } from '../../../models';
import StudyCardSmall from './StudyCardSmall.vue';

defineProps({
  study: {
    type: StudyCardModel,
    required: true
  }
});
const deviceSize = useResizeListener().currentDeviceSize;
const emits = defineEmits(['searchFilterAdded']);
</script>

<template>
  <StudyCardMedium v-if="deviceSize >= DeviceSize.sm"
                   :study="study"
                   @search-filter-added="emits('searchFilterAdded', $event)" />
  <StudyCardSmall v-if="deviceSize < DeviceSize.sm"
                  :study="study"
                  @search-filter-added="emits('searchFilterAdded', $event)" />
</template>

