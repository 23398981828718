<script setup>
</script>

<template>
  <div class="text-center">
    <router-link class="btn sp-btn-primary" :to="{ name: 'studies' }">
      {{ $t('browse.btnTitle') }}
    </router-link>
  </div>
</template>

<style scoped lang="scss">
.btn {
  width: 250px;
}
</style>
