import { ref } from 'vue';

export const defaultProps = {
  studySuggestions: {
    type: Array,
    required: true
  },
  sorting: {
    type: Object,
    required: false // Although not required, it should always be present when navBarMode=false!!
  },
  // The search value that was actually sent back to the backend and so is currently active
  // This is different from the model binding with the "searchValue" variable defined below
  activeSearchValue: {
    type: String,
    required: false
  },
  // When the search input is displayed in the navbar, this will change the suggestion container's layout
  navBarMode: {
    type: Boolean,
    required: false,
    default: false
  },
  locationFilterActive: {
    type: Boolean,
    required: false,
    default: false
  },
  totalHits: {
    type: Number,
    required: false,
    default: 0
  }
};

export const defaultEmits = ['redirect', 'search', 'sortByUpdated', 'loadSuggestions'];
export function useStudySearch(props, emits) {
  const timeout = ref(null);
  const searchValue = ref('');
  const displaySuggestions = ref(false);

  // Set initial search value
  // eslint-disable-next-line vue/no-setup-props-destructure
  searchValue.value = props.activeSearchValue;

  const clearSuggestionsTimer = () => {
    if (timeout.value) {
      clearTimeout(timeout.value);
    }
  };

  const hideSuggestions = () => {
    displaySuggestions.value = false;
    clearSuggestionsTimer();
  };

  const showSuggestions = () => {
    displaySuggestions.value = true;
  };
  const searchStudies = () => {
    // Search all studies, this will update the studies store so they become visible automatically
    hideSuggestions();

    emits('search', searchValue.value || '');
  };

  const onSuggestionClicked = suggestion => {
    // Either redirect to a direct study, or perform a search with the selected item
    hideSuggestions();
    searchValue.value = '';
    if (suggestion.mustRedirect) {
      emits('redirect', suggestion);
    } else {
      searchValue.value = suggestion.suggestionLabel;
      searchStudies();
    }
  };

  const clearSearch = () => {
    searchValue.value = '';
    emits('search', '');
  };

  const getSuggestions = event => {
    searchValue.value = event.target.value.trimStart();
    clearSuggestionsTimer();
    if (!searchValue.value) {
      return;
    }

    timeout.value = setTimeout(() => {
      emits('loadSuggestions', searchValue.value);
      showSuggestions();
    }, 250);
  };
  return {
    searchValue,
    showSuggestions,
    hideSuggestions,
    getSuggestions,
    searchStudies,
    clearSearch,
    onSuggestionClicked,
    displaySuggestions
  };
}
