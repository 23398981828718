import { useSanitizedHtml } from '../../../src/composables';

/**
 * @param  {} element
 * @param  args: string html
 */
export const sanitizedHtml = {
  mounted(element, binding) {
    element.innerHTML = useSanitizedHtml(binding.value);
  },
  updated(element, binding) {
    element.innerHTML = useSanitizedHtml(binding.value);
  },
  unmounted(element, binding) {
    element.innerHTML = '';
  }
};
