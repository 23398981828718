<script setup>
const emits = defineEmits(['click']);
</script>

<template>
  <div class="d-inline-block sp-btn-clear me-2">
    <font-awesome-icon class="clear-icon" :icon="['fal', 'times']" @click="emits('click')" />
  </div>
</template>

<style scoped lang="scss">
</style>
