<script setup>
const props = defineProps({
  label: {
    type: String,
    required: true
  },
  clickable: {
    type: Boolean,
    required: false,
    default: true
  }
});
const emits = defineEmits(['click']);
function onClicked() {
  if (props.clickable) {
    emits('click');
  }
}
</script>

<template>
  <div class="d-inline-block sp-label small-12 text-center py-1 px-2 bg-white" :class="{ clickable }" @click.stop.prevent="onClicked">
    <div class="d-flex align-items-center">
      <!-- Only render div when slot has content -->
      <div v-if="$slots.default">
        <slot></slot>
      </div>
      <div>{{ label }}</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.sp-label {
  color: var(--sp-tag-label);
  border-radius: 1.875rem;
  border: 1px solid var(--sp-tag-label-border);
  &.clickable:hover {
    cursor: pointer;
    border: 1px solid var(--sp-tag-label-border-hover);
  }
}
</style>
