<script setup>
import { DeviceSize, useResizeListener } from '@yuzulabs/lib-general';
import { useStore } from 'vuex';
import { computed } from 'vue';
import LanguageSwitcher from './LanguageSwitcher.vue';

defineProps({
  image: {
    type: String,
    required: true
  },
  imageLinkUrl: {
    type: String,
    required: false
  },
  title: {
    type: String,
    required: true
  },
  researcherLogin: {
    // When not provided, you can use the slot to render your own login button
    type: String,
    required: false
  },
  menuItems: {
    type: Array,
    required: true
  },
  compactTitle: {
    type: Boolean,
    required: false
  }
});
const deviceSize = useResizeListener().currentDeviceSize;
const store = useStore();
const languageSwitcherVisible = computed(() => store.getters['general/languageSwitcherVisible']);
</script>
<template>
  <div class="row">
    <div class="col col-xl-8 offset-xl-2">
      <!-- Show logo and researcher login for bigger devices -->
      <div v-if="deviceSize >= DeviceSize.md" class="grey-border-bottom py-3">
        <div class="d-flex align-items-center">
          <template v-if="!compactTitle">
            <div class="d-flex align-items-center img-wrapper w-100 h-100">
              <div class="w-100 h-100">
                <a v-if="imageLinkUrl"
                   :href="imageLinkUrl"
                   target="_blank"
                   rel="noopener">
                  <img :src="image" alt="logo" class="img-fluid rounded">
                </a>
                <router-link v-else to="/">
                  <img :src="image" alt="logo" class="img-fluid rounded">
                </router-link>
              </div>
            </div>
            <div class="logo-title-divider mx-3"></div>
            <div class="title-20-medium grey-700">
              {{ title }}
            </div>
            <div class="ms-auto">
              <a v-if="researcherLogin" :href="researcherLogin" class="text-nowrap researcher-login btn-sm sp-btn-secondary">
                {{ $t('header.researcherLogin') }}
              </a>
              <!-- Optional slot, you either pass a researcher login url or you use this slot -->
              <slot v-if="!researcherLogin" name="researcher-login-slot">
              </slot>
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center flex-row">
              <div class="img-wrapper-tall h-100">
                <a v-if="imageLinkUrl"
                   :href="imageLinkUrl"
                   target="_blank"
                   rel="noopener">
                  <img :src="image" alt="logo" class="img-fluid rounded">
                </a>
                <router-link v-else to="/">
                  <img :src="image" alt="logo" class="img-fluid rounded">
                </router-link>
              </div>
              <div class="logo-title-divider mx-3"></div>
              <div class="title-20-medium grey-700">
                {{ title }}
              </div>
            </div>
            <div class="ms-auto">
              <a v-if="researcherLogin" :href="researcherLogin" class="text-nowrap researcher-login btn-sm sp-btn-secondary">
                {{ $t('header.researcherLogin') || "Researcher Login" }}
              </a>
              <!-- Optional slot, you either pass a researcher login url or you use this slot -->
              <slot v-if="!researcherLogin" name="researcher-login-slot">
              </slot>
            </div>
          </template>
        </div>
      </div>

      <div class="navbar-wrapper">
        <nav class="navbar navbar-expand-md navbar-light p-0">
          <!-- We wrap navbar brand and toggle-button in a flex div
          so in case of a long navbar brand name it gets wrapped nicely -->
          <div v-if="deviceSize < DeviceSize.md" class="d-flex w-100 py-2 align-items-center">
            <div class="yuzu-gallery flex-grow-1 navbar-brand title-20-medium grey-700">
              {{ title }}
            </div>
            <!-- On mobile, show the language switcher next to the hamburger menu -->
            <LanguageSwitcher v-if="languageSwitcherVisible" class="me-2" />
            <div>
              <button aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      class="navbar-toggler"
                      data-bs-target="#navbarSupportedContent"
                      data-bs-toggle="collapse"
                      type="button">
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
          <div id="navbarSupportedContent" class="collapse navbar-collapse">
            <ul class="navbar-nav yuzu-gallery my-0">
              <li v-for="item in menuItems" :class="{ 'text-center': deviceSize >= DeviceSize.md }" class="nav-item">
                <!-- Mobile only => collapse navbar when clicked on -->
                <router-link v-if="deviceSize < DeviceSize.md"
                             :to="{ name: item.pathName }"
                             active-class="active"
                             class="nav-link d-inline-block">
                  <span data-bs-target="#navbarSupportedContent"
                        data-bs-toggle="collapse"
                        class="avenir-dem">{{ item.label }}</span>
                </router-link>
                <!-- Desktop only -->
                <router-link v-if="deviceSize >= DeviceSize.md"
                             :to="{ name: item.pathName }"
                             active-class="active"
                             class="nav-link d-inline-block">
                  <div>
                    <div class="my-3 text-nowrap">
                      {{ item.label }}
                    </div>
                    <div class="nav-link-bottom"></div>
                  </div>
                </router-link>
              </li>
            </ul>
            <!-- for mobile only showing researcher login link at the bottom of the menu -->
            <div v-if="deviceSize < DeviceSize.md" class="mb-2">
              <a v-if="researcherLogin" :href="researcherLogin" class="text-nowrap researcher-login btn-sm sp-btn-secondary ps-0">
                {{ $t('header.researcherLogin') || "Researcher Login" }}
              </a>
              <!-- Optional slot, you either pass a researcher login url or you use this slot -->
              <slot v-if="!researcherLogin" name="researcher-login-slot">
              </slot>
            </div>
            <!-- spacer -->
            <div class="flex-grow-1"></div>

            <!-- slot for showing the study search and optional extra stuff -->
            <slot></slot>
            <!-- For desktop, show the language switcher next to the search -->
            <LanguageSwitcher v-if="deviceSize >= DeviceSize.md && languageSwitcherVisible" class="ms-2" />
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@yuzulabs/lib-general/src/styles/theme/font-styles';

.img-wrapper {
  max-height: 70px;
  max-width: 160px;
  img {
    max-height: 70px;
  }
}

.img-wrapper-tall {
  max-height: 90px;
  max-width: 200px;
  img {
    max-height: 90px;
  }
}

.logo-title-divider {
  align-self: stretch;
  width: 1px;
  background-color: var(--grey-200);
}

.grey-border-bottom {
  border-bottom: 1px solid var(--grey-200);
}

.navbar-wrapper {
  ul li {
    width: 100%;
  }

  .yuzu-gallery {
    &.navbar-brand {
      white-space: normal; // Wrap long names, by default navbar-brand doesn't wrap and overflows
    }

    &.navbar-nav {
      font-size: 20px;
      .nav-link {
        @extend .body-16;
        color: var(--sp-navbar-link);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        margin-right: 7rem;
        margin-left: 0;

        &:hover {
          color: var(--sp-navbar-link-hover);
        }

        &.active {
          .nav-link-bottom {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background-color: var(--sp-navbar-link-bottom);
            height: 4px;
          }
        }
      }
    }
  }
}

@media (max-width: 1485px) {
  .navbar-wrapper{
    .yuzu-gallery{
      &.navbar-nav{
        .nav-link{
          margin-right: 3rem;
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .navbar-wrapper{
    .yuzu-gallery{
      &.navbar-nav{
        .nav-link{
          margin-right: 7rem;
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .navbar-wrapper{
    .yuzu-gallery{
      &.navbar-nav{
        .nav-link{
          margin-right: 4.3rem;
        }
      }
    }
  }
}
@media (max-width: 850px) {
  .navbar-wrapper{
    .yuzu-gallery{
      &.navbar-nav{
        .nav-link{
          margin-right: 3.5rem;
        }
      }
    }
  }
}
</style>
