/**
 * @param {*} platformUrlObject single object from the platformUrl
 * @param {*} pathParameters pathParameters to be replaced in the url can be an array or object
 * if array, the pathParameters will be replaced in the order they appear in the url
 * if object, the pathParameters will be replaced based on the key value
 * @param {*} options options object
 * @param {*} options.useBaseUrl if true, the base url will be used prefixing window.location.origin
 * @returns string
 */
export function platformUrlResolver(platformUrlObject, pathParameters = null, options = { useBaseUrl: true }) {
  const { djangoPath, path } = platformUrlObject;
  const regex = /<([^>]+)>/g;

  // Replace placeholders in djangoPath with values from pathParameters
  const replacedPath = djangoPath.replace(regex, (match, key) => {
    if (Array.isArray(pathParameters)) {
      return pathParameters.shift() || match; // Use shift to get and remove the first element
    } if (pathParameters && typeof pathParameters === 'object') {
      return pathParameters[key] || match; // Return the value if it exists, otherwise return the original placeholder
    }
    return match; // Return original placeholder if no parameters provided
  });

  // Check for unmatched placeholders
  if (regex.test(replacedPath)) {
    console.warn('Not all path parameters were used');
    return `${window.origin}${path}`; // Fallback to base path
  }

  // Construct the final URL
  return options.useBaseUrl ? `${window.origin}${replacedPath}` : replacedPath;
}
