export const FieldNames = {
  ageMin: 'age_min',
  ageMax: 'age_max',
  gender: 'target_gender',
  searchCategoryPaths: 'sc_paths',
  languages: 'languages',
  healthyVolunteer: 'healthy_volunteer',
  hasCompensation: 'has_compensation',
  locations: 'locations',
  recruitingStatus: 'recruiting_status'
};

export const FilterValues = {
  genderMale: 'M',
  genderFemale: 'F',
  ltePrefix: 'lte_',
  gtePrefix: 'gte_'
};

export const SortOptions = {
  score: 'score', // Sort by relevance
  date: 'date', // Sort by created date
  location: 'location' // Sort by study location
};

export const SortDirection = {
  ascending: 'asc',
  descending: 'desc'
};

export const SortLabels = {
  relevance: 'relevance',
  newest: 'newestFirst',
  oldest: 'oldestFirst'
};

export const DefaultSortOptions = [
  {
    label: SortLabels.relevance,
    key: SortOptions.score,
    direction: undefined
  },
  {
    label: SortLabels.newest,
    key: SortOptions.date,
    direction: SortDirection.descending
  },
  {
    label: SortLabels.oldest,
    key: SortOptions.date,
    direction: SortDirection.ascending
  }
];

export const General = {
  lteGteRegex: /(lte|gte)_([0-9]+)/,
  distanceSeparator: '_',
  distanceMetric: 'mi', // Maybe in the future we'll support km
  zipCodePrefix: 'zip:'
};
