<template>
  <div class="infinite-loading-container">
    <div v-show="isShowSpinner" class="infinite-status-prompt">
      <slot name="spinner">
        <spinner />
      </slot>
    </div>
    <div v-show="isShowNoResults" class="infinite-status-prompt">
      <slot name="no-results">
      </slot>
    </div>
    <div v-show="isShowNoMore" class="infinite-status-prompt">
      <slot name="no-more">
      </slot>
    </div>
    <div v-show="isShowError" class="infinite-status-prompt">
      <slot :trigger="attemptLoad" name="error">
        <component :is="slots.error"
                   v-if="slots.error.render"
                   :trigger="attemptLoad">
        </component>
        <template v-else>
          {{ slots.error }}
          <br>
          <button class="btn-try-infinite"
                  @click="attemptLoad"
                  v-text="slots.errorBtnText">
          </button>
        </template>
      </slot>
    </div>
  </div>
</template>
<script>
import mitt from 'mitt';
import Spinner from './Spinner.vue';
import config, {
  STATUS, WARNINGS, evt3rdArg
} from './config';
import {
  isVisible, loopTracker, scrollBarStorage, throttleer, warn
} from './utils';

export default {
  name: 'InfiniteLoading',
  components: {
    Spinner
  },
  data() {
    return {
      scrollParent: null,
      scrollHandler: null,
      isFirstLoad: true, // save the current loading whether it is the first loading
      status: STATUS.READY,
      slots: config.slots,
      emitter: mitt()
    };
  },
  computed: {
    isShowSpinner() {
      return this.status === STATUS.LOADING;
    },
    isShowError() {
      return this.status === STATUS.ERROR;
    },
    isShowNoResults() {
      return (
        this.status === STATUS.COMPLETE &&
          this.isFirstLoad
      );
    },
    isShowNoMore() {
      return (
        this.status === STATUS.COMPLETE &&
          !this.isFirstLoad
      );
    }
  },
  props: {
    distance: {
      type: Number,
      default: config.props.distance
    },
    direction: {
      type: String,
      default: 'bottom'
    },
    forceUseInfiniteWrapper: {
      type: [Boolean, String],
      default: config.props.forceUseInfiniteWrapper
    },
    identifier: {
      default: +new Date()
    },
    onInfinite: Function
  },
  watch: {
    identifier() {
      this.stateChanger.reset();
    }
  },
  mounted() {
    this.$watch('forceUseInfiniteWrapper', () => {
      this.scrollParent = this.getScrollParent();
    }, { immediate: true });

    this.scrollHandler = ev => {
      if (this.status === STATUS.READY) {
        if (ev && ev.constructor === Event && isVisible(this.$el)) {
          throttleer.throttle(this.attemptLoad);
        } else {
          this.attemptLoad();
        }
      }
    };

    setTimeout(() => {
      this.scrollHandler();
      this.scrollParent.addEventListener('scroll', this.scrollHandler, evt3rdArg);
    }, 1);

    this.emitter.on('$InfiniteLoading:loaded', ev => {
      this.isFirstLoad = false;

      if (this.direction === 'top') {
        // wait for DOM updated
        this.$nextTick(() => {
          scrollBarStorage.restore(this.scrollParent);
        });
      }

      if (this.status === STATUS.LOADING) {
        this.$nextTick(this.attemptLoad.bind(null, true));
      }

      if (!ev || ev.target !== this) {
        warn(WARNINGS.STATE_CHANGER);
      }
    });

    this.emitter.on('$InfiniteLoading:complete', ev => {
      this.status = STATUS.COMPLETE;

      // force re-complation computed properties to fix the problem of get slot text delay
      this.$nextTick(() => {
        this.$forceUpdate();
      });

      this.scrollParent.removeEventListener('scroll', this.scrollHandler, evt3rdArg);

      if (!ev || ev.target !== this) {
        warn(WARNINGS.STATE_CHANGER);
      }
    });

    this.emitter.on('$InfiniteLoading:reset', ev => {
      this.status = STATUS.READY;
      this.isFirstLoad = true;
      scrollBarStorage.remove(this.scrollParent);
      this.scrollParent.addEventListener('scroll', this.scrollHandler, evt3rdArg);

      // wait for list to be empty and the empty action may trigger a scroll event
      setTimeout(() => {
        throttleer.reset();
        this.scrollHandler();
      }, 1);

      if (!ev || ev.target !== this) {
        warn(WARNINGS.IDENTIFIER);
      }
    });

    /**
     * change state for this component, pass to the callback
     */
    this.stateChanger = {
      loaded: () => {
        this.emitter.emit('$InfiniteLoading:loaded', { target: this });
      },
      complete: () => {
        this.emitter.emit('$InfiniteLoading:complete', { target: this });
      },
      reset: () => {
        this.emitter.emit('$InfiniteLoading:reset', { target: this });
      },
      error: () => {
        this.status = STATUS.ERROR;
        throttleer.reset();
      }
    };
  },
  /**
   * To adapt to keep-alive feature, but only work on Vue 2.2.0 and above, see: https://vuejs.org/v2/api/#keep-alive
   */
  deactivated() {
    /* istanbul ignore else */
    if (this.status === STATUS.LOADING) {
      this.status = STATUS.READY;
    }
    this.scrollParent.removeEventListener('scroll', this.scrollHandler, evt3rdArg);
  },
  activated() {
    this.scrollParent.addEventListener('scroll', this.scrollHandler, evt3rdArg);
  },
  unmounted() {
    /* istanbul ignore else */
    if (!this.status !== STATUS.COMPLETE) {
      throttleer.reset();
      scrollBarStorage.remove(this.scrollParent);
      this.scrollParent.removeEventListener('scroll', this.scrollHandler, evt3rdArg);
    }
  },
  methods: {
    /**
     * attempt trigger load
     * @param {Boolean} isContinuousCall  the flag of continuous call, it will be true
     *                                    if this method be called in the `loaded`
     *                                    event handler
     */
    attemptLoad(isContinuousCall) {
      if (
        this.status !== STATUS.COMPLETE &&
          isVisible(this.$el) &&
          this.getCurrentDistance() <= this.distance
      ) {
        this.status = STATUS.LOADING;

        if (this.direction === 'top') {
          // wait for spinner display
          this.$nextTick(() => {
            scrollBarStorage.save(this.scrollParent);
          });
        }

        if (typeof this.onInfinite === 'function') {
          this.onInfinite.call(null, this.stateChanger);
        } else {
          this.emitter.emit('infinite', this.stateChanger);
        }

        if (isContinuousCall && !this.forceUseInfiniteWrapper && !loopTracker.isChecked) {
          // check this component whether be in an infinite loop if it is not checked
          // more details: https://github.com/PeachScript/vue-infinite-loading/issues/55#issuecomment-316934169
          loopTracker.track();
        }
      } else if (this.status === STATUS.LOADING) {
        this.status = STATUS.READY;
      }
    },
    /**
     * get current distance from the specified direction
     * @return {Number}     distance
     */
    getCurrentDistance() {
      let distance;

      if (this.direction === 'top') {
        distance = typeof this.scrollParent.scrollTop === 'number' ?
          this.scrollParent.scrollTop :
          this.scrollParent.pageYOffset;
      } else {
        const infiniteElmOffsetTopFromBottom = this.$el.getBoundingClientRect().top;
        const scrollElmOffsetTopFromBottom = this.scrollParent === window ?
          window.innerHeight :
          this.scrollParent.getBoundingClientRect().bottom;

        distance = infiniteElmOffsetTopFromBottom - scrollElmOffsetTopFromBottom;
      }

      return distance;
    },
    /**
     * get the first scroll parent of an element
     * @param  {DOM} elm    cache element for recursive search
     * @return {DOM}        the first scroll parent
     */
    getScrollParent(elm = this.$el) {
      let result;

      if (typeof this.forceUseInfiniteWrapper === 'string') {
        result = document.querySelector(this.forceUseInfiniteWrapper);
      }

      if (!result) {
        if (elm.tagName === 'BODY') {
          result = window;
        } else if (!this.forceUseInfiniteWrapper && ['scroll', 'auto'].indexOf(getComputedStyle(elm).overflowY) > -1) {
          result = elm;
        } else if (elm.hasAttribute('infinite-wrapper') || elm.hasAttribute('data-infinite-wrapper')) {
          result = elm;
        }
      }

      return result || this.getScrollParent(elm.parentNode);
    }
  }
};
</script>
<style lang="scss" scoped>
.infinite-loading-container {
  clear: both;
  text-align: center;

  ::v-deep(*[class^=loading-]) {
    $size: 28px;
    display: inline-block;
    margin: 5px 0;
    width: $size;
    height: $size;
    font-size: $size;
    line-height: $size;
    border-radius: 50%;
  }
}

.btn-try-infinite {
  margin-top: 5px;
  padding: 5px 10px;
  color: #999;
  font-size: 14px;
  line-height: 1;
  background: transparent;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  cursor: pointer;

  &:not(:active):hover {
    opacity: 0.8;
  }
}
</style>
