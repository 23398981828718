<script setup>
import { DeviceSize, useResizeListener } from '@yuzulabs/lib-general';
import { ScrollTop } from '../general';

const deviceSize = useResizeListener().currentDeviceSize;
</script>

<template>
  <div class="row">
    <div class="col-md-4 d-none d-md-block">
      <slot v-if="deviceSize >= DeviceSize.md" name="side-medium"></slot>
    </div>
    <div class="col-md-8 col-12">
      <slot name="main"></slot>
    </div>
  </div>
  <slot v-if="deviceSize < DeviceSize.md" name="side-small"></slot>
  <ScrollTop />
</template>

<style scoped lang="scss">

</style>
