<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { constants } from '../../constants';

const store = useStore();
const config = computed(() => store.state.general.footerData);
const routeNames = constants.routeNames;
</script>
<template>
  <div class="w-100 text-center general-bg custom-footer">
    <div class="text-center py-3">
      <a href="https://studypages.com" target="_blank" rel="noopener noreferrer">
        {{ $t('footer.poweredBy') }}
        <img id="sp-power-logo"
             alt="StudyPages logo"
             src="https://yuzu-studypages-prod-east.s3.amazonaws.com/staticroot/images/logos/updated/sp-icon.png">
        StudyPages
      </a>
    </div>
    <div class="row footer-bottom py-2">
      <div class="col-12 col-md-6 text-center">
        <!-- Privacy policy -->
        <router-link v-if="config.usePrivacyPolicyStaticPage"
                     :to="{ name: routeNames.privacyPolicy }"
                     class="pe-3">
          {{ config.privacyPolicyLabel }}
        </router-link>
        <a v-else
           :href="config.privacyPolicyUrl"
           class="pe-3"
           target="_blank"
           rel="noopener noreferrer">
          {{ config.privacyPolicyLabel }}
        </a>
        <!-- Terms -->
        <router-link v-if="config.useTermsStaticPage"
                     :to="{ name: routeNames.terms }">
          {{ config.termsLabel }}
        </router-link>
        <a v-else
           :href="config.termsUrl"
           target="_blank"
           rel="noopener noreferrer">
          {{ config.termsLabel }}
        </a>
      </div>
      <div class="col-12 col-md-6 text-center">
        &#169; {{ config.yearLabel }} <a :href="config.studyPagesUrl">{{ config.studyPagesLabel }}</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-footer {
  img {
    width: 40px;
    height: 40px;
  }

  a, a:hover {
    text-decoration: none;
    color: inherit;
  }

  .footer-bottom {
    a, a:hover {
      text-decoration: underline;
    }
    font-size: 12px;
  }
}
</style>
