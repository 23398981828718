import { globalAxios } from '@yuzulabs/lib-infra';
import { platformUrlResolver, PlatformUrls } from '@yuzulabs/lib-general';

import { GallerySettingsModel } from '../models';

export class GeneralService {
  constructor(orgPostfix) {
    if (!orgPostfix) {
      throw new Error('Could not initialize GeneralService, invalid org endpoint provided');
    }
    this.orgEndpoint = window.location.origin + orgPostfix;
  }

  // Record activity for every page view
  async recordPageView(routerPathName) {
    if (!routerPathName) {
      return;
    }
    await globalAxios.post(`${this.orgEndpoint}record-activity/`, { page_kind: routerPathName });
  }

  async getGallerySettings(galleryUrlName) {
    const response = await globalAxios.get(platformUrlResolver(PlatformUrls.orgGalleryInfo, [galleryUrlName]));
    return new GallerySettingsModel(response.data);
  }
}
