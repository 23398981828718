<script setup>

import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { DeviceSize, useResizeListener } from '@yuzulabs/lib-general';

const deviceSize = useResizeListener().currentDeviceSize;
const isLoading = ref(true);
const studyUrl = ref(null);
const store = useStore();
const props = defineProps({
  id: {
    type: String,
    required: false // Either an id or vct is required
  },
  vct: {
    type: String,
    required: false
  }
});

// The other way would be to useRouter, but then I needed to explicitly set the vue-router dependency :-)
const goBack = routerObj => {
  store.commit('studies/forceRehydrateOnUrlChange');
  // Rudimentary way of checking if we're not coming from the gallery studies search page
  if (!window.history.state.back || !window.history.state.back.includes('/studies/')) {
    routerObj.push({ name: 'studies' });
  } else {
    routerObj.back();
  }
};
onMounted(async () => {
  try {
    if (!props.id && !props.vct) {
      throw new Error('Missing id or vct code');
    }
    studyUrl.value = await store.dispatch('studies/getStudyUrl', {
      studyId: props.id,
      vctCode: props.vct
    });
  } finally {
    isLoading.value = false;
  }
});

const printIframe = () => {
  window.frames.iframeId.focus();
  window.frames.iframeId.print();
};

document.addEventListener('keydown', event => {
  if ((event.metaKey || event.ctrlKey) && event.key === 'p') {
    event.preventDefault();
    printIframe();
  }
});
</script>

<template>
  <div class="pb-4" :class="{ 'pt-4': deviceSize < DeviceSize.md }">
    <button :class="{ 'w-100': deviceSize < DeviceSize.md }" class="btn btn-sm sp-btn-secondary print-none" @click="goBack($router)">
      <font-awesome-icon class="me-1"
                         icon="long-arrow-left" />
      Back to studies list
    </button>
  </div>
  <div class="vh-100">
    <iframe v-if="!isLoading && studyUrl"
            id="iframeId"
            class="h-100 w-100 border rounded"
            name="iframeId"
            :src="studyUrl"></iframe>
    <div v-if="isLoading" class="text-center m-5">
      <span class="spinner-border" role="status" />
    </div>
  </div>
</template>

<style lang="scss">
</style>
