export class SubscriptionModel {
  constructor(currentFilter) {
    this.searchFilters = currentFilter.searchFilters || [];
    this.searchQuery = currentFilter.searchQuery || '';
    this.fullName = '';
    this.email = '';
    this.periodicity = 1; // 0: daily, 1: weekly, 2: monthly -> defined in periodicity enum in backend
    this.checkExtraSearchCriteria();
  }

  checkExtraSearchCriteria() {
    this.hasExtraCriteria = this.searchQuery || this.searchFilters.length > 0;
  }

  removeSearchQuery() {
    this.searchQuery = null;
    this.checkExtraSearchCriteria();
  }

  removeFilterFromSubscription(filter) {
    this.searchFilters = this.searchFilters.filter(x => x !== filter);
    this.checkExtraSearchCriteria();
  }

  mapForBackend() {
    return {
      full_name: this.fullName,
      email: this.email,
      periodicity: this.periodicity,
      search: this.searchQuery,
      search_filters: this.searchFilters.map(x => x.mapForBackend())
    };
  }
}
