<script setup>
// Like the default sorting options but without the active one
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { SortDirection, SortOptions } from '../../../../models';

const props = defineProps({
  sorting: {
    type: Object,
    required: false // Although not required, it should always be present when navBarMode = false!!
  },
  // Since we `useStore`now, in the future we could refactor to remove this because we can get it from the store
  locationFilterActive: {
    type: Boolean,
    required: false,
    default: false
  }
});
const store = useStore();
const defaultSortOptions = computed(() => store.state.studies.sortOptions);
const emits = defineEmits(['sortByUpdated']);

const locationSortOption = {
  label: 'Distance',
  key: SortOptions.location,
  direction: SortDirection.ascending
};

const sortOptions = computed(() => {
  if (props.locationFilterActive) {
    return [...defaultSortOptions.value, locationSortOption];
  }
  return defaultSortOptions.value;
});

const availableSortOptions = computed(() => sortOptions.value.filter(
  x => x.key !== props.sorting.sortBy ||
        x.direction !== props.sorting.direction
));

// Currently selected sorting option
const currentSortOption = computed(() => sortOptions.value.find(
  x => x.key === props.sorting.sortBy &&
        x.direction === props.sorting.direction
));

const changeSortBy = newOption => {
  emits('sortByUpdated', {
    sortBy: newOption.key,
    direction: newOption.direction
  });
};
const lowercaseFirstLetter = string => string.charAt(0).toLowerCase() + string.slice(1);

// When the user enters a location, we should automatically order by distance
watch(
  () => props.locationFilterActive,
  newValue => {
    if (newValue && props.sorting.sortBy !== SortOptions.location) {
      changeSortBy(locationSortOption);
    }
  },
  { immediate: true }
);

</script>

<template>
  <div class="dropdown-center h-100">
    <button class="btn custom-white dropdown-toggle h-100 w-100"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
      <div class="d-flex align-items-center justify-content-center">
        <div class="grey-400 me-1 body-14-light">
          {{ $t('studies.main.sort.title') }}
        </div>
        <div class="grey-700 body-14-medium">
          {{
            currentSortOption && $t(`studies.main.sort.${lowercaseFirstLetter(currentSortOption.label)}`, { fallback: currentSortOption.label })
          }}
        </div>
        <font-awesome-icon class="sort-icon ms-2" :icon="['fal', 'chevron-down']" />
      </div>
    </button>
    <ul class="dropdown-menu p-0 m-0 w-100">
      <li v-for="(item, index) in availableSortOptions"
          :key="index"
          class="dropdown-item cursor-pointer text-center body-14-medium"
          @click="changeSortBy(item)">
        {{ $t(`studies.main.sort.${lowercaseFirstLetter(item.label)}`, { fallback: item.label }) }}
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.dropdown-center {
  min-width: 192px;
  button.btn.custom-white.dropdown-toggle {
    --bs-btn-bg: white;
    --bs-btn-hover-bg: white;
    --bs-btn-focus-shadow-rgb: var(--light-shadow);
    --bs-btn-active-bg: white;
    --bs-btn-active-border-color: white;
    border-radius: 6px;

    &::after {
      content: none;
    }

    .sort-icon {
      transition: all 0.2s linear;
      color: var(--blue-deep);
      width: 17px;
      height: 24px;
    }

    &.show {
      border-radius: 6px 6px 0 0;
      box-shadow: var(--light-shadow);
      .sort-icon {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    border: none;
    min-width: 192px;
    border-radius: 0 0 6px 6px;
    box-shadow: var(--light-shadow);
    .dropdown-item {
      color: var(--grey-700);
      padding: 10px;
    }
  }
}
</style>
