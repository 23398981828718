<script setup>
import { computed, ref, watch } from 'vue';
import {
  FieldNames, FilterKind, General, SearchFilterModel
} from '../../models';

const props = defineProps({
  locationFilter: {
    type: SearchFilterModel,
    required: false
  }
});

const emits = defineEmits(['update']);
const distanceOptions = [10, 50, 100, 250, 500];
const defaultDistance = 100;
const zipCode = ref(null);
const zipCodeIsValid = computed(() => {
  if (!zipCode.value || zipCode.value.length !== 5) {
    return false;
  }
  // https://stackoverflow.com/questions/160550/zip-code-us-postal-code-validation
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode.value);
});
const submitZipCodeAndDistance = newDistance => {
  if (!zipCode.value) {
    emits('update', null);
    return;
  }

  if (!zipCodeIsValid.value) {
    return;
  }

  const newSearchFilter = new SearchFilterModel(
    FieldNames.locations,
    FilterKind.geoDistance,
    `${General.zipCodePrefix}${zipCode.value}${General.distanceSeparator}${newDistance}`
  );
  emits('update', newSearchFilter);
};
const distance = computed({
  get() {
    if (!props.locationFilter) {
      return defaultDistance;
    }
    // Extract distance from filter value (zip:zipcode_distance), example: zip:95817_50
    return props.locationFilter.filterValue.split(General.distanceSeparator)[1];
  },
  set(newValue) {
    submitZipCodeAndDistance(newValue);
  }
});


watch(() => props.locationFilter, newValue => {
  // Extract zipcode from filter value (zip:zipcode_distance), example: zip:95817_50
  zipCode.value = newValue ?
    props.locationFilter.filterValue.split(General.distanceSeparator)[0].replace(General.zipCodePrefix, '') :
    null;
});
</script>
<template>
  <div class="d-flex mt-2">
    <input v-model="zipCode"
           class="form-control"
           type="text"
           placeholder="Zipcode"
           @keyup.enter="submitZipCodeAndDistance(distance)" />
    <select v-model="distance" :disabled="!zipCodeIsValid" class="form-select mx-2 ">
      <option v-for="distanceOption in distanceOptions" :key="distanceOption" :value="distanceOption">
        {{ distanceOption }}{{ General.distanceMetric }}
      </option>
    </select>
    <div>
      <button :disabled="!zipCodeIsValid" class="btn search-location-btn" @click="submitZipCodeAndDistance(distance)">
        <font-awesome-icon :icon="['fas', 'search']" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
select.form-select {
  max-width: 100px;
}
input.form-control {
  max-width: 100px;
}

.search-location-btn {
  &:not([disabled]) {
    &:hover {
      color: var(--blue-bright);
      font-weight: 700;
    }
  }
}
</style>
