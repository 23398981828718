<template>
  <div class="loc-browsable-section">
    <H4Title v-if="!compactMode">
      {{ searchCategory.label }}
    </H4Title>
    <H5Title v-if="compactMode">
      {{ searchCategory.label }}
    </H5Title>
    <div :id="`search-cat-item-wrapper_${searchCategory.label}`" :class="[orderBy === 'row' ? 'row' : 'column-count']">
      <div v-for="child in searchCategory.children"
           :key="child.id"
           class="my-1"
           :class="{ 'col-md-4': orderBy === 'row' }">
        <BrowsableSectionItem :level="0"
                              :show-counts="showCounts"
                              :search-category="child"
                              @item-clicked="itemClicked($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import { dom } from '@fortawesome/fontawesome-svg-core';
import BrowsableSectionItem from './BrowsableSectionItem.vue';
import { AggregatedGscModel } from '../../models';
import { H4Title, H5Title } from '../general/titles';

export default {
  components: {
    H5Title,
    H4Title,
    BrowsableSectionItem
  },
  props: {
    searchCategory: {
      type: AggregatedGscModel,
      required: true
    },
    showCounts: {
      type: Boolean,
      required: false,
      default: true
    },
    orderBy: {
      type: String,
      required: false,
      default: 'row'
    },
    compactMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['itemClicked'],
  async mounted() {
    const domIdToSearch = `search-cat-item-wrapper_${this.searchCategory.label}`;
    // Automagically transform <i> elements into Fontawesome svg's where possible
    // + limit scope of searching <i> elements to this template div
    await dom.i2svg({ node: document.getElementById(domIdToSearch) });
  },
  methods: {
    itemClicked(mapped) {
      this.$emit('itemClicked', mapped);
    }
  }
};
</script>
<style lang="scss" scoped>
.column-count{
  column-count: 3;
}
@media (max-width: 767px) {
  .column-count {
    column-count: 1;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .column-count {
    column-count: 2;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .column-count {
    column-count: 3;
  }
}


.list-none {
  list-style-type: none;
}


</style>
