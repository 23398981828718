<template>
  <i class="loading-default"></i>
</template>

<script>

export default {
  name: 'Spinner'
};
</script>

<style lang="scss" scoped>
@keyframes loading-rotating {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-default {
  position: relative;
  border: 1px solid #999;
  animation: loading-rotating ease 1.5s infinite;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    margin-top: -3px;
    margin-left: -3px;
    width: 6px;
    height: 6px;
    background-color: #999;
    border-radius: 50%;
  }
}
</style>
