<template>
  <div class="sp-switch form-check form-switch">
    <input :id="fieldName"
           :disabled="!locationSupported || locationErrorMessage || retrievingLocation"
           :checked="locationFilter"
           class="form-check-input"
           type="checkbox"
           @change="onLocationToggled" />
    <label :for="fieldName" class="form-check-label pe-3">{{ $t('studies.main.geoFilter.showNearMe') }}</label>
  </div>
  <template v-if="locationErrorMessage && !retrievingLocation">
    <div>
      {{ $t('studies.main.geoFilter.couldNotRetrieve') }}:
    </div>
    <div>
      {{ locationErrorMessage }}
    </div>
  </template>
  <template v-if="!locationErrorMessage">
    <div v-if="!locationFilter && retrievingLocation">
      {{ $t('studies.main.geoFilter.retrievingLocation') }}
    </div>
    <div v-if="locationFilter && !retrievingLocation" class="mt-2">
      <select v-model="distanceModel" class="form-select">
        <option v-for="distance in distanceOptions" :key="distance" :value="distance">
          {{ distance }}{{ distanceMetric }}
        </option>
      </select>
    </div>
  </template>
</template>

<script>
import {
  FieldNames, FilterKind, General, SearchFilterModel
} from '../../models';

export default {
  props: {
    locationFilter: {
      type: SearchFilterModel,
      required: false
    }
  },
  emits: ['update'],
  data() {
    return {
      retrievingLocation: false,
      locationSupported: 'geolocation' in navigator,
      locationErrorMessage: null,
      fieldName: FieldNames.locations,
      distanceOptions: [10, 50, 100, 250, 500],
      defaultDistance: 100,
      distanceMetric: General.distanceMetric
    };
  },
  computed: {
    distanceModel: {
      get() {
        if (!this.locationFilter) {
          return null;
        }
        // Extract distance from filter value (lat_long_distance), example: 234.45_23.33_100mi
        return this.locationFilter.filterValue.split(General.distanceSeparator)[2];
      },
      async set(newValue) {
        await this.distanceChanged(newValue);
      }
    }
  },
  methods: {
    async onLocationToggled() {
      if (this.locationFilter) {
        // Remove location search filter if already active
        this.emitUpdate(null);
      } else {
        // When toggled, use the default distance
        await this.distanceChanged(this.defaultDistance);
      }
    },
    async distanceChanged(newDistance) {
      const coords = await this.getCurrentLocationCoords();
      if (!coords) {
        return;
      }

      const separator = General.distanceSeparator;
      this.emitUpdate(new SearchFilterModel(
        FieldNames.locations,
        FilterKind.geoDistance,
        `${coords.latitude}${separator}${coords.longitude}${separator}${newDistance}`
      ));
    },
    async getCurrentLocationCoords() {
      if (!this.locationSupported) {
        return null;
      }
      this.retrievingLocation = true;
      let coords = null;
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        coords = position.coords;
      } catch (e) {
        this.locationErrorMessage = e.message;
      } finally {
        this.retrievingLocation = false;
      }
      return coords;
    },
    emitUpdate(newSearchFilter) {
      this.$emit('update', newSearchFilter);
    }
  }
};
</script>
<style lang="scss" scoped>
select.form-select {
  max-width: 200px;
}
</style>
